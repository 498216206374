import React, { useState } from 'react'
import { buy_btn, DEMO_IMAGE_EIGHT, dummy, live_calender, live_clock, no_live, playBtn } from '../../Constants/images'
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../Helper/route-helper';
import PriceTag from '../../CreaterDashboard/component/price-tag';
import BuyModal from './buyModal';

function ShopLives({ setTabSwitch, loading, detailList, setLoading, getListAPI, check, }) {
    const navigate = useNavigate()
    const [buyModal, setBuyModal] = useState(false)
    const [item, setItem] = useState({})

    const dateFunction = (date) => {
        const parsedDate = new Date(date);
        if (isNaN(parsedDate.getTime())) {
            return "";
        }
        const formattedDate = parsedDate.toLocaleDateString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric'
        }).replace(/\//g, '-');
        return formattedDate;
    }

    function addTime(originalDateTimeString) {
        let originalDateTime = new Date(originalDateTimeString);
        // originalDateTime.setHours(originalDateTime.getHours() + 5);
        // originalDateTime.setMinutes(originalDateTime.getMinutes() + 30);
        let modifiedTime = originalDateTime.toLocaleString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        });
        return modifiedTime;
    }

    const handleClick = (item) => {
        localStorage.setItem("item", JSON.stringify(item))
        if (check == "shop") {
            navigate(ROUTES.USER_RECORDED_VIDEO_SHOP)
        }
        else if (check == "download"
        ) {
            navigate(ROUTES.USER_RECORDED_VIDEO_DOWNLOAD)
        }
    }

    const handleOpen = (item) => {
        setItem(item)
        setBuyModal(true)
    }

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="stream-btn pt-3 pb-4">
                        <button className="common-btn btn-white left-corner" onClick={() => {
                            setTabSwitch(0)
                        }}>Series</button>
                        <button className="common-btn btn-blue left-corner" onClick={() => setTabSwitch(1)}>Recorded Lives</button>
                        <button className="common-btn btn-white left-corner" onClick={() => setTabSwitch(2)}>Upcoming Lives</button>
                        <button className="common-btn btn-white left-corner" onClick={() => setTabSwitch(3)}> Images</button>
                        <button className="common-btn btn-white left-corner" onClick={() => setTabSwitch(4)}>Audios</button>
                        <button className="common-btn btn-white left-corner" onClick={() => setTabSwitch(5)}>Videos</button>
                    </div>
                    <div className='row mt-4'>
                        {detailList?.length > 0 ? (
                            <>
                                {check === "download" ? (
                                    detailList?.filter(item => item.is_purchase === 1).length > 0 ? (
                                        detailList?.filter(item => item.is_purchase === 1).map(item => (
                                            <div className="col-12 col-sm-6 col-lg-4 col-xl-4 col-xxl-3 pb-4 custom-cols" key={item?.id}>
                                                <div className="latest-video mx-auto">
                                                    <div className='position-relative'>
                                                        <div className="latest-video-content pt-2 pt-sm-3 px-2 px-sm-3" style={{ cursor: "pointer" }}>
                                                            <img src={item.thumbnail} className="img-fluid latest-vid-img cus-latest-vid-img" alt="thumbnail" />
                                                        </div>
                                                        <div className="d-flex justify-content-start align-items-center gap-2 lives-logo" onClick={() => navigate(ROUTES.CHANNELS_DETAILS + "?cd=liveChannel", { state: { item: item } })} style={{ cursor: "pointer" }}>
                                                            <img src={item.channelIcon} className="img-fluid grid-data-img " alt="" style={{ width: '30px', height: '30px', borderRadius: '100%', border: '1px solid #CA3392' }} />
                                                            <h6 className="fw-500 mb-0 text-white" style={{ fontSize: '16px' }}>{item.channelName}</h6>
                                                        </div>
                                                    </div>
                                                    <div className="latest-video-cus px-3 py-3 schedule-grid mb-2">
                                                        <div className=''>
                                                            <h4 className="schedule-title mb-0">{item.name?.length > 25 ? item.name.substring(0, 25) + "..." : item.name}</h4>                                                            <div className="timing-flex">
                                                                <div className='mb-0 timing-inner-flex'>
                                                                    <img src={live_calender} alt="calendar" />
                                                                    <span className='ps-1'>{dateFunction(item.start_time)}</span>
                                                                </div>
                                                                <div className='mb-0 timing-inner-flex'>
                                                                    <img src={live_clock} alt="clock" />
                                                                    <span className='ps-1'>{addTime(item.start_time)}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='dotschedule bg-transparent'>
                                                            <div className='dropdownschedule'>
                                                                <span style={{ cursor: "pointer" }} onClick={() => handleClick(item)} className="text-end">
                                                                    <img src={playBtn} alt='buy-btn' style={{ width: '50px', height: '50px' }} />                                                            </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <div style={{ flexDirection: 'column' }} className='no-search-results'>
                                            <img src={DEMO_IMAGE_EIGHT} alt="No Challenges" />
                                            <h6 className='position-absolute' style={{ bottom: 15 }}>No lives yet</h6>                                           </div>
                                    )
                                ) : (
                                    detailList?.map(item => (
                                        <div className="col-12 col-sm-6 col-lg-4 col-xl-4 col-xxl-3 pb-4 custom-cols" key={item?.id}>
                                            <div className="latest-video mx-auto">
                                                <div className='position-relative'>
                                                    <div className="latest-video-content pt-2 pt-sm-3 px-2 px-sm-3" style={{ cursor: "pointer" }}>
                                                    <div className='position-relative'>
                                                        <img src={item.thumbnail} className="img-fluid latest-vid-img cus-latest-vid-img" alt="thumbnail" />
                                                        {item?.is_purchase == 0 && <PriceTag classes={"price-episodes-label"} firstValue={`$${item.price}`} secondValue={"00"} />}
                                                        </div>
                                                    </div>
                                                    <div className="d-flex justify-content-start align-items-center gap-2 lives-logo" onClick={() => navigate(ROUTES.CHANNELS_DETAILS + "?cd=liveChannel", { state: { item: item } })} style={{ cursor: "pointer" }}>
                                                        <img src={item.channelIcon} className="img-fluid grid-data-img " alt="" style={{ width: '30px', height: '30px', borderRadius: '100%', border: '1px solid #CA3392' }} />
                                                        <h6 className="fw-500 mb-0 text-white" style={{ fontSize: '16px' }}>{item.channelName}</h6>
                                                    </div>
                                                </div>
                                                <div className="latest-video-cus px-3 py-3 schedule-grid mb-2">
                                                    <div className='d-flex justify-content-between flex-column'>
                                                        <div className="tooltip-container">
                                                            <h4 className="schedule-title mb-0">{item.name?.length > 25 ? item.name.substring(0, 25) + "..." : item.name}</h4>
                                                            <span className="tooltip-text">{item.name}</span>
                                                        </div>


                                                        <div className="timing-flex">
                                                            <div className='mb-0 timing-inner-flex'>
                                                                <img src={live_calender} alt="calendar" />
                                                                <span className='ps-1'>{dateFunction(item.start_time)}</span>
                                                            </div>
                                                            <div className='mb-0 timing-inner-flex'>
                                                                <img src={live_clock} alt="clock" />
                                                                <span className='ps-1'>{addTime(item.start_time)}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='dotschedule bg-transparent'>
                                                        <div className='dropdownschedule'>
                                                            {item.is_purchase == 0 ? (
                                                                <span onClick={() => handleOpen(item)}>
                                                                    <img src={buy_btn} alt='buy-btn' style={{ width: '50px', height: '50px' }} />
                                                                </span>
                                                            ) : (
                                                                <span style={{ cursor: "pointer" }} onClick={() => handleClick(item)} className="text-end">
                                                                    <img src={playBtn} alt='buy-btn' style={{ width: '50px', height: '50px' }} />                                                            </span>

                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                )}
                            </>
                        ) : (
                            <>
                                {loading === false && (
                                    <div style={{ flexDirection: 'column' }} className='no-search-results'>
                                        <img src={DEMO_IMAGE_EIGHT} alt="No Challenges" />
                                        <h6 className='position-absolute' style={{ bottom: 15 }}>No lives yet</h6>                                       </div>
                                )}
                            </>
                        )}
                        {buyModal && <BuyModal item={item} setLoading={setLoading} getListAPI={getListAPI} setBuyModal={setBuyModal} check="live" />}
                    </div>
                </div>
            </div>

        </>
    )
}

export default ShopLives