import React, { useEffect, useState } from 'react';
import { Arrow_channel, buy_btn, DEMO_IMAGE_THREE, live_icon, no_live, playBtn } from '../../Constants/images';
import BuyModalLive from './buyLive';
import PriceTag from '../../CreaterDashboard/component/price-tag';
import ROUTES from '../../Helper/route-helper';
import { useNavigate } from 'react-router-dom';

function UserLivesSection({ lives, isLoading, handleClick, getApi, getrecordingApi, setIsLoading, selectedOption, setSelectedOption }) {
    const [activeTab, setActiveTab] = useState(localStorage.getItem("activeTab") || "0")
    const [buyModal, setBuyModal] = useState(false);
    const [item, setItem] = useState(null);
    const navigate = useNavigate()

    useEffect(() => {
        if (activeTab === "0") {
            getApi();
        } else {
            getrecordingApi();
        }
    }, [activeTab])

    const handleTabClick = (tabIndex) => {
        setActiveTab(tabIndex.toString());
        localStorage.setItem("activeTab", tabIndex.toString());
    }

    const options = [
        { value: '0', label: 'All' },
        { value: '1', label: 'Free' },
        { value: '2', label: 'Paid' },
    ];

    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleOptionClick = (option) => {
        setSelectedOption(option);
        setIsOpen(false);
    };
    console.log(selectedOption, "Select")

    return (
        <div>
            <section className="schedule-wrapper">
                <div className="row align-items-center">
                    <div className='col-lg-9'>
                        <div className="stream-btn pt-3">
                            <button onClick={() => handleTabClick(0)} className={`common-btn btn-blue ${activeTab === "0" ? "active" : ""}`}>Lives</button>
                            <button onClick={() => handleTabClick(2)} className={`common-btn btn-white ${activeTab === "2" ? "active" : ""}`}>Upcoming Lives</button>
                            <button onClick={() => handleTabClick(1)} className={`common-btn btn-white ${activeTab === "1" ? "active" : ""}`}>Recorded Lives</button>
                        </div>
                    </div>

                    <div className='col-lg-3 ms-auto d-flex justify-content-end  mt-3 mt-lg-0' onClick={toggleDropdown} style={{ cursor: "pointer" }}>
                        <div className="custom-select">
                            <div className="selected-option" >
                                {selectedOption ? selectedOption.label : 'Select an option'}
                            </div>
                            {isOpen && (
                                <div className="options">
                                    {options.map((option) => (
                                        <div
                                            key={option.value}
                                            onClick={() => handleOptionClick(option)}
                                            className={`option ${selectedOption && selectedOption.value === option.value ? 'active-option' : ''}`}

                                        >
                                            {option.label}
                                        </div>
                                    ))}
                                </div>
                            )}
                            <div className='arrow-down'>
                                <img src={Arrow_channel} />
                            </div>


                        </div>
                    </div>
                </div>
                <div className='row mt-4'>
                    {
                        lives?.map((item) => (
                            <div className="col-12 col-sm-6 col-lg-4 col-xl-4 col-xxl-3 pb-4">
                                <div className="latest-video mx-auto"
                                >
                                    <div className='position-relative'>
                                        <div className="latest-video-content position-relative pt-2 pt-sm-3 px-2 px-sm-3">
                                            <div className="position-relative">
                                                <img src={item.schedule.thumbnail} className="img-fluid latest-vid-img cus-latest-vid-img" alt="thumbnail" />
                                                {(item?.schedule.is_purchase == 0 && item.schedule.price != 0) && <PriceTag classes={"price-episodes-label"} firstValue={`$${item.schedule.price}`} secondValue={"00"} />}
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-start align-items-center gap-2 lives-logo" style={{ cursor: "pointer" }} onClick={() => navigate(ROUTES.CHANNELS_DETAILS + "?cd=favChannel", { state: { item: item } })}>
                                            <img src={item.profile_pic} className="img-fluid grid-data-img " alt="" style={{ width: '30px', height: '30px', borderRadius: '100%', border: '1px solid #CA3392' }} />
                                            <h6 className="fw-500 mb-0 text-white" style={{ fontSize: '16px' }}>{item.name}</h6>
                                        </div>
                                        <div className='live-img-btn'>
                                            <p className='f16-size mb-0 fw-600 d-flex align-items-center'>
                                                <img src={live_icon} className='me-2' />Live</p>
                                        </div>
                                    </div>

                                    <div className="latest-video-cus px-3 py-3 schedule-grid">
                                        <div class="grid-data-detail-lives position-relative">
                                            <div className="tooltip-container">
                                                <h6 className='fw-600 f20-size channel-text text-capitalize  me-2 mb-3'>
                                                    {item.schedule.name.length > 20 ? item.schedule.name.substring(0, 20) + ".." : item.schedule.name}

                                                </h6>
                                                <span className="tooltip-text">{item.schedule.name}</span>

                                            </div>
                                            <div className='d-flex align-items-center my-3'>
                                                <div className='live-img-btn-below '>
                                                    <p className='f16-size mb-0 fw-600 d-flex align-items-center'>
                                                        <img src={live_icon} className='me-2' />Live</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='dotschedule bg-transparent mb-1'>
                                            <div className='dropdownschedule'>
                                                {
                                                    (item.schedule.is_purchase == 0 && item.schedule.price != 0) ? <span style={{ cursor: "pointer" }} className="text-end">
                                                        <img src={buy_btn} alt='buy-btn' style={{ width: '50px', height: '50px' }} onClick={() => {
                                                            setBuyModal(true)
                                                            setItem(item)
                                                        }} />                                                   </span>
                                                        :
                                                        <span style={{ cursor: "pointer" }} className="text-end" onClick={() => handleClick(item)}
                                                        >
                                                            <img src={playBtn} alt='buy-btn' style={{ width: '50px', height: '50px' }} />                                                        </span>


                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                    {
                        (isLoading == false && lives?.length == 0) && <div style={{ flexDirection: 'column' }} className='no-search-results'>
                            <img src={DEMO_IMAGE_THREE} alt="No Challenges" />
                            <h6 className='position-absolute' style={{ top: 571 }}>No lives yet</h6>                            </div>
                    }
                </div>

            </section >
            {
                buyModal && <BuyModalLive setBuyModal={setBuyModal} getApi={getApi} setIsLoading={setIsLoading} item={item} check="live" />
            }
        </div >
    );
}

export default UserLivesSection;
