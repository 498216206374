import React from 'react'
import { copyLink, facebook, facebookImg, instaImg, linkdenImg, modalCross, pinterest, shareFreinds, twitter, twitterImg ,Whatsapp_bg} from '../../Constants/images'
import { NotificationManager } from 'react-notifications'

const ShareModalSeries = ({ setShareModal,id}) => {

    const baseUrl = `${window.location.protocol}//${window.location.host}`;
    const contentUrl = `${baseUrl}/series-list/series-detail/${id}`

    // const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(contentUrl)}`;
    // const handleClick = ()=>{
    //     navigator.clipboard.writeText(contentUrl).then(() => {
    //         alert('Video link copied! Now go to Instagram and paste the link in a DM.');
    //         // Open Instagram Direct Message page
    //         window.open('https://www.instagram.com/direct/new/', '_blank');
    //       }).catch((err) => {
    //         console.error('Failed to copy the video link: ', err);
    //       });
    // }
    const whatsappShareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(contentUrl)}`;

    const handleCopyLink = () => {
        navigator.clipboard.writeText(contentUrl).then(() => {
            NotificationManager.success("Link copied!")
        });
    };

    return (
        <div className="modal fade show modal-cast" style={{ display: "block", opacity: "1", backdropFilter: "blur(5px)" }}>
            <div className='loader'>
            </div>
            <div className="modal-dialog modal-dialog-centered modal-schedule-width justify-content-center"  style={{cursor:'pointer'}}>
                <div className="modal-content modal-radius  border-0">
                    <div className="modal-header forgot-header align-items-center pt-4">
                        <h3 className="modal-title text-center mx-auto forgot-title" id="exampleModalLabel">Share With Friends</h3>
                        <a className="m-0 px-0 btn-closed-main" data-bs-dismiss="modal" aria-label="Close"><img style={{ cursor: "pointer" }} onClick={() => {
                            setShareModal(false)
                        }}
                            src={modalCross} alt="" className="img-fluid btn-cloesed" /></a>
                    </div>
                    <div className="modal-body  verify-modal-box px-2 px-sm-2">
                        <div className='share-friend-img'><img src={shareFreinds}></img></div>
                        <div className='main-icon'>
                            {/* <a 
                            href={facebookShareUrl}
                             className='social-share-icon mt-2 mt-md-0'><img src={facebookImg} className='ml-3 ml-md-0 icon-img'></img>
                                <div className='icon-text'>facebook</div>
                            </a> */}
                            {/* <a
                             onClick={handleClick}
                              className='social-share-icons mt-2 mt-md-0'><img src={instaImg} className='icon-img'></img>
                                <div className='icon-text'>Instagram</div>
                            </a> */}
                            <a
                             href={whatsappShareUrl} 
                             className='social-share-icons mt-2 mt-md-0'><img src={Whatsapp_bg} className='icon-img'></img>
                                <div className='icon-text'>Whatsapp</div>
                            </a>

                            <a onClick={handleCopyLink} className='social-share-icons mt-2 mt-md-0'><img src={copyLink} className='icon-img'></img>
                                <div className='icon-text'>Copy Link</div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ShareModalSeries
