import React, { useRef, useState } from 'react'
import { gallery, modalCross, plusgray } from '../../Constants/images'
import uploadToS3 from '../component/fileUpload'
import ProgressBarComponent from '../../ControlComponents/progressBar'
import ERR_MESSAGE from '../../Helper/error-helper'
import DEVELOPMENT_CONFIG from "../../../src/Helper/config"
import apiHelper from '../../Helper/api-helper'
import { NotificationManager } from 'react-notifications'
import { useNavigate } from 'react-router-dom'
import ROUTES from '../../Helper/route-helper'

const LiveStartModal = ({ setLiveStartModal, setIsLoading, }) => {
    let chatId = "1"
    const [progressLoading, setProgressLoading] = useState(false)
    const [open, setOpen] = useState(false)
    const [errors, setErrors] = useState("")
    const [progress, setProgress] = useState(0);
    const [thumbnail, setThumbnail] = useState("")
    const navigate = useNavigate()
    const [selectedOption, setSelectedOption] = useState(2);
    const [fields, setFields] = useState({
        title: "",
        description: "",
        price: ""
    });

    const handleOptionChange = (event) => {
        setSelectedOption(Number(event.target.value));
    }

    const handleChange = (e) => {
        setErrors("")
        setFields({
            ...fields,
            [e.target.name]: e.target.value
        })
    }

    const fileChange = (e) => {
        setErrors("")
        if (e.target.files.length === 0) {
            return;
        }
        const file = e.target.files[0];
        if (file) {
            if (file.type === 'image/jpeg' || file.type === 'image/svg+xml' || file.type === 'image/png' || file.type === 'image/jpg') {
                const formData = new FormData();
                formData.append("image", file);
                fileUpload(file)
            }
        }
    };

    const fileUpload = async (file, thumb) => {
        const state = { videoLocation: null, thumbnailLocation: null };
        setProgressLoading(true)
        try {
            if (file) {
                await uploadToS3(thumb, file, state, setProgress);
                if (state.videoLocation) {
                    setProgressLoading(false)
                    setThumbnail(state.videoLocation)
                }
            }
        } catch (error) {
        }
    }

    const titleRef = useRef(null);
    const thumbnailRef = useRef(null);
    const descriptionRef = useRef(null);
    const priceRef = useRef(null);

    const handleValidation = () => {
        let errors = {};
        let formIsValid = true;
        const { title, description, price } = fields;
        if (!thumbnail) {
            formIsValid = false;
            errors["thumbnail"] = ERR_MESSAGE.THUMBNAIL_EMPTY
        }
        if (!title || title.trim().length === 0) {
            formIsValid = false;
            errors["title"] = ERR_MESSAGE.TITLE_EMPTY
        }
        if (!description) {
            formIsValid = false;
            errors["description"] = ERR_MESSAGE.DESCRIPTION_EMPTY
        }
        if (selectedOption == "2") {
            if (!price) {
                formIsValid = false;
                errors["price"] = ERR_MESSAGE.EMPTY_PRICE
            }
            else if (price == 0) {
                formIsValid = false;
                errors["price"] = ERR_MESSAGE.PRICE_LIMIT
            }
        }
        const firstErrorField = Object.keys(errors)[0];
        const firstErrorRef =
            firstErrorField === 'thumbnail' ? thumbnailRef :
                firstErrorField === 'title' ? titleRef :
                    firstErrorField === 'description' ? descriptionRef :
                        firstErrorField === 'price' ? priceRef :
                            null;

        if (firstErrorRef && firstErrorRef.current) {
            firstErrorRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
        setErrors(errors);
        return formIsValid;
    }

    async function handleSubmit(e) {
        e.preventDefault();
        const currentDate = new Date();
        const formattedDateTime =
            currentDate.getFullYear() +
            '-' +
            ('0' + (currentDate.getMonth() + 1)).slice(-2) +
            '-' +
            ('0' + currentDate.getDate()).slice(-2) +
            ' ' +
            ('0' + currentDate.getHours()).slice(-2) +
            ':' +
            ('0' + currentDate.getMinutes()).slice(-2) +
            ':' +
            ('0' + currentDate.getSeconds()).slice(-2);

        if (handleValidation()) {
            let data = {
                "thumbnail": thumbnail,
                "name": fields.title,
                "date_time": formattedDateTime,
                "description": fields.description,
                "status": 1
            };
            if (selectedOption == "2") {
                data.price = parseInt(fields?.price?.slice(0, 3))
            }
            setIsLoading(true);
            try {
                let result = await apiHelper.postService("creator/create-schedules", data);
                if (result.code === DEVELOPMENT_CONFIG.createStatusCode) {
                    setIsLoading(false);
                    setLiveStartModal(false);
                    const trimmedName = result?.data?.name?.replace(/\s/g, '');
                    const chatId = `${result?.data?.id}${trimmedName}`;
                    localStorage.setItem("chatId", chatId);
                    localStorage.setItem("dateTime", formattedDateTime);
                    const id = result.data.id;
                    navigate(ROUTES.CREATER_START_LIVE, { state: { id } });
                } else {
                    setIsLoading(false);
                    NotificationManager.error(result.message);
                }
            } catch (error) {
                setIsLoading(false);
                NotificationManager.error("An error occurred while creating the schedule.");
            }
        }
    }

    return (
        <div className="modal fade show modal-react" style={{ display: "block", opacity: "1", backdropFilter: "blur(5px)" }} ref={thumbnailRef}>
            <div className='loader'>
            </div>
            {progressLoading && <>
                <ProgressBarComponent progress={progress} />
            </>}
            <div className="modal-dialog modal-dialog-centered modal-schedule-width">
                <div className="modal-content modal-radius  border-0">
                    <div className="modal-header forgot-header align-items-center pt-4">
                        <h3 className="modal-title text-center mx-auto forgot-title" id="exampleModalLabel" >Start live</h3>
                        <a className="m-0 px-0 btn-closed-main" data-bs-dismiss="modal" aria-label="Close"><img onClick={() => setLiveStartModal(false)} style={{ cursor: "pointer" }} src={modalCross} alt="" className="img-fluid btn-cloesed" /></a>
                    </div>
                    <div className="modal-body  verify-modal-box px-2 px-sm-5">
                        <form>
                            <div className='schedule-height'>
                                <label className='fw-500 f18-size text-black pb-2' ref={titleRef}>Thumbnail</label>
                                <div className="mb-3 schedule-thumb">
                                    {/* <div className="video-upload video-select position-relative">
                    <video id='video' controls ></video>
                    <img src={videoPlayButton} alt="" className="img-fluid playbtn-image" />
                  </div>  */}
                                    <div className="video-upload video-schedule position-relative">
                                        {
                                            thumbnail && <div className={thumbnail ? " episode-preview" : ""}><img src={thumbnail ? thumbnail : ""}></img></div>
                                        }
                                        {
                                            thumbnail ? "" : <div className='d-flex m-auto justify-content-center align-items-center h-100 episode-preview-after'>
                                                <div className=''>
                                                    <div className='text-center'>
                                                        <img src={gallery}></img>
                                                    </div>
                                                    <div className="d-flex m-auto justify-content-center align-items-center h-100">
                                                        <img src={plusgray} alt="" className="img-fluid me-1" />
                                                        <span className="Upload-text fw-500 create-text border-bottom ">Add Thumbnail</span>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        <input type="file" style={{ cursor: "pointer" }} onChange={fileChange} accept=".jpg, .jpeg, .png, .svg" className="video-file-schedule" />
                                        {errors.thumbnail && <span className="err_msg">{errors.thumbnail}</span>}

                                    </div>
                                </div>
                                <div className='mb-3' ref={descriptionRef}>
                                    <label  className='fw-500 f18-size text-black pb-2'>Title</label>
                                    <input type='text' placeholder='Enter here' value={fields.title} name='title' onChange={handleChange} className='border-edit  w-100'></input>
                                    {errors.title && <span className="err_msg">{errors.title}</span>}
                                </div>
                                <div className='mb-3' ref={priceRef}>
                                    <label  className='fw-500 f18-size text-black pb-2'>Description</label>
                                    <textarea class=" text-summary place-grey d-block w-100" value={fields.description} onChange={handleChange} placeholder="Enter Here" rows="6" name="description"></textarea>
                                    {errors.description && <span className="err_msg">{errors.description}</span>}
                                </div>

                                <div className='creat-label pb-3' >
                                    <label>
                                        <input
                                            type="radio"
                                            value="2"
                                            checked={selectedOption === 2}
                                            onChange={handleOptionChange}
                                            className='radio-login'
                                        />
                                        <span>Paid</span>
                                    </label>
                                    <label className='ms-3'>
                                        <input
                                            type="radio"
                                            value="1"
                                            checked={selectedOption === 1}
                                            onChange={handleOptionChange}
                                            className='radio-login'
                                        />
                                        <span>Free</span>
                                    </label>
                                </div>
                                {
                                    selectedOption == "2" && <div className='mb-3'>
                                        <label className='fw-500 f18-size text-black pb-2'>Price</label>
                                        <input type='text' placeholder='Enter here' value={fields?.price?.slice(0, 3)}
                                            name='price' onChange={handleChange} className='border-edit  w-100'></input>
                                        {errors.price && <span className="err_msg">{errors.price}</span>}
                                    </div>
                                }
                            </div>
                            <div className="my-4" onClick={handleSubmit}>
                                <input type='submit' value="Start Live" className="common-btn w-100" />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LiveStartModal
