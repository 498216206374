import React from 'react'
import { Link } from 'react-router-dom'
import { download, landingBanner, landingBannernew } from '../../../../Constants/images'
import './banner.css'



const Banner = () => {
   return (
      <>
         <section className="banner-wraper py-100">
            <div className="row">
               <div className="banner-conent banner-text  d-flex align-items-center justify-content-center ">
                  <div
                     className="banner-wrapper-text"
                  >
                     <h1 className='hero-text'>
                        <span className="pink-text font-120">D</span>iscover the Magic of
                        Movies <span className="pink-text font-120">And</span> Episodes
                     </h1>
                     <h3 className="my-5 fw-400 greybanner">
                        Get Lost in Unforgettable Stories
                     </h3>
                     <div className="d-flex gap-3 flex-wrap">
                        <Link to="" className="common-btn btn-blue banner-join">Join Play Human</Link>
                        <a href="/" className="common-btn app-btn"
                        ><img
                              src={download}
                              className="down-img mr-1"
                              alt=""
                           />
                           Get the app</a>
                     </div>
                  </div>
               </div>
               <div className="banner-conent">
                  <img
                     src={landingBanner}
                     className="banner-img"
                     alt=""
                  />
                  <img
                     src={landingBannernew}
                     className="banner-img-new"
                     alt=""
                  />
               </div>
            </div>
         </section>
      </>
   )
}

export default Banner