import React from 'react'
import Header1 from '../../Components/Header1/Header1'
import Footer from '../../Components/Footer/Footer'
import ContactInformation from './Components/ContactInformation'
import { ContactUsBack } from '../../Constants/images'
import './contactUs.css'


function ContactUs() {
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className='contact-back-banner' style={{backgroundImage:`url(${ContactUsBack})`}}>
        <Header1/>
        <ContactInformation/>
        <Footer/>
      </div>
  )
}

export default ContactUs