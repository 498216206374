import React, { useEffect, useState } from 'react';
import apiHelper from '../../Helper/api-helper';
import DEVELOPMENT_CONFIG from '../../Helper/config';
import './bucket.css';
import ReactLoader from '../../ControlComponents/react-loader'
import BucketSeries from './bucketSeries';
import Subscribers from './subscribers';
import EditBucketModal from './editBucketModal';


const BucketDetail = () => {
    const [tabSwitch, setTabSwitch] = useState(0);
    const [favChannels, setFavChannels] = useState([])
    const [favSeries, setFavSeries] = useState([])
    const [refresh, setRefresh] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [bucketModal, setBucketModal] = useState(false)

    const handleOpen = () => {
        setBucketModal(!bucketModal)
    }

    async function getDetailsAPI() {
        setIsLoading(true)
        let result = await apiHelper.getRequest("channel/get-favorites-list?type=" + tabSwitch)
        if (result.code == DEVELOPMENT_CONFIG.statusCode) {
            if (tabSwitch == 0) {
                setFavSeries(result.data)
            } else {
                setFavChannels(result.data)
            }
            setIsLoading(false)
        }
        else if (result.code == 401) {
            let refreshToken = localStorage.getItem("refresh-token")
            let data = {
                "refresh_token": refreshToken,
                "scope": "refresh_token"
            }
            let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
            if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
                localStorage.setItem("token", refreshTokenResult.data.token)
                localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
                setRefresh(true)
            }
        }
    }

    useEffect(() => {
        getDetailsAPI()
    }, [tabSwitch, refresh])
    return (
        <div>
            {isLoading && <ReactLoader />}
            <div className="main-inner-content">
            <div className="bucket-inner p-3">
                <div className='row align-items-center'>
                    <div className='col-sm-5 ps-4'>
                        <div className="">
                            <h3 className="fw-600">Buckets</h3>
                        </div>
                    </div>
                    <div className='col-sm-7 ms-auto text-end d-flex justify-content-end live-start-btn'>
                        <button className="common-btn" onClick={handleOpen}> Edit</button>
                    </div>
                </div>
                <div className='row align-items-center mt-3'>
                    <div className='col-sm-5 ps-4'>
                        <div className="getaccess_text">
                            <h2 className="fw-600 channel-text">Get Access</h2>
                            <div class="d-flex align-items-center  gap-3 bucket-btn-month pt-2">
                                <p class="text-pink mb-0 fw-500">3 Months</p>
                                <p class="text-pink mb-0 fw-500">10 series</p>
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-7 ms-auto text-end d-flex justify-content-end'>
                        <h3 class="text-pink pt-4 number-count">$150.<span class="fw-600 bucket-price">00</span></h3>
                    </div>
                </div>
                {tabSwitch == 0 ?
                    <>
                        <BucketSeries favSeries={favSeries} setTabSwitch={setTabSwitch} />
                    </>
                    : <>
                        <Subscribers favChannels={favChannels} setTabSwitch={setTabSwitch} />
                    </>
                }
                {bucketModal && <EditBucketModal setBucketModal={setBucketModal} />}
            </div>
            </div>
        </div>
    )
}

export default BucketDetail;