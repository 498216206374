import React, { useEffect, useState } from 'react'
import Featured from '../../Components/Featured/Featured'
import NewTalents from './Components/NewTalents'
import './talent.css'



function Talent() {
  return (
    <div className="main-inner-content">
      {/* <Featured /> */}
      <NewTalents />
    </div>
  )
}

export default Talent