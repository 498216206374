import React, { useState } from 'react'
import { favRed, favouriteLight, playLight, profile_image_placeholder, shareIcon, } from '../../../../Constants/images';
import TrailorPlayer from '../../../../CreaterDashboard/component/TrailorPlayer';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../../../Helper/route-helper';
import ShareModalSeries from '../../shareSeries';

function VideoBanner(props) {
  const navigate = useNavigate()
  const [shareModal, setShareModal] = useState(false)
  const handleSearch = (item) => {
    navigate(ROUTES.GLOBAL_SEARCH, { state: { item } })
  }
  const [videoReady, setVideoReady] = useState(false);

  const handleVideoReady = () => {
    setVideoReady(true);
  };

  return (
    <div>
      <section className="frame-video-banner frame-video-main">
        <div className="container position-relative">
          <div className="frame-video-img shop-trailor ">
            {/* {!videoReady && (
                <img
                  src={props?.seriesDetail?.thumbnail}
                  width="100%"
                  className="video-frame-fluid"
                  alt="Video Thumbnail"
                />
              )} */}
            <TrailorPlayer
              videoUrl={props.seriesDetail?.series_clips?.find(clip => clip.type == 1)?.video}
              onVideoReady={handleVideoReady}
              setIsLoading={props.setIsLoading}
            />
          </div>
          <div class=" like-fav-cus" style={{ position: "absolute", top: 15, right: 100, cursor: 'pointer' }} >
            {
              props?.seriesDetail?.is_fav == 1 ? <img src={favRed} onClick={() => props?.addFavourites(props.seriesDetail)} alt="" class="fav-like-series" />
                : <img src={favouriteLight} onClick={() => props?.addFavourites(props?.seriesDetail)} alt="" class="fav-like-series" />
            }
          </div>
          <img src={shareIcon} className=" like-fav-cus" style={{ position: "absolute", top: 15, right: 35, cursor: 'pointer' }} onClick={() => {
            setShareModal(true)
          }} />
          <div className="movie-wrapper">
            <div className="row d-flex flex-wrap gap-3">
              <div className="col-lg-2 bgwhite" style={{ cursor: 'pointer' }} onClick={() => navigate(ROUTES.CHANNELS_DETAILS + "?cd=seriesChannel", { state: { item: props?.seriesDetail?.channelDetails } })}>
                <div className="d-flex align-items-center gap-4" >
                  <div className="mvie-title">
                    <img src={props?.seriesDetail?.channelDetails?.profile_pic ? props?.seriesDetail?.channelDetails?.profile_pic : profile_image_placeholder} className="py-2 px-2 mv-title" alt="" />
                  </div>
                  <h4 className="m-0">{props?.seriesDetail?.channelDetails?.name}</h4>
                </div>
              </div>
              <div className="col-lg-2 bgwhite"
                style={{ cursor: 'pointer' }} onClick={() => navigate(ROUTES.CATEGORY, { state: { id: props?.seriesDetail?.category?.id, catName: props?.seriesDetail?.category?.name } })}
              >
                <div className="d-flex align-items-center gap-4" >
                  <div className="mvie-title">
                    <img
                      src={props?.seriesDetail?.category?.icon}
                      className="py-2 px-2 mv-title"
                      alt=""
                    />
                  </div>
                  <h4 className="m-0">{props?.seriesDetail?.category?.name}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="movie-detail py-60">
        <div className="container">
          <h2>{props?.seriesDetail?.title}</h2>
          <div className="d-flex gap-5 my-3 flex-wrap">
            <h5 className='video-text'>{props?.seriesDetail?.year}</h5>
            {/* <h5  className='video-text'>12h 34min</h5> */}
            <h5 className='video-text'>{props?.seriesDetail?.episodes_count} Episodes</h5>
          </div>
          <div className="stream-btn">
            {
              props?.seriesDetail?.tags?.split(",")?.map((item) => {
                return (
                  <button className=" btn-blue" style={{ cursor: "pointer" }} onClick={() => handleSearch(item)}>{item}</button>
                )
              })
            }
          </div>
        </div>
      </section>

      <section className="plot-summary">
        <div className="container">
          <h3 className="fw-600">Plot Summary</h3>
          <p className="mt-2">
            {props?.seriesDetail?.description}
          </p>
        </div>
      </section>
      {
        shareModal && <ShareModalSeries setShareModal={setShareModal} id={props.id} />
      }
    </div>
  )
}

export default VideoBanner;