import React from 'react'

const ImageShowModal = ({ setImageShow, url }) => {

    const handleClick = () => {
        setImageShow(false)
    }

 return (
        <div className="modal-cus modalvideoplayer" id="videoplayer">
            <div className="container-fluid px-0">
                <a onClick={() => handleClick()} style={{ cursor: "pointer" }} className="m-0 px-0 video-back-btn">
                    <i className=" fa fa-chevron-left video-back-control" />
                </a>
                <div className="position-relative p-0 custom-image-preview">
                    <img src={url} controls></img>
                </div>
            </div>
        </div>
    )
}

export default ImageShowModal