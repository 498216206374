import React, { useState } from 'react'
import ROUTES from '../../../Helper/route-helper';
import { playLight } from '../../../Constants/images';
import { useNavigate } from 'react-router-dom';
import BiteModal from '../../../Components/Bites';



function BiteSearch({ bitesList,tabSwitch }) {
    const [showBite, setShowBite] = useState(false);
    const [currentBiteIndex, setCurrentBiteIndex] = useState(0);
    const navigate = useNavigate();
    const [index, setIndex] = useState(0);

    const handleBitePlay = (index) => {
        setShowBite(!showBite);
        setCurrentBiteIndex(index);
    };


    return (
        <div className="row" style={{ marginTop: 0 }}>
             {
        (bitesList?.length > 0 && tabSwitch == 0
        ) && <div className="chanel-header">
          <h3 className="fw-600 my-3">Bites</h3>
        </div>
      }
            <div className="chanel-wrapper mt-4">
                <div className="row gy-2" style={{ cursor: "pointer" }}>
                    {bitesList?.length > 0 && bitesList?.map((bite, index) =>
                        <div className="col-xl-4 col-xxl-3 col-lg-4 col-md-6  response-col" key={bite.id}>
                            <div className="channel-bites-content">
                                <img src={bite.thumbnail} className="channelbite-img" alt="" onClick={() => handleBitePlay(index)} />
                                <div className="bite-wrapper">
                                    <h4>{bite?.title}</h4>
                                    <div className="chanel-text d-flex gap-3 align-items-center" style={{ cursor: "pointer" }} onClick={() => navigate(ROUTES.CHANNELS_DETAILS + "?cd=biteChannel", { state: { item: bite } })}>
                                        <img src={bite?.biteChannel?.profile_pic} className="bite-inner-img" alt="" />
                                        <div>
                                            <h6 className='fw-600 mb-0'>{bite?.biteChannel?.name}</h6>
                                            <p className='mt-1'>{bite.category?.name}</p>
                                        </div>
                                    </div>
                                    <button onClick={() => handleBitePlay(index)} className="common-btn"><img src={playLight} alt="" />Play</button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {
                showBite &&
                <BiteModal currentBiteIndex={currentBiteIndex} setCurrentBiteIndex={setCurrentBiteIndex} index={index} bitesList={bitesList} showBite={showBite} setShowBite={setShowBite} check="channel" />
            }

        </div>
    )
}

export default BiteSearch;