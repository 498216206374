import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { DEMO_IMAGE_SIX, SCHEDULE_DOT, dummy, favGrey, image1, live_calender, live_clock, no_audio, no_live, show_audio } from '../../Constants/images'
import { Dropdown } from 'react-bootstrap'
import PriceTag from '../component/price-tag'
import ReactLoader from '../../ControlComponents/react-loader'
import apiHelper from '../../Helper/api-helper'
import DEVELOPMENT_CONFIG from "../../Helper/config"
import DeleteShopModal from './deleteModal'
import { NotificationManager } from 'react-notifications'
import ROUTES from '../../Helper/route-helper'
import AddDetailAudioModal from './detailAudioModal'
import AddAudioModal from './addAudioModal'

function Audio({ setTabSwitch, getAudiosAPI, audiosList }) {
    const [loading, setIsLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const [deltId, setDeltId] = useState(null)
    const [addDetailAudioModal, setAddDetailAudioModal] = useState(false)
    const [addAudioModal, setAddAudioModal] = useState(false)
    const handleEdit = (item) => {
        setDeltId(item.id)
        setAddAudioModal(true)
    }

    async function getDeleteAPI() {
        setIsLoading(true)
        let result = await apiHelper.getRequest("shop/delete-shop?shop_id=" + deltId + "&shop_type=audio")
        if (result.code == DEVELOPMENT_CONFIG.statusCode) {
            setIsLoading(false)
            setDeleteModal(false)
            if (result.message == "Shop deleted successfully") {
                NotificationManager.success("Audio deleted successfully")
            }
            else {
                NotificationManager.success(result.message)
            }
            getAudiosAPI()
            setAddDetailAudioModal(false)

        }
        else if (result.code == 401) {
            let refreshToken = localStorage.getItem("refresh-token")
            let data = {
                "refresh_token": refreshToken,
                "scope": "refresh_token"
            }
            let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
            if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
                localStorage.setItem("token", refreshTokenResult.data.token)
                localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
                setRefresh(true)
            }
        }
        else {
            NotificationManager.error(result.message)

        }
    }



    const handleDelete = (id) => {
        setDeltId(id)
        setDeleteModal(true)
    }



    return (
        <>
            {loading && <ReactLoader />}

            <div className="container-fluid">
                <div className="row">
                    <div className="stream-btn pt-3 pb-4">
                        <button className="common-btn btn-white left-corner" onClick={() => setTabSwitch(0)}>Series</button>
                        <button className="common-btn btn-white left-corner" onClick={() => setTabSwitch(1)}>Recorded Lives</button>
                        <button className="common-btn btn-white left-corner" onClick={() => setTabSwitch(2)}>Upcoming Lives</button>
                        <button className="common-btn btn-white left-corner" onClick={() => setTabSwitch(3)}> Images</button>
                        <button className="common-btn btn-blue left-corner" onClick={() => setTabSwitch(4)}>Audios</button>
                        <button className="common-btn btn-white left-corner" onClick={() => setTabSwitch(5)}>Videos</button>
                    </div>

                    <div className='row mt-4'>
                        {audiosList?.length > 0 ? (
                            <>
                                {
                                    audiosList?.map((item) => (
                                        <div className="col-12 col-sm-6 col-lg-4 col-xl-4 col-xxl-3 pb-4"
                                            key={item?.id}
                                        >
                                            <div className="latest-video mx-auto" >
                                                <div className='position-relative'
                                                >
                                                    <div
                                                        onClick={() => {
                                                            setDeltId(item.id)
                                                            setAddDetailAudioModal(true)
                                                        }}
                                                        className="latest-video-content position-relative pt-2 pt-sm-3 px-2 px-sm-3"

                                                        style={{ cursor: "pointer" }}>
                                                        <div className='show-audio'><img
                                                            src={show_audio}
                                                        /></div>

                                                        <PriceTag classes={"price_tag"} firstValue={`$${item.price}`} secondValue={"00"} />

                                                    </div>
                                                    <div className='dotschedule bg-transparent images-dropdown'>
                                                        <div className='dropdownschedule'>
                                                            <Dropdown>
                                                                <Dropdown.Toggle id="custom-dropdown-toggle">
                                                                    <img src={SCHEDULE_DOT} className='img-fluid' alt="dot" />
                                                                </Dropdown.Toggle>

                                                                <Dropdown.Menu className='record-card-dropdown'>
                                                                    <Dropdown.Item
                                                                        onClick={() => handleEdit(item)}
                                                                    >Edit</Dropdown.Item>
                                                                    <Dropdown.Item
                                                                        onClick={() => handleDelete(item.id)}
                                                                    >Delete</Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="latest-video-cus px-3 py-3 schedule-grid" onClick={() => {
                                                    setDeltId(item.id)
                                                    setAddDetailAudioModal(true)
                                                }} >
                                                    <div className=''
                                                    >
                                                        <h4 className="schedule-title">{item.title.length > 20 ? item.title.substring(0, 20) + "..." : item.title}</h4>
                                                        <h6 className="images-count">
                                                            {item?.shop_media?.length}  {item?.shop_media?.length == 1 ? "Audios" : "Audios"}
                                                        </h6>
                                                        <p className="images-description" >
                                                            {item.description}                                                    </p>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    ))}
                            </>

                        ) : <>
                            {
                                loading == false &&
                                (
                                    <div style={{ flexDirection: 'column' }} className='no-search-results position-relative'>
                                         <img src={DEMO_IMAGE_SIX} alt="No Challenges" />
                                         <h6 className='position-absolute' style={{ bottom: 15 }}>No audios yet</h6>      
                                        {/* <img src={no_audio} alt="no live" />
                                        <h6 className='position-absolute' style={{ bottom: 0 }} >No Audios yet</h6> */}
                                    </div>
                                )
                            }
                        </>
                        }
                    </div>
                </div>
                {
                    deleteModal && <DeleteShopModal setDeleteModal={setDeleteModal} getDeleteAPI={getDeleteAPI} check="audio" />
                }
                {
                    addAudioModal && <AddAudioModal setAddAudioModal={setAddAudioModal} setTabSwitch={setTabSwitch} deltId={deltId} getAudiosAPI={getAudiosAPI} />
                }
                {
                    addDetailAudioModal && <AddDetailAudioModal setAddDetailAudioModal={setAddDetailAudioModal} deltId={deltId} getAudiosAPI={getAudiosAPI} deleteModal={deleteModal} getDeleteAPI={getDeleteAPI} setDeleteModal={setDeleteModal} />
                }
            </div>
        </>
    )
}

export default Audio