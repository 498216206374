import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { biteImg, bitesDark, challenge1, challengeBack, dotsbg, favRed, favouriteLight, favouritesDark, playLight, playLightBtn, videoPlayButton } from '../../Constants/images';
import ROUTES from '../../Helper/route-helper';
import './bucket.css'

function BucketSeries({ setTabSwitch, favSeries }) {
  const navigate = useNavigate()

  return (
    <div><section className="streaming py-6 response-padding ">
      <div className="container-fluid">
        <div className="row">
         
          <div className="stream-btn pt-3">
            <button onClick={() => setTabSwitch(0)} className="common-btn btn-blue">Series</button>
            <button onClick={() => setTabSwitch(1)} className="common-btn btn-white">Subscribers</button>
          </div>
        </div>
      </div>
    </section>
      <section className="favchannel-main-cus ">
        <div className="container-fluid">
          <div className='row'>
          <div className="chanel-wrapper mt-4">
                  <div className="row gy-2">
                        <div className="col-xl-4 col-xxl-3 col-lg-4 col-md-6  response-col">
                           <div className="bucketseries-custom channel-bites-content">
                              <div className='position-relative'>
                                 <img src={biteImg} className="channelbite-img" alt="" />
                                 <div className='dots_bucketseries'>
                                     <img src={dotsbg} className='dotsbuckeyseries'></img>
                                 </div>
                              </div>
                              <div className="bite-wrapper-series">
                                 <h4 className=''>The walking dead</h4>
                                 <div className="chanel-text d-flex gap-3 align-items-center">
                                    <div>
                                       <p className='mt-1 mb-0'>13 episodes</p>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                  </div>
               </div>
          </div>
        </div>
      </section>

    </div>
  )
}

export default BucketSeries;