import React, { useState, useEffect, useRef } from 'react';
import './trailorPlayer.css';
import ReactPlayer from 'react-player';
import { mute_trailor, unmute_trailor } from '../../Constants/images';

const TrailorPlayer = ({ videoUrl, setIsLoading }) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [isMuted, setIsMuted] = useState(() => {
        return localStorage.getItem('isMuted') === 'true';
    });

    const sliderRef = useRef(null);

    useEffect(() => {
        if (isLoaded) {
            setIsLoading(false);
        }
    }, [isLoaded]);

    const handleVideoReady = () => {
        setIsLoaded(true);
    };

    const handleMuteToggle = () => {
        setIsMuted(prevIsMuted => {
            const newMutedState = !prevIsMuted;
            localStorage.setItem('isMuted', newMutedState);
            return newMutedState;
        });
    };

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    const userMuted = localStorage.getItem('isMuted') === 'true';
                    setIsMuted(userMuted);
                    localStorage.setItem('focus', 1);
                } else {
                    setIsMuted(true);
                    localStorage.setItem('focus', 0);
                }
            },
            { threshold: 0.1 }
        );

        if (sliderRef.current) {
            observer.observe(sliderRef.current);
        }

        return () => {
            if (sliderRef.current) {
                observer.unobserve(sliderRef.current);
            }
        };
    }, []);

    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.visibilityState === 'hidden') {
                setIsMuted(true);
            } else {
                const focus = localStorage.getItem('focus');
                const userMuted = localStorage.getItem('isMuted') === 'true';
                setIsMuted(userMuted);
            }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, []);

    useEffect(() => {
        return () => {
            localStorage.setItem('isMuted', false);
            localStorage.removeItem('focus');
        };
    }, []);

    const autoplayUrl = `${videoUrl}?autoplay=1&mute=1`;
    return (
        <div className="modal-cus" id="videoplayer" ref={sliderRef}>
            <div className="container-fluid px-0">

                <div className="custom-trailorplayer position-relative p-0 video-width">
                    <ReactPlayer
                        className="video-seriesdetail"
                        url={autoplayUrl}
                        controls={false}
                        playing
                        loop
                        width="100%"
                        height="500"
                        muted={isMuted}
                        onReady={handleVideoReady}
                        onError={(e) => console.error("Video failed to load", e)}
                    />

                    <div
                        onClick={handleMuteToggle}
                        className="mute-trailor-btn">
                        {isMuted ? (
                            <img src={mute_trailor} alt="Muted" />
                        ) : (
                            <img src={unmute_trailor} alt="Unmuted" />
                        )}
                    </div>
                    
                </div>

            </div>
        </div>
    );
};

export default TrailorPlayer;
