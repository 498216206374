import React, { useState } from 'react'
import { modalCross, modalEmail } from '../../../../Constants/images';
import ERR_MESSAGE from '../../../../Helper/error-helper';
import { NotificationManager } from "react-notifications";
import apiHelper from '../../../../Helper/api-helper';
import DEVELOPMENT_CONFIG from '../../../../Helper/config';
import OtpInput from 'react-otp-input';
import ReactLoader from '../../../../ControlComponents/react-loader';


const VerifyEmail = ({ setVerifyEmail, setResetPassword, getEmail }) => {

  const [errors, setErrors] = useState("");
  const [isLoading, setLoading] = useState(false);

  const [otp, setOtp] = useState('');

  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;
    if (!otp || otp.trim().length === 0) {
      formIsValid = false;
      errors["otp"] = ERR_MESSAGE.EMPTY_OTP
    }
    else if (otp.length < 6) {
      formIsValid = false;
      errors["otp"] = ERR_MESSAGE.INVALID_OTP
    }
    setErrors(errors);
    return formIsValid;
  }

  async function postAPI(e) {
    e.preventDefault();
    if (handleValidation()) {
      let data = JSON.stringify({
        "email": getEmail,
        "otp": otp
      });
      setLoading(true)
      let result = await apiHelper.postRequest("auth/confirm-otp", data)
      if (result.code === DEVELOPMENT_CONFIG.statusCode) {
        // localStorage.setItem("token", result.data.token);
        setLoading(false)
        setOtp("")
        setVerifyEmail(false)
        setResetPassword(true)
        NotificationManager.success(result.message)
      } else {
        setLoading(false)
        setOtp("")
        NotificationManager.error(result.message)
      }
    }
  }

  async function resendAPI(e) {
    e.preventDefault();

    let data = JSON.stringify({
      "email": getEmail,
    });
    setLoading(true)
    let result = await apiHelper.postRequest("auth/resend-otp", data)
    if (result.code === DEVELOPMENT_CONFIG.statusCode) {
      // localStorage.setItem("token", result.data.token);
      setLoading(false)
    } else {
      setLoading(false)

    }
  }
  return (

    <div className="modal fade show" style={{ display: "block", opacity: "1", backdropFilter: "blur(5px)" }}>
      <div className='loader'>
        {isLoading && <ReactLoader />}
      </div>

      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content modal-radius forgot-body">
          <div className="modal-header forgot-header align-items-center">
            <h3 className="modal-title text-center mx-auto forgot-title" id="exampleModalLabel">Verify E-mail</h3>
            <a className="m-0 px-0" data-bs-dismiss="modal" aria-label="Close"><img onClick={() => setVerifyEmail(false)} src={modalCross} alt="" className="img-fluid" /></a>
          </div>
          <div className="modal-body forgot-body verify-modal-box px-2 px-sm-4">
            <div className="forgot-img text-center">
              <p>Please enter the verification code sent to <br />your E-mail</p>
              <img src={modalEmail} alt="" className="img-fluid py-4" />
            </div>
            <form onSubmit={postAPI}>
              <div className="form-group mb-3 mx-auto text-center   ">
                <OtpInput
                  containerStyle="d-flex flex-wrap justify-content-center"
                  inputStyle="reset-otp forgot-input form-control "
                  form-control
                  value={otp}
                  onChange={setOtp}
                  numInputs={6}
                  renderInput={(props) => <input {...props} />}
                />
                <span className="err_msg">{errors.otp}</span>
              </div>

              <p className="resend-code">Didn't received code? <a onClick={resendAPI} className="text-pink">Resend Code</a></p>
              <div className=" mt-4 mb-5">
                <input type='submit' value="Submit" className="common-btn w-100" />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

  )
}

export default VerifyEmail
