import React, { useState, useEffect } from 'react';
import { modalCross, playLightBtn } from '../../Constants/images';
import apiHelper from '../../Helper/api-helper';
import DEVELOPMENT_CONFIG from "../../Helper/config";
import ReactLoader from '../../ControlComponents/react-loader';
import AddVideoModal from './addVideoModal';
import DeleteShopModal from './deleteModal';
import VideoPlayModal from './videoPlay';

const AddDetailVideoModal = ({ setAddDetailVideoModal, setTabSwitch, deltId, getVideosAPI, setDeleteModal, deleteModal, getDeleteAPI }) => {
    const [detailList, setDetailList] = useState([]);
    const [loading, setIsLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [addVideoModal, setAddVideoModal] = useState(false);
    const [videoPlay, setVideoPlay] = useState(false);
    const [url, setUrl] = useState("");

    async function getDetailAPI() {
        setIsLoading(true);
        try {
            let result = await apiHelper.getRequest(`shop/get-shop-media?shopType=video&shop_id=${deltId}`);
            if (result?.code === DEVELOPMENT_CONFIG?.statusCode) {
                setDetailList(result.data);
            } else if (result?.code === 401) {
                let refreshToken = localStorage.getItem("refresh-token");
                let data = {
                    "refresh_token": refreshToken,
                    "scope": "refresh_token"
                };
                let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data);
                if (refreshTokenResult.code === DEVELOPMENT_CONFIG.statusCode) {
                    localStorage.setItem("token", refreshTokenResult.data.token);
                    localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token);
                    setRefresh(true);
                }
            }
        } catch (error) {
            console.error("Failed to fetch video detail:", error);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getDetailAPI();
    }, [refresh, deltId]);

    return (
        <>
            {loading && <ReactLoader />}
            <div className="modal fade show modal-cast" style={{ display: "block", opacity: "1", backdropFilter: "blur(5px)" }}>
                <div className='loader'></div>
                <div className="modal-dialog modal-lg  modal-dialog-centered" style={{ justifyContent: "center" }}>
                    <div className="modal-content modal-radius border-0">
                        <div className="modal-header forgot-header align-items-center pt-4">
                            <h3 className="modal-title text-center mx-auto forgot-title" id="exampleModalLabel">Detail</h3>
                            <a className="m-0 px-0 btn-closed-main" data-bs-dismiss="modal" aria-label="Close">
                                <img style={{ cursor: "pointer" }} onClick={() => setAddDetailVideoModal(false)} src={modalCross} alt="" className="img-fluid btn-cloesed" />
                            </a>
                        </div>
                        <div className="modal-body d-flex flex-column justify-center align-items-center gap-3  verify-modal-box px-2 px-sm-5">
                            <form className='price-form-input'>
                                {detailList && detailList?.length > 0 && (
                                     <>
                                        <div className='d-flex justify-content-between'>
                                            <div>
                                                <h4>{detailList[0]?.title}</h4>
                                                <h6 className="images-count">
                                                    {detailList[0]?.shop_media?.length} {detailList[0]?.shop_media?.length === 1 ? "Video" : "Videos"}
                                                </h6>
                                            </div>
                                            <div className='detail-main'>
                                                <span className='first-price-detail'>${detailList[0]?.price}</span>
                                                {/* <span className='second-price-detail'>00</span> */}
                                            </div>
                                        </div>
                                        <p className="image-detail-description">{detailList[0]?.description}</p>
                                        <h4>Videos</h4>
                                        <div className='d-flex align-items-start gap-3 flex-wrap overflow-scroll justify-content-center justify-content-sm-start' style={{ maxHeight: 350, cursor: "pointer" }}>
                                            {detailList[0]?.shop_media?.map((item, index) => (
                                                <div key={index} className='position-relative' onClick={() => {
                                                    setVideoPlay(true);
                                                    setUrl(item.media_url);
                                                }} style={{ cursor: "pointer" }}>
                                                    <div className='multi-images'>
                                                        <img src={item.media_thumbnail} alt={`Video thumbnail ${index}`} />
                                                    </div>
                                                    <img className='detail-video-play' src={playLightBtn} alt="Play button" />
                                                </div>
                                            ))}
                                        </div>
                                        <button type='submit' className='mt-4 price-submit-btn' onClick={(e) => {
                                            e.preventDefault();
                                            setAddVideoModal(true);
                                        }}>Edit</button>
                                        <div className='mt-3 mb-2 delete-detail-image' style={{ cursor: "pointer" }} onClick={() => setDeleteModal(true)}>Delete</div>
                                    </>
                                )}
                            </form>
                        </div>
                        {addVideoModal && <AddVideoModal setAddVideoModal={setAddVideoModal} setTabSwitch={setTabSwitch} deltId={deltId} getVideosAPI={getVideosAPI} setAddDetailVideoModal={setAddDetailVideoModal} />}
                        {deleteModal && <DeleteShopModal setDeleteModal={setDeleteModal} getDeleteAPI={getDeleteAPI} check="video" />}
                        {videoPlay && <VideoPlayModal setVideoPlay={setVideoPlay} url={url} />}
                    </div>
                </div>
            </div>
        </>
    );
};

export default AddDetailVideoModal;
