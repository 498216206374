import React, { useEffect, useRef, useState } from 'react'
import { analyst, bites, bitesDark, bucket, challenges, challengesDark, dashboard, discoverDark, download_img, favouritesDark, homeDark, interactivesCreater, lives, logoPurple, series, shopIcon, socialfeed, subscribers, teamMembers } from '../../Constants/images'
import './sidebar.css'
import ROUTES from '../../Helper/route-helper'
import { NavLink } from 'react-router-dom'
import $ from 'jquery'
import SocialFeeds from '../../CreaterDashboard/SocialFeeds/socialFeeds'

const Sidebar = (props) => {
   const role = localStorage.getItem("role")
   function handleFunction() {
      $('.main-content').removeClass("content-open");
      $('.sidebar-wrapper').removeClass("sidebar-uper");
      $('.main-content').removeClass("content-open-uper");
      $('.sidebar-wrapper').removeClass("sidebar-closed");
   }
   
   return (
      <div>
         {
            role == 2 ?   <div className="sidebar-wrapper"  onClick={()=>props.setUser(!props.user)}>
            <i onClick={() => handleFunction()} className="fa fa-chevron-left sidebar-left-icon close_cross" aria-hidden="true"></i>
            <div className="top-logo-sidebar">
               <img src={logoPurple} alt="fish_logo_green" className="logo-fish" />
            </div>
            <div className="navbar-left-sidebar">
               <h4 className="fw-600 text-pink main-menu-text"> Menu</h4>
               <ul className="list-unstyled mb-0  list_access">
                  <li>
                     <NavLink activeclassname="active" to={ROUTES.CREATER_DASHBOARD}  className="text-decoration-none fw-500">
                        <img src={dashboard} className="pe-3" /><span>Dashboard</span> </NavLink>
                  </li>
                  <li>
                     <NavLink activeclassname="active" to={ROUTES.SOCIAL_FEEDS}  className="text-decoration-none fw-500">
                        <img src={socialfeed} className="pe-3" /><span>Feeds</span>
                     </NavLink>
                  </li>
                  <li>
                     <NavLink activeclassname="active" to={ROUTES.SHOP}  className="text-decoration-none fw-500">
                        <img src={shopIcon} className="pe-3" /><span>Shop</span>
                     </NavLink>
                  </li>
                  <li>
                     <NavLink activeclassname="active" to={ROUTES.CREATER_LIVES}  className="text-decoration-none fw-500">
                        <img src={lives} className="pe-3" /><span>Lives</span>
                     </NavLink>
                  </li>
                  <li>
                     <NavLink activeclassname="active" to={ROUTES.CREATER_SERIES} className="text-decoration-none fw-500">
                        <img src={series} className="pe-3" /><span>Series</span>
                     </NavLink>
                  </li>
                  <li>
                     <NavLink  activeclassname="active" to={ROUTES.CREATER_CHALLENGES} className="text-decoration-none fw-500">
                        <img src={challenges} className="pe-3" /><span>Challenges</span>
                     </NavLink>
                  </li>
                  <li>
                     <NavLink  activeclassname="active" to={ROUTES.CREATER_BITES} className="text-decoration-none fw-500">
                        <img src={bites} className="pe-3" /><span>Bites</span>
                     </NavLink>
                  </li>
                  <li>
                     <NavLink activeclassname="active" to={ROUTES.CREATER_INTERACTIVES} className="text-decoration-none fw-500">
                        <img src={interactivesCreater} className="pe-3" /><span>Interactives</span>
                     </NavLink>
                  </li>
                  <li>
                     <NavLink  activeclassname="active" to={ROUTES.CREATER_SUBSCRIBERS} className="text-decoration-none fw-500">
                        <img src={subscribers} className="pe-3" /><span>Followers</span>
                     </NavLink>
                  </li>
                  <li>
                     <NavLink activeclassname="active" to={ROUTES.CREATER_BUCKETS} className="text-decoration-none fw-500">
                        <img src={bucket} className="pe-3" /><span>Tiers</span>
                     </NavLink>
                  </li>
                   <li>
                     <NavLink activeclassname="active" to={ROUTES.CREATER_ANALYST} className="text-decoration-none fw-500">
                        <img src={analyst} className="pe-3" /><span>Analytics</span>
                     </NavLink>
                  </li>
                  <li>
                     <NavLink id='challenges' activeclassname="active" to={ROUTES.CREATER_TEAM_MEMBER} className="text-decoration-none fw-500">
                        <img src={teamMembers} className="pe-3" /><span>Team Members</span>
                     </NavLink>
                  </li>
               </ul>
            </div>
         </div> :   <div className="sidebar-wrapper"  onClick={()=>props.setUser(!props.user)}>
            <i onClick={() => handleFunction()} className="fa fa-chevron-left sidebar-left-icon close_cross" aria-hidden="true"></i>
            <div className="top-logo-sidebar">
               <img src={logoPurple} alt="fish_logo_green" className="logo-fish" />
            </div>
            <div className="navbar-left-sidebar">
               <h4 className="fw-600 text-pink main-menu-text"> Menu</h4>
               <ul className="list-unstyled mb-0  list_access" >
                  <li>
                     <NavLink activeclassname="active"  to={ROUTES.HOME} className="text-decoration-none fw-500">
                        <img src={homeDark} className="pe-3" /><span>Home</span> </NavLink>
                  </li>
                  <li>
                     <NavLink activeclassname="active" to={ROUTES.USER_SOCIAL_FEEDS}  className="text-decoration-none fw-500">
                        <img src={socialfeed} className="pe-3" /><span>Feeds</span>
                     </NavLink>
                  </li>
                  <li>
                     <NavLink activeclassname="active" to={ROUTES.USER_SHOP}  className="text-decoration-none fw-500">
                        <img src={shopIcon} className="pe-3" /><span>Shop</span>
                     </NavLink>
                  </li>
                  <li>
                     <NavLink activeclassname="active" to={ROUTES.DOWNLOAD}  className="text-decoration-none fw-500">
                        <img src={download_img} className="pe-3" /><span>Downloads</span>
                     </NavLink>
                  </li>
                  <li>
                     <NavLink activeclassname="active" to={ROUTES.LIVES}  className="text-decoration-none fw-500">
                        <img src={lives} className="pe-3" /><span>Lives</span>
                     </NavLink>
                  </li>
                  <li>
                     <NavLink activeclassname="active" to={ROUTES.USER_SERIES} className="text-decoration-none fw-500">
                        <img src={series} className="pe-3" /><span>Series</span>
                     </NavLink>
                  </li>
                  <li>
                     <NavLink activeclassname="active" to={ROUTES.DISCOVER} className="text-decoration-none fw-500">
                        <img src={discoverDark} className="pe-3" /><span>Discover</span>
                     </NavLink>
                  </li>
                  <li>
                     <NavLink activeclassname="active" to={ROUTES.BITES} className="text-decoration-none fw-500">
                        <img src={bitesDark} className="pe-3" /><span>Bites</span>
                     </NavLink>
                  </li>
                  <li>
                     <NavLink  activeclassname="active" to={ROUTES.FAVOURITES} className="text-decoration-none fw-500">
                        <img src={favouritesDark} className="pe-3" /><span>Favourites</span>
                     </NavLink>
                  </li>
                  <li>
                     <NavLink id='challenges' activeclassname="active" to={ROUTES.CHALLENGES} className="text-decoration-none fw-500">
                        <img src={challengesDark} className="pe-3" /><span>Challenges</span>
                     </NavLink>
                  </li>
               </ul>
            </div>
         </div>
         }
      </div >
   )
}

export default Sidebar