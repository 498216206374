import React, { useState } from 'react'
import { eye, closeEye, modalCross, modalReset } from '../../../../Constants/images'
import ERR_MESSAGE from '../../../../Helper/error-helper';
import apiHelper from '../../../../Helper/api-helper';
import DEVELOPMENT_CONFIG from '../../../../Helper/config'
import ReactLoader from '../../../../ControlComponents/react-loader';
import "../ResetPassword/ResetPassword.css"
import { NotificationManager } from 'react-notifications';
const ResetPassword = ({ setResetPassword, getEmail }) => {

  const [errors, setErrors] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [fields, setFields] = useState({
    password1: "",
    password2: ""
  });

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFields({
      ...fields,
      [name]: value,
    });
  };
  const [eye_icon1, setEye_icon1] = useState(closeEye);
  const [type1, setType1] = useState("password");
  const [msg1, setMsg1] = useState("reveal password")
  const [eye_icon2, setEye_icon2] = useState(closeEye);
  const [type2, setType2] = useState("password");
  const [msg2, setMsg2] = useState("reveal password")
  const showPassword = () => {
    if (type1 === "password") {
      setType1("text");
      setEye_icon1(eye);
      setMsg1("hide password")
    }
    else {
      setType1("password");
      setEye_icon1(closeEye);
    }
  }
  const showConfirmPassword = () => {
    if (type2 === "password") {
      setType2("text");
      setEye_icon2(eye);
      setMsg2("hide password")
    }
    else {
      setType2("password");
      setEye_icon2(closeEye);
    }
  }
  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;
    let passwordReg = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,}$/;
    const { password1, password2 } = fields;
    if (password1.trim() == "") {
      formIsValid = false
      errors["password1"] = ERR_MESSAGE.PASSWORD_EMPTY
    } else if (passwordReg.test(password1) === false) {
      formIsValid = false
      errors["password1"] = ERR_MESSAGE.INVALID_PASSWORD
    }
    else if (password2.trim() == "") {
      formIsValid = false
      errors["password2"] = ERR_MESSAGE.EMPTY_CONFIRMPASSWORD
    }
    else if (password2.trim() !== password1.trim()) {
      formIsValid = false
      errors["password2"] = ERR_MESSAGE.NOTMATCHED
    }
    setErrors(errors);
    return formIsValid;
  };



  async function postAPI(e) {
    e.preventDefault();
    if (handleValidation()) {
      let data = JSON.stringify({
        "email": getEmail,
        "password": fields.password2
      });
      setLoading(true)
      let result = await apiHelper.postRequest("auth/reset-password", data)
      if (result.code === DEVELOPMENT_CONFIG.statusCode) {
        // localStorage.setItem("token", result.data.token);
        setLoading(false)
        setResetPassword(false)
        NotificationManager.success(result.message)
      } else {
        setLoading(false)
        NotificationManager.error(result.message)
      }
    }
  }

  return (
    <>
      {isLoading && <ReactLoader />}

      <div className="modal fade" style={{ display: "block", opacity: "1", backdropFilter: "blur(5px)" }}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content modal-radius forgot-body">
            <div className="modal-header forgot-header align-items-center">
              <h3 className="modal-title text-center mx-auto forgot-title" id="exampleModalLabel">Reset Password</h3>
              <a onClick={() => setResetPassword(false)} className="m-0 px-0" style={{ cursor: "pointer" }} data-bs-dismiss="modal" aria-label="Close"><img src={modalCross} alt="" className="img-fluid" /></a>
            </div>
            <div className="modal-body forgot-body px-2 px-sm-5">
              <div className="forgot-img text-center">
                <p>Please Enter your new password</p>
                <img src={modalReset} alt="" className="img-fluid py-4" />
              </div>
              <form onSubmit={postAPI} >
                <div className="form-group mb-3 position-relative">
                  <label className="fw-500 mb-2 forgot-label">New Password</label>
                  <input type={type1} className="form-control  fw-500 forgot-input" name='password1' value={fields.password1} onChange={handleChange} error={errors.password1} placeholder="Enter New Password" />
                  <span className="err_msg">{errors.password1}</span>
                  <div className='eyeclosed'>
                    <img src={eye_icon1} onClick={showPassword} className="img-fluid" title={msg1} />
                  </div>
                </div>
                <div className="form-group mb-3 position-relative">
                  <label className="fw-500 mb-2 forgot-label">Confirm New Password</label>
                  <input type={type2} className="form-control  fw-500 forgot-input" name='password2' value={fields.password2} onChange={handleChange} error={errors.password2} placeholder="Confirm New Password" />
                  <span className="err_msg">{errors.password2}</span>
                  <span className="err_msg">{errors.passwords}</span>
                  <div >
                    <div className='eyeclosed'>
                      <img src={eye_icon2} onClick={showConfirmPassword} className="img-fluid" title={msg2} />
                    </div>
                  </div>
                </div>
                <div className="my-5">
                  <input type='submit'value="Submit" className="common-btn w-100" />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ResetPassword
