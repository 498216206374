import React, { useState } from 'react'
import { dummy, modalCross } from '../../Constants/images'


const SelectSeriesModal = ({ setSeriesModal, favSeries, setSelectSeries, selectSeries, setErrors,setSeriesId }) => {

  const [series, setSeries] = useState(selectSeries)
  const handleSeriesSelection = (event, title,series_id) => {
    setSeries(title)
    setSeriesId(series_id)
    setErrors("")
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSelectSeries(series)
    setSeriesModal(false)
  };

  return (
    <div className="modal fade show modal-react" style={{ display: "block", opacity: "1", backdropFilter: "blur(5px)" }}>
      <div className='loader'>
      </div>
      <div className="modal-dialog modal-dialog-centered modal-schedule-width">
        <div className="modal-content modal-radius  border-0">
          <div className="modal-header forgot-header align-items-center pt-4">
            <h3 className="modal-title text-center mx-auto forgot-title" id="exampleModalLabel">Select Series</h3>
            <a className="m-0 px-0 btn-closed-main" data-bs-dismiss="modal" aria-label="Close"><img onClick={() => setSeriesModal(false)} style={{ cursor: "pointer" }} src={modalCross} alt="" className="img-fluid" /></a>
          </div>
          <div className="modal-body forgot-body verify-modal-box p-4 pt-4 p-md-0">
            <form >
              <div className=''>
                <ul className='ps-0 series-listing pt-3'>
                  {
                    favSeries.map((item) => (
                      <li>
                        <div className='selectseries-listing'>
                          <div className=''>
                            <img src={item.thumbnail} className='selectseriesimg' />
                          </div>
                          <div className='text-start'>
                            <h5 className='mb-2 f20-size fw-600 one-line'>{item.title}</h5>
                            <p className='text-start mb-2 f16-size fw-500'><p className='text-start mb-2 f16-size fw-500'>{item?.tags?.split(",").map((tag, index) => (
                              <span key={index}>
                                {tag}
                                {index !== item.tags.split(",").length - 1 && ", "}
                              </span>
                            ))}
                            </p>

                            </p>
                            <p className='text-start mb-2 f16-size fw-500'>{item.episodesCount
                            } Episodes</p>
                          </div>
                          <div className="round-radio p-0">
                            <label className="main">
                              <input
                                type="radio"
                                className="profile_type"
                                name="series"
                                value={item.title}
                                checked={series === item.title}
                                onChange={(event) => handleSeriesSelection(event, item.title,item.id)}
                              />
                              <span className="custom-check"></span>
                            </label>
                          </div>
                        </div>
                      </li>
                    ))
                  }

                </ul>
              </div>
              <div className=" mt-4 mb-5" onClick={handleSubmit}>
                <input type='submit' value="Submit" className="common-btn w-100" />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

  )
}

export default SelectSeriesModal
