// landing
export const download = '/assets/images/landing/download.svg'
export const landingBanner = '/assets/images/landing/Landingbanner.png'
export const landingBannernew = '/assets/images/landing/landingpage_img.png'
export const loaderIcon = '/assets/images/loader-icon.svg'

export const section2 = '/assets/images/landing/section2.png'
export const section3 = '/assets/images/landing/section3.png'
export const play = '/assets/images/landing/play.png'
export const section4 = '/assets/images/landing/section4.png'
export const landingPage = '/assets/images/landing/LandingPage.png'

// header
export const logoPurple = '/assets/images/header/logo_purple.svg'
export const polygon = '/assets/images/header/Polygon.svg'
export const downArrow = '/assets/images/header/Down-Arrow.svg'
export const filter = '/assets/images/header/filter.svg'
export const modalCross = '/assets/images/header/modal_cross.svg'
export const notificationCopy = '/assets/images/header/notification copy.svg'
export const notification = '/assets/images/header/notification.svg'
export const search = '/assets/images/header/search.svg'
export const chnnelmuvi = '/assets/images/header/chnnel_muvi.png'


//sidebar
export const challengesDark = '/assets/images/sidebar/challenges_dark.svg';
export const discoverDark = '/assets/images/sidebar/discover_dark.svg';
export const favouritesDark = '/assets/images/sidebar/favourite_dark.svg';
export const homeDark = '/assets/images/sidebar/home_dark.svg';
export const dashboard = '/assets/images/sidebar/dashboard.svg';
export const bitesDark = '/assets/images/sidebar/bites.svg';
export const lives = '/assets/images/sidebar/lives.svg';
export const socialfeed = '/assets/images/sidebar/socialfeed.svg';
export const series = '/assets/images/sidebar/series.svg';
export const challenges = '/assets/images/sidebar/challenges.svg';
export const bites = '/assets/images/sidebar/bites.svg';
export const interactivesCreater = '/assets/images/sidebar/Interactives.svg';
export const subscribers = '/assets/images/sidebar/Subscribers.svg';
export const bucket = '/assets/images/sidebar/Buckets.svg';
export const analyst = '/assets/images/sidebar/Analyst.svg';
export const teamMembers = '/assets/images/sidebar/Team Members.svg';



// Footer
export const facebook = '/assets/images/footer/facebook.svg'
export const twitter = '/assets/images/footer/twitter.svg'
export const insta = '/assets/images/footer/insta.svg'
export const logoFooter = '/assets/images/footer/logo-footer.svg'
export const footer = '/assets/images/footer/footer.png'

// popularChannel & testimonial Images
export const amazon = '/assets/images/landing/amazon.png'
export const amasmall = '/assets/images/landing/amasmall.png'
export const discovery = '/assets/images/landing/discovery.png'
export const sky = '/assets/images/landing/sky.png'
export const df = '/assets/images/landing/df.png'
export const amazon_gradient = '/assets/images/landing/amazon-gradient.png'
export const discovery_gradient = '/assets/images/landing/discovery-gradient.png'
export const sky_gradient = '/assets/images/landing/sky-gradient.png'
export const df_gradient = '/assets/images/landing/2DF.png'
export const testimonialPng = '/assets/images/landing/testimonial.png'
export const testimonialsvg = '/assets/images/landing/testimonial.svg'

// Sign In page 
export const eye = '/assets/images/signin/eye.svg';
export const google = '/assets/images/signin/google.svg';
export const apple = '/assets/images/signin/apple.svg';
export const bodySign = '/assets/images/signin/body-sign.png';
export const signBg = '/assets/images/signin/sign-bg.png';
export const line = '/assets/images/signin/line.svg';
export const closeEye = '/assets/images/signin/close-eye.svg';
export const modalForgot = '/assets/images/signin/modal_forgot.svg';
export const modalEmail = '/assets/images/signin/modal_email.svg';
export const modalReset = '/assets/images/signin/modal_reset.svg';
export const facebook1 = '/assets/images/facebook.svg';

// export const facebook = './images/signin/facebook.svg';
export const signRight = '/assets/images/signin/sign-right.png';
export const squareSign = '/assets/images/signin/square-sign.png';
export const signCloud = '/assets/images/signin/sign-cloud.png';
export const vectorSign = '/assets/images/signin/vector-sign.png';
export const signCircle = '/assets/images/signin/sign-circle.png';


// about us page 

export const aboutUsBackground = '/assets/images/about-us/About_us_background.png'
export const aboutUsBanner = '/assets/images/about-us/about_banner.png'
export const visionSec = '/assets/images/about-us/vision_Sec.png'
export const aboutVision = '/assets/images/about-us/about_vision.png'
export const entertainmentSec = '/assets/images/about-us/entertainment_Sec.png'

// contact us page
export const contactBanner = '/assets/images/contact-us/contact_banner.png'
export const contacLine = '/assets/images/contact-us/contact_line.svg'
export const ContactUsBack = '/assets/images/contact-us/Contact_us_back.png'
export const contactBannerBackground = '/assets/images/contact-us/contact_banner_background.png'
export const mapBackground = '/assets/images/contact-us/map_background.png'
export const contactPhone = '/assets/images/contact-us/contact_phone.svg'
export const contactLocation = '/assets/images/contact-us/contact_location.svg'
export const contactEmail = '/assets/images/contact-us/contact_email.svg'



//dashboard home 
export const dashBackground1 = '/assets/images/dashboardHome/dash-backgroung1.png'
export const one = '/assets/images/dashboardHome/1.png'
export const two = '/assets/images/dashboardHome/2.png'
export const three = '/assets/images/dashboardHome/3.png'
export const four = '/assets/images/dashboardHome/4.png'
export const mv = '/assets/images/dashboardHome/mv.png'
export const homeBanner = '/assets/images/dashboardHome/home-banner.png'
export const reelVideo = '/assets/images/dashboardHome/reelVideo.mp4'

// help 
export const faqright = '/assets/images/help/faq.png'

//Channels
export const seeAllArrow = '/assets/images/channels/seeall_arrow.svg'
export const leftArrow = '/assets/images/channels/leftarrow.svg'
export const favouriteLight = '/assets/images/channels/favourite_light.svg'
export const bites1 = '/assets/images/channels/bites1.png'

//Bites
export const playLight = '/assets/images/channels/play-light.svg'
export const biteImg = '/assets/images/channels/bites_img.png'
export const biteVideo = '/assets/images/channels/portarait-video.mp4'
export const shareIcon = '/assets/images/channels/share_icon.svg'

// Latest Challenges
export const challenge1 = '/assets/images/latestchallenge/challenges1.png'
export const featured1 = '/assets/images/latestchallenge/featured1.png'
export const playS = '/assets/images/latestchallenge/play.svg'


// interactives
export const interactives = '/assets/images/interactives/interactives.png'

// Coming Soon
export const frame208 = '/assets/images/Frame208.png'
export const logoWhite = '/assets/images/comingsoon/logo_white.svg'

//Discover category
export const education = '/assets/images/discover/education.svg'
export const society = '/assets/images/discover/society.svg'
export const sports = '/assets/images/discover/sports.svg'
export const health = '/assets/images/discover/health.svg'
export const adventure = '/assets/images/discover/adventure.svg'
export const environment = '/assets/images/discover/environment.svg'
export const fashion = '/assets/images/discover/fashion.svg'
export const technology = '/assets/images/discover/technology.svg'
export const science = '/assets/images/discover/science.svg'
export const entrepreneurship = '/assets/images/discover/Entrepreneurship.svg'
export const culture = '/assets/images/discover/culture.svg'
export const entertainment = '/assets/images/discover/entertainment.svg'

//Discover Recomended

export const recommended1 = '/assets/images/discover/recommended_1.png'
export const recommended2 = '/assets/images/discover/recommended_2.png'
export const recommended3 = '/assets/images/discover/recommended_3.png'
export const recommended4 = '/assets/images/discover/recommended_4.png'

//AllChallanges
export const whiteFilter = '/assets/images/allChallanges/white_filter.svg'
export const plusBtn = '/assets/images/allChallanges/plus_btn.svg'
export const video = '/assets/images/allChallanges/modal_video.mp4'
export const challengeBack = '/assets/images/allChallanges/challenges_back.png'
export const gEasy = '/assets/images/allChallanges/g_easy.png'
export const bunny = '/assets/images/allChallanges/bunny.mp4'

//Frame20
export const topVideoThumbnail = '/assets/images/frame20/top_video_thumbnail.png'
export const entertainmentIcon = '/assets/images/frame20/entertainment_icon.svg'
export const img1 = '/assets/images/frame20/img_1.png'
export const img2 = '/assets/images/frame20/img_2.png'
export const img3 = '/assets/images/frame20/img_3.png'
export const img4 = '/assets/images/frame20/img_4.png'
export const img5 = '/assets/images/frame20/img_5.png'
export const playLightbtn = '/assets/images/frame20/playLightbtn.svg'
export const cast1 = '/assets/images/frame20/cast_1.png'
export const cast2 = '/assets/images/frame20/cast_2.png'
export const cast3 = '/assets/images/frame20/cast_3.png'
export const cast4 = '/assets/images/frame20/cast_4.png'
export const cast5 = '/assets/images/frame20/cast_5.png'
export const trailor2 = '/assets/images/frame20/trailor_2.png'
export const trailor3 = '/assets/images/frame20/trailor_3.png'
export const trailor4 = '/assets/images/frame20/trailor_4.png'
export const trailor1 = '/assets/images/frame20/trailor_1.png'
export const more1 = '/assets/images/frame20/more_1.png'
export const more2 = '/assets/images/frame20/more_2.png'
export const more3 = '/assets/images/frame20/more_3.png'
export const more4 = '/assets/images/frame20/more_4.png'

// Favourite Series
export const image1 = '/assets/images/favseries/1.png'
export const favouriteLike = '/assets/images/favseries/favourite_like.svg'
export const playLightBtn = '/assets/images/favseries/play-light-btn.svg'
export const videoPlayButton = '/assets/images/favseries/video_play_button.svg'

// Favourite Channel
export const favGrey = '/assets/images/favchannel/fav_grey.svg'
export const favRed = '/assets/images/fav-red.svg'
export const channelEasy = '/assets/images/favchannel/channel_easy.png'
export const channelCase = '/assets/images/favchannel/channel_case.png'
export const channelGfx = '/assets/images/favchannel/channel_gfx.png'
export const channelFashion = '/assets/images/favchannel/channel_fashion.png'
export const channelHealth = '/assets/images/favchannel/channel_health.png'
export const channelTech = '/assets/images/favchannel/channel_tech.png'
export const channelUnacademy = '/assets/images/favchannel/channel_unacademy.png'

//Latest Videos
export const video1 = '/assets/images/search/video_1.png'
export const video2 = '/assets/images/search/video_2.png'
export const video3 = '/assets/images/search/video_3.png'
export const video4 = '/assets/images/search/video_4.png'
export const video5 = '/assets/images/search/video_5.png'
export const video6 = '/assets/images/search/video_6.png'
export const video7 = '/assets/images/search/video_7.png'
export const video8 = '/assets/images/search/video_8.png'

// edit profile

export const editProfile = '/assets/images/edit_icon.png'
export const profileImage = '/assets/images/edit_profile.png'
export const crossWhite = '/assets/images/cross_white.svg'
export const noDataFound = '/assets/images/no_data_found.svg'

//Error images
export const noChallenges = '/assets/images/challenges.svg'
export const noSeries = '/assets/images/series.svg'
export const noFavourites = '/assets/images/favourites.svg'
export const noBites = '/assets/images/bites.svg'
export const noInteractives = '/assets/images/interactives.svg'
export const mychallanges = '/assets/images/challenges_new.svg'
export const noSearchResults = '/assets/images/searchnew.svg'
export const noChannelFound = '/assets/images/channel.svg'
export const SampleNextArrow = "/assets/images/channle/leftarrow.svg"

// creater dashboard 
export const filter_icon = '/assets/images/creater-dashboard/filter.svg'
export const SCHEDULE_DOT = '/assets/images/creater-dashboard/schedule-dot.svg'
export const live_clock = '/assets/images/creater-dashboard/clock.svg'
export const live_calender = '/assets/images/creater-dashboard/livecalender.svg'
export const gallery = '/assets/images/creater-dashboard/gallery.svg'
export const plusgray = '/assets/images/creater-dashboard/grey-plus.svg'
export const gray_add = '/assets/images/creater-dashboard/grey-add.svg'
export const pink_add = '/assets/images/creater-dashboard/pink-add.svg'
export const thumb_img = '/assets/images/creater-dashboard/thumb-img.svg'
export const pink_video = '/assets/images/creater-dashboard/pink-video.svg'
export const add_cast = '/assets/images/creater-dashboard/add-cast.svg'
export const cast_user = '/assets/images/creater-dashboard/cast-user.png'
export const pinkgallery = '/assets/images/creater-dashboard/pink-gallery.svg'
export const themecross = '/assets/images/creater-dashboard/theme-cross.svg'
export const crosstheme = '/assets/images/creater-dashboard/cross-theme.svg'
export const crossthemebgpink = '/assets/images/creater-dashboard/cross-theme-bg-pink.svg'
export const selectImage = '/assets/images/creater-dashboard/select-image.svg'
export const dotsbg = '/assets/images/creater-dashboard/dot-bg.svg'
export const dummy = '/assets/images/creater-dashboard/dummy.png'
export const graycalender = '/assets/images/creater-dashboard/gary-calender.svg'
export const graytimer = '/assets/images/creater-dashboard/gray-timer.svg'
export const embed = '/assets/images/creater-dashboard/embed.svg'
export const linkVideo = '/assets/images/creater-dashboard/link.svg'
export const noCreatorSeries = '/assets/images/creater-dashboard/no-creator-series.svg'
export const bucketImg = '/assets/images/creater-dashboard/bucket.svg'
export const typeBelow = '/assets/images/creater-dashboard/type-below.svg'
export const typeAbove = '/assets/images/creater-dashboard/type-above.svg'
export const blackdot = '/assets/images/creater-dashboard/blackdot.svg'
export const dotsVertical = '/assets/images/creater-dashboard/dotsVertical.svg'
export const downarrowdown = '/assets/images/creater-dashboard/downaarow.svg'
export const overlaypink = '/assets/images/creater-dashboard/pink-overlay.png'
export const bucketlist = '/assets/images/creater-dashboard/bucketlist.svg'
export const addbucket = '/assets/images/creater-dashboard/addbucket.svg'
export const rightArrowLive = '/assets/images/creater-dashboard/right-arrow-live.svg'
export const liveIcon = '/assets/images/creater-dashboard/live-icon.svg'
export const liveAudio = '/assets/images/creater-dashboard/audio.svg'
export const liveVideo = '/assets/images/creater-dashboard/video.svg'
export const mute_video = '/assets/images/creater-dashboard/mute-video.svg'
export const mute_audio = '/assets/images/creater-dashboard/mute-audio.svg'
export const no_live = '/assets/images/creater-dashboard/no-live.svg'
export const circle_live = '/assets/images/creater-dashboard/circleLive.svg'
export const live_icon = '/assets/images/creater-dashboard/live-icon.svg'
export const send = '/assets/images/creater-dashboard/send.svg'
export const dotshorizontal = '/assets/images/creater-dashboard/dotshorizontal.svg'
export const smile= '/assets/images/creater-dashboard/smile.svg'
export const bottomshape = '/assets/images/creater-dashboard/bottom-shape.svg'
export const graphicon = '/assets/images/creater-dashboard/graph-icon.svg'
export const subscribeicon = '/assets/images/creater-dashboard/subscribe-icon.svg'
export const tincome = '/assets/images/creater-dashboard/tincome.svg'
export const tipsbox= '/assets/images/creater-dashboard/tips.svg'
export const videoicon = '/assets/images/creater-dashboard/video-icon.svg'
export const arrowbg = '/assets/images/creater-dashboard/arrow-bg.svg'
export const playbg = '/assets/images/creater-dashboard/bgplay.svg'
export const like = '/assets/images/like-icon.svg'
export const reply_icon = '/assets/images/creater-dashboard/reply-icon.svg'
export const uparrow = '/assets/images/up-arrow.png'
export const access = '/assets/images/creater-dashboard/access.svg'
export const heartfill = '/assets/images/creater-dashboard/heart_fill.svg'
export const profile_image_placeholder = '/assets/images/creater-dashboard/profile_image_placeholder.png'
export const message = '/assets/images/creater-dashboard/message.svg'
export const Send_feed = '/assets/images/creater-dashboard/Send_feed.svg'
export const shopIcon = '/assets/images/creater-dashboard/shop.svg'
export const cancel = '/assets/images/creater-dashboard/cancel.png'
export const check = '/assets/images/creater-dashboard/check.png'
export const priceCart="/assets/images/price-cart-img.png"
export const upwardIcon="/assets/images/upward_icon.svg"
export const angry = '/assets/images/angry.svg'
export const happy = '/assets/images/happy.svg'
export const sad = '/assets/images/sad.svg'
export const shock = '/assets/images/shock.svg'
export const heartlike = '/assets/images/heartlike.svg'
export const postlike = '/assets/images/like.svg'
export const galleryImg = '/assets/images/creater-dashboard/galleryImg.svg'
export const shareFreinds = '/assets/images/creater-dashboard/shareFriend.svg'
export const facebookImg = '/assets/images/creater-dashboard/facebook_img.svg'
export const instaImg = '/assets/images/creater-dashboard/insta_img.svg'
export const linkdenImg = '/assets/images/creater-dashboard/linkden_img.svg'
export const twitterImg = '/assets/images/creater-dashboard/twitter.svg'
export const pinterest = '/assets/images/creater-dashboard/pinterest.svg'
export const copyLink = '/assets/images/creater-dashboard/copy_link.svg'
export const dummyFeed = '/assets/images/creater-dashboard/dummyFeed.svg'
export const comming_soon = '/assets/images/creater-dashboard/comming_soon.png'
export const download_img = '/assets/images/creater-dashboard/download.svg'
export const selectVideo = '/assets/images/creater-dashboard/selectVideo.svg'
export const audio_img = '/assets/images/creater-dashboard/audio-img.svg'
export const selectAudio = '/assets/images/creater-dashboard/add-audio.svg'
export const show_audio = '/assets/images/creater-dashboard/show_audio.svg'
export const clinet_demo = '/assets/images/creater-dashboard/client-demo.jpg'
export const pink_headphone = '/assets/images/creater-dashboard/pink_headphone.svg'
export const audio_icon = '/assets/images/creater-dashboard/audio-icon.png'
export const play_btn = '/assets/images/creater-dashboard/play-btn.png'
export const no_audio = '/assets/images/creater-dashboard/no audios.svg'
export const no_videos = '/assets/images/creater-dashboard/no videos.svg'
export const no_images = '/assets/images/creater-dashboard/no image.svg'
export const audio_pause = '/assets/images/creater-dashboard/pause_audio.svg'
export const challenge_icon = '/assets/images/creater-dashboard/challenge_icon.png'
export const playBtn = '/assets/images/creater-dashboard/playbtn.svg'
export const playGrey = '/assets/images/creater-dashboard/playgrey.svg'
export const bites_icon = '/assets/images/bite-slide.png'
export const portrait_video = '/assets/images/portrait.png'
export const buy_btn = '/assets/images/buy-btn.svg'
export const DEMO_IMAGE_ONE = '/assets/images/creater-dashboard/demoImage1.png'
export const DEMO_IMAGE_TWO = '/assets/images/creater-dashboard/demoImage2.png'
export const DEMO_IMAGE_THREE = '/assets/images/creater-dashboard/demoImage3.png'
export const DEMO_IMAGE_FOUR= '/assets/images/creater-dashboard/demoImage4.png'
export const DEMO_IMAGE_FIVE = '/assets/images/creater-dashboard/demoImage5.png'
export const DEMO_IMAGE_SIX = '/assets/images/creater-dashboard/demoImage6.png'
export const DEMO_IMAGE_SEVEN = '/assets/images/creater-dashboard/demoImage7.png'
export const DEMO_IMAGE_EIGHT = '/assets/images/creater-dashboard/demoImage8.png'
export const Arrow_channel = '/assets/images/creater-dashboard/arrowchannel.svg '
export const Metaverse = '/assets/images/creater-dashboard/metaverse.png'
export const MetaverseText = '/assets/images/creater-dashboard/metaverse-text.png'
export const Whitecross = '/assets/images/creater-dashboard/white-cross.svg'
export const Righticons = '/assets/images/creater-dashboard/right.svg'
export const Lefticons = '/assets/images/creater-dashboard/left.svg'
export const pinkLeftIcons = '/assets/images/creater-dashboard/left-pink-icons.svg'
export const pinkRightIcons = '/assets/images/creater-dashboard/right-pink-icons.svg'
export const unmute_trailor = '/assets/images/creater-dashboard/unmute_trailor.svg'
export const mute_trailor = '/assets/images/creater-dashboard/mute_trailor.svg'
export const Pinksmile = '/assets/images/pink-smile.svg'
export const no_post_yet = '/assets/images/creater-dashboard/no_post_yet.svg'
export const Whatsapp_bg = '/assets/images/creater-dashboard/whatsapp_bg.svg'






