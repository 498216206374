import React, { useEffect, useState } from 'react'
import apiHelper from '../../Helper/api-helper'
import DEVELOPMENT_CONFIG from "../../Helper/config"
import ReactLoader from '../../ControlComponents/react-loader'
import DownloadDetail from './download-detail'
function Download() {
    const [channelList, setChannelList] = useState([])
    const [loading, setLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)



    return (
        <div className='main-inner-content'>
            {loading && <ReactLoader />}
            <section className="streaming py-6 response-padding ">
                <div className="container-fluid">
                    <div className="row">
                        <div className="">
                            <h3 className="fw-600">Downloads</h3>
                        </div>
                    </div>
                </div>
            </section>
            <section className="favchannel-main-cus">
                {/* <PurchaseList channelList={channelList} loading={loading} /> */}
                <DownloadDetail />
            </section>
        </div>

    )
}

export default Download
