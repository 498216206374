import React, { useEffect, useState } from 'react';
import { profile_image_placeholder } from '../../Constants/images';

const ChatComponent = ({ videoTimestamp, messages }) => {
  const [visibleChat, setVisibleChat] = useState([]);
  const [expandedComments, setExpandedComments] = useState([]);

  useEffect(() => {
    const filteredChat = messages?.filter(
      chat => chat.timeStamp <= videoTimestamp,
    );
    setVisibleChat(filteredChat);
  }, [messages, videoTimestamp]);


  const renderCommentText = (text, uuid) => {
    const isExpanded = expandedComments.includes(uuid);

    if (text.length > 200 && !isExpanded) {
      return (
        <>
          {`${text.substring(0, 200)}...`}
          <span className="read-more" style={{ cursor: "pointer" }} onClick={() => handleExpandComment(uuid)}> Read More</span>
        </>
      );
    } else if (isExpanded) {
      return (
        <>
          {text}
          <span className="read-more" style={{ cursor: "pointer" }} onClick={() => handleCollapseComment(uuid)}> ...Read Less</span>
        </>
      );
    } else {
      return text;
    }
  };

  const handleExpandComment = (uuid) => {
    setExpandedComments(prevState => [...prevState, uuid]);
  };

  const handleCollapseComment = (uuid) => {
    setExpandedComments(prevState => prevState.filter(item => item !== uuid));
  }

  
  return (
    <div className='get-msg' style={{ position: 'absolute' ,width:'90%'}}>
      {
        visibleChat.map((item, index) => {
          const formattedTime = new Date(item?.createdAt).toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            // second: '2-digit',
            hour12: true
          });
          return (
            <div className='chat-wrapper border-bottom' key={index}>
              <div className='chat-listing py-3'>
                <div className='profile-chat'>
                  
                  <img src={item.profile_pic ? item.profile_pic: profile_image_placeholder}></img>
                </div>
                <div className='d-flex align-items-start'>
                  <p className='mb-0 lh-sm f16-size fw-500  text-capitalize side_text user-sidetext'>{item.name}:</p>
                  <p className='mb-0 channel-text f16-size fw-600 user-side-comment'>
                    {renderCommentText(item.comment,item.uuid)}</p>
                </div>
                <p className='user-side-day'>
                  {formattedTime}</p>
              </div>
            </div>
          )
        }
        )
      }      
    </div>
  );
};

export default ChatComponent;
