import React from 'react';
import { noDataFound } from '../../Constants/images';

const NotFound = () => {
    let token = localStorage.getItem("token");
    return (
        <>
            {token ? (
                <div className='main-inner-content'>
                    <div className='no-data-found'>
                        <img src={noDataFound} className='img-fluid' alt="No Data Found" />
                    </div>
                </div>

            ) : (
                <div className='data-main'>
                <div className='no-data-found'>
                    <img src={noDataFound} className='img-fluid' alt="No Data Found" />
                </div>
                </div>
            )}</>

    );
};

export default NotFound;
