import React, { useState } from 'react'
import { modalCross } from '../../Constants/images'


const FilterModal = ({ setFilterModal, setTagsSelected, tagsSelected, catList, getFilterAPI, setTabSwitch, tabSwitch }) => {

  const [selectedTags, setSelectedTags] = useState(tagsSelected)
  function handleSelection(dataObj) {
   
    let tagsData = [...selectedTags];
    const tagsIndex = tagsData?.findIndex(product => product?.id === dataObj?.id);
    if (tagsIndex != -1) {
      if (tagsIndex > -1) {
        tagsData?.splice(tagsIndex, 1);
      }
    } else {
      tagsData?.push(dataObj);
    }
    setSelectedTags(tagsData);
    const namesArray = tagsData?.map(item => item?.id);
    setTagsSelected(namesArray);
  } 

  return (
    <div className="modal fade show modal-react" style={{ display: "block", opacity: "1", backdropFilter: "blur(5px)" }}>
      <div className='loader'>
      </div>
      <div className="modal-dialog modal-dialog-centered modal-schedule-width">
        <div className="modal-content modal-radius  border-0">
          <div class="modal-header forgot-header align-items-center pt-4">
            <h3 class="modal-title text-center mx-auto forgot-title" id="exampleModalLabel">Filter</h3>
            <a class="m-0 px-0 btn-closed-main" data-bs-dismiss="modal" aria-label="Close">
              <img onClick={() => setFilterModal(false)} style={{ cursor: "pointer" }} src={modalCross} alt="" className="img-fluid" /></a>
          </div>
          <div className="modal-body forgot-body  p-4 pt-4 p-md-0">
            <div className="filter-category-cus pt-3 pb-4">
              <h4>Category</h4>
            </div>
            <div className="filter-head">
              {
                catList?.map((dataObj) => {
                  return (
                    <div style={{ cursor: "pointer" }} key={dataObj?.id} onClick={() => handleSelection(dataObj)}>
                      <p className={selectedTags?.findIndex(
                        item => item?.id === dataObj?.id,
                      ) != -1 ? "active cus-btn-blue" : "cus-btn-blue"}>{dataObj?.name}</p>
                    </div>
                  )
                })
              }
            </div>
            <div className="filter-category-cus pt-5 pb-3">
              <h4>Sort By</h4>
            </div>
            <div className="filter-head" style={{ cursor: "pointer" }} >
              <div onClick={() => setTabSwitch(0)}>
                <p className={tabSwitch == 0 ? "active cus-btn-blue" : "cus-btn-blue"}>Latest</p>
              </div>
              <div onClick={() => setTabSwitch(1)}>
                <p className={tabSwitch == 1 ? "active cus-btn-blue" : "cus-btn-blue"}>Popular</p>
              </div>
            </div>
            <div className="my-5" onClick={getFilterAPI} >
              <a className="common-btn w-100 text-white" data-bs-dismiss="modal" aria-label="Close" style={{ cursor: "pointer" }}>Save</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FilterModal
