import React, { useEffect, useState } from 'react';
import apiHelper from '../../Helper/api-helper';
import DEVELOPMENT_CONFIG from '../../Helper/config';
import './bucket.css';
import ReactLoader from '../../ControlComponents/react-loader'

import { biteImg, bucketImg} from '../../Constants/images';
import AddBucketModal from './addBucketModal';
import { useNavigate } from 'react-router-dom';
import BucketAccess from './bucketDetail';
import BucketList from './bucketList';


const Bucket = () => {
const navigate = useNavigate()
    const [refresh, setRefresh] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [addBucketModal, setAddBucketModal] = useState(false)

    const handleAdd = () => {
        setAddBucketModal(!addBucketModal)
    }

    async function getDetailsAPI() {
        setIsLoading(true)
        let result = await apiHelper.getRequest("channel/get-favorites-list?type=")
        if (result.code == DEVELOPMENT_CONFIG.statusCode) {

            setIsLoading(false)
        }
        else if (result.code == 401) {
            let refreshToken = localStorage.getItem("refresh-token")
            let data = {
                "refresh_token": refreshToken,
                "scope": "refresh_token"
            }
            let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
            if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
                localStorage.setItem("token", refreshTokenResult.data.token)
                localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
                setRefresh(true)
            }
        }
    }

    // useEffect(() => {
    //     getDetailsAPI()
    // }, [ refresh])
    return (
          <div>
            {isLoading && <ReactLoader />}
            <div className="main-inner-content">
                <div className='row align-items-center'>
                    <div className='col-sm-5 ps-4'>
                        <div className="">
                            <h3 className="fw-600 mb-0">Tiers</h3>
                        </div>
                    </div>
                    <div style={{ flexDirection: 'column' }} className='no-search-results'>
                        <img src={bucketImg} />
                        <div className="mt-5" onClick={handleAdd}>
                            <a className="bucket-btn common-btn w-100" style={{cursor:"pointer"}}>Add Tier</a>
                        </div>
                    </div>
                    {/* <BucketList/> */}
                </div>
            </div>
            {addBucketModal && <AddBucketModal setAddBucketModal={setAddBucketModal} />}
          </div>
    )
}

export default Bucket;