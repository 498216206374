import React from 'react'
import { img1, img2, img3, img4, img5, playLightBtn, seeAllArrow } from '../../../../Constants/images';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

function MoreEpisode() {
   const owlCarousel2 =  {
    loop: true,
    margin: 10,
    autoplay: false,
    nav: true,
    responsive: {
      0: {
        items: 1,
      },

      600: {
        items: 2,
      },

      1024: {
        items: 4.5,
      },

      1366: {
        items: 5.5,
      },
    },
  }; 
  return (
    <div>
        <section className="more_epsoide py-60">
          <div className="container">
            <div className="chanel-header">
              <h3 className="fw-600 mb-0">More Episodes</h3>
              <button className="see-all fw-600 f18-size">
                See all
                <img src={seeAllArrow} alt="" className="ms-2" />
              </button>
            </div>

            <div className="epsiode-slider mt-5">
              <OwlCarousel className="owlslider owl-carousel owl-theme" id="epsiode-owl"{...owlCarousel2}>
                <div target="1" className="item">
                  <div className="epsoide-slider-wrapper">
                    <img
                      src={img1}
                      className="movie-more br30"
                      alt=""
                    />
                    <div className="epsoide-title">
                      <h5 className="white m-0">Days Gone by</h5>
                      <p className="white">43:06</p>
                    </div>
                    <div className="trending-number">
                      <h2 className="white">1</h2>
                    </div>
                    <div className="epsoide-play">
                      <button className="eps-play">
                        <img src={playLightBtn} alt="" />
                      </button>
                    </div>
                  </div>
                </div>
                <div target="2" className="item">
                  <div className="epsoide-slider-wrapper">
                    <img
                      src={img2}
                      className="movie-more br30"
                      alt=""
                    />
                    <div className="epsoide-title">
                      <h5 className="white m-0">Days Gone by</h5>
                      <p className="white">43:06</p>
                    </div>
                    <div className="trending-number">
                      <h2 className="white">2</h2>
                    </div>
                    <div className="epsoide-play">
                      <button className="eps-play">
                        <img src={playLightBtn} alt="" />
                      </button>
                    </div>
                  </div>
                </div>
                <div target="3" className="item">
                  <div className="epsoide-slider-wrapper">
                    <img
                      src={img3}
                      className="movie-more br30"
                      alt=""
                    />
                    <div className="epsoide-title">
                      <h5 className="white m-0">Days Gone by</h5>
                      <p className="white">43:06</p>
                    </div>
                    <div className="trending-number">
                      <h2 className="white">3</h2>
                    </div>
                    <div className="epsoide-play">
                      <button className="eps-play">
                        <img src={playLightBtn} alt="" />
                      </button>
                    </div>
                  </div>
                </div>
                <div target="4" className="item">
                  <div className="epsoide-slider-wrapper">
                    <img
                      src={img4}
                      className="movie-more br30"
                      alt=""
                    />
                    <div className="epsoide-title">
                      <h5 className="white m-0">Days Gone by</h5>
                      <p className="white">43:06</p>
                    </div>
                    <div className="trending-number">
                      <h2 className="white">4</h2>
                    </div>
                    <div className="epsoide-play">
                      <button className="eps-play">
                        <img src={playLightBtn} alt="" />
                      </button>
                    </div>
                  </div>
                </div>
                <div target="5" className="item">
                  <div className="epsoide-slider-wrapper">
                    <img
                      src={img5}
                      className="movie-more br30"
                      alt=""
                    />
                    <div className="epsoide-title">
                      <h5 className="white m-0">Days Gone by</h5>
                      <p className="white">43:06</p>
                    </div>
                    <div className="trending-number">
                      <h2 className="white">5</h2>
                    </div>
                    <div className="epsoide-play">
                      <button className="eps-play">
                        <img src={playLightBtn} alt="" />
                      </button>
                    </div>
                  </div>
                </div>
              </OwlCarousel>
            </div>
          </div>
        </section>
    </div>
  )
}

export default MoreEpisode;