import React, { useState, useEffect } from 'react'
import { bites1, playLight, seeAllArrow } from '../../../../Constants/images';
import ROUTES from '../../../../Helper/route-helper';
import apiHelper from '../../../../Helper/api-helper';
import { useNavigate } from 'react-router-dom';
import DEVELOPMENT_CONFIG from '../../../../Helper/config';
import './bites.css'
import BiteModal from '../../../../Components/Bites';

function Bites(props) {
   const [bitesList, setBitesList] = useState([])
   const [bitesList2, setBitesList2] = useState([])
   const navigate = useNavigate()
   const [refresh, setRefresh] = useState(false)
   const [showBite, setShowBite] = useState(false)
   const [index, setIndex] = useState(0)
   const [offset, setOffset] = useState(0);
   const [limit, setLimit] = useState(4);
   const [currentBiteIndex, setCurrentBiteIndex] = useState(0);

   async function getAPI() {
      props?.setIsLoading(true)
      let url
      if (props?.category != 0) {
         url = "bites/bites-list?category_id=" + props.category + "&sort=z-a" 
      } else {
         url = "bites/bites-list?sort=z-a"
      }
      let result = await apiHelper.getRequest(url)
      if (result.code === DEVELOPMENT_CONFIG.statusCode) {
         setBitesList(result.data?.rows)
         props?.setIsLoading(false)
      } else if (result.code == 401) {
         let refreshToken = localStorage.getItem("refresh-token")
         let data = {
            "refresh_token": refreshToken,
            "scope": "refresh_token"
         }
         let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
         if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
            localStorage.setItem("token", refreshTokenResult.data.token)
            localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
            setRefresh(true)
         }
      }
   }
   
   async function getDetailAPI(index) {
      props?.setIsLoading(true)
      let url ="bites/bites-detail?id=" + index
      let url2 = "bites/bites-list?"+"offset="+offset+"&limit="+limit
      let result = await apiHelper.getRequest(url)
      let result2;
      if (result.code === DEVELOPMENT_CONFIG.statusCode) {
         result2 = await apiHelper.getRequest(url2);
         let newArr = [result.data, ...result2.data.data] 
         setBitesList2(newArr.filter(
            (obj, index, self) => index === self.findIndex((o) => o.id === obj.id)
          ));
         props?.setIsLoading(false)  
          setOffset(offset + 4);
      } else if (result.code == 401 ) {
         let refreshToken = localStorage.getItem("refresh-token")
         let data = {
            "refresh_token": refreshToken,
            "scope": "refresh_token"
         }
         let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
         if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
            localStorage.setItem("token", refreshTokenResult.data.token)
            localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
            setRefresh(true)
         }
      }
   }

   const handleChange = (index) => {
      console.log(index,'=====')
      // getDetailAPI(index)
      setShowBite(true)
      setCurrentBiteIndex(index);
   }

   useEffect(() => {
      getAPI()
   }, [props.category, refresh]);

   return (
      <div className='bites-home'>
         {
            bitesList?.length > 0 &&

            <section className="bite response-padding">
               <div className="container">
                  <div className="chanel-header">
                  <h3 className="fw-600 mb-0 common-btn btn-blue btn-shape f20-size" style={{fontSize:"20px"}}>Bites</h3>
                     <button onClick={() => navigate(ROUTES.BITES)} className="see-all fw-600 f18-size">See all <img src={seeAllArrow} className="ms-2" /></button>
                  </div>
                  <div className="chanel-wrapper mt-2">
                     <div className="row" style={{cursor:"pointer"}} >
                        {
                           bitesList?.slice(0, 3).map((bite, index) =>
                              <div  className="col-lg-4  col-md-6  response-col">
                                 <div className="chanel-content bg-white">
                                    <div className="bite-relative">
                                       <img src={bite?.thumbnail} className="bite-img bite-thumb" onClick={() => handleChange(index)}  key={bite.id}/>
                                    </div>
                                    <div className="bite-wrapper w-100">
                                       <h4>{bite?.title}</h4>
                                       <div className="chanel-text d-flex gap-3 align-items-center"  onClick={()=>navigate(ROUTES.CHANNELS_DETAILS + "?cd=biteChannel", { state: { item: bite}})}>
                                          <img src={bite?.biteChannel?.profile_pic} className="bite-inner-img" />
                                          <div>
                                             <h6 className='fw-600 mb-0'>{bite?.biteChannel?.name}</h6>
                                             <p>{bite?.category?.name}</p>
                                          </div>
                                       </div>
                                       <button onClick={() => handleChange(index)} className="common-btn"><img src={playLight} />Play</button>
                                    </div>
                                 </div>
                              </div>
                           )
                        }
                     </div>
                  </div>
               </div>
               {showBite && 
                  <BiteModal currentBiteIndex={currentBiteIndex} setCurrentBiteIndex={setCurrentBiteIndex} index={index} bitesList={bitesList} showBite={showBite} setShowBite={setShowBite} />
               }
            </section>
         }
      </div>
   )
}

export default Bites;