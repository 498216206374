import React from 'react'

const PriceTag = ({classes=null,firstValue=null,secondValue=null}) => {
  return (
    <div className={classes!==null ? `${classes}` : ""}>
    {firstValue!==null ?  `${firstValue}`: ""}
    </div>
  )
}

export default PriceTag