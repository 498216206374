import React, { useState } from 'react'
import { modalCross } from '../../Constants/images'

const DeleteLiveModal = ({ setDeleteModal, getDeleteAPI,check
}) => {
    return (
        <div className="modal fade show modal-react" style={{ display: "block", opacity: "1", backdropFilter: "blur(5px)" }}>
            <div className='loader'>
            </div>
            <div className="modal-dialog modal-dialog-centered modal-schedule-width">
                <div className="modal-content modal-radius  border-0">
                    <div className="modal-header forgot-header align-items-center pt-4">
                        <h3 className="modal-title text-center mx-auto forgot-title" id="exampleModalLabel">Delete {check == "past"?"Recorded live":"Scheduled"} </h3>
                        <a onClick={() => {
                            setDeleteModal(false)
                        }}
                            className="m-0 px-0 btn-closed-main" data-bs-dismiss="modal" aria-label="Close"><img style={{ cursor: "pointer" }} src={modalCross} alt="" className="img-fluid btn-cloesed" /></a>
                    </div>
                    <div className="modal-body forgot-body verify-modal-box px-2 px-sm-4">
                        <div className='delete-content text-center'>
                            <p> Are you sure you want to delete the scheduled?
                            </p>
                        </div>
                        <div className='text-center p-3' style={{ cursor: "pointer" }}>
                            <button className="common-btn yes-option" onClick={() => getDeleteAPI()}>Yes</button>

                            <button className="no-btn" onClick={() => {
                                setDeleteModal(false)
                            }}> No</button>                     </div>

                    </div>
                </div>
            </div>
        </div>

    )
}

export default DeleteLiveModal
