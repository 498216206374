import React, { useState } from 'react'
import { modalCross, editProfile, profileImage, profile_image_placeholder } from '../../Constants/images'
import './editProfile.css'
import { S3_CREDS } from '../../Helper/config';
import DEVELOPMENT_CONFIG from '../../Helper/config'
import { NotificationManager } from 'react-notifications';
import apiHelper from '../../Helper/api-helper';
import ReactLoader from '../../ControlComponents/react-loader';
import PhoneInput from "react-phone-input-2";
import ERR_MESSAGE from '../../Helper/error-helper';
import "react-phone-input-2/lib/bootstrap.css";
import ProgressBarComponent from '../../ControlComponents/progressBar';
import uploadToS3 from '../../CreaterDashboard/component/fileUpload';


const EditProfile = (props) => {
    const [progressLoading, setProgressLoading] = useState(false)
    const [progress, setProgress] = useState();
    const [isLoading, setLoading] = useState(false)
    const [_, forceUpdate] = useState(0)
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        const url = URL.createObjectURL(file);
        props.profileDetail.profile_pic = url
        forceUpdate(Math.random())
        fileUpload(file)
    };
    const [errors, setErrors] = useState("");

    const fileUpload = async (file, thumb) => {
        const state = { videoLocation: null, thumbnailLocation: null };
        setProgressLoading(true)
        try {
            if (file) {
                await uploadToS3(thumb, file, state, setProgress);
                if (state.videoLocation) {
                    setProgressLoading(false);
                    props.profileDetail.profile_pic = state.videoLocation
                    forceUpdate(Math.random())
                }
            }

        } catch (error) {
        }
    };



    async function getDetailsAPI() {
        let result = await apiHelper.getRequest("auth/get-my-detail")
        if (result.code == DEVELOPMENT_CONFIG.statusCode) {
            props.setProfileDetail(result.data);
            forceUpdate(Math.random())
        }
    }

    const handleOnChange = (value, data) => {
        props.setProfileDetail({
            ...props?.profileDetail,
            country: data.dialCode,
            phone_no: value.slice(data.dialCode.length)
        })
    }

    const handleOnChangeName = (e) => {
        props?.setProfileDetail({
            ...props?.profileDetail,
            name: e.target.value
        })
    }


    const handleValidation = () => {
        let errors = {};
        let formIsValid = true;
        if (!props?.profileDetail?.name || props?.profileDetail?.name.trim().length === 0) {
            formIsValid = false;
            errors["userName"] = ERR_MESSAGE.EMPTY_FULLNAME
        } else if (props?.profileDetail?.name.length < 3 || props?.profileDetail?.name.length > 30) {
            formIsValid = false;
            errors["userName"] = ERR_MESSAGE.INVALID_FULLNAME
        }
        else if (!props?.profileDetail?.phone_no || props?.profileDetail?.phone_no.trim().length === 0) {
            formIsValid = false;
            errors["mobile"] = ERR_MESSAGE.MOBILE_EMPTY
        } else if (props?.profileDetail?.phone_no.length <= 9) {
            formIsValid = false;
            errors["mobile"] = ERR_MESSAGE.INVALID_MOBILE
        }
        setErrors(errors);
        return formIsValid;
    }

    const saveProfile = async () => {

        if (handleValidation()) {
            let data = {
                "name": props?.profileDetail?.name,
                "phone_no": props?.profileDetail?.phone_no,
                "country": props?.profileDetail?.country,
                "profile_pic": props?.profileDetail?.profile_pic
            }
            setLoading(true)
            let result = await apiHelper.postRequest("auth/set-profile", data)
            if (result.code === DEVELOPMENT_CONFIG.statusCode) {
                getDetailsAPI()
                setLoading(false)
                NotificationManager.success(result.message)
                props?.setIsModalVisible(!props?.isModalVisible);
            } else {
                setLoading(false)
                NotificationManager.error(result.message)
            }
        }

    }


    return (
        <>

            {props?.isModalVisible && <div className="modal fade editprofile-modal show" style={{ display: "block", opacity: "1", backdropFilter: "blur(15px)" }}>
                {isLoading &&
                    <div className='loader'>
                        <ReactLoader />
                    </div>
                }
                {progressLoading && <>
                <ProgressBarComponent progress={progress} />
            </>}
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content modal-radius forgot-body">
                        <div className="modal-header forgot-header align-items-center position-relative">
                            <h3 className="modal-title text-center mx-auto forgot-title" id="exampleModalLabel">Edit Profile</h3>
                        </div>
                        <a onClick={() => props?.setIsModalVisible(!props?.isModalVisible)} className="m-0 px-0 close-btn" data-bs-dismiss="modal" aria-label="Close">
                            <img src={modalCross} alt="" className="img-fluid" />
                        </a>

                        <div className="modal-body forgot-body px-2 px-sm-5">
                            <div className="forgot-img">
                                <div className="profile-upload mb-2 user-profile-upload position-relative" >
                                    {props?.profileDetail?.profile_pic ? <img src={props?.profileDetail?.profile_pic} id="profileimage" alt="" className="profile-edit" /> : <img src={profile_image_placeholder} id="profileimage" alt="" className="profile-edit" />}
                                    <img src={editProfile} alt="" className="img-fluid profile-edit-icon" />
                                    <input onChange={handleFileChange} type="file" accept="image/png, image/jpg, image/jpeg" className="profile-edit-file" />
                                </div>
                                <p className="fw-400 profile-mail mailto:pt-1">{props?.profileDetail?.email}</p>

                                <form>
                                    <div className="form-group mb-3">
                                        <label className="fw-500 mb-2 forgot-label">Name</label>
                                        <input onChange={handleOnChangeName} type="text" className="form-control  fw-500 forgot-input" value={props?.profileDetail?.name} placeholder="Name" />
                                        <span className="err_msg">{errors.userName}</span>
                                    </div>
                                    <div className="form-group mb-3">
                                        <PhoneInput
                                            required={true}
                                            value={props?.profileDetail?.country + props?.profileDetail?.phone_no}
                                            enableSearch={true}
                                            onChange={handleOnChange}
                                        />
                                        <span className="err_msg">{errors.mobile}</span>
                                        {/* {/ <input type="text" id="mobile_code" name='mobile' value={fields.mobile} onChange={handleChange} required={true} error={errors.mobile} className="form-control input-login fw-500" placeholder="Mobile No." maxLength={11} /> /} */}
                                    </div>
                                    {/* <div className="form-group mb-3">
                                    <label className="fw-500 mb-2 forgot-label">Mobile No.</label>
                                    <input type="tel" className="form-control  fw-500 forgot-input" placeholder="Mobile no." />
                                </div> */}
                                    <div onClick={saveProfile} className="my-5">
                                        <a className="common-btn w-100" >Save</a>
                                    </div>
                                </form>
                            </div>

                        </div>

                    </div>
                </div>
            </div>}
        </>

    )
}

export default EditProfile