import React, { useState } from 'react';
import './seriesDetail.css'
import { playLightBtn } from '../../Constants/images';
import SeriesVideoPlayer from '../../Components/VideoPlayer/seriesVideoPlayer';

function ClipPage({ setTabSwitch, clip, modifyDuration }) {
    const [show, setShow] = useState(false)
    const [video, setVideo] = useState()
    const handleClick = (item) => {
        setVideo(item?.video)
        setShow(!show)
    }

    return (
        <div>
            <section className="streaming py-6 response-padding ">
                <div className="container-fluid">
                    <div className="row">
                        <div className="stream-btn pt-3">
                            <button onClick={() => setTabSwitch(0)} className="common-btn btn-white">Trailors</button>
                            <button onClick={() => setTabSwitch(1)} className="common-btn btn-blue">Clips</button>
                        </div>
                    </div>
                </div>
            </section>
            <section className=" favchannel-main-cus">
                <div className="container-fluid">
                    <div className='row'>
                        <div className=" trailor-slide-main mt-5 ">
                            {
                                clip?.map((item) => {
                                    return (
                                        <div
                                            key={item?.id} style={{ cursor: "pointer" }}
                                            onClick={() => handleClick(item)}
                                            className="item ">
                                            <div className="trailor-slider-cus ">
                                                <img
                                                    src={item.thumbnail}
                                                    className="movie-more br30"
                                                    alt=""
                                                />
                                                <div className="trailor-duration">
                                                    <p className="white">
                                                        {modifyDuration(item.duration)}
                                                    </p>
                                                </div>
                                                <div className="trailor-play">
                                                    <button className="eps-play">
                                                        <img src={playLightBtn} alt="" className='img-fluid' />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
                {
                    show && <SeriesVideoPlayer show={show} setShow={setShow} video_url={video} />
                }
            </section>

        </div>
    )
}

export default ClipPage;