import React, { useEffect, useState } from 'react'
import { bites1, noBites, playLight } from '../../../Constants/images';
import DEVELOPMENT_CONFIG from '../../../Helper/config';
import apiHelper from '../../../Helper/api-helper';
import ReactLoader from '../../../ControlComponents/react-loader';
import '../../Bite/bite.css'
import BiteModal from '../../../Components/Bites';
function NewTalents() {
   const [refresh, setRefresh] = useState(false)
   const [talentList, setTalentList] = useState([])
   const [talentList2, setTalentList2] = useState([])
   const [featuredList, setFeaturedList] = useState([])
   const [isLoading, setIsLoading] = useState(false)
   const [showBite, setShowBite] = useState(false);
   const [offset, setOffset] = useState(0);
   const [limit, setLimit] = useState(4);
   const [currentBiteIndex, setCurrentBiteIndex] = useState(0);
   const [index, setIndex] = useState(0)


   async function getAPI() {
      setIsLoading(true)
      let result = await apiHelper.getRequest("bites/new-talent-list")
      if (result.code === DEVELOPMENT_CONFIG.statusCode) {
         setTalentList(result.data.reels)
         setFeaturedList(result.data.featured)
         setIsLoading(false)
      } else if (result.code == 401) {
         let refreshToken = localStorage.getItem("refresh-token")
         let data = {
            "refresh_token": refreshToken,
            "scope": "refresh_token"
         }
         let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
         if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
            localStorage.setItem("token", refreshTokenResult.data.token)
            localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
            setRefresh(true)
         }
      }
   }
   async function getDetailAPI(index) {
      setIsLoading(true)
      let url = "bites/bites-detail?id=" + index
      let url2 = "bites/bites-list?" + "offset=" + offset + "&limit=" + limit
      let result = await apiHelper.getRequest(url)
      let result2;
      if (result.code === DEVELOPMENT_CONFIG.statusCode) {
         result2 = await apiHelper.getRequest(url2);
         let newArr = [result.data, ...result2.data.data]
         setTalentList2(newArr.filter(
            (obj, index, self) => index === self.findIndex((o) => o.id === obj.id)
         ));
         setIsLoading(false)
         setOffset(offset + 4);
      } else if (result.code == 401) {
         let refreshToken = localStorage.getItem("refresh-token")
         let data = {
            "refresh_token": refreshToken,
            "scope": "refresh_token"
         }
         let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
         if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
            localStorage.setItem("token", refreshTokenResult.data.token)
            localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
            setRefresh(true)
         }
      }
   }

   useEffect(() => {
      getAPI()
   }, [refresh]);

   function handleClick(index) {
      setShowBite(!showBite);
      setCurrentBiteIndex(index);
   }

   return (
      <div>
         <section className="bite response-padding">

            {isLoading && <ReactLoader />}

            <div className="container">
               <div className="chanel-header">
                  <h3 className="fw-600 mb-0">New Talents</h3>
               </div>
               <div className="chanel-wrapper mt-4">
                  <div className="row gy-2">
                     {
                        talentList?.length > 0 ?
                        talentList?.map((item, index) => {
                           return (
                              <div onClick={() => handleClick(index)}  key={item?.id} className="col-xl-4 col-xxl-3 col-lg-4 col-md-4 col-sm-6 response-col">
                                 <div className="chanel-content">
                                    <img src={item?.thumbnail} className="bite-img" alt="" />
                                    <div className="bite-wrapper">
                                       <h4>{item?.title}</h4>
                                       <div className="chanel-text d-flex gap-3 align-items-center">
                                          <img src={item?.biteChannel?.profile_pic} className="bite-inner-img" alt="" />
                                          <div>
                                             <h5>{item?.biteChannel?.name}</h5>
                                             <p>{item?.category?.name}</p>
                                          </div>
                                       </div>
                                       <button onClick={() => handleClick(index)} className="common-btn"><img src={playLight} alt="" />Play</button>
                                    </div>
                                 </div>
                              </div>
                           )
                        })
                        :
                        <div className="no-search-results">
                        <img src={noBites} alt="No Challenges" />
                    </div>
                     }

                  </div>
               </div>
            </div>
         </section>

         {
            showBite && <BiteModal index={index} offset={offset} setOffset={setOffset} limit={limit} setLimit={setLimit} currentBiteIndex={currentBiteIndex} setCurrentBiteIndex={setCurrentBiteIndex} bitesList={talentList} showBite={showBite} setShowBite={setShowBite} />
         }


      </div>
   )
}

export default NewTalents;