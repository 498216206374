import React, { useEffect, useState } from "react";
import { image1, noSeries } from "../../Constants/images";
import PriceModal from "./priceModal";
import apiHelper from "../../Helper/api-helper";
import DEVELOPMENT_CONFIG from '../../Helper/config';
import ReactLoader from "../../ControlComponents/react-loader";
import { useNavigate } from "react-router-dom";
import ROUTES from "../../Helper/route-helper";


const ShopSeriesChoose = () => {
    const [loading, setLoading] = useState(false);
    const [series, setSeries] = useState([]);
    const [refresh, setRefresh] = useState(false)
    const [seriesId, setSeriesId] = useState("");
    const [viewOn, setViewOn] = useState(false);
   const navigate = useNavigate()
    const handleRadio = (id) => {
        if (seriesId === id) {
            setSeriesId("");
        } else {
            setSeriesId(id);
        }
    }

    const handleSubmit = () => {
        if (seriesId) {
            setViewOn(true);
        }
    }

    async function getSeries() {
        setLoading(true)
        const result = await apiHelper.getRequest("creator/my-series?is_premium=0")
        if (result.code == DEVELOPMENT_CONFIG.statusCode) {
            setSeries(result?.data?.allSeries)
            setLoading(false)
        }
        else if (result.code == 401) {
            let refreshToken = localStorage.getItem("refresh-token")
            let data = {
                "refresh_token": refreshToken,
                "scope": "refresh_token"
            }
            let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
            if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
                localStorage.setItem("token", refreshTokenResult.data.token)
                localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
                setRefresh(true)
            }
        }
    }

    useEffect(() => {
        getSeries();
    }, [refresh])

    const handleView = (id) => {
        localStorage.setItem("series_id", id)
        navigate(ROUTES.CREATER_SERIES_DETAIL)
    }
    return (
        <div className="main-inner-content">
            {loading && <ReactLoader />}
            <section className="choose-wrapper">
                <div className="container">
                    <div className="row align-items-center py-3">
                        <div className="col-sm-5">
                            <div className="">
                                <h3 className="fw-600">Choose Series</h3>
                            </div>
                        </div>
                        <div className="col-sm-7 ms-auto text-end d-flex justify-content-end live-start-btn">
                            <button className={seriesId ? "common-btn" : "common-btn-grey"} onClick={handleSubmit} style={{ cursor: seriesId ? "pointer" : "not-allowed" }}>Submit</button>
                        </div>
                    </div>
                    <div className="row">
                        {
                            (series.length == 0 && loading == false
                            ) ? <div style={{ flexDirection: 'column' }} className='no-search-results'>
                                <img src={noSeries} />
                            </div> :
                                series?.map((data) => {
                                    return <div className=" col-lg-6 col-xxl-4 mb-3">

                                        <div key={data?.id} className="chooseseries-inner">
                                            <div className="chooseimg-main" onClick={()=>handleView(data.id)} style={{cursor:"pointer"}} >
                                                <img src={data?.thumbnail} className="chooseinner-img"></img>
                                            </div>
                                            <div className="chooseinner-heading"  onClick={()=>handleView(data.id)} style={{cursor:"pointer"}}>
                                                <h5>{data?.title}</h5>
                                                <p className="mb-0"><span>{`${data?.episodesCount}`} </span>{" "}{data?.episodesCount == 1 ? "Episode" : "Episodes"}</p>
                                            </div>

                                            <div className="choose-live-card-radio" style={{ cursor: "pointer" }}>
                                                <input className="radio-btn" type="radio" checked={seriesId === data?.id} onClick={() => handleRadio(data?.id)} />
                                            </div>

                                        </div>
                                    </div>
                                })}
                    </div>
                </div>
            </section>
            {viewOn && <PriceModal handlerFunction={setViewOn} seriesId={seriesId} check="series" />}
        </div>

    )
}
export default ShopSeriesChoose