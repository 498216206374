import React, { useEffect, useState } from 'react';
import { modalCross, plusgray, typeAbove, typeBelow, videoPlayButton } from '../../Constants/images';
import SelectSeriesModal from './selectSeriesModal';
import uploadToS3 from '../component/fileUpload';
import ERR_MESSAGE from '../../Helper/error-helper';
import { NotificationManager } from 'react-notifications';
import ROUTES from '../../Helper/route-helper';
import apiHelper from '../../Helper/api-helper';
import DEVELOPMENT_CONFIG from "../../../src/Helper/config"
const BiteModalCreator = ({ setBiteModal, setProgress, setProgressLoading, getBiteAPI, favSeries, setIsLoading, id, refresh, setRefresh }) => {
  const [seriesModal, setSeriesModal] = useState(false);
  const [thumbnail, setThumbnail] = useState('');
  const [video, setVideo] = useState('');
  const [open, setOpen] = useState(true);
  const [typeOption, setTypeOption] = useState('Only bite');
  const [seriesShow, setSeriesShow] = useState(false)
  const [title, setTitle] = useState("")
  const [description, setDescription] = useState("")
  const [category, setCategory] = useState([])
  const [categoryId, setCategoryId] = useState("")
  const [selectSeries, setSelectSeries] = useState()
  const [seriesId, setSeriesId] = useState()
  const [videoThumbnail, setVideoThumbnail] = useState("")
  const [errors, setErrors] = useState("")

  const handleChange = (event) => {
    setErrors("")
    setSeriesShow(false)
    setTypeOption(event.target.value);
    if (event.target.value == "For series") {
      setSeriesShow(true)
    }
  };

  async function getDetailAPI() {
    setIsLoading(true)
    let result = await apiHelper.getRequest("creator/bite-details?id=" + id)
    if (result?.code == DEVELOPMENT_CONFIG.statusCode) {
      setIsLoading(false)
      if (result?.data?.series_id == 0) {
        setTypeOption("Only bite")
      }
      else {
        setTypeOption("For series")
      }
      setTitle(result?.data?.title)
      setThumbnail(result?.data?.thumbnail)
      setDescription(result.data.description)
      if (result?.data?.series?.title) {
        setSeriesShow(true)
        setSelectSeries(result?.data?.series?.title)
      }
      setVideoThumbnail(result.data.video_thumbnail)
      setVideo(result.data.video)
      setCategoryId(result.data.category_id)
    }
    else if (result.code == 401) {
      let refreshToken = localStorage.getItem("refresh-token")
      let data = {
        "refresh_token": refreshToken,
        "scope": "refresh_token"
      }
      let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
      if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
        localStorage.setItem("token", refreshTokenResult.data.token)
        localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
        setRefresh(true)
      }
    }
    else {
      NotificationManager.error(result.message)
    }
  }

  useEffect(() => {
    if (id != undefined) {
      getDetailAPI()
    }
  }, [refresh])

  async function getDetailsAPI() {
    setIsLoading(true)
    let result = await apiHelper.getRequest("creator/challenge-category")
    if (result.code == DEVELOPMENT_CONFIG.statusCode) {
      setCategory(result?.data)
      setIsLoading(false)
    }
    else if (result.code == 401) {
      let refreshToken = localStorage.getItem("refresh-token")
      let data = {
        "refresh_token": refreshToken,
        "scope": "refresh_token"
      }
      let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
      if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
        localStorage.setItem("token", refreshTokenResult.data.token)
        localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
        setRefresh(true)
      }
    }
  }

  useEffect(() => {
    getDetailsAPI()
  }, [refresh])

  const handleClick = () => {
    setOpen(!open);
  };

  const handleOpen = () => {
    setSeriesModal(!seriesModal);
    setOpen(false)
  };

  const fileChange = (e) => {
    setErrors("")
    if (e.target.files.length === 0) {
      return;
    }
    const file = e.target.files[0];
    if (file) {
      if (file.type === 'image/jpeg' || file.type === 'image/svg + xml'  || file.type === 'image/png' || file.type === 'image/jpg') {
        const formData = new FormData();
        formData.append("image", file);
        fileUpload(file)
      }
    }
  }

  const fileUpload = async (file, thumb) => {
    const state = { videoLocation: null, thumbnailLocation: null };
    setProgressLoading(true)
    try {
      if (file) {
        await uploadToS3(thumb, file, state, setProgress);
        if (state.videoLocation) {
          setProgressLoading(false)
          setThumbnail(state.videoLocation)
        }
      }
    } catch (error) {
    }
  };

   const fileChangeVideo = async (e) => {
    setErrors("");
    if (e.target.files.length === 0) {
      return;
    }
    const file = e.target.files[0];
    if (file) {
      const fileType = file.type.split('/')[0];
      if (fileType === 'video') {
        const files = e.target.files;
        const videoElement = document.createElement('video');
        videoElement.preload = 'metadata';
        videoElement.addEventListener('loadedmetadata', async () => {
          const width = videoElement.videoWidth;
          const height = videoElement.videoHeight;
          const aspectRatio = width / height;
          if (aspectRatio < 1) {
            const durationInSeconds = videoElement.duration;
            const { thumbnailURL, fileThumbnail } = await generateThumbnailFromVideo(videoElement, durationInSeconds / 2, files);
            const formData = new FormData();
            formData.append('mediaFile', file);
            fileUpload2(fileThumbnail, file);
          } else {
          }
        });
        videoElement.src = URL.createObjectURL(file);
      }
    }
  };

  const generateThumbnail = async (videoElement, files) => {
    const canvas = document.createElement('canvas');
    const videoWidth = videoElement.videoWidth;
    const videoHeight = videoElement.videoHeight;
    canvas.width = videoWidth;
    canvas.height = videoHeight;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(videoElement, 0, 0, videoWidth, videoHeight);
    const thumbnailURL = canvas.toDataURL('image/jpeg');
    const fileThumbnail = dataURLtoFile(thumbnailURL, files[0].name.split(".")[0]);
    return { thumbnailURL, fileThumbnail };
  };

  const generateThumbnailFromVideo = async (videoElement, timeInSeconds, files) => {
    return new Promise((resolve) => {
      const seekTime = Math.min(timeInSeconds, videoElement.duration - 0.1);
      videoElement.currentTime = seekTime;
      videoElement.addEventListener('seeked', async () => {
        const { thumbnailURL, fileThumbnail } = await generateThumbnail(videoElement, files);
        resolve({ thumbnailURL, fileThumbnail });
      });
    });
  };

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  const fileUpload2 = async (thumb, file) => {
    const state = { videoLocation: null, thumbnailLocation: null };
    setProgressLoading(true)
    try {
      if (file || thumb) {
        await uploadToS3(thumb, file, state, setProgress);
        if (state.videoLocation || state.thumbnailLocation) {
          setProgressLoading(false)
          setVideoThumbnail(state.thumbnailLocation)
          setVideo(state.videoLocation)
        }

      }
    }
    catch (error) {
    }
  }

  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;
    if (!typeOption) {
      formIsValid = false;
      errors["typeOption"] = ERR_MESSAGE.TYPE_EMPTY
    }
    if (seriesShow == true
    ) {
      if (!selectSeries) {
        formIsValid = false;
        errors["selectSeries"] = ERR_MESSAGE.EMPTY_SERIES
      }
    }
    if (!categoryId) {
      formIsValid = false;
      errors["categoryId"] = ERR_MESSAGE.EMPTY_CATEORY
    }
    if (!title || title.trim().length === 0) {
      formIsValid = false;
      errors["title"] = ERR_MESSAGE.TITLE_EMPTY
    }
    if (!description || description.trim().length === 0) {
      formIsValid = false;
      errors["description"] = ERR_MESSAGE.SUMMARY_DESCRIPTION
    }
    if (!thumbnail || thumbnail.trim().length === 0) {
      formIsValid = false;
      errors["thumbnail"] = ERR_MESSAGE.THUMBNAIL_EMPTY1
    }
    if (!video || video.trim().length === 0) {
      formIsValid = false;
      errors["video"] = ERR_MESSAGE.EMPTY_VIDEO
    }
    setErrors(errors);
    return formIsValid;
  };

  async function handleSubmit(e) {
    e.preventDefault()
    if (handleValidation()) {
      let data = {
        "bite_type": typeOption == "Only bite " ? 0 : 1,
        "title": title,
        "video": video,
        "category_id": parseInt(categoryId),
        "thumbnail": thumbnail,
        "series_id": seriesId,
        "video_thumbnail": videoThumbnail,
        "description": description,
      }
      setIsLoading(true)
      let result = await apiHelper.postService("creator/create-bite", data)
      if (result.code === DEVELOPMENT_CONFIG.statusCode) {
        setIsLoading(false)
        setBiteModal(false)
        getBiteAPI()
        NotificationManager.success(result.message)
      }
      else {
        setIsLoading(false)
        NotificationManager.error(result.message)
      }
    }
  }

  async function handleEdit(e) {
    e.preventDefault()
    if (handleValidation()) {
      let data = {
        "id": id,
        "bite_type": typeOption == "Only bite" ? 0 : 1,
        "title": title,
        "video": video,
        "category_id": parseInt(categoryId),
        "thumbnail": thumbnail,
        "series_id": seriesId,
        "video_thumbnail": videoThumbnail,
        "description": description
      }
      setIsLoading(true)
      let result = await apiHelper.postService("creator/edit-bite", data)
      if (result.code === DEVELOPMENT_CONFIG.statusCode) {
        setIsLoading(false)
        setBiteModal(false)
        getBiteAPI()
        NotificationManager.success(result.message)
      }
      else {
        setIsLoading(false)
        NotificationManager.error(result.message)
      }
    }
  }

  return (
    <div className="modal fade show modal-react" style={{ display: 'block', opacity: '1', backdropFilter: 'blur(5px)' }}>
      <div className="loader"></div>
      <div className="modal-dialog modal-dialog-centered modal-schedule-width">
        <div className="modal-content modal-radius border-0">
          <div className="modal-header forgot-header align-items-center pt-4">
            <h3 className="modal-title text-center mx-auto forgot-title" id="exampleModalLabel">{id != undefined ? "Edit Bite" : "Create Bite"}</h3>
            <a className="m-0 px-0 btn-closed-main" data-bs-dismiss="modal" aria-label="Close"><img onClick={() => setBiteModal(false)} style={{ cursor: 'pointer' }} src={modalCross} alt="" className="img-fluid" /></a>
          </div>
          <div className="modal-body forgot-body verify-modal-box p-4 pt-4 p-md-0">
            <form>
              <div className='bite-height'>
                <div className='mb-3'>
                  <label className='fw-500 f18-size text-black pb-2'>Type</label>
                  <div className='mb-3'>
                    <div className="round-radio border-edit" onClick={() => handleClick()}>
                      <span className="mb-0 fw-400 f-16">{typeOption ? typeOption : "Select"}</span>
                      <label className="main">
                        <input type="radio" className="profile_type" />
                        <span onClick={(e) => e.stopPropagation()}> <img src={open ? typeBelow : typeAbove} /> </span>
                      </label>
                    </div>
                    {errors.typeOption && <span className="err_msg">{errors.typeOption}</span>}
                  </div>
                </div>
                {(open) && (
                  <>
                    <div className='mb-3'>
                      <div className="round-radio border-edit round-radius">
                        <span className="mb-0 fw-400 f-16">Only Bite</span>
                        <label className="main">
                          <input type="radio" onChange={handleChange} checked={typeOption == "Only bite"} value="Only bite" />
                          <span className="custom-check"></span>
                        </label>
                      </div>
                      <div className="round-radio border-edit border-round">
                        <span className="mb-0 fw-400 f-16">For series </span>
                        <label className="main" >
                          <input type="radio" onChange={handleChange} className="profile_type" checked={typeOption == "For series"} value="For series" />
                          <span className="custom-check"></span>
                        </label>
                      </div>
                    </div>
                  </>
                )}
                {
                  seriesShow == true && <div className='mb-3'>
                    <label className='fw-500 f18-size text-black pb-2' onClick={handleOpen}>Series</label>
                    <div className='mb-3'>
                      <div className="round-radio border-edit" onClick={() => handleOpen()}>
                        <span className="mb-0 fw-400 f-16">{selectSeries ? selectSeries : "Select series"}</span>
                        <label className="main">
                          <input type="radio" className="profile_type" checked />
                          <span onClick={(e) => e.stopPropagation()}> <img src={seriesModal ? typeBelow : typeAbove} /> </span>
                        </label>
                      </div>
                      {errors.selectSeries && <span className="err_msg">{errors.selectSeries}</span>}

                    </div>
                  </div>
                }

                <div className='mb-3'>
                  <label className='fw-500 f18-size text-black pb-2'>Category</label>
                  <select className='form-select border-edit w-100' name="category" value={categoryId} onChange={(e) => {
                    setCategoryId(e.target.value)
                    setErrors("")
                  }}>
                    <option>Select Here</option>
                    {
                      category?.map((item) => (
                        <>
                          <option value={item.id}>{item.name}</option>
                        </>
                      ))
                    }
                  </select>
                  {errors.categoryId && <span className="err_msg">{errors.categoryId}</span>}
                </div>

                <div className='mb-3'>
                  <label className='fw-500 f18-size text-black pb-2'>Title</label>
                  <input type='text' className='border-edit w-100' value={title} onChange={(e) => {
                    setTitle(e.target.value)
                    setErrors("")
                  }} placeholder='Enter Here' ></input>
                  {errors.title && <span className="err_msg">{errors.title}</span>}

                </div>
                <div className='mb-3'>
                  <label className='fw-500 f18-size text-black pb-2'>Description</label>
                  <textarea class=" text-summary place-grey d-block w-100"
                    value={description} onChange={(e) => {
                      setErrors("")
                      setDescription(e.target.value)
                    }}
                    placeholder="Enter Here" rows="6" name="description"></textarea>
                  {errors.description && <span className="err_msg">{errors.description}</span>}

                </div>

                <div className="row pt-3">
                  <div className="col-md-8">
                    <div className="mb-3 schedule-thumb d-md-flex gap-4">
                      <div className="video-upload video-schedule position-relative mb-3 mb-md-0">
                        {
                          thumbnail &&
                          <div className={thumbnail ? " bite-preview " : ""}><img src={thumbnail}></img>
                          </div>
                        }
                        {
                          thumbnail ? "" : <div className="d-flex m-auto justify-content-center align-items-center h-100 episode-preview-after">
                            <div className="">
                              <div className="text-center h-100">
                                <img src={plusgray} alt="" className="img-fluid me-1" />
                                <p className="Upload-text fw-600  create-text ">Upload <br></br>  Thumbnail</p>
                              </div>
                            </div>
                          </div>
                        }
                        <input type="file" className="video-file-schedule" onChange={fileChange} accept=".jpg, .jpeg, .png, .svg" style={{ cursor: "pointer" }} />
                        {errors.thumbnail && <span className="err_msg">{errors.thumbnail}</span>}
                      </div>
                      <div className="video-upload video-schedule position-relative">
                        {
                          videoThumbnail &&
                          <div className={videoThumbnail ? " bite-preview " : ""}><img src={videoThumbnail}></img>
                            <div class="play-bite"><img src={videoPlayButton} class="img-fluid img-bite" />
                            </div>
                          </div>
                        }
                        {
                          videoThumbnail ? "" : <div className="d-flex m-auto justify-content-center align-items-center h-100 episode-preview-after">
                            <div className="">
                              <div className="text-center h-100">
                                <img src={plusgray} alt="" className="img-fluid me-1" />
                                <p className="Upload-text fw-600  create-text ">Upload<br></br> Video</p>
                              </div>
                            </div>
                          </div>
                        }
                        <input type="file" className="video-file-schedule" onChange={fileChangeVideo} accept=".mp4, .mov, .avi" style={{ cursor: "pointer" }} />
                        {errors.video && <span className="err_msg">{errors.video}</span>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {
                id != undefined ? <div className=" mt-4 mb-5" onClick={handleEdit}>
                  <input type='submit' value="Submit" className="common-btn w-100" />
                </div> : <div className=" mt-4 mb-5" onClick={handleSubmit}>
                  <input type='submit' value="Submit" className="common-btn w-100" />
                </div>
              }
            </form>
          </div>
        </div>
      </div>
      {seriesModal && <SelectSeriesModal setSeriesId={setSeriesId} setErrors={setErrors} setSelectSeries={setSelectSeries} setSeriesModal={setSeriesModal} favSeries={favSeries} selectSeries={selectSeries} />}
    </div>
  );
};

export default BiteModalCreator;
