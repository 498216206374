import React, { useState } from "react";
// import { Link, useNavigate } from 'react-router-dom'
import apiHelper from '../../../../Helper/api-helper';
import ERR_MESSAGE from '../../../../Helper/error-helper';
import { modalCross, modalForgot } from "./../../../../Constants/images";
import ReactLoader from './../../../../ControlComponents/react-loader';
import DEVELOPMENT_CONFIG from './../../../../Helper/config';
import { NotificationManager } from "react-notifications";
const ForgotPassword = ({ handleForgot, setVerifyEmail, getEmailFn }) => {

    const [errors, setErrors] = useState("");
    const [isLoading, setLoading] = useState(false);

    const [fields, setFields] = useState({
        email: ""
    });


    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setFields({
            ...fields,
            [name]: value,
        });
    };

    const handleValidation = () => {
        let errors = {};
        let formIsValid = true;
        let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
        const { email } = fields;
        if (!email || email.trim().length === 0) {
            formIsValid = false;
            errors["email"] = ERR_MESSAGE.EMAIL_EMPTY
        }
        else if (reg.test(email.toLowerCase()) === false) {
            formIsValid = false;
            errors["email"] = ERR_MESSAGE.EMAIL_INVALID
        }
        setErrors(errors);
        return formIsValid;
    }

    async function postAPI(e) {
        e.preventDefault();
        if (handleValidation()) {
            getEmailFn(fields.email)
            let data = JSON.stringify({
                "email": fields.email,
            });
            setLoading(true)
            let result = await apiHelper.postRequest("auth/forgot-password", data)
            if (result.code === DEVELOPMENT_CONFIG.statusCode) {
                setLoading(false)
                handleForgot();
                setVerifyEmail(true);
                NotificationManager.success(result.message)
            } else {
                setLoading(false)
                NotificationManager.error(result.message)
            }
        }
    }
   return (
        <>
            {isLoading && <ReactLoader />}
            <div className="modal fade" style={{ display: "block", opacity: "1", backdropFilter: "blur(5px)" }}>
                <div className="modal-dialog modal-dialog-centered" style={{ marginTop: "5rem" }}>
                    <div className="modal-content modal-radius forgot-body" >
                        <div className="modal-header forgot-header align-items-center">
                            <h3 className="modal-title text-center mx-auto forgot-title" id="exampleModalLabel">Forget Password</h3>
                            <a onClick={handleForgot} className="m-0 px-0" data-bs-dismiss="modal" aria-label="Close"><img src={modalCross} alt="" className="img-fluid" /></a>
                        </div>
                        <div className="modal-body forgot-body px-2 px-sm-5">
                            <div className="forgot-img text-center">
                                <p>Please Enter your email to reset your password</p>
                                <img src={modalForgot} alt="" className="img-fluid py-4" />
                            </div>
                            <form onSubmit={postAPI} >
                                <div className="form-group mb-3">
                                    <label className="fw-500 mb-2 forgot-label">Email</label>
                                    <input type="text" name='email' value={fields.email} onChange={handleChange} error={errors.email} className="form-control  fw-500 forgot-input" placeholder="Enter Email" />
                                    <span className="err_msg">{errors.email}</span>
                                </div>
                                <div className="my-5">
                                    {/* <a href="" className="common-btn w-100"  >Submit</a> */}
                                    <input type='submit' value="Submit" className="common-btn w-100" />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default ForgotPassword;