import React, { useState, useMemo } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import ERR_MESSAGE from '../../Helper/error-helper';
import { eye, closeEye, google, signRight, signCircle, signCloud, squareSign, vectorSign, bodySign, signBg, line, facebook1, logoPurple } from '../../Constants/images';
import apiHelper from '../../Helper/api-helper';
import ReactLoader from '../../ControlComponents/react-loader';
import DEVELOPMENT_CONFIG from '../../Helper/config'
import VerifyEmail2 from './components/VerifyEmail2';
import './signup.css';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { NotificationManager } from 'react-notifications';
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import ROUTES from '../../Helper/route-helper';

const SignUp = () => {
  const [errors, setErrors] = useState("");
  const [eye_icon, setEye_icon] = useState(closeEye);
  const [isLoading, setLoading] = useState(false);
  const [verifyEmail2, setVerifyEmail2] = useState(false);
  const [selectedOption, setSelectedOption] = useState(2);
  const navigate = useNavigate()
  const handleOptionChange = (event) => {
    setSelectedOption(Number(event.target.value));
  };

  const [fields, setFields] = useState({
    userName: "",
    email: "",
    countryCode: "",
    mobile: "",
    password: "",
  });

  const handleChange = (e) => {
    setErrors("")
    const name = e.target.name;
    const value = e.target.value;
    setFields({
      ...fields,
      [name]: value,
    });
  };

  const [type, setType] = useState("password");
  const [msg, setMsg] = useState("reveal password")
  const show = () => {
    if (type === "password") {
      setType("text");
      setEye_icon(eye);
      setMsg("hide password")
    }
    else {
      setType("password");
      setEye_icon(closeEye);
    }
  }

  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
    let passwordReg = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,}$/;
    const { userName, email, mobile, password } = fields;
    if (!userName || userName.trim().length === 0) {
      formIsValid = false;
      errors["userName"] = ERR_MESSAGE.EMPTY_FULLNAME
    } else if (userName.length < 3 || userName.length > 30) {
      formIsValid = false;
      errors["userName"] = ERR_MESSAGE.INVALID_FULLNAME
    }
    if (!email || email.trim().length === 0) {
      formIsValid = false;
      errors["email"] = ERR_MESSAGE.EMAIL_EMPTY
    }
    else if (reg.test(email.toLowerCase()) === false) {
      formIsValid = false;
      errors["email"] = ERR_MESSAGE.EMAIL_INVALID
    }
    if (!mobile || mobile.trim().length === 0) {
      formIsValid = false;
      errors["mobile"] = ERR_MESSAGE.MOBILE_EMPTY
    } else if (mobile.length <= 9) {
      formIsValid = false;
      errors["mobile"] = ERR_MESSAGE.INVALID_MOBILE
    }
    if (!password || password.trim().length === 0) {
      formIsValid = false;
      errors["password"] = ERR_MESSAGE.PASSWORD_EMPTY
    }
    else if (passwordReg.test(password) === false) {
      formIsValid = false;
      errors["password"] = ERR_MESSAGE.INVALID_PASSWORD
    }
    setErrors(errors);
    return formIsValid;
  }

  async function postAPI(e) {
    e.preventDefault();
    if (handleValidation()) {
      let data = JSON.stringify({
        "name": fields.userName,
        "email": fields.email,
        "country": fields.countryCode,
        "phone_no": fields.mobile,
        "password": fields.password,
        "role_id": selectedOption,
      });
      setLoading(true);
      let result = await apiHelper.postRequest("auth/sign-up", data)
      if (result.code === DEVELOPMENT_CONFIG.statusCode) {
        localStorage.setItem("token", result.data.token);
        localStorage.setItem("refresh-token", result.data.refresh_token);
        setLoading(false)
        setVerifyEmail2(true)
        NotificationManager.success(result.message)
      } else {
        setLoading(false)
        NotificationManager.error(result.message)
      }
    }
  }

  const handleOnChange = (value, data, event, formattedValue) => {
    setFields({
      ...fields,
      countryCode: data.dialCode,
      mobile: value.slice(data.dialCode.length)
    })
  }

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const login = useGoogleLogin({
    onSuccess: async tokenResponse => {
      try {
        const userInfo = await axios.get('https://www.googleapis.com/oauth2/v3/userinfo', {
          headers: {
            Authorization: `Bearer ${tokenResponse.access_token}`,
          },
        });
        console.log('User Info:', userInfo.data);
        SocialLogin(userInfo.data)
      } catch (error) {
        console.error('Error fetching user info:', error);
      }
    },
    onError: error => console.log('Login Failed:', error),
  });

  async function SocialLogin(info) {
    let data = JSON.stringify({
      "name": info.name,
      "email": info.email,
      "unique_id": info.sub,
      "login_type": "1",
      "profile_pic": "",
      "role_id": selectedOption
    });
    setLoading(true);
    let result = await apiHelper.postRequest("auth/social-login", data)
    if (result.code === DEVELOPMENT_CONFIG.statusCode) {
      localStorage.setItem("token", result.data.token);
      localStorage.setItem("refresh-token", result.data.refresh_token);
      setLoading(false)
      if (result.data?.userdata.role_id == 2) {
        navigate(ROUTES.CREATER_MEMBERSHIP)
      }
      else {
        navigate(ROUTES.HOME)
      }
      localStorage.setItem("role", result.data.userdata.role_id)
      localStorage.setItem("creator_id", result?.data?.userdata.id)
    } else {
      setLoading(false)
      NotificationManager.error(result.message)
    }
  }


  return (
    <>
      <div className="loader">
        {isLoading && <ReactLoader />}
      </div>
      <section className="login-wrapper signup-form" style={{ backgroundImage: `url(${bodySign})` }}>
        <div className="container">
          <div className="row">
            <div className=" col-12 col-lg-10 m-auto">
              <div className="login-width" style={{ backgroundImage: `url(${signBg})` }}>

                <div className="row">
                  <div className="col-12 col-lg-5 px-0">
                    <div className='logo-sign text-center   d-block d-lg-none'>
                      <img src={logoPurple} className='img-fluid'></img>
                    </div>
                    <div className="sign-form  position-relative  ">
                      <div id="exTab1" className="container">
                        <ul className="nav nav-pills list-styled d-flex justify-content-around">
                          <li>
                            <Link to="/sign-in" className=" fw-600">SIGN IN </Link>
                          </li>
                          <li className="active"><a className="active fw-600">SIGN UP</a>
                          </li>
                        </ul>
                        <div className="tab-content clearfix">
                          <div className="tab-pane active sign-up" id="signup" >
                            <form onSubmit={postAPI}>
                              <div className='creat-label pb-3'>
                                <label>
                                  <input
                                    type="radio"
                                    value="2"
                                    checked={selectedOption === 2}
                                    onChange={handleOptionChange}
                                    className='radio-login'
                                  />
                                  <span>Creator</span>
                                </label>
                                <label className='ms-3'>
                                  <input
                                    type="radio"
                                    value="1"
                                    checked={selectedOption === 1}
                                    onChange={handleOptionChange}
                                    className='radio-login'
                                  />
                                  <span>User</span>
                                </label>
                              </div>
                              <div className="form-group mb-3">
                                <input type="text" name='userName' value={fields.userName} onChange={handleChange} error={errors.userName} className="form-control input-login fw-500" placeholder="Name" />
                                <span className="err_msg">{errors.userName}</span>
                              </div>
                              <div className="form-group mb-3">
                                <input type="text" name='email' value={fields.email} onChange={handleChange} error={errors.email} className="form-control input-login fw-500" placeholder="Email" />
                                <span className="err_msg">{errors.email}</span>
                              </div>
                              <div className="form-group mb-3">
                                <PhoneInput
                                  error={errors.mobile}
                                  className=""
                                  country={"us"}
                                  enableSearch={true}
                                  onChange={handleOnChange}
                                />
                                <span className="err_msg">{errors.mobile}</span>
                              </div>
                              <div className="form-group mt-3 position-relative">
                                <div className="eye-closed">
                                  <img src={eye_icon} onClick={show} className="img-fluid" title={msg} />
                                </div>
                                <input type={type} name='password' value={fields.password} onChange={handleChange} error={errors.password} className="form-control input-login fw-500 " placeholder="Password" />
                                <span className="err_msg">{errors.password}</span>
                              </div>
                              <div className="login-btn mt-4">
                                {/* <a href="" className="common-btn w-100" >Login</a> */}
                                <input type="submit" value="Sign Up" className="common-btn w-100" />
                              </div>
                              <div className="footer-login text-center">
                                <p className="or-bg mt-3 mb-2" style={{ backgroundImage: `url(${line})` }}><span>OR</span></p>
                                <a className="blue-text fw-500" href="">Sign up with</a>
                                <div className="social-link mt-3 d-flex justify-content-center align-items-center gx-3">
                                  <span><img style={{cursor:'pointer'}} src={google} onClick={() => login()} className="img-fluid" /><span>Google</span></span>
                                  <span><img src={facebook1} className="img-fluid" /><span>Facebook</span></span>

                                </div>
                              </div>

                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-7 px-0  d-none d-lg-block">
                    <div className="h-100 position-relative">
                      <img src={signRight} className="right-login " />
                      <img src={squareSign} className="square-box" />
                      <img src={signCloud} className="cloud-sign" />
                      <img src={vectorSign} className="vector-sign" />
                      <img src={signCircle} className="circle-sign" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {verifyEmail2 && <VerifyEmail2 setVerifyEmail2={setVerifyEmail2} email={fields.email} />}
      </section>
    </>
  )
}


export default SignUp;