import React from 'react';
import { ProgressBar } from 'react-bootstrap';
import './loader.css';
function ProgressBarComponent({ progress }) {
  return (
    <div className="loader-new">
      <div className="custom-loading-color progress-bar-outer" style={{ position: "absolute", top: "350px", left: "700px", color: "#5951e5" }}>
      {/* <div className="k-loading-image" > */}
        <ProgressBar
          now={progress}
          label={`${Math.round(progress)}%`}
          // visuallyHidden
        />       
         </div>
        {/* </div> */}
      {/* <div className="k-loading-color"  >
      </div> */}
    </div>
  )
}
export default ProgressBarComponent;
