import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import '../AllChallenges/Components/ChallengesDetail/challengesDetail.css';
import ReactLoader from '../../ControlComponents/react-loader';

function MyChallengesDetail() {
    const [isLoading, setIsLoading] = useState(false)
    const location = useLocation()
    const [challengeData, setChallengeData] = useState(location?.state?.item)

    return (
        <div>
            <div className='loader'>
                {isLoading && <ReactLoader />}
            </div>
            <div className="main-inner-content">
                <section className="challenge-upload-custom">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 pt-3  pb-0">
                                <h3 className="fw-600">Challenges Detail</h3>
                            </div>
                        </div>
                        <div className="row   justify-content-center">
                            <div className="col-12 text-center">
                                <div className="challenge-title">
                                    <img src={challengeData?.channel?.logo_url} className="img-fluid challenge-img" />
                                </div>
                            </div>
                        </div><div className='challenges row  justify-content-center'>
                            <div className="col-12  text-center py-5 mt-4">
                                <div className="challenge-head">
                                    <h3 className="fw-600">{challengeData?.title}</h3>
                                    <p className="py-2">challenges  you to create and record your fan art based<br />on their series ‘Creed’</p>
                                </div>
                            </div>
                            <div className="col-12 col-lg-4 pb-5 pb-lg-0">
                                <div className="video-upload position-relative ms-auto me-3">
                                    <video style={{ objectFit: 'contain' }} controls src={challengeData?.sample}></video>
                                    {/* <img src={videoPlayButton} className="img-fluid playbtn-image" /> */}
                                </div>
                            </div>
                            <div className="col-12 col-lg-4">
                                <div className="video-upload video-select position-relative">
                                    <video id='video' style={{ objectFit: 'contain' }} controls src={location.state.video_url}></video>
                                    {/* <img src={videoPlayButton} className="img-fluid playbtn-image" /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default MyChallengesDetail;