import React, { useState } from 'react'
import { modalCross, priceCart } from '../../Constants/images'
import apiHelper from '../../Helper/api-helper';
import DEVELOPMENT_CONFIG from '../../Helper/config';
import ReactLoader from "../../ControlComponents/react-loader";
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../Helper/route-helper';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';


const PriceModal = ({ handlerFunction, seriesId = null, check }) => {
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const navigate = useNavigate()
    const [price, setPrice] = useState(null);

    function changeHandler(e) {
        setErrorMessage("")
        setPrice(e.target.value);
    }

    async function assignSeriesPriceApi() {
        setLoading(true);
        let data = {
            "series_id": seriesId,
            "price": parseInt(price?.slice(0, 3))
        }
        try {
            const result = await apiHelper.postRequest("shop/assign-series-price", data);
            if (result.code == DEVELOPMENT_CONFIG.statusCode) {
                handlerFunction(false);
                const message = result.message + "series"
                localStorage.setItem("message", message)
                navigate(ROUTES.SHOP)
                setLoading(false)

            }
            else{
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)

        }
    }

    async function assignLivePriceApi() {
        setLoading(true);
        let data = {
            "id": seriesId,
            "price": parseInt(price?.slice(0, 3))
        }
        try {
            const result = await apiHelper.postRequest("shop/assign-recording-price", data);
            if (result.code == DEVELOPMENT_CONFIG.statusCode) {
                handlerFunction(false);
                const message = result.message + "live"
                localStorage.setItem("message", message)
                navigate(ROUTES.SHOP)
                setLoading(false)

            }
            else{
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
        }
    }
    async function assignSchedulePriceApi() {
        setLoading(true);
        let data = {
            "id": seriesId,
            "price": parseInt(price?.slice(0, 3))
        }
        try {
            const result = await apiHelper.postRequest("shop/assign-schedule-price", data);
            if (result.code == DEVELOPMENT_CONFIG.statusCode) {
                handlerFunction(false);
                const message = result.message + "schedule"
                localStorage.setItem("message", message)
                navigate(ROUTES.SHOP)
                setLoading(false)

            }
            else{
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
        }
    }


    function submitHandler(e) {
        e.preventDefault();
        if (!price || price.trim() === "") {
            setErrorMessage("Enter price");
        } else {
            if (check == "series") {
                assignSeriesPriceApi();
            }
            else if (check == "live") {
                assignLivePriceApi()
            }
            else if(check == "schedule"){
                assignSchedulePriceApi()
            }
        }
    }

    function handleKeyDown(e) {
        if (e.key === 'Enter') {
            submitHandler(e)
        }
    }

    return (
        <> {loading && <ReactLoader />}
            <div className="modal fade show modal-cast" style={{ display: "block", opacity: "1", backdropFilter: "blur(5px)" }}>
                <div className='loader'>
                </div>
                <div className="modal-dialog modal-dialog-centered" style={{ justifyContent: "center" }}>
                    <div className="modal-content modal-radius  border-0">
                        <div className="modal-header forgot-header align-items-center pt-4">
                            <h3 className="modal-title text-center mx-auto forgot-title" id="exampleModalLabel">Set Price</h3>
                            <a className="m-0 px-0 btn-closed-main" data-bs-dismiss="modal" aria-label="Close"><img style={{ cursor: "pointer" }}
                                onClick={() => {
                                    handlerFunction("")
                                }}
                                src={modalCross} alt="" className="img-fluid btn-cloesed" /></a>
                        </div>
                        <div className="modal-body d-flex flex-column justify-center align-items-center gap-3  verify-modal-box px-2 px-sm-5">
                            <img src={priceCart} alt='price-cart-img' width={230} />

                            <form className='price-form-input' onSubmit={submitHandler}>
                             
                                <TextField
                                    className='price-input'
                                    type='number'
                                    min="1"
                                    value={price?.slice(0, 3)} 
                                    onChange={changeHandler}
                                    placeholder='Enter Price'
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    }}
                                />
                                {errorMessage && <span className="err_msg">{errorMessage}</span>}

                                <button type='submit' className='price-submit-btn' onKeyDown={handleKeyDown}>Save</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PriceModal
