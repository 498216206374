import React, { useState, useEffect, useRef } from 'react'
import { DEMO_IMAGE_ONE, playLight } from '../../../Constants/images';
import apiHelper from '../../../Helper/api-helper';
import DEVELOPMENT_CONFIG from '../../../Helper/config'
import ReactLoader from '../../../ControlComponents/react-loader';
import BiteModal from '../../../Components/Bites';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ROUTES from '../../../Helper/route-helper';

function AllBites() {
   const navigate = useNavigate();
   const [bitesList, setBitesList] = useState([]);
   const [bitesList2, setBitesList2] = useState([]);
   const [showBite, setShowBite] = useState(false);
   const [isLoading, setIsLoading] = useState(false);
   const [refresh, setRefresh] = useState(false);
   const [index, setIndex] = useState(0);
   const [offset, setOffset] = useState(0);
   const [currentBiteIndex, setCurrentBiteIndex] = useState(0);
   const [page, setPage] = useState(1);
   const loadMoreRef = useRef(null);
   const [scroll, setScroll] = useState(false)
   const [hasMoreItems, setHasMoreItems] = useState(true);
   const location = useLocation();
   const { id } = useParams()
   const handleAPIError = async (result) => {
      if (result.code === 401) {
         const refreshToken = localStorage.getItem("refresh-token");
         const data = {
            refresh_token: refreshToken,
            scope: "refresh_token",
         };
         const refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data);
         if (refreshTokenResult.code === DEVELOPMENT_CONFIG.statusCode) {
            localStorage.setItem("token", refreshTokenResult.data.token);
            localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token);
            setRefresh(true);
         }
      }
   };

   const fetchBitesList = async () => {
      setIsLoading(true);
      try {
         const result = await apiHelper.getRequest(`bites/bites-list?page=${page}`);
         if (result.code === DEVELOPMENT_CONFIG.statusCode) {
            const newBites = result?.data.reels;
            if (page === 1) {
               setBitesList(result?.data.reels);
            } else {
               setBitesList((prevBites) => [...prevBites, ...newBites]);
            }
            setHasMoreItems(newBites.length === 12);
            if (id != undefined) {
               const index = result?.data.reels.findIndex(bite => bite.id == id);
                  handleBitePlay(index)
            }
         } else {
            handleAPIError(result);
         }
      } catch (error) {
         console.error("Error fetching bites list:", error);
      } finally {
         setIsLoading(false);
      }
   };

   const fetchBiteDetails = async (index) => {
      setIsLoading(true);
      try {
         const url = `bites/bites-detail?id=${index}`;
         const result = await apiHelper.getRequest(url);
         if (result.code === DEVELOPMENT_CONFIG.statusCode) {
            const result2 = await apiHelper.getRequest(`bites/bites-list?page=${page}`);
            const newArr = [result.data, ...result2.data.reels];
            setBitesList2(
               newArr.filter((obj, index, self) => index === self.findIndex((o) => o.id === obj.id))
            );
            setOffset(offset + 4);
         } else {
            handleAPIError(result);
         }
      } catch (error) {
         console.error("Error fetching bite details:", error);
      } finally {
         setIsLoading(false);
      }
   };

   const handleBitePlay = (index) => {
      setShowBite(true);
      setCurrentBiteIndex(index);
      if (id != undefined) {
         const selectedBiteId = bitesList[index]?.id;
         console.log(selectedBiteId, "selectedBiteIdselectedBiteId", bitesList[index])
         if (selectedBiteId != undefined) {
            navigate(`/bites/${selectedBiteId}`);
         }
      }
   };

   const handleLoadMore = () => {
      setScroll(true)
      setPage(prevPage => prevPage + 1);
   };

   useEffect(() => {
      fetchBitesList();
    
   }, [refresh, page]);

   useEffect(() => {
      if (loadMoreRef.current && scroll) {
         loadMoreRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
         setScroll(false)
      }
   }, [bitesList, scroll]);

   return (
      <div className='main-inner-content'>
         {isLoading && <ReactLoader />}
         {
            bitesList.length > 0 &&
            <section className="featured response-padding py-60">
               <div className="container">
                  <div className="chanel-header">
                     <h3 className="fw-600">Featured</h3>
                  </div>

                  <div className="challene-wrapper mt-4">
                     <div className=" row" style={{ cursor: "pointer" }}>
                        {
                           bitesList.length > 0 && bitesList?.slice(0, 3).map((bite, index) => (
                              <div
                                 key={bite.id}
                                 className="featured-box-cus position-relative col-lg-4 mb-4 mb-lg-0">
                                 <div className="video-bg video-wrapr">
                                    <div className="video-content">
                                       <img
                                          onClick={() => handleBitePlay(index)}
                                          src={bite.thumbnail}
                                          className="img-video img-fluid"
                                          alt=""
                                       />
                                    </div>
                                    <div className='content-video-feat'>
                                       <h4 className="mt-3">{bite?.title}</h4>
                                       <div className="video-detail" style={{ cursor: "pointer" }} onClick={() => navigate(ROUTES.CHANNELS_DETAILS + "?cd=biteChannel", { state: { item: bite } })}>
                                          <div>
                                             <img
                                                src={bite?.biteChannel?.profile_pic}
                                                className="bite-inner-img chllge-img"
                                                alt=""
                                             />
                                          </div>
                                          <div>
                                             <h5 className="mb-0 fw-600">{bite?.biteChannel?.name}</h5>
                                             <p className="mb-0">{bite?.category?.name}</p>
                                          </div>
                                       </div>
                                    </div>

                                 </div>

                              </div>
                           ))
                        }
                     </div>
                  </div>
               </div>
            </section>
         }

         <section className="bite response-padding">
            <div className="container px-0 px-md-2">
               <div className="chanel-header">
                  <h3 className="fw-600 mb-0">Bites</h3>
               </div>

               <div className="chanel-wrapper mt-4">
                  <div className="row gy-2" style={{ cursor: "pointer" }}>
                     {bitesList?.length > 0 && bitesList?.map((bite, index) =>
                        <div className="col-xl-4 col-xxl-3 col-lg-4 col-md-6  response-col" key={bite.id}>
                           <div className="channel-bites-content">
                              <img src={bite.thumbnail} className="channelbite-img" alt="" onClick={() => handleBitePlay(index)} />
                              <div className="bite-wrapper">
                                 <h4>{bite?.title}</h4>
                                 <div className="chanel-text d-flex gap-3 align-items-center" style={{ cursor: "pointer" }} onClick={() => navigate(ROUTES.CHANNELS_DETAILS + "?cd=biteChannel", { state: { item: bite } })}>
                                    <img src={bite?.biteChannel?.profile_pic} className="bite-inner-img" alt="" />
                                    <div>
                                       <h6 className='fw-600 mb-0'>{bite?.biteChannel?.name}</h6>
                                       <p className='mt-1'>{bite?.category?.name}</p>
                                    </div>
                                 </div>
                                 <button onClick={() => handleBitePlay(index)} className="common-btn"><img src={playLight} alt="" />Play</button>
                              </div>
                           </div>
                        </div>
                     )}
                  </div>
               </div>
               {
                  showBite &&
                  <BiteModal currentBiteIndex={currentBiteIndex} setCurrentBiteIndex={setCurrentBiteIndex} index={index} bitesList={bitesList} showBite={showBite} setShowBite={setShowBite} handleBitePlay={handleBitePlay} />
               }
               {
                  hasMoreItems && <div style={{ cursor: 'pointer' }} onClick={handleLoadMore} className="load-text mb-3">
                     Load More...
                  </div>
               }
               <div ref={loadMoreRef} ></div>
            </div>

         </section>

         {
            !isLoading &&
            <>
               {
                  bitesList?.length === 0 &&
                  <div className='no-search-results'>
                     <img src={DEMO_IMAGE_ONE} alt="No Challenges" />
                     <h6 className='position-absolute' style={{ top: 500 }}>No bites yet</h6> </div>
               }
            </>
         }

      </div>
   );
}

export default AllBites;
