import React, { useEffect, useRef, useState } from 'react'
import Header2 from './Components/Header2/Header2'
import Sidebar from './Components/Sidebar/Sidebar'
import { dashBackground1 } from './Constants/images'
import ROUTES from './Helper/route-helper'
import { useLocation } from 'react-router-dom'
import apiHelper from './Helper/api-helper'
import DEVELOPMENT_CONFIG from "./Helper/config"
const Layout = ({ children }) => {
  const location = useLocation();
  const refToTop = useRef(null);
  const [user, setUser] = useState(false)
  useEffect(() => {
    refToTop.current && refToTop.current.scrollIntoView();
  })
  const userFn = (setShowModal) => {
    setShowModal(false)
  }

  const filteredRoutes = Object.keys(ROUTES)
    .filter(route => route !== 'CREATER_MEMBERSHIP')
    .map(key => {
      const routeWithoutSlash = ROUTES[key];
      const routeWithSlash = ROUTES[key].endsWith("/") ? ROUTES[key] : ROUTES[key] + "/";
      return [routeWithoutSlash, routeWithSlash];
    })
    .flat();

  async function onlineCreators() {
    let result = await apiHelper.getRequest(`creator/online-offline?type=0`)
    if (result.code == DEVELOPMENT_CONFIG.statusCode) {
    } else if (result.code == 401) {
      let refreshToken = localStorage.getItem("refresh-token");
      let data = {
        "refresh_token": refreshToken,
        "scope": "refresh_token"
      };
      let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data);
      if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
        localStorage.setItem("token", refreshTokenResult.data.token);
        localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token);
      }
    }
  }

  useEffect(() => {
    return () => {
      onlineCreators()
    }
  }, [])

  const routeExists = filteredRoutes.includes(location.pathname);

  return (
    <div>
      {/* {((routeExists)) &&  */}
      <Sidebar user={user} setUser={setUser} />
      {/* } */}
      <div style={{ backgroundImage: `url(${dashBackground1})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}
        className={(location.pathname == ROUTES.CREATER_MEMBERSHIP ) ? "main-content content-open content-open-uper" : "main-content"}>
        <Header2 user={user} userFn={userFn} />
        <main ref={refToTop}>
          {children}
        </main>
      </div>
    </div>
  )
}
export default Layout;
