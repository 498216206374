import React, { useEffect, useState } from 'react';
import ShopSeries from './shopSeries';
import ShopLives from './shopLives';
import apiHelper from '../../Helper/api-helper';
import DEVELOPMENT_CONFIG from "../../../src/Helper/config";
import UpcomingLivesShop from './upcomingLive';
import Images from './images';
import Video from './video';
import Audio from './audio';
import ReactLoader from '../../ControlComponents/react-loader';

function UserShopDetail() {
    const [tabSwitch, setTabSwitch] = useState(0);
    const [seriesList, setSeriesList] = useState([]);
    const [imagesList, setImagesList] = useState([]);
    const [recoringList, setRecordingList] = useState([]);
    const [upcomingList, setUpcomingList] = useState([]);
    const [audiosList, setAudiosList] = useState([]);
    const [videosList, setVideosList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [channelName, setChannelName] = useState();
    const [channelImg, setChannelImg] = useState();
    const [channelId, setChannelId] = useState();

    async function getListAPI() {
        setLoading(true);
        let url;
        switch (tabSwitch) {
            case 0:
                url = "shop/seriesAndRecordingList?type=1&is_premium=1";
                break;
            case 1:
                url = "shop/seriesAndRecordingList?type=2&is_premium=1";
                break;
            case 2:
                url = "shop/upcoming-live?is_premium=1";
                break;
            case 3:
                url = "shop/get-shop-media?shopType=image";
                break;
            case 4:
                url = "shop/get-shop-media?shopType=audio";
                break;
            case 5:
                url = "shop/get-shop-media?shopType=video";
                break;
            default:
                setLoading(false);
                return;
        }
        try {
            let result = await apiHelper.getRequest(url);
            if (result?.code === DEVELOPMENT_CONFIG?.statusCode) {
                switch (tabSwitch) {
                    case 0:
                        setSeriesList(result.data);
                        break;
                    case 1:
                        setRecordingList(result.data);
                        break;
                    case 2:
                        setUpcomingList(result.data);
                        break;
                    case 3:
                        setImagesList(result.data);
                        break;
                    case 4:
                        setAudiosList(result.data);
                        break;
                    case 5:
                        setVideosList(result.data);
                        break;
                    default:
                        break;
                }

            } else if (result.code === 401) {
                let refreshToken = localStorage.getItem("refresh-token");
                let data = {
                    "refresh_token": refreshToken,
                    "scope": "refresh_token"
                };
                let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data);

                if (refreshTokenResult.code === DEVELOPMENT_CONFIG.statusCode) {
                    localStorage.setItem("token", refreshTokenResult.data.token);
                    localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token);
                    setRefresh(true);
                } else {
                    console.error("Failed to refresh token", refreshTokenResult);
                }
            } else {
                console.error("Unexpected result code", result);
            }
        } catch (error) {
            console.error("Error in getListAPI", error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        getListAPI();
    }, [tabSwitch, refresh]);

    return (
        <section className="streaming py-6 response-padding">
            {loading && <ReactLoader />}
            {
                tabSwitch === 0 ?
                    <ShopSeries detailList={seriesList} channelImg={channelImg} channelId={channelId} channelName={channelName} setTabSwitch={setTabSwitch} getListAPI={getListAPI} tabSwitch={tabSwitch} loading={loading} setLoading={setLoading} check="shop" /> :
                    tabSwitch === 1 ?
                        <ShopLives detailList={recoringList} setLoading={setLoading} setTabSwitch={setTabSwitch} getListAPI={getListAPI} loading={loading} check="shop" /> :
                        tabSwitch === 2 ?
                            <UpcomingLivesShop setTabSwitch={setTabSwitch} detailList={upcomingList} getListAPI={getListAPI} loading={loading} setLoading={setLoading} check="shop" /> :
                            tabSwitch === 3 ?
                                (<Images setTabSwitch={setTabSwitch} tabSwitch={tabSwitch} getListAPI={getListAPI} loading={loading} detailList={imagesList} check="shop" setLoading={setLoading} />)
                                :
                                tabSwitch === 4 ?
                                    <Audio setTabSwitch={setTabSwitch} tabSwitch={tabSwitch} getListAPI={getListAPI} loading={loading} detailList={audiosList} check="shop" setLoading={setLoading} /> :
                                    <Video setTabSwitch={setTabSwitch} tabSwitch={tabSwitch} getListAPI={getListAPI} loading={loading} detailList={videosList} check="shop" setLoading={setLoading} />
            }
        </section>
    )
}

export default UserShopDetail;
