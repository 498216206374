import React, { useRef } from 'react'
import { crossWhite } from '../../Constants/images'

const VideoPlayer = (props) => {
    const ref = useRef(null)
    function handleClick() {
        props?.setShowVideoPlayer(!props?.showVideoPlayer)
        ref.current.pause()
    }

    return (
        <div className="modal fade" id="video-modal" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg justify-content-center">
                <div className="modal-content body-radius">
                    <div className="modal-body position-relative p-0">
                        <div className="modal-video-content">
                            <video ref={ref} src={props?.url} controls></video>
                            <a onClick={() => handleClick()} className="m-0 px-0 video-cross" data-bs-dismiss="modal" aria-label="Close">
                                <img src={crossWhite} alt="" className="img-fluid video-cross-img" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VideoPlayer