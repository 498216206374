import React, { useState } from 'react'
import { buy_btn, no_videos, playBtn, playLightbtn } from '../../Constants/images'
import ReactLoader from '../../ControlComponents/react-loader'
import PriceTag from '../../CreaterDashboard/component/price-tag'
import AddDetailVideoModal from '../UserShop/detailVideoModal'
import BuyModal from '../UserShop/buyModal'


function VideoChannel({ loading,detailList,setLoading,getListAPI}) {
    const [deltId, setDeltId] = useState(null)
    const [addDetailVideoModal, setAddDetailVideoModal] = useState(false)
    const [buyModal, setBuyModal] = useState(false)
    const [item, setItem] = useState({})

    const handleOpen = (item) => {
        setItem(item)
        setBuyModal(true)
    }

    const handleClick = (item) => {
        setDeltId(item.id)
        setAddDetailVideoModal(true)
    }

    return (
        <>
            {loading && <ReactLoader />}
            <div className="container-fluid">
                <div className="row">
                   
                    <div className='row mt-4'>
                        {detailList?.length > 0 ? (
                            <>
                                { (
                                    detailList?.map(item => (
                                        <div className="col-12 col-sm-6 col-lg-4 col-xl-4 col-xxl-3 pb-4" key={item?.id}>
                                            <div className="latest-video mx-auto">
                                                <div className='position-relative'>
                                                    <div className="latest-video-content position-relative pt-2 pt-sm-3 px-2 px-sm-3" style={{ cursor: "pointer" }}>
                                                        <img
                                                            src={item.shop_media[0]?.media_thumbnail}
                                                            className="img-fluid latest-vid-img new-latest-video"
                                                            alt="thumbnail"
                                                        />
                                                        {item.is_purchase === 0 && <PriceTag classes={"price_tag"} firstValue={`$${item.price}`} secondValue={"00"} />}
                                                        <img src={playLightbtn} className='play-btn-video' alt="play button" />
                                                     
                                                    </div>
                                                </div>
                                                <div className="px-3 py-3 schedule-grid">
                                                    <div className=''>
                                                    <div className="tooltip-container">
                                                                <h4 className="schedule-title">{item.title?.length > 25 ? item.title.substring(0, 25) + "..." : item.title}</h4>
                                                                <span className="tooltip-text">{item.title}</span>
                                                            </div>   
                                                        <h6 className="images-count">
                                                            {item.shop_media.length} {item.shop_media.length === 1 ? "Image" : "Images"}
                                                        </h6>
                                                        <p className="images-description">{item.description}</p>
                                                        {item.is_purchase === 0 ? (
                                                            <button style={{ cursor: 'pointer' }} className="common-btn watch-btn buy-btn-shop" 
                                                            onClick={() => handleOpen(item)}
                                                            >
                                                                <img src={buy_btn} alt='buy-btn' style={{ width: '50px', height: '50px' }} />                                                            </button>
                                                        ) : (


                                                            <span style={{ cursor: "pointer" }} 
                                                            onClick={() => handleClick(item)} 
                                                            className="text-end">
                                                                <img src={playBtn} alt='buy-btn' style={{ width: '50px', height: '50px' }} />                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                )}
                            </>
                        ) : (
                            <>
                                {loading == false && (
                                    <div style={{ flexDirection: 'column' }} className='no-search-results position-relative'>
                                        <img src={no_videos} alt="no videos" />
                                        <h6 className='position-absolute' style={{ bottom: 21 }}>No Videos yet</h6>
                                    </div>
                                )}
                            </>
                        )}
                    </div>

                </div>

                {
                    addDetailVideoModal && <AddDetailVideoModal setAddDetailVideoModal={setAddDetailVideoModal} deltId={deltId} />
                }
                {
                    buyModal && <BuyModal item={item} setLoading={setLoading} setBuyModal={setBuyModal} check="videos" getListAPI={getListAPI} />
                }
            </div>
        </>
    )
}

export default VideoChannel