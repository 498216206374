import React, { useState } from 'react'
import { playLightBtn, seeAllArrow } from '../../Constants/images'
import './seriesDetail.css'
import PlayBiteModal from '../Bites/playBite'
import ROUTES from '../../Helper/route-helper'
import { useNavigate } from 'react-router-dom'

function BitesSeries({ seriesDetail, creatorName, creatorImg }) {
    const [videoUrl, setVideoUrl] = useState("")
    const [biteModal, setBiteModal] = useState("")
    const [id, setId] = useState(null)
    const [item, setItem] = useState("")
    const [showBite, setShowBite] = useState(false)
    const [currentBiteIndex, setCurrentBiteIndex] = useState("")
    const handleBitePlay = (item) => {
        setVideoUrl(item.video)
        setShowBite(!showBite);
        // setCurrentBiteIndex(id);
        setId(item.id)
        setItem(item)
    }
    const navigate = useNavigate()
    console.log(seriesDetail.reels, "dfgh")
    return (
        <div>
            <section className=" latest-challdata latest-challenge response-padding mt-5">
                <div className="container">
                    {
                        seriesDetail?.reels?.length > 0 && <div className="chanel-header">
                            <h3 className="fw-600 mb-0">Bites</h3>
                            {/* <button
                                onClick={() => navigate(ROUTES.CREATER_BITES)}
                                className="see-all fw-600 f18-size">See all <img src={seeAllArrow} alt="" className="ms-2" /></button> */}
                        </div>
                    }

                    <div className='d-flex flex-wrap gap-4'>
                        {
                            seriesDetail?.reels?.map((item) => {
                                return (
                                    <div className='new-bites-series'
                                    // key={challenges?.id} onClick={() => navigate(ROUTES.CHALLENGES_DETAILS, { state: { item: challenges } })} 
                                    >
                                        <div className='bites-seriesdetails mt-5' onClick={() => handleBitePlay(item)}>
                                            <div className='video-challenge position-relative'>
                                                <img src={item.thumbnail} className="video-deatil-chall img-fluid" alt="video-thumbnail" style={{ borderRadius: '25px' }} />
                                                <div className=" btn-play-video"><img src={playLightBtn} alt="" className='img-fluid play-icon-data' /></div>
                                                <h4 className='text-white' style={{ position: 'absolute', bottom: '10px', paddingLeft: '10px', fontSize: '22px', fontWeight: '500' }}>{item.title}</h4>
                                                <div className="grid-data-detail" style={{ gap: '0px' }}>
                                                    {/* <div className='grid-circle-img'>
                                                        <img src={item.channelIcon} className="img-fluid grid-data-img " alt="" style={{ width: '30px', height: '30px' }} />
                                                    </div>
                                                    <div>
                                                        <h6 className="white fw-500 mb-0" style={{ fontSize: '14px' }}>{item.channelName} </h6>
                                                        <p className=' mb-0'>{item.channelName}</p>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </section>
            {
                showBite &&
                <PlayBiteModal currentBiteIndex={currentBiteIndex} setCurrentBiteIndex={setCurrentBiteIndex} showBite={showBite} setShowBite={setShowBite} videoUrl={videoUrl} id={id} item={item} check="creator" />
            }
        </div>

    )
}
export default BitesSeries