import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { biteImg, bitesDark, challenge1, challengeBack, favRed, favouriteLight, favouritesDark, noFavourites, playLight, playLightBtn, videoPlayButton } from '../../Constants/images';
import ROUTES from '../../Helper/route-helper';
import './subscribers.css'

function Subscribers({ setTabSwitch, favSeries }) {
  const navigate = useNavigate()

  return (
    <div><section className="streaming py-6 response-padding ">
      <div className="container-fluid">
        <div className="row">

          <div className="stream-btn pt-3">
            <button onClick={() => setTabSwitch(0)} className="common-btn btn-white">Series</button>
            <button onClick={() => setTabSwitch(1)} className="common-btn btn-blue">Subscribers</button>
          </div>
        </div>
      </div>
    </section>
      <section className="py-60  favchannel-main-cus">
        <div className="container-fluid">
          <div className="row">
            <div className="bucket-sub-wrapper mb-4 position-relative">
              <div className="bucketsubscribe channels-box mb-3 mb-lg-3 mb-xl-0" style={{ cursor: 'pointer' }}>
                <img src={noFavourites} className="img-fluid bucket-img" />
                <h4 className="channel-text fw-600 my-2">John Smith</h4>
                <p className="channel-para mb-0 text-pink">Expiry date : 31-10-2023</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Subscribers;