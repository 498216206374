import React from 'react'
import Header1 from './../../Components/Header1/Header1'
import PopularTestimonial from '../../Components/PopularTestimonial/PopularTestimonial'
import Footer from './../../Components/Footer/Footer'
import { ContactUsBack, aboutUsBackground, contacLine, contactBanner, contactBannerBackground, faqright } from '../../Constants/images'
// import BannerEntertainment from './Components/BannerEntertainment/BannerEntertainment'
import '../../Pages/Landing/Landing'
const Help = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className="contact-back-banner" style={{ backgroundImage: `url(${ContactUsBack})` }} className='about-us-back'>
      <Header1 />
      <section className="Contact-banner py-60" style={{ backgroundImage: `url(${contactBannerBackground})` }} >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-lg-6 contact-head">
              <img src={faqright} alt="" />
            </div>
            <div className="col-12 col-lg-6">
              <h1 className="blue-text pb-3"><span className="pink-text font-120">F</span>requently asked questions</h1>
              <p className="fw-500 pb-2 ">Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua.Ut enim ad minim veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur.</p>
              <img src={contacLine} alt="" className="img-fluid" />
            </div>
          </div>
        </div>
      </section>
      <section className='accordion-help'>
        <div className='container'>

          <div className='row'>
            <div className='col-12 text-center faq-bottom'>
              <h2>FAQs</h2>
            </div>
            <div className='col-12 col-lg-8 m-auto'>
              <div class="accordion accordion-flush" id="accordionFlushExample">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingOne">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                      How can i start streaming series?
                    </button>
                  </h2>
                  <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">Lorem ipsum dolor sit amet consectetur. Bibendum scelerisque non egestas et sem aliquam quis turpis. Bibendum pretium egestas eu tortor auctor nibh non maecenas ipsum. Est turpis scelerisque tempor consectetur sodales cras eget arcu. Purus sit hendrerit fermentum viverra quis pellentesque nunc cras tellus. Eu nisl etiam massa lacus dui faucibus dolor. At enim elementum elit commodo eu egestas purus. Turpis quam nulla pharetra aliquet sem morbi. Amet dolor auctor pretium tempus urna ut est sapien pellentesque. A egestas maecenas semper egestas porttitor mauris in. Convallis ultrices eu pulvinar ac nullam amet neque.</div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingTwo">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                      How can i participate in challenges?
                    </button>
                  </h2>
                  <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">Lorem ipsum dolor sit amet consectetur. Bibendum scelerisque non egestas et sem aliquam quis turpis. Bibendum pretium egestas eu tortor auctor nibh non maecenas ipsum. Est turpis scelerisque tempor consectetur sodales cras eget arcu. Purus sit hendrerit fermentum viverra quis pellentesque nunc cras tellus. Eu nisl etiam massa lacus dui faucibus dolor. At enim elementum elit commodo eu egestas purus. Turpis quam nulla pharetra aliquet sem morbi. Amet dolor auctor pretium tempus urna ut est sapien pellentesque. A egestas maecenas semper egestas porttitor mauris in. Convallis ultrices eu pulvinar ac nullam amet neque.</div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingThree">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                      Can i save or bookmark my favourite series?
                    </button>
                  </h2>
                  <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">Lorem ipsum dolor sit amet consectetur. Bibendum scelerisque non egestas et sem aliquam quis turpis. Bibendum pretium egestas eu tortor auctor nibh non maecenas ipsum. Est turpis scelerisque tempor consectetur sodales cras eget arcu. Purus sit hendrerit fermentum viverra quis pellentesque nunc cras tellus. Eu nisl etiam massa lacus dui faucibus dolor. At enim elementum elit commodo eu egestas purus. Turpis quam nulla pharetra aliquet sem morbi. Amet dolor auctor pretium tempus urna ut est sapien pellentesque. A egestas maecenas semper egestas porttitor mauris in. Convallis ultrices eu pulvinar ac nullam amet neque.</div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingfour">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefour" aria-expanded="false" aria-controls="flush-collapsefour">
                    Are there any subscription-based features?
                    </button>
                  </h2>
                  <div id="flush-collapsefour" class="accordion-collapse collapse" aria-labelledby="flush-headingfour" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">Lorem ipsum dolor sit amet consectetur. Bibendum scelerisque non egestas et sem aliquam quis turpis. Bibendum pretium egestas eu tortor auctor nibh non maecenas ipsum. Est turpis scelerisque tempor consectetur sodales cras eget arcu. Purus sit hendrerit fermentum viverra quis pellentesque nunc cras tellus. Eu nisl etiam massa lacus dui faucibus dolor. At enim elementum elit commodo eu egestas purus. Turpis quam nulla pharetra aliquet sem morbi. Amet dolor auctor pretium tempus urna ut est sapien pellentesque. A egestas maecenas semper egestas porttitor mauris in. Convallis ultrices eu pulvinar ac nullam amet neque.</div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingfive">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefive" aria-expanded="false" aria-controls="flush-collapsefive">
                    Can i share series with my friends?
                    </button>
                  </h2>
                  <div id="flush-collapsefive" class="accordion-collapse collapse" aria-labelledby="flush-headingfive" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">Lorem ipsum dolor sit amet consectetur. Bibendum scelerisque non egestas et sem aliquam quis turpis. Bibendum pretium egestas eu tortor auctor nibh non maecenas ipsum. Est turpis scelerisque tempor consectetur sodales cras eget arcu. Purus sit hendrerit fermentum viverra quis pellentesque nunc cras tellus. Eu nisl etiam massa lacus dui faucibus dolor. At enim elementum elit commodo eu egestas purus. Turpis quam nulla pharetra aliquet sem morbi. Amet dolor auctor pretium tempus urna ut est sapien pellentesque. A egestas maecenas semper egestas porttitor mauris in. Convallis ultrices eu pulvinar ac nullam amet neque.</div>
                  </div>
                </div>
              </div>
            </div>
          </div>


        </div>
      </section>

      <Footer />
    </div>
  )
}

export default Help