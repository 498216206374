import React, { useState } from 'react'
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import "react-responsive-carousel/lib/styles/carousel.min.css";
function CastAndCrew(props) {


    return (
        <div>
            <section className="cast-crew">
                <div className="container mt-5">
                    <h3 className="fw-600">Cast & Crew</h3>
                    <div className="crew-wrpper-slider text-center gap-4 mt-5 flex-wrap">
                        {
                            props?.seriesDetail?.series_casts.map((item) => {
                                return (
                                    <div className="crew-caste">
                                        <div className='width-img'>
                                            <img src={item?.image} alt="" className='cast-crew-img'/>
                                        </div>
                                        <h5 className="mt-3">{item?.name}</h5>
                                        <p className="movie-tex video-text">{item?.name_in_series}</p>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </section>


        </div >
    )
}

export default CastAndCrew;