import React from 'react'
import { modalCross } from '../../Constants/images'

const ChangePasssword = () => {
    return (

        <div className="modal fade" id="change" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content modal-radius forgot-body">
                    <div className="modal-header forgot-header align-items-center">
                        <h3 className="modal-title text-center mx-auto forgot-title" id="exampleModalLabel">Change Password</h3>
                        <a className="m-0 px-0" data-bs-dismiss="modal" aria-label="Close"><img src={modalCross} alt="" className="img-fluid" /></a>
                    </div>
                    <div className="modal-body forgot-body pt-4 px-2 px-sm-5">
                        <form>
                            <div className="form-group mb-3 pt-4">
                                <label className="fw-500 mb-2 forgot-label">Old Password</label>
                                <input type="password" className="form-control  fw-500 forgot-input" placeholder="Enter old Password" />
                            </div>
                            <div className="form-group mb-3">
                                <label className="fw-500 mb-2 forgot-label">New Password</label>
                                <input type="password" className="form-control  fw-500 forgot-input" placeholder="Enter New Password" />
                            </div>
                            <div className="form-group mb-3 pb-2">
                                <label className="fw-500 mb-2 forgot-label">Confirm Password</label>
                                <input type="password" className="form-control  fw-500 forgot-input" placeholder="Confirm New Password" />
                            </div>
                            <div className="my-5">
                                <a href="" className="common-btn w-100" >Save</a>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default ChangePasssword