import React from "react";
import { image1 } from "../../Constants/images";

const ChooseSeries = () => {
    return (
        <div className="main-inner-content">
            <section className="choose-wrapper">
                <div className="container">
                    <div className="row align-items-center py-3">
                        <div className="col-sm-5">
                            <div className="">
                                <h3 className="fw-600">Choose Series</h3>
                            </div>
                        </div>
                        <div className="col-sm-7 ms-auto text-end d-flex justify-content-end live-start-btn">
                            <button className="common-btn ">Submit</button>
                        </div>
                    </div>
                    <div className="row">
                        <div className=" col-lg-6 col-xxl-4 mb-3">
                            <div className="chooseseries-inner">
                                    <div className="chooseimg-main">
                                        <img src={image1} className="chooseinner-img"></img>
                                    </div>
                                    <div className="chooseinner-heading">
                                        <h5>The Walking deed</h5>
                                        <p className="mb-0">Entertainment</p>
                                        <p className="mb-0">13 Episodes</p>
                                    </div>
                                <div class="roundcheckbox">
                                    <input type="checkbox"  id="checkbox" />
                                    <label for="checkbox"></label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

    )
}
export default ChooseSeries