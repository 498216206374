import React, { useEffect, useState } from 'react';
import apiHelper from '../../Helper/api-helper';
import DEVELOPMENT_CONFIG from '../../Helper/config';
import './interactives.css';
import ReactLoader from '../../ControlComponents/react-loader'
import MyInteractives from './myInteractives';
import InteractiveModal from './InteractiveModal';
import { comming_soon, frame208 } from '../../Constants/images';


const CreaterInteractives = () => {
    const [tabSwitch, setTabSwitch] = useState(0);
    const [favChannels, setFavChannels] = useState([])
    const [favSeries, setFavSeries] = useState([])
    const [refresh, setRefresh] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [interactiveModal, setInteractiveModal] = useState(false)

    const handleOpen = () => {
        setInteractiveModal(!interactiveModal)
    }

    async function getDetailsAPI() {
        setIsLoading(true)
        let result = await apiHelper.getRequest("channel/get-favorites-list?type=" + tabSwitch)
        if (result.code == DEVELOPMENT_CONFIG.statusCode) {
            if (tabSwitch == 0) {
                setFavSeries(result.data)
            } else {
                setFavChannels(result.data)
            }
            setIsLoading(false)
           }
           else if (result.code == 401) {
            let refreshToken = localStorage.getItem("refresh-token")
            let data = {
                "refresh_token": refreshToken,
                "scope": "refresh_token"
            }
            let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
            if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
                localStorage.setItem("token", refreshTokenResult.data.token)
                localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
                setRefresh(true)
            }
        }
    }

    // useEffect(() => {
    //     getDetailsAPI()
    // }, [tabSwitch, refresh])

    return (
        <div>
            {isLoading && <ReactLoader />}
            <div className="main-inner-content">
            <section class="comming-soon py-60 response-padding"><div class="container"><div class="chanel-header"><h3 class="fw-600 mb-3">Coming Soon</h3></div><div class="coming-nft-img position-relative"><img src={comming_soon} alt="" class="w-100 img-fluid"/></div></div></section>
                {/* <div className='row align-items-center'>
                    <div className='col-sm-5'>
                        <div className="">
                            <h3 className="fw-600">Interactives</h3>
                        </div>
                    </div>
                    <div className='col-sm-7 ms-auto text-end d-flex justify-content-end live-start-btn'>
                        <button className="common-btn " onClick={handleOpen}>Create Interactive</button>
                    </div>
                </div>

                <MyInteractives favChannels={favChannels} setTabSwitch={setTabSwitch} />
                {interactiveModal && <InteractiveModal setInteractiveModal={setInteractiveModal} />} */}
            </div>
        </div>
    )
}

export default CreaterInteractives;