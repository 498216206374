import React, { useState } from 'react'
import { gallery, modalCross, plusgray, videoPlayButton } from '../../Constants/images'
import ERR_MESSAGE from '../../Helper/error-helper';


const EpisodeModal = ({ setEpisodeModal, info, handleChange, fileChangeEpisode, thumbnail1, errors, handleSubmitEpisode, setErrors, setThumbnail1, setInfo
}) => {

    return (
        <div className="modal fade show modal-react" style={{ display: "block", opacity: "1", backdropFilter: "blur(5px)" }}>
            <div className='loader'>
            </div>
            <div className="modal-dialog modal-dialog-centered modal-schedule-width">
                <div className="modal-content modal-radius  border-0">
                    <div className="modal-header forgot-header align-items-center pt-4">
                        <h3 className="modal-title text-center mx-auto forgot-title" id="exampleModalLabel">Add Episode</h3>
                        <a onClick={() => {
                            setErrors("")
                            setEpisodeModal(false)
                            setInfo({
                                ...info,
                                episodeTitle: "",
                                episodeSummary: ""
                            })

                            setThumbnail1("")
                        }}
                            className="m-0 px-0 btn-closed-main" data-bs-dismiss="modal" aria-label="Close"><img style={{ cursor: "pointer" }} src={modalCross} alt="" className="img-fluid btn-cloesed" /></a>
                    </div>
                    <div className="modal-body  verify-modal-box px-2 px-sm-5">
                        <form >
                            <div className=''>
                                <label className='fw-500 f18-size text-black pb-2'>Episode Video</label>
                                <div className="mb-3 schedule-thumb">
                                    <div className="video-upload video-schedule position-relative">
                                        {
                                            thumbnail1 && <div className={thumbnail1 ? " episode-preview" : ""}><img src={thumbnail1 ? thumbnail1 : ""}></img>
                                                <div class="play-bite"><img src={videoPlayButton} class="img-fluid img-bite" />
                                                </div>
                                            </div>

                                        }
                                        {
                                            thumbnail1 ? "" : <>
                                                <div className='d-flex m-auto justify-content-center align-items-center h-100 episode-preview-after'>

                                                    <div className=''>
                                                        <div className='text-center'>
                                                            <img src={gallery}></img>
                                                        </div>

                                                        <div className="d-flex m-auto justify-content-center align-items-center h-100">
                                                            <img src={plusgray} alt="" className="img-fluid me-1" />
                                                            <span className="Upload-text fw-500 create-text border-bottom ">Add Episode Video</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                        <input type="file" className="video-file-schedule" onChange={fileChangeEpisode} accept=".mp4, .mov, .avi" style={{ cursor: "pointer" }}
                                        />
                                        {errors.thumbnail1 && <span className="err_msg">{errors.thumbnail1}</span>}
                                    </div>
                                </div>
                                <div className='mb-3'>
                                    <label className='fw-500 f18-size text-black pb-2'>Episode Title</label>
                                    <input type='text' className='border-edit  w-100' placeholder="Enter Here" name='episodeTitle' value={info.episodeTitle} onChange={handleChange}></input>
                                    {errors.episodeTitle && <span className="err_msg">{errors.episodeTitle}</span>}
                                </div>
                                <div className='mb-3'>
                                    <label className='fw-500 f18-size text-black pb-2'>Summary</label>
                                    <textarea className="fw-500d-block border-edit resize-none w-100 h-150 pt-3" placeholder="Enter Here" rows="6" name='episodeSummary' value={info.episodeSummary} onChange={handleChange} ></textarea>
                                    {errors.episodeSummary && <span className="err_msg">{errors.episodeSummary}</span>}
                                </div>
                            </div>
                            <div className=" my-4" onClick={handleSubmitEpisode}>
                                <input type='submit' value="Save" className="common-btn w-100" />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default EpisodeModal
