import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { favGrey, favRed, noChannelFound, profile_image_placeholder } from '../../Constants/images';
import ReactLoader from '../../ControlComponents/react-loader';
import apiHelper from '../../Helper/api-helper';
import DEVELOPMENT_CONFIG from '../../Helper/config';
import ROUTES from '../../Helper/route-helper';
import './dashchannel.css';

const AllChannels = () => {
    const [filter, setFilter] = useState(0)
    const [_, forceUpdate] = useState(0)
    const navigate = useNavigate();
    const [refresh, setRefresh] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [channelList, setChannelList] = useState([])
    const [inputText, setInputText] = useState('')

    async function getAPI() {
        setIsLoading(true)
        let url;
        if (filter == 0) {
            url = "channel/channels-list"
        }
        else if (filter == 1) {
            url = "channel/channels-list?filterSort=desc"
        }
        else if (filter == 2) {
            url = "channel/channels-list"
        }
        let result = await apiHelper.getRequest(url)
        if (result.code === DEVELOPMENT_CONFIG.statusCode) {
            if (filter == 0 || filter == 1) {
                setChannelList(result?.data.channels)
            }
            else if (filter == 2) {
                setChannelList(result?.data?.popularChannels)
            }
            setIsLoading(false)
        }
        else if (result.code == 401) {
            let refreshToken = localStorage.getItem("refresh-token")
            let data = {
                "refresh_token": refreshToken,
                "scope": "refresh_token"
            }
            let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
            if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
                localStorage.setItem("token", refreshTokenResult.data.token)
                localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
                setRefresh(true)
            }
        }
    }

    async function postAPI(item) {
        let data = {
            "type": "1",
            "channel_id": item?.id
        }

        if (item.is_fav == 1) {
            item.is_fav = 0
        } else {
            item.is_fav = 1
        }
        forceUpdate(Math.random())
        let result = await apiHelper.postRequest("channel/add-favorites", data)
        if (result?.code === DEVELOPMENT_CONFIG?.statusCode) {

        }
    }

    useEffect(() => {
        getAPI()
    }, [refresh, filter]);

    const handleChange = async (e) => {
        setInputText(e.target.value)
        let result = await apiHelper.getRequest(`channel/channels-list?search=${e.target.value}`)
        if (result.code === DEVELOPMENT_CONFIG.statusCode) {
            if (filter == 2) {
                setChannelList(result?.data?.popularChannels)
            }
            else {
                setChannelList(result?.data?.channels)
            }
        }
    }

    return (
        <div className='main-inner-content'>
            {isLoading && <ReactLoader />}
            <section className="streaming py-6 response-padding">
                <div className="container">
                    <div className='row justify-content-end align-items-center mb-3 mb-md-0'>
                        <div className='col-12 col-lg-8 col-md-8 '>
                            <h3 className='mb-4 mb-md-0 fw-600'>All Channels</h3>
                        </div>
                        <div className='col-12 col-lg-4 col-md-4 px-0'>
                            <div className="form-allchannel-search position-relative w-100">
                                <form><input
                                    onChange={handleChange}
                                    type="text" name="search" className="form-control" placeholder="Search" />
                                    <button type="button" className="icon-search"><img src="/assets/images/header/search.svg" alt="" className="search-img" />
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="stream-btn pt-3">
                            <button onClick={() => setFilter(0)} className={filter == 0 ? "common-btn btn-blue" : "common-btn btn-white"}>All</button>
                            <button onClick={() => setFilter(1)} className={filter == 1 ? "common-btn btn-blue" : "common-btn btn-white"}>Latest</button>
                            <button onClick={() => setFilter(2)} className={filter == 2 ? "common-btn btn-blue" : "common-btn btn-white"}>Popular</button>
                        </div>
                    </div>
                </div>
            </section>
            {
                channelList?.length > 0 &&
                <section className="py-60">
                    <div className="container">
                        <div className="row">
                            {

                                channelList.map((channels) => {
                                    return (
                                        <div key={channels?.id} className="col-12 col-sm-6 col-lg-6 col-xl-3 mb-4 position-relative">
                                            <div className="channels-box  mb-3 mb-lg-3 mb-xl-0" style={{ cursor: 'pointer' }}
                                                onClick={() => navigate(ROUTES.CHANNELS_DETAILS + "?cd=allChannels", { state: { item: channels } })}>
                                                <img src={channels?.profile_pic ? channels?.profile_pic : profile_image_placeholder} className="img-fluid channel-img" />
                                                <h4 className="channel-text fw-600 my-2">{channels?.name}</h4>
                                                {/* <p className="channel-para mb-0">{channels?.category?.name}</p> */}
                                            </div>
                                            <div onClick={() => postAPI(channels)} className="like-icon-channel">
                                                {
                                                    channels?.is_fav == 1 ?
                                                        <img onClick={postAPI} src={favRed} className="img-fluid fill-icon" /> :
                                                        <img onClick={postAPI} src={favGrey} className="img-fluid fill-icon" />
                                                }
                                            </div>
                                        </div>
                                    )
                                })

                            }
                        </div>
                    </div>
                </section>
            }

            {
                !isLoading &&
                <>
                    {
                        channelList.length <= 0 &&
                        <div style={{ flexDirection: 'column' }} className='no-search-results'>
                            <img className='img-fluid' src={noChannelFound} />
                            {/* <p className="mb-0">
                                No results found for {inputText}, try searching again with another keyword
                            </p> */}
                        </div>
                    }
                </>
            }
        </div>
    )
}

export default AllChannels

