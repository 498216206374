import React, { useEffect, useState } from 'react'
import { dummy, seeAllArrow, show_audio } from '../../../../Constants/images'
import PriceTag from '../../../../CreaterDashboard/component/price-tag'
import ROUTES from '../../../../Helper/route-helper'
import apiHelper from '../../../../Helper/api-helper'
import DEVELOPMENT_CONFIG from "../../../../Helper/config"
import { useNavigate } from 'react-router-dom'
import BuyModalOther from '../buyModal/buyModal'
import AddDetailVideoModal from '../../../UserShop/detailVideoModal'
import AddDetailModal from '../../../UserShop/detailImageModal'
import AddDetailAudioModal from '../../../UserShop/detailAudioModal'
function ShopHome(props) {
    const [shopList, setShopList] = useState([])
    const [refresh, setRefresh] = useState(false)
    const [shopOpen, setShopOpen] = useState(false)
    const [item, setItem] = useState(false)
    const [addDetailAudioModal, setAddDetailAudioModal] = useState(false)
    const [deltId, setDeltId] = useState(null)
    const [detailOpen, setDetailOpen] = useState(false)
    const [addDetailVideoModal, setAddDetailVideoModal] = useState(false)

    const navigate = useNavigate()
    async function getAPI() {
        props?.setIsLoading(true)
        let result = await apiHelper.getRequest("shop/home-shop-media")
        if (result.code === DEVELOPMENT_CONFIG.statusCode) {
            setShopList(result.data)
            props?.setIsLoading(false)
        } else if (result.code == 401) {
            let refreshToken = localStorage.getItem("refresh-token")
            let data = {
                "refresh_token": refreshToken,
                "scope": "refresh_token"
            }
            let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
            if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
                localStorage.setItem("token", refreshTokenResult.data.token)
                localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
                setRefresh(true)
            }
        }
    }

    useEffect(() => {
        getAPI()
    }, [refresh])
    return (
        <div className='bites-home'>
            <section className="bite response-padding">

                <div className="container">
                    {
                        shopList.length > 0 && <div className="chanel-header">
                            <h3 className="fw-600 mb-0 common-btn btn-blue btn-shape f20-size">Shop</h3>
                            <button
                                onClick={() => navigate(ROUTES.USER_SHOP)}
                                className="see-all fw-600 f18-size">See all <img src={seeAllArrow} alt="" className="ms-2" /></button>
                        </div>
                    }

                    <div className="chanel-wrapper mt-4">
                        <div className="row one-row-scroll">
                            {
                                shopList.map((item) => (
                                    <div className="col-12 col-sm-6 col-lg-4 col-xl-4 col-xxl-3 pb-4"
                                        key={item?.id}
                                    >
                                        <div className="latest-video mx-auto">
                                            <div className='position-relative'>
                                                <div className="latest-video-content position-relative pt-2 pt-sm-3 px-2 px-sm-3"
                                                    style={{ cursor: "pointer" }}>
                                                    <img
                                                        src={item.shop_type == "audio" ? show_audio : item.shop_type == "video" ? item?.shop_media[0]?.media_thumbnail : item?.shop_media[0]?.media_url}
                                                        className="img-fluid latest-vid-img new-latest-video" alt="thumbnail" />
                                                    {
                                                        item.is_purchase == 0 && <PriceTag classes={"price_tag new-price-tag"} firstValue={`$${123}`} secondValue={"00"} />
                                                    }
                                                    <div className="d-flex justify-content-start align-items-center gap-2 lives-logo">
                                                        <img src={item.profile_pic} className="img-fluid grid-data-img " alt="" style={{ width: '30px', height: '30px', borderRadius: '100%', border: '1px solid #CA3392' }} />
                                                        <h6
                                                            className="fw-500 mb-0 "
                                                            style={{
                                                                fontSize: '16px',
                                                                color: item.shop_type === "audio" ? "#ca3392" : "#fff"
                                                            }}
                                                        >
                                                            {item.name}
                                                        </h6>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="px-3 py-3 schedule-grid">
                                                <div className=''>
                                                    <h4 className="schedule-title">
                                                        {item.title}
                                                    </h4>
                                                    <h6 className="images-count">
                                                        {item.shop_media.length} {item.shop_media.length === 1 ? "Image" : "Images"}
                                                    </h6>
                                                    <p className="images-description shop-description-detail">
                                                        {item.description}
                                                    </p>
                                                    {item.is_purchase == 0 ? (
                                                        <button style={{ cursor: 'pointer' }} className="common-btn watch-btn buy-btn-shop"
                                                            onClick={() => {
                                                                setShopOpen(true)
                                                                setItem(item)
                                                            }}                                                        >
                                                            <span className='me-2' >Buy Now</span>
                                                        </button>
                                                    ) : (
                                                        <button style={{ cursor: 'pointer' }}
                                                            onClick={() => {
                                                                console.log(item.shop_type, "type")
                                                                setDeltId(item.id)
                                                                if (item.shop_type == "image") {
                                                                    setDetailOpen(true)
                                                                }
                                                                else if (item.shop_type == "audio") {
                                                                    setAddDetailAudioModal(true)
                                                                }
                                                                else {
                                                                    setAddDetailVideoModal(true)
                                                                }
                                                            }}
                                                            className="common-btn watch-btn buy-btn-shop">
                                                            <span className='me-2'>View</span>
                                                        </button>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }

                        </div>
                    </div>
                </div>
                {
                    shopOpen && <BuyModalOther setShopOpen={setShopOpen} item={item} getAPI={getAPI}
                        setLoading={props.setIsLoading} />
                }
                {
                    addDetailAudioModal && <AddDetailAudioModal setAddDetailAudioModal={setAddDetailAudioModal} deltId={deltId} />
                }
                {
                    detailOpen && <AddDetailModal setDetailModal={setDetailOpen} deltId={deltId} />
                }
                {
                    addDetailVideoModal && <AddDetailVideoModal setAddDetailVideoModal={setAddDetailVideoModal} deltId={deltId} />
                }
            </section>
        </div>
    )
}

export default ShopHome