import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { dotsbg, playLight, profile_image_placeholder } from '../../Constants/images';
import './createrBites.css'
import { Dropdown } from 'react-bootstrap';
import BiteModalCreator from './createBiteModal';
import DeleteBite from './deleteBiteModal';
import apiHelper from '../../Helper/api-helper';
import DEVELOPMENT_CONFIG from "../../../src/Helper/config"
import { NotificationManager } from 'react-notifications';
import PlayBiteModal from './playBite';
function MyBites({ bitesList, favSeries, getBiteAPI, setIsLoading, setProgress, setProgressLoading, setRefresh, refresh }) {
   const [biteModal, setBiteModal] = useState(false)
   const [showBite, setShowBite] = useState(false)
   const [currentBiteIndex, setCurrentBiteIndex] = useState();
   const [videoUrl, setVideoUrl] = useState()
   const [deleteModal, setDeleteModal] = useState(false)
   const [id, setId] = useState(null)
  const[item,setItem] = useState()
   const handleDeleteOpen = (id) => {
      setDeleteModal(!deleteModal)
      setId(id)
   }

   
   const handleBitePlay = (item) => {
      setVideoUrl(item.video)
      setShowBite(!showBite);
      // setCurrentBiteIndex(id);
      setId(item.id)
      setItem(item)
   }

   const handleOpen = (id) => {
      setBiteModal(!biteModal)
      setId(id)
   }

   async function getDeleteAPI() {
      setIsLoading(true)
      let result = await apiHelper.getRequest("creator/delete-bite?id=" + id)
      if (result.code == DEVELOPMENT_CONFIG.statusCode) {
         setIsLoading(false)
         setDeleteModal(false)
         getBiteAPI()
         NotificationManager.success(result.message)
      }
      else if (result.code == 401) {
         let refreshToken = localStorage.getItem("refresh-token")
         let data = {
            "refresh_token": refreshToken,
            "scope": "refresh_token"
         }
         let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
         if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
            localStorage.setItem("token", refreshTokenResult.data.token)
            localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
            setRefresh(true)
         }
      }
      else {
         NotificationManager.error(result.message)
      }
   }

   return (
      <div>
         <section className="favchannel-main-cus createchallenge-wrapper">
            <div className="container-fluid">
               <div className='row'>
                  <div className="chanel-wrapper mt-2">
                     <div className="row gy-2">
                        {
                           bitesList.map((item) => (
                              <div className="col-xl-4 col-xxl-3 col-lg-4 col-md-6  response-col" key={item.id} >
                                 <div className="channel-bites-content">
                                    <div className='position-relative'>
                                       <img src={item.thumbnail} className="channelbite-img" alt="" />
                                       <div className='dotsmain'>
                                          <Dropdown>
                                             <Dropdown.Toggle id="custom-dropdown-toggle">
                                                <img src={dotsbg} className='img-fluid'></img>
                                             </Dropdown.Toggle>
                                             <Dropdown.Menu>
                                                <Dropdown.Item onClick={() => handleOpen(item.id)} >Edit</Dropdown.Item>
                                                <Dropdown.Item
                                                   onClick={() => handleDeleteOpen(item.id)}
                                                > Delete</Dropdown.Item>
                                             </Dropdown.Menu>
                                          </Dropdown>
                                       </div>
                                    </div>
                                    <div className="bite-wrapper">
                                       <h4>{item.title}</h4>
                                       <div className="chanel-text d-flex gap-3 align-items-center">
                                          <img src={item.biteChannel.profile_pic}className="bite-inner-img" alt="" />
                                          <div>
                                             <h6 className='fw-600 mb-0'>{item?.biteChannel?.name ? item?.biteChannel?.name: profile_image_placeholder}</h6>
                                             <p className='mt-1'>{item?.category?.name}</p>
                                          </div>
                                       </div>
                                       <button className="common-btn" onClick={() => handleBitePlay(item)} > <img src={playLight} alt=""></img>Play</button>
                                    </div>
                                 </div>
                              </div>
                           ))
                        }

                     </div>
                  </div>
               </div>
            </div>
         </section>
         {
            showBite &&
            <PlayBiteModal currentBiteIndex={currentBiteIndex} setCurrentBiteIndex={setCurrentBiteIndex} showBite={showBite} setShowBite={setShowBite} videoUrl={videoUrl} id={id} item={item}/>
         }
         {biteModal && <BiteModalCreator getBiteAPI={getBiteAPI} setIsLoading={setIsLoading} setProgressLoading={setProgressLoading} setProgress={setProgress} setBiteModal={setBiteModal} favSeries={favSeries} id={id} setRefresh={setRefresh} refresh={refresh} />}

         {
            deleteModal && <DeleteBite setDeleteModal={setDeleteModal} getDeleteAPI={getDeleteAPI} />
         }
      </div>
   )
}

export default MyBites;