import React from 'react'
import { access, arrowbg, dummy, graphicon, playbg, subscribeicon, tincome, tipsbox, videoicon } from '../../Constants/images'
import "./dashboard.css"
function Dashboard() {
  return (
    <div className="main-inner-content">
      <div className="row ">
        <div className=" col-md-6 col-lg-4 col-xxl-3 mb-3 mb-xxl-0">
          <div className="tip-box bg-white p-3 ">
            <div className="d-flex justify-content-between">
              <div className="col-6  "><img src={tincome} className="icon-background" />
              </div>
              <div className="col-6 text-end"><img src={graphicon} /></div>
            </div>
            <h5 className="mt-3 fw-600">Total Income <span className="text-success mx-2 fs-6"><i
              className="fa-solid fa-caret-up mx-2"></i>10%</span></h5>
            <div className="d-flex justify-content-between justify-content-center mt-3">
              <h3 className=" fw-600">$50,000</h3>
              <img src={arrowbg} className="next-icon" />
            </div>
          </div>
        </div>
        <div className=" col-md-6 col-lg-4 col-xxl-3 mb-3 mb-xxl-0">
          <div className="tip-box bg-white p-3 ">
            <div className="d-flex justify-content-between">
              <div className="col-6 "><img src={subscribeicon} className="icon-background" />
              </div>
              <div className="col-6 text-end "><img src={graphicon} /></div>
            </div>
            <h5 className="mt-3 fw-600">Subscribers<span className="text-success mx-2 fs-6"><i
              className="fa-solid fa-caret-up mx-2"></i>5%</span></h5>
            <div className="d-flex justify-content-between  mt-3">
              <h3 className="fw-600">568</h3>
              <img src={arrowbg} className="next-icon" />
            </div>
          </div>
        </div>
        <div className=" col-md-6 col-lg-4 col-xxl-3 mb-3 mb-xxl-0">
          <div className="tip-box bg-white p-3 ">
            <div className="d-flex justify-content-between">
              <div className="col-6  "><img src={tipsbox} className="icon-background" />
              </div>
              <div className="col-6 text-end"><img src={graphicon} /></div>
            </div>
            <h5 className="mt-3 fw-600">Tips <span className="text-success mx-2 fs-6"><i
              className="fa-solid fa-caret-up mx-2"></i>20%</span></h5>
            <div className="d-flex justify-content-between  mt-3">
              <h3 className=" fw-600">$50,000</h3>
              <img src={arrowbg} className="next-icon" />
            </div>
          </div>
        </div>
        <div className=" col-md-6 col-lg-4 col-xxl-3 mb-3 mb-xxl-0">
          <div className="tip-box bg-white p-3 ">
            <div className="d-flex justify-content-between">
              <div className="col-6 "><img src={videoicon} className="icon-background" />
              </div>
              <div className="col-6 text-end"><img src={graphicon} /></div>
            </div>
            <h5 className="mt-3 fw-600">Uploaded Videos<span className="text-success mx-2 fs-6"><i
              className="fa-solid fa-caret-up mx-2"></i>10%</span></h5>
            <div className="d-flex justify-content-between  mt-3">
              <h3 className="fw-600">251</h3>
              <img src={arrowbg} className="next-icon" />
            </div>
          </div>
        </div>

      </div>
      <div className='row mt-4'>
        <div className='col-lg-6  col-xxl-4 mb-3 mb-xxl-0'>
          <div className='bucketvideo_wrapper bg-white p-3'>
            <div className='d-flex justify-content-between align-items-center  pb-4'>
              <h3 className='fw-600 mb-0'>Uploaded Videos</h3>
              <a className='cursor-pointer text-pink f16-size fw-600'>See all</a>
            </div>
            <ul className='uploadedvideo_listed ps-0 '>
              <li className='pb-3'>
                <div className='upload_inner border-bottom pb-3'>
                  <div className='upload-profile'>
                    <img src={dummy}></img>
                  </div>
                  <div className='upload-text'>
                    <h4 className='mb-1'>Pixel Pursuit</h4>
                    <p className='mb-0 lh-base f16-size fw-500 video-text' >12h 34min</p>
                    <p className='mb-0 lh-base f16-size fw-500 video-text'>13 Episodes</p>
                  </div>
                  <div className=''>
                    <img src={playbg}></img>
                  </div>
                </div>
              </li>
              <li className='pb-3'>
                <div className='upload_inner border-bottom pb-3'>
                  <div className='upload-profile'>
                    <img src={dummy}></img>
                  </div>
                  <div className='upload-text'>
                    <h4 className='mb-1'>Pixel Pursuit</h4>
                    <p className='mb-0 lh-base f16-size fw-500 video-text' >12h 34min</p>
                    <p className='mb-0 lh-base f16-size fw-500 video-text'>13 Episodes</p>
                  </div>
                  <div className=''>
                    <img src={playbg}></img>
                  </div>
                </div>
              </li>
              <li className='pb-3'>
                <div className='upload_inner border-bottom pb-3'>
                  <div className='upload-profile'>
                    <img src={dummy}></img>
                  </div>
                  <div className='upload-text'>
                    <h4 className='mb-1'>Pixel Pursuit</h4>
                    <p className='mb-0 lh-base f16-size fw-500 video-text' >12h 34min</p>
                    <p className='mb-0 lh-base f16-size fw-500 video-text'>13 Episodes</p>
                  </div>
                  <div className=''>
                    <img src={playbg}></img>
                  </div>
                </div>
              </li>
              <li className='pb-3'>
                <div className='upload_inner border-bottom pb-3'>
                  <div className='upload-profile'>
                    <img src={dummy}></img>
                  </div>
                  <div className='upload-text'>
                    <h4 className='mb-1'>Pixel Pursuit</h4>
                    <p className='mb-0 lh-base f16-size fw-500 video-text' >12h 34min</p>
                    <p className='mb-0 lh-base f16-size fw-500 video-text'>13 Episodes</p>
                  </div>
                  <div className=''>
                    <img src={playbg}></img>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className='col-lg-6  col-xxl-4 mb-3 mb-xxl-0'>
          <div className='bucketvideo_wrapper bg-white p-3'>
            <div className='d-flex justify-content-between align-items-center  pb-4'>
              <h3 className='fw-600 mb-0'>Buckets</h3>
              <a className='cursor-pointer text-pink f16-size fw-600'>See all</a>
            </div>
            <ul className='uploadedvideo_listed ps-0 '>
              <li className='pb-3'>
                <div className='bucketdash_inner'>
                  <div className='upload-profile'>
                    <img src={access}></img>
                  </div>
                  <div className='upload-text'>
                    <h4 className='mb-1'>Pixel Pursuit</h4>
                    <p className='mb-0 lh-base f16-size fw-500 video-text' >12h 34min</p>
                    <p className='mb-0 lh-base f16-size fw-500 video-text'>13 Episodes</p>
                  </div>
                  <div className='bucketprice'>
                    <p className='mb-0'>$150.<span className='f14-size'>00</span></p>
                  </div>
                </div>
              </li>
              <li className='pb-3'>
                <div className='bucketdash_inner'>
                  <div className='upload-profile'>
                    <img src={access}></img>
                  </div>
                  <div className='upload-text'>
                    <h4 className='mb-1'>Pixel Pursuit</h4>
                    <p className='mb-0 lh-base f16-size fw-500 video-text' >12h 34min</p>
                    <p className='mb-0 lh-base f16-size fw-500 video-text'>13 Episodes</p>
                  </div>
                  <div className='bucketprice'>
                    <p className='mb-0'>$150.<span className='f14-size'>00</span></p>
                  </div>
                </div>
              </li>
              <li className='pb-3'>
                <div className='bucketdash_inner'>
                  <div className='upload-profile'>
                    <img src={access}></img>
                  </div>
                  <div className='upload-text'>
                    <h4 className='mb-1'>Pixel Pursuit</h4>
                    <p className='mb-0 lh-base f16-size fw-500 video-text' >12h 34min</p>
                    <p className='mb-0 lh-base f16-size fw-500 video-text'>13 Episodes</p>
                  </div>
                  <div className='bucketprice'>
                    <p className='mb-0'>$150.<span className='f14-size'>00</span></p>
                  </div>
                </div>
              </li>
              <li className='pb-3'>
                <div className='bucketdash_inner'>
                  <div className='upload-profile'>
                    <img src={access}></img>
                  </div>
                  <div className='upload-text'>
                    <h4 className='mb-1'>Pixel Pursuit</h4>
                    <p className='mb-0 lh-base f16-size fw-500 video-text' >12h 34min</p>
                    <p className='mb-0 lh-base f16-size fw-500 video-text'>13 Episodes</p>
                  </div>
                  <div className='bucketprice'>
                    <p className='mb-0'>$150.<span className='f14-size'>00</span></p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className='col-lg-12  col-xxl-4 mb-3 mb-xxl-0'>
          <div className='bucketvideo_wrapper bg-white p-3'>
            <div className='d-flex justify-content-between align-items-center  pb-4'>
              <h3 className='fw-600 mb-0'>Tips</h3>
              <a className='cursor-pointer text-pink f16-size fw-600'>See all</a>
            </div>
            <ul className='tipsdash_wrapper ps-0 mb-0 uploadedvideo_listed'>
              <li className='pb-3'>
                <div className='upload_inner border-bottom pb-3'>
                  <div className='upload-profile'>
                    <img src={dummy}></img>
                  </div>
                  <div className='upload-text'>
                    <h4 className='mb-1 fw-600'>Pixel Pursuit</h4>
                  </div>
                  <div className='bucketprice'>
                    <p className='mb-0'>$50.<span className='f14-size'>00</span></p>
                  </div>
                </div>
              </li>
              <li className='pb-3'>
                <div className='upload_inner border-bottom pb-3'>
                  <div className='upload-profile'>
                    <img src={dummy}></img>
                  </div>
                  <div className='upload-text'>
                    <h4 className='mb-1 fw-600'>Pixel Pursuit</h4>
                  </div>
                  <div className='bucketprice'>
                    <p className='mb-0'>$50.<span className='f14-size'>00</span></p>
                  </div>
                </div>
              </li>
              <li className='pb-3'>
                <div className='upload_inner border-bottom pb-3'>
                  <div className='upload-profile'>
                    <img src={dummy}></img>
                  </div>
                  <div className='upload-text'>
                    <h4 className='mb-1 fw-600'>Pixel Pursuit</h4>
                  </div>
                  <div className='bucketprice'>
                    <p className='mb-0'>$50.<span className='f14-size'>00</span></p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className='row mt-4'>
        <div className='col-12'>
          <div className="subscribers-table">
            <h3 className='fw-600'>Subscribers</h3>
            <div className="table-responsive">
              <table className="table">
                <thead className="sticky-top bg-white">
                  <tr>
                    <td className="text-start">
                      <h5 className="video-text f20-size ">Name</h5>
                    </td>
                    <td>
                      <h5 className="video-text f20-size ">Bucket</h5>
                    </td>
                    <td>
                      <h5 className="video-text f20-size ">Expiry Date</h5>
                    </td>
                    <td>
                      <h5 className="video-text f20-size ">Tips</h5>
                    </td>
                    <td></td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div className="d-flex  align-items-center justify-content-start">
                        <img src={dummy}
                          className=" rounded-circle subs-img" />
                        <p className="subscribers ms-3 mb-0 f20-size fw-500">John Smith</p>
                      </div>
                    </td>
                    <td>
                      <p className="lh-0  mb-0 f20-size fw-500 blue-text">Combo</p>
                    </td>
                    <td>
                      <p className="subscribers f20-size fw-500 mb-0 lh-0">11/08/2023</p>
                    </td>
                    <td>
                      <p className="subscribers f20-size fw-500 mb-0 lh-0">10</p>
                    </td>
                    <td><button
                      className="btn text-white view-btn text-center">View</button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dashboard