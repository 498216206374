import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { FIREBASE_CRED } from "./Helper/config";

const firebaseConfig = {
    apiKey: FIREBASE_CRED.API_KEY,
    authDomain: FIREBASE_CRED.AUTH_DOMAIN,
    projectId: FIREBASE_CRED.PROJECT_ID,
    storageBucket:FIREBASE_CRED.STORAGE_BUCKET,
    messagingSenderId: FIREBASE_CRED.MEASUREMENT_ID,
    appId: FIREBASE_CRED.APP_ID,
    measurementId: FIREBASE_CRED.MEASUREMENT_ID
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);