import React, { useState, useEffect } from 'react'
import { interactives, mv, dummy, play_btn, playS, seeAllArrow } from '../../../../Constants/images';
import apiHelper from '../../../../Helper/api-helper';
import DEVELOPMENT_CONFIG from '../../../../Helper/config'
import './dashinteractivity.css';
import ROUTES from '../../../../Helper/route-helper';
import { useNavigate } from 'react-router-dom';
function Intractives(props) {
   const [interactivesList, setInteractivesList] = useState([])
   const [refresh, setRefresh] = useState(false)

   const navigate = useNavigate()
   async function getAPI() {
      props?.setIsLoading(true)
      let url;
      if (props.category != 0) {
         url = "interactive/interactive-list?category_id=" + props.category + "&offset=0&limit=3&sort=z-a"
      } else {
         url = "interactive/interactive-list?offset=0&limit=3&sort=z-a"
      }
      let result = await apiHelper.getRequest(url)
      if (result.code === DEVELOPMENT_CONFIG.statusCode) {
         setInteractivesList(result.data.data)
         props?.setIsLoading(false)
      } else if (result.code == 401) {
         let refreshToken = localStorage.getItem("refresh-token")
         let data = {
            "refresh_token": refreshToken,
            "scope": "refresh_token"
         }
         let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
         if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
            localStorage.setItem("token", refreshTokenResult.data.token)
            localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
            setRefresh(true)
         }
      }
   }

   useEffect(() => {
      getAPI()
   }, [props.category, refresh]);
   return (
      <div>
         {
            interactivesList?.length > 0 &&
            <section className="interactive response-padding  interactive-wrapper ">
               <div className="container">
                  <div className="chanel-header">
                     <h3 className="fw-600 mb-0">Interactives</h3>
                     <button onClick={() => navigate(ROUTES.INTERACTIVES)} className="see-all fw-600 f18-size">See all <img src={seeAllArrow} alt="" className="ms-2" /></button>
                  </div>
                  <div className="chanel-wrapper mt-4">
                     <div className="row">
                        {
                           interactivesList?.map(
                              (interactive) =>
                                 <div
                                    onClick={() => navigate(ROUTES.INTERACTIVE_PALY, { state: { id: interactive.id } })} key={interactive?.id}
                                    className="col-sm-12 col-lg-6  col-xxl-4 response-col response-rtf">
                                    <div className="chanel-content-cus">
                                       <div className="bite-relative">
                                          <img src={interactive.thumbnail} className="bite-img-cus" alt="" />
                                          <button className=" f12-size btn-play"><img src={playS} alt="" /></button>
                                       </div>

                                       <div className="bite-wrapper">
                                          <h4>{interactive.title}</h4>
                                          <div className="chanel-text d-flex gap-3 align-items-center mb-2">
                                             <img src={play_btn} className="bite-inner-img inter-img" alt="" />
                                             <div>
                                                <h6 className='fw-600'>{interactive.title}</h6>
                                                <p>{interactive.title}</p>
                                             </div>
                                          </div>
                                          <p className="m-0  mt-1 line-limit-para" >{interactive.description}</p>
                                       </div>
                                    </div>
                                 </div>
                           )
                        }

                     </div>
                  </div>
               </div>
            </section>
         }

      </div >
   )
}

export default Intractives;