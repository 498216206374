import React from 'react'
import UserShopDetail from './UserShopDetail'
function UserShop() {

return (
        <div className='main-inner-content'>
            <section className="streaming py-6 response-padding ">
                <div className="container-fluid">
                    <div className="row">
                        <div className="">
                            <h3 className="fw-600">Shop</h3>
                        </div>
                    </div>
                </div>
            </section>
            <section className="favchannel-main-cus">
                <UserShopDetail />
            </section>
        </div>
    )
}

export default UserShop
