import React from 'react'
import { frame208, logoWhite, Metaverse, MetaverseText } from '../../../../Constants/images';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

function ComingSoon() {
   return (
      <div>
         <section className="comming-soon response-padding">
            <div className="container">
               <div className="chanel-header">
                  <h3 className="fw-600 mb-0 common-btn btn-blue btn-shape f20-size" style={{ fontSize: "20px" }}>Metaverse</h3>
               </div>
               {/* <div className='coming-nft-img position-relative mt-4'>
                  <img src={Metaverse} alt="" className='w-100 img-fluid' />
                  <button style={{ cursor: 'pointer' }} className="common-btn watch-btn enter-metaverse">
                  <span className='me-2' >Enter now</span>
               </button>
               </div> */}
               <div className='metaverse-bg mt-4 d-flex justify-content-center align-items-center'>
                  <div className='d-flex flex-column justify-content-center align-items-center'>
                     <img src={MetaverseText} alt="" className='img-fluid meta-icons mb-3'/>
                     <button style={{ cursor: 'pointer' }} className="common-btn watch-btn meta-btns">
                        <span className='me-2'>Enter now</span>
                     </button>
                  </div>
               </div>
              
            </div>
         </section>
      </div>
   )
}

export default ComingSoon;