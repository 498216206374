import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import EpisodeSeriesChat from './episodeChat';

const EpisodeSeriesVideoPlayer = (props) => {
    const videoUrl = props?.video_url;
    const matchedUrl = videoUrl ? videoUrl.match(/src="([^"]+)"/) : videoUrl;
    const embedUrl = matchedUrl ? matchedUrl[1] : videoUrl;

    const [isMuted, setIsMuted] = useState(false);

    const handleClick = () => {
        if (props.embededVedio) {
            props.setEmbededVedio(false);
        }
        props?.setShow(!props?.show);
    };

    const handleVisibilityChange = () => {
        if (document.visibilityState === 'hidden') {
            setIsMuted(true); 
        } else if (document.visibilityState === 'visible') {
            setIsMuted(false); 
        }
    };

    useEffect(() => {
        document.addEventListener('visibilitychange', handleVisibilityChange);
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, []);

    return (
        <div className="modal-cus modalvideoplayer epispdePlayer" id="videoplayer">
            <div className="container-fluid">
                <a onClick={() => handleClick()} style={{ cursor: 'pointer' }} className="m-0 px-0 video-back-btn">
                    <i className="fa fa-chevron-left video-back-control-episode" />
                </a>
                <div className="custom-videoplayer position-relative p-0">
                    {props.embededVedio && embedUrl ? (
                        <div className="video_wrapper">
                            <ReactPlayer
                                url={embedUrl}
                                controls
                                playing
                                width="100%"
                                height="100%"
                                muted={isMuted} 
                            />
                        </div>
                    ) : (
                        <video playsInline autoPlay src={props?.video_url} controls muted={isMuted}></video>
                    )}
                    <EpisodeSeriesChat chatOpen={props.chatOpen} creatorName={props.creatorName} creatorImg={props.creatorImg} />
                </div>
            </div>
        </div>
    );
};

export default EpisodeSeriesVideoPlayer;
