import React from 'react'
import { contacLine, contactBanner, contactBannerBackground, contactEmail, contactLocation, contactPhone, mapBackground } from '../../../Constants/images'
import { Link } from 'react-router-dom'
import './contactInformation.css'


function ContactInformation() {

  const mapStyle = {
    backgroundImage:`url(${mapBackground})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
}
  return (
    <div>
          <section className="Contact-banner py-60" style={{backgroundImage:`url(${contactBannerBackground})`}}>
      <div className="container">
          <div className="row align-items-center">
              <div className="col-12 col-lg-6 contact-head">
                  <img src={contactBanner} alt=""/>
              </div>
              <div className="col-12 col-lg-6">
                  <h1 className="blue-text pb-3"><span className="pink-text font-120">C</span>ontact information</h1>
                  <p className="contact-content pb-2">Play Human is here to provide you with more information. You have any questions Contact us</p>
                  <img src={contacLine} alt="" className="img-fluid"/>
              </div>
          </div>
      </div>
    </section>
    <section className="get-in-banner py-60" style={mapStyle}>
      <div className="container">
        <div className="row">
          <div className="col-12 text-center get-in-head">
            <h2>Get in Touch with us !</h2>
          </div>
          <div className="col-12 col-lg-4 py-0 py-5">
            <div className="get-card text-center">
              <img src={contactPhone} alt="" className="img-fluid mb-4"/>
              <h4>Phone</h4>
              <p>Phone : 1234567890</p>
              <p>Phone : 1234567890</p>
            </div>
          </div>
          <div className="col-12 col-lg-4 py-0 py-5">
            <div className="get-card text-center">
              <img src={contactLocation} alt="" className="img-fluid mb-4"/>
              <h4>Location</h4>
              <p>3773 Howard Hughes Pkwy #500s, Las<br/> Vegas , NV 89169, US</p>
            </div>
          </div>
          <div className="col-12 col-lg-4 py-0 py-5">
            <div className="get-card text-center">
              <img src={contactEmail} alt="" className="img-fluid mb-4"/>
              <h4>email</h4>
              <p>Mail to : support@playhuman.com</p>
              <p>Mail to : support12@playhuman.com</p>
            </div>
          </div>
          <div className="col-12 text-center get-form-head ">
            <h2>If you got any questions<br/> 
              please do not hesitate to send us a message</h2>
          </div>
          <div className="col-12">
            <form className="get-in-form">
              <div className="form-group mb-4">
                  <input type="text" className="form-control get-contact-form" placeholder="Enter Here"/>
              </div>
              <div className="form-group mb-4">
                  <input type="text" className="form-control get-contact-form" placeholder="Enter Here"/>
              </div>
              <div className="form-group mb-4">
                  <input type="text" className="form-control get-contact-form" placeholder="Enter Here"/>
              </div>
              <div className="form-group mb-4">
                 <textarea  className="form-control get-contact-form get-message" placeholder="Message"></textarea>
              </div>
              <div className="send-mesg-btn mt-5 text-center">
                  <Link to="" className="common-btn send-btn">Send Message</Link>
              </div>
          </form>
          </div>
        </div>
      </div>
    </section>
    </div>
  )
}

export default ContactInformation