import React, { useEffect, useState } from 'react';
import { video } from '../../Constants/images';
import axios from 'axios';
import DEVELOPMENT_CONFIG from "../../Helper/config"
const MultiVideoPlayer = (props) => {
    const [videoUrl, setVideoUrl] = useState(""); 

    const handleClick = () => {
        if (props.embededVedio) {
            props.setEmbededVedio(false);
        }
        props?.setShow(!props?.show);
    };

    const handlePrevious = () => {
        if (props.currentVideoIndex > 0) {
            props.setCurrentVideoIndex(props.currentVideoIndex - 1);
        }
    }

    const handleNext = () => {
        if (props.currentVideoIndex < props.videoList.length - 1) {
            props.setCurrentVideoIndex(props.currentVideoIndex + 1);
        }
    }

    const getYouTubeUrl = async () => {
        if (props.embededVedio && props.videoId != null) {
            try {
                let token = localStorage.getItem("token");
                let config = {
                    method: 'get',
                    maxBodyLength: Infinity,
                    url: `${DEVELOPMENT_CONFIG.base_url}auth/generate-url?url=${props.videoId}`,
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                };
                const response = await axios.request(config);
                setVideoUrl(response.data.generatedUrl);
            } catch (error) {
                console.log(error);
            }
        }
    };

    useEffect(() => {
        getYouTubeUrl(); 
    }, [props.embededVedio, props.videoId]);

    return (
        <div className="modal-cus modalvideoplayer d-flex justify-content-center align-items-center" id="videoplayer">
            <div className="container-fluid">
                <a onClick={handleClick} style={{ cursor: "pointer" }} className="m-0 px-0 video-back-btn">
                    <i className="fa fa-chevron-left video-back-control" />
                </a>
                <div className=" position-relative p-0" style={{ height: "400px" }}>
                    {props.embededVedio ? (
                        <div className="multi_video_wrapper">
                             <div className="multi_video_wrapper">
                            <video playsInline={true} autoPlay={true} src={videoUrl} controls className="video_frame"></video>
                        </div>
                        </div>
                    ) : (
                        <div className="multi_video_wrapper">
                            <video playsInline={true} autoPlay={true} src={props.video_url} controls className="video_frame"></video>
                        </div>
                    )}
                    
                    <div className='position-absolute w-100 ' style={{ top: "50%" }}>
                        <div className="d-flex justify-content-between mb-2 ">
                            <div 
                                style={{ cursor:props.currentVideoIndex === 0 ? "not-allowed":"pointer"}}
                                onClick={handlePrevious}                         >
                                <span className={props.currentVideoIndex === 0 ? "carousel-control-prev-icon challenge-prev-btn disable-btns" : "carousel-control-prev-icon challenge-prev-btn "} aria-hidden="true">
                                    <i className="fa fa-angle-left" aria-hidden="true" style={{ fontSize: "35px" }}></i>
                                </span>
                                <span className="visually-hidden">Previous</span>
                            </div>
                            
                            <div
                                style={{ cursor:props.currentVideoIndex === props.videoList.length - 1 ?"not-allowed":"pointer" }}
                                onClick={handleNext}  >
                                <span className={props.currentVideoIndex === props.videoList.length - 1 ? "carousel-control-next-icon challenge-next-btn disable-btns " : "carousel-control-next-icon challenge-next-btn"} aria-hidden="true">
                                    <i className="fa fa-angle-right" aria-hidden="true" style={{ fontSize: "35px" }}></i>
                                </span>
                                <span className="visually-hidden">Next</span>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default MultiVideoPlayer;
