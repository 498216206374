import React from 'react'
import Header1 from '../../Components/Header1/Header1'
import { landingPage } from '../../Constants/images'
import Banner from './Components/Banner/Banner'
import StreamEntertainment from './Components/StreamEntertainment/StreamEntertainment'
import Community from './Components/Community/Community'
import PopularTestimonial from '../../Components/PopularTestimonial/PopularTestimonial'
import Footer from '../../Components/Footer/Footer'
import'./landing.css'



const Landing = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className='bg-body'  style={{backgroundImage:`url(${landingPage })`}}>
    <Header1/> 
    <Banner/>
    <StreamEntertainment/>
    <Community/>
    <PopularTestimonial/>
    <Footer/>
    </div>
    )
}

export default Landing