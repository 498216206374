import React from 'react'
import { bunny } from '../../../../Constants/images';

function MainFrame() {
  return (
<div>
<section className="frame-video-banner">
          <div className="container">
            <div className="frame-video-img">
              {/* <img
                src="assets/image/top_video_thumbnail.png"
                className="img-fluid"
                alt=""
              /> */}
               <video width="500" height="300" controls controlsList="nodownload">
            <source src={bunny} type="video/mp4"/>
           </video>
            </div>
            {/* <div className="movie-wrapper">
              <div className="row d-flex flex-wrap gap-5">
                <div className="col-lg-2 bgwhite">
                  <div className="d-flex align-items-center gap-4">
                    <div className="mvie-title">
                      <img src="assets/image/mv.png" className="mv-title" alt="" />
                    </div>
                    <h4 className="m-0">Muvi Star</h4>
                  </div>
                </div>
                <div className="col-lg-2 bgwhite">
                  <div className="d-flex align-items-center gap-4">
                    <div className="mvie-title">
                      <img
                        src="assets/image/entertainment_icon.svg"
                        className="py-2 px-2 mv-title"
                        alt=""
                      />
                    </div>
                    <h4 className="m-0">Entertainment</h4>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </section>
         <section className="movie-detail py-60">
         <div className="container">
           <h2>Episode 04 : <span className="fw-bold">Vatos</span></h2>
         </div>
       </section>
      
       <section className="plot-summary">
         <div className="container">
           <h3 className="fw-600">Summary</h3>
           <p className="mt-2">
             Sorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu
             turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus
             nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum
             tellus elit sed risus. Maecenas eget condimentum velit, sit amet
             feugiat lectus. className aptent taciti sociosqu ad litora torquent
             per conubia nostra, per inceptos himenaeos. Praesent auctor purus
             luctus enim egestas, ac scelerisque ante pulvinar. Donec ut
             rhoncus ex. Suspendisse ac rhoncus nisl, eu tempor urna. Curabitur
             vel bibendum lorem. Morbi convallis convallis diam sit amet
             lacinia. Aliquam in elementum tellus. Curabitur tempor quis eros
             tempus lacinia. Nam bibendum pellentesque quam.
           </p>
         </div>
       </section>
       </div>
      
  )
}

export default MainFrame;