import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import DEVELOPMENT_CONFIG from '../../Helper/config'
import ROUTES from '../../Helper/route-helper'
import { mychallanges, noChallenges, whiteFilter } from '../../Constants/images'
import AllChallengesFilter from '../AllChallenges/Components/AllChallengesFilter/AllChallengesFilter'
import ReactLoader from '../../ControlComponents/react-loader'
import apiHelper from '../../Helper/api-helper'


const MyChallenges = (props) => {
    const navigate = useNavigate()
    const [allChallenges, setAllChallenges] = useState([])
    const [refresh, setRefresh] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    async function getDetailsAPI() {
        setIsLoading(true)
        let result = await apiHelper.getRequest("challenges/my-challenges-list")
        if (result.code == DEVELOPMENT_CONFIG.statusCode) {
            setAllChallenges(result.data);
            setIsLoading(false)
        }
        else if (result.code == 401) {
            let refreshToken = localStorage.getItem("refresh-token")
            let data = {
                "refresh_token": refreshToken,
                "scope": "refresh_token"
            }
            let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
            if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
                localStorage.setItem("token", refreshTokenResult.data.token)
                localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
                setRefresh(true)
            }
        }
    }

    useEffect(() => {
        getDetailsAPI()
    }, [refresh])

    return (
        <div className="main-inner-content">
            {
                isLoading && <ReactLoader />
            }
            <section className="favourite-cus  response-padding ">
                <div className="container">
                    <div className="chanel-header d-flex align-items-center justify-content-between">
                        <h3 className="fw-600 mb-0">My Challenges</h3>
                    </div>

                    <div className="chanel-wrapper mt-4">
                        <div className="row gy-2">
                            {
                                allChallenges?.map((element, index) => {
                                    return (
                                        <div key={element?.id} className="col-12  col-lg-6 col-md-6 col-xxl-4">
                                            <div className="chanel-content">
                                                {element?.thumbnail != null && <img src={element?.thumbnail} className="bite-img-fav img-width" />}
                                                <div className="bite-wrapper">
                                                    <h4 className='mb-0'>{element.challenge?.title}</h4>
                                                    <div className="chanel-text my-2 d-flex gap-2 align-items-center">
                                                        <img src={element?.challenge?.channel?.logo_url} className="bite-inner-img" />
                                                        <div>
                                                            <p className='fw-600 mb-0 py-2'>{element?.challenge?.channel?.name}</p>
                                                            <p className='py-2'>{element?.challenge?.channel?.category?.name}</p>
                                                        </div>
                                                    </div>
                                                    <button className="common-btn" style={{ cursor: "pointer" }}
                                                        onClick={() => navigate(ROUTES.MY_CHALLENGES_DETAIL,
                                                            { state: { item: element?.challenge, video_url: element?.video_url } })}>View Detail</button>
                                                </div>
                                            </div>
                                        </div>
                                    )

                                })
                            }
                            {
                                !isLoading &&
                                <>
                                    {allChallenges?.length == 0 &&
                                        <div className='no-search-results'>
                                            <img src={mychallanges} />
                                        </div>

                                    }
                                </>
                            }
                        </div>
                    </div>
                </div>
            </section>
            <AllChallengesFilter />
        </div>
    )
}

export default MyChallenges