import React, { useState } from 'react'
import { downArrow, modalCross } from '../../Constants/images'


const EditBucketModal = ({setBucketModal}) => {
  return (
<div className="modal fade show modal-react" style={{ display: "block", opacity: "1", backdropFilter: "blur(5px)" }}>
<div className='loader'>
</div>
<div className="modal-dialog modal-dialog-centered modal-schedule-width">
  <div className="modal-content modal-radius  border-0">
    <div className="modal-header forgot-header align-items-center pt-4">
      <h3 className="modal-title text-center mx-auto forgot-title" id="exampleModalLabel">Edit Bucket</h3>
      <a className="m-0 px-0 btn-closed-main" data-bs-dismiss="modal" aria-label="Close"><img onClick={()=>setBucketModal(false)} style={{cursor:"pointer"}} src={modalCross} alt="" className="img-fluid" /></a>
    </div>
    <div className="modal-body forgot-body verify-modal-box px-0">
      <form >
        <div className='mb-3'>
          <label className='fw-500 f18-size text-black pb-2'>Bucket Name</label>
          <input type='text' className='border-edit  w-100' placeholder='Enter Here' ></input>
        </div>
        <div className='mb-3'>
          <label className='fw-500 f18-size text-black pb-2'>Price</label>
          <input type='text' className='border-edit  w-100' placeholder='Enter Here' ></input>
        </div>
        <label className='fw-500 f18-size text-black pb-2'>Duration</label>
        <div className='row'>
          <div className='col-3 pe-0'>
            <div className='mb-3'>
              <select className='form-select border-edit w-100 month-select'>
                  <option>Month</option>
              </select>
            </div>
          </div>
          <div className='col-9'>
            <div className='mb-3'>
            <select className='form-select border-edit w-100'>
                  <option>Select date</option>
                  <option>dg</option>
              </select>
            </div>
          </div>
        </div>
        <div className='mb-5'>
          <label className='fw-500 f18-size text-black pb-2'>Series</label>
          <div className='position-relative'>
              <div className='left-series-icon'>
                <img src={downArrow}></img>
              </div>
              <input type='text' className='border-edit  w-100' placeholder='Enter Here' ></input>
         </div>
          <span className='fw-500 f16-size mt-2'><i>10 series added</i></span>
        </div>
        <div className=" mt-4 mb-5">
          <input type='submit' value="Save" className="common-btn w-100" />
        </div>
      </form>
    </div>
  </div>
</div>
</div>
  )
}

export default EditBucketModal
