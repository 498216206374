import React from 'react'
import { dashBackground1 } from '../../Constants/images';
import Header2 from '../../Components/Header2/Header2';
import Sidebar from '../../Components/Sidebar/Sidebar';
import MainFrame from './Components/MainFrame/MainFrame';
import MoreEpisode from './Components/MoreEpisode/MoreEpisode';
import './frame2.css'

function Frame2() {
  return (
    <div style={{ backgroundImage: `url(${dashBackground1})` }}>
      <div className="main-inner-content">
        <MainFrame />
        <MoreEpisode />
      </div>
    </div>
  )
}

export default Frame2;