import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Arrow_channel, bites1, favRed, favouriteLight, favouritesDark, noSeries, playLightBtn, profile_image_placeholder, search } from '../../Constants/images';
import apiHelper from '../../Helper/api-helper';
import DEVELOPMENT_CONFIG from '../../Helper/config';
import './channelDetails.css';
import SeriesChannel from './seriesChannel';
import ShopLives from '../UserShop/shopLives';
import LivesChannel from './livesChannel';
import UpcomingChannel from './upcomingChannel';
import ImagesChannel from './imagesChannel';
import AudioChannel from './audioChannel';
import VideoChannel from './videoChannel';
import BitesChannel from './bitesChannel';
import ChallengesChannel from './challengesChannel';
import ReactLoader from '../../ControlComponents/react-loader';

const ChannelDetails = () => {
  const [selectedOption, setSelectedOption] = useState({
    value: '0', label: 'All'
  });
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(false)
  const location = useLocation()
  const [_, forceUpdate] = useState(0)
  const [tabSwitch, setTabSwitch] = useState(0);
  const [seriesList, setSeriesList] = useState([]);
  const [imagesList, setImagesList] = useState([]);
  const [recoringList, setRecordingList] = useState([]);
  const [upcomingList, setUpcomingList] = useState([]);
  const [audiosList, setAudiosList] = useState([]);
  const [videosList, setVideosList] = useState([]);
  const [bitesList, setBitesList] = useState([]);
  const [challengesList, setChallengesList] = useState([]);
  const searchParams = new URLSearchParams(location.search);
  const channelDetail = searchParams.get('cd');
  console.log(location?.state?.item, "gggg")
  const [loading, setLoading] = useState(false);

  function getChannelName(channelName) {
    const { item } = location.state;
    switch (channelName) {
      case "favChannel":
      case "imgChannel":
      case "seriesChannel":
      case "latestChannel":
      case "allChannels":
        return item.name;
      case "liveChannel":
        return item.channelName;
      case "homeChannel":
        return item.channelDetail?.name;
      case "favrouiteChannel":
        return item.ChannelName
      case "searchChannel":
      case "shopChannel":
      case "moreMovie":
        return item.channelDetails.name;
      case "biteChannel":
        return item.biteChannel.name;
      default:
        return "category";
    }
  }


  // function getChannelCategory(channelName) {
  //   let category;
  //   switch (channelName) {
  //     case "favChannel":
  //       category = "category"
  //       break;
  //     case "shopChannel":
  //       category = location.state.item.category.name;
  //       break;
  //     case "moreMovie":
  //       category = location.state.item.category.name;
  //       break;
  //     case "latestChannel":
  //       category = location.state.item.category.name;
  //       break;
  //     case "allChannels":
  //       category = location.state.item.category.name;
  //       break;
  //     default:
  //       category = "name";
  //   }
  //   return "";
  // }

  function getChannelImage(channelName) {
    const { item } = location.state;
    let image;
    switch (channelName) {
      case "favChannel":
      case "imgChannel":
      case "seriesChannel":
        image = item.profile_pic;
        break;
      case "liveChannel":
        image = item.channelIcon;
        break;
      case "homeChannel":
        image = item.channelDetail?.profile_pic;
        break;
      case "searchChannel":
      case "shopChannel":
      case "moreMovie":
        image = item.channelDetails.profile_pic;
        break;
      case "favrouiteChannel":
        image = item.ChannelImage
        break;
      case "biteChannel":
        image = item.biteChannel.profile_pic;
        break;
      case "latestChannel":
      case "allChannels":
        image = item.logo_url;
        break;
      default:
        image = profile_image_placeholder;
    }

    return image || profile_image_placeholder;
  }


  function getChannelId(channelName) {
    const { item } = location.state;

    switch (channelName) {
      case "favChannel":
      case "searchChannel":
      case "seriesChannel":
        return item.id;
      case "imgChannel":
      case "biteChannel":
      case "liveChannel":
      case "homeChannel":
      case "latestChannel":
      case "allChannels":
        return item.creator_id;
      case "shopChannel":
      case "moreMovie":
        return item.channelDetails.id;
      case "favrouiteChannel":
        return item.channelId
      default:
        return 0;
    }
  }


  async function getListAPI() {
    setLoading(true);
    let url;
    switch (tabSwitch) {
      case 0:
        url = `shop/seriesAndRecordingList?type=1&is_premium=${selectedOption.value == 0 ? 2 : selectedOption.value == 1 ? 0 : 1}&channelId=${getChannelId(channelDetail)}`;
        break;
      case 1:

        url = `shop/seriesAndRecordingList?type=2&is_premium=${selectedOption.value == 0 ? 2 : selectedOption.value == 1 ? 0 : 1}&channelId=${getChannelId(channelDetail)}`;
        break;
      case 2:
        url = `shop/upcoming-live?is_premium=${selectedOption.value == 0 ? 2 : selectedOption.value == 1 ? 0 : 1}&channelId=${getChannelId(channelDetail)}`;
        break;
      case 3:
        url = `shop/get-shop-media?shopType=image&creatorId=${getChannelId(channelDetail)}`;
        break;
      case 4:
        url = `shop/get-shop-media?shopType=audio&creatorId=${getChannelId(channelDetail)}`;
        break;
      case 5:
        url = `shop/get-shop-media?shopType=video&creatorId=${getChannelId(channelDetail)}`;
        break;
      case 6:
        url = `bites/bites-list?creatorId=${getChannelId(channelDetail)}`;
        break;
      case 7:
        url = `challenges/challenges-list?creatorId=${getChannelId(channelDetail)}`;
        break;
      default:
        setLoading(false);
        return;
    }
    try {
      let result = await apiHelper.getRequest(url);
      if (result?.code === DEVELOPMENT_CONFIG?.statusCode) {
        switch (tabSwitch) {
          case 0:
            setSeriesList(result.data);
            break;
          case 1:
            setRecordingList(result.data);
            break;
          case 2:
            setUpcomingList(result.data);
            break;
          case 3:
            setImagesList(result.data);
            break;
          case 4:
            setAudiosList(result.data);
            break;
          case 5:
            setVideosList(result.data);
            break;
          case 6:
            setBitesList(result.data?.reels);
            break;
          case 7:
            setChallengesList(result.data.allChallenge);
            break;
          default:
            break;
        }

      } else if (result.code === 401) {
        let refreshToken = localStorage.getItem("refresh-token");
        let data = {
          "refresh_token": refreshToken,
          "scope": "refresh_token"
        };
        let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data);

        if (refreshTokenResult.code === DEVELOPMENT_CONFIG.statusCode) {
          localStorage.setItem("token", refreshTokenResult.data.token);
          localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token);
          setRefresh(true);
        } else {
          console.error("Failed to refresh token", refreshTokenResult);
        }
      } else {
        console.error("Unexpected result code", result);
      }
    } catch (error) {
      console.error("Error in getListAPI", error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getListAPI();
  }, [tabSwitch, refresh, selectedOption]);

  async function addFavourites(item) {
    if (item.is_fav == 0) {
      item.is_fav = 1
    } else {
      item.is_fav = 0
    }
    let data = {
      "series_id": item?.id,
      "type": "2"
    }
    forceUpdate(Math.random())
    let result = await apiHelper.postRequest("channel/add-favorites", data)
    if (result?.code === DEVELOPMENT_CONFIG?.statusCode) {
    }
  }

  const handleChange = async (e) => {
    let result = await apiHelper.getRequest("series/series-list?channel_id=" + location.state?.item?.id + "&search=" + e.target.value)
    if (result.code === DEVELOPMENT_CONFIG.statusCode) {
      setSeriesList(result.data.data)
    }
  }

  const options = [
    { value: '0', label: 'All' },
    { value: '1', label: 'Free' },
    { value: '2', label: 'Paid' },
  ];

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };
  return (

    <div className="main-inner-content">
      {
        loading && <ReactLoader />
      }
      <div className="row">
        <div className="col-12 pt-3  pb-0">
          <h3 className="fw-600">Network Detail</h3>
        </div>
        <div className="col-12 text-center">
          <div className="challenge-title">
            <img src={getChannelImage(channelDetail)} alt="" className="img-fluid challenge-img" />
          </div>
        </div>
      </div>

      <section className="challenges">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 text-center py-5 mt-4">
              <div className="challenge-head">
                <h3 className="fw-600">{getChannelName(channelDetail)}</h3>
                {/* <p className="py-2 mb-0">challenges  you to create and record your fan art based<br/>on their series ‘Creed’</p> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="row align-items-center">
        <div className='col-lg-10'>

          <div className="stream-btn pt-5 pb-3">
            <button
              className={`common-btn network-btns ${tabSwitch === 0 ? 'btn-blue' : 'btn-white'} left-corner`}
              onClick={() => setTabSwitch(0)}
            >
              Series
            </button>
            <button
              className={`common-btn network-btns ${tabSwitch === 1 ? 'btn-blue' : 'btn-white'} left-corner`}
              onClick={() => setTabSwitch(1)}
            >
              Recorded Lives
            </button>
            <button
              className={`common-btn network-btns ${tabSwitch === 2 ? 'btn-blue' : 'btn-white'} left-corner`}
              onClick={() => setTabSwitch(2)}
            >
              Upcoming Lives
            </button>
            <button
              className={`common-btn network-btns ${tabSwitch === 3 ? 'btn-blue' : 'btn-white'} left-corner`}
              onClick={() => setTabSwitch(3)}
            >
              Images

            </button>
            <button
              className={`common-btn network-btns ${tabSwitch === 4 ? 'btn-blue' : 'btn-white'} left-corner`}
              onClick={() => setTabSwitch(4)}
            >
              Audios
            </button>
            <button
              className={`common-btn network-btns ${tabSwitch === 5 ? 'btn-blue' : 'btn-white'} left-corner`}
              onClick={() => setTabSwitch(5)}
            >
              Videos
            </button>
            <button
              className={`common-btn network-btns ${tabSwitch === 6 ? 'btn-blue' : 'btn-white'} left-corner`}
              onClick={() => setTabSwitch(6)}
            >
              Bites
            </button>
            <button
              className={`common-btn network-btns ${tabSwitch === 7 ? 'btn-blue' : 'btn-white'} left-corner`}
              onClick={() => setTabSwitch(7)}
            >
              Challenges
            </button>
          </div>
        </div>
        {
          (tabSwitch == 0 || tabSwitch == 1 || tabSwitch == 2) &&
          <div className='col-lg-2 ms-auto d-flex justify-content-end  mt-4 pt-2' onClick={toggleDropdown} style={{ cursor: "pointer" }}>
            <div className="custom-select">
              <div className="selected-option" >
                {selectedOption ? selectedOption.label : 'Select an option'}
              </div>
              {isOpen && (
                <div className="options">
                  {options.map((option) => (
                    <div
                      key={option.value}
                      onClick={() => handleOptionClick(option)}
                      className={`option ${selectedOption && selectedOption.value === option.value ? 'active-option' : ''}`}
                    >
                      {option.label}
                    </div>
                  ))}
                </div>
              )}
              <div className='arrow-down'>
                <img src={Arrow_channel} />
              </div>

            </div>
          </div>
        }

      </div>
      <section className="streaming py-6 response-padding ">
        {
          tabSwitch == 0 ?
            <SeriesChannel detailList={seriesList} loading={loading} setLoading={setLoading} getListAPI={getListAPI} /> :
            tabSwitch == 1 ?
              <LivesChannel detailList={recoringList} loading={loading} setSelectedOption={setSelectedOption}
                selectedOption={selectedOption} setLoading={setLoading} getListAPI={getListAPI} />
              :
              tabSwitch == 2 ?
                <UpcomingChannel detailList={upcomingList} loading={loading} setSelectedOption={setSelectedOption}
                  selectedOption={selectedOption} setLoading={setLoading} getListAPI={getListAPI} />
                :
                tabSwitch == 3 ?
                  <ImagesChannel detailList={imagesList} loading={loading} setLoading={setLoading} getListAPI={getListAPI} />
                  :
                  tabSwitch == 4 ?
                    <AudioChannel detailList={audiosList} loading={loading} setLoading={setLoading} getListAPI={getListAPI} />
                    :
                    tabSwitch == 5 ?
                      <VideoChannel detailList={videosList} loading={loading} setLoading={setLoading} getListAPI={getListAPI} />
                      :
                      tabSwitch == 6 ?
                        <BitesChannel detailList={bitesList} loading={loading} setLoading={setLoading} getListAPI={getListAPI} />
                        :
                        <ChallengesChannel detailList={challengesList} loading={loading} setLoading={setLoading} getListAPI={getListAPI} />
        }
      </section>
    </div>
  )
}

export default ChannelDetails;