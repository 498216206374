import React, { useState } from 'react'
import { cast_user, editProfile, modalCross } from '../../Constants/images'
import './team-series.css'
const EditDetailModal = ({ setEditModal }) => {
    return (

        <div className="modal fade show modal-react editprofile-modal" style={{ display: "block", opacity: "1", backdropFilter: "blur(5px)" }}>
            <div className='loader'>
            </div>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content modal-radius forgot-body">
                    <div className="modal-header forgot-header align-items-center position-relative">
                        <h3 className="modal-title text-center mx-auto forgot-title" id="exampleModalLabel">Edit Profile</h3>
                    </div>

                    <a onClick={() => setEditModal(false)} className="m-0 px-0 close-btn" data-bs-dismiss="modal" aria-label="Close">
                        <img src={modalCross} alt="" className="img-fluid" />
                    </a>

                    <div className="modal-body forgot-body px-2 px-sm-5">
                        <div className="forgot-img">
                            <div className="profile-upload mb-2 user-profile-upload position-relative" >
                                {/* {props?.profileDetail?.profile_pic != null ? <img src={props?.profileDetail?.profile_pic} id="profileimage" alt="" className="profile-edit" /> :  */}
                                <img src={cast_user} id="profileimage" alt="" className="profile-edit" />
                                <img src={editProfile} alt="" className="img-fluid profile-edit-icon" />
                                <input type="file" accept="image/png, image/jpg, image/jpeg" className="profile-edit-file" />
                            </div>
                            <p className="fw-400 profile-mail mailto:pt-1">starplus@yomail.com</p>

                            {/* <form> */}
                            <div className="form-group mb-3">
                                <label className="fw-500 mb-2 forgot-label">Name</label>
                                <input type="text" className="form-control  fw-500 forgot-input" value="starPlus" placeholder="Name" />
                                {/* <span className="err_msg">{errors.userName}</span> */}
                            </div>

                            <div className="my-5">
                                <a className="common-btn w-100" >Save</a>
                            </div>
                            {/* </form> */}
                        </div>

                    </div>

                </div>
            </div>
        </div>

    )
}

export default EditDetailModal
