import React, { useEffect, useState } from 'react'
import { dashBackground1, interactives, mv, playS } from '../../Constants/images';

import './interactives.css'
import { useLocation, useNavigate } from 'react-router-dom';
import ROUTES from '../../Helper/route-helper';
import apiHelper from '../../Helper/api-helper';
import DEVELOPMENT_CONFIG from '../../Helper/config';
import ReactLoader from '../../ControlComponents/react-loader';


function Interactives() {
  const navigate = useNavigate();
  const [interactiveList, setInteractivesList] = useState([])
  const [refresh, setRefresh] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const location = useLocation()

  async function getAPI() {
    setIsLoading(true)
    let result = await apiHelper.getRequest("interactive/interactive-list")
    if (result.code === DEVELOPMENT_CONFIG.statusCode) {
      setInteractivesList(result.data.data)
      setIsLoading(false)

    } else if (result.code == 401) {
      let refreshToken = localStorage.getItem("refresh-token")
      let data = {
        "refresh_token": refreshToken,
        "scope": "refresh_token"
      }
      let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
      if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
        localStorage.setItem("token", refreshTokenResult.data.token)
        localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
        setRefresh(true)
      }
    }
  }

  useEffect(() => {
    getAPI()
  }, [refresh]);
  return (
    <div className="main-inner-content">
      {isLoading && <ReactLoader />}
      <div>
        <section className="response-padding  interactive-main">
          <div className="container">
            <div className="chanel-header">
              <h3 className="fw-600 mb-3">Interactives</h3>
            </div>
            <div className="chanel-wrapper mt-4">
              <div className="row">
                {
                  interactiveList?.map((interactive) => {
                    return (
                      <div key={interactive?.id} onClick={() => navigate(ROUTES.INTERACTIVE_PALY, { state: { id: interactive?.id } })} className="col-lg-4 response-col response-rtf">
                        <div className="chanel-content">
                          <div className='position-relative'>
                            <div className="bite-relative" style={{ cursor: "pointer" }}>
                              <img
                                src={interactive?.thumbnail}
                                className="inter-cus-img "
                                alt=""
                              />
                            </div>
                            <button className="f12-size btn-play" style={{ cursor: "pointer" }}>
                              <img src={playS} alt="" className='img-fluid' />
                            </button>
                          </div>
                          <div className="bite-wrapper">
                            <h4>{interactive?.title}</h4>
                            <div className="chanel-text d-flex gap-3 align-items-center">
                              <img
                                src={interactive?.channel?.logo_url}
                                className="bite-inner-img inter-img img-fluid"
                                alt=""
                              />
                              <div>
                                <h6 className='fw-600'>{interactive?.channel?.name}</h6>
                                <p>{interactive?.channel?.category?.name}</p>
                              </div>
                            </div>
                            <p className="m-0">
                              {interactive?.description}
                            </p>
                          </div>
                        </div>
                      </div>
                    )
                  })
                }

              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default Interactives;