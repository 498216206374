import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { biteImg, bitesDark, blackdot, challenge1, challengeBack, dotsbg, favRed, favouriteLight, favouritesDark, playLight, playLightBtn, videoPlayButton } from '../../Constants/images';
import './interactives.css'
import { Dropdown } from 'react-bootstrap';
import ROUTES from '../../Helper/route-helper';

function MyInteractives({ setTabSwitch, favSeries }) {
    const navigate = useNavigate()
    return (
        <div>
            <section className=" favchannel-main-cus">
                <div className="container-fluid">
                    <div className='row'>
                        <div className="chanel-wrapper mt-4">
                            <div className="row gy-2">
                                <div className="col-xl-4  col-lg-4 col-md-6  response-col position-relative" onClick={()=>navigate(ROUTES.CREATER_INTERACTIVE_DETAIL)} style={{cursor:"pointer"}}>
                                    <div className="channel-bites-content br-23 ">
                                        <div className='position-relative'>
                                           <img src={biteImg} className="inter-profile" alt="" />
                                           <div className='play-inter'>
                                               <img src={playLightBtn} className='img-fluid'></img>
                                           </div>
                                        </div>
                                        <div className="bite-wrapper">
                                            <h4>Pixel Persuit</h4>
                                            <div className="chanel-text d-flex gap-3 align-items-center">
                                                <img src={favouritesDark} className="bite-inner-img" alt="" />
                                                <div>
                                                    <h6 className='fw-600 mb-0'>The walking dead</h6>
                                                </div>
                                            </div>
                                            <p className='line-limit-para'>Lorem ipsum dolor sit amet consectetur. Lectus dolor maurisz....</p>
                                        </div>
                                    </div>

                                    <div className='dotdropdown'>
                                        <Dropdown>
                                            <Dropdown.Toggle id="custom-dropdown-toggle">
                                                <img src={blackdot} className='img-fluid'></img>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item >Edit</Dropdown.Item>
                                                <Dropdown.Item> Delete</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                                <div className="col-xl-4  col-lg-4 col-md-6  response-col position-relative" onClick={()=>navigate(ROUTES.CREATER_INTERACTIVE_DETAIL)} style={{cursor:"pointer"}}>
                                    <div className="channel-bites-content br-23 ">
                                        <div className='position-relative'>
                                           <img src={biteImg} className="inter-profile" alt="" />
                                           <div className='play-inter'>
                                               <img src={playLightBtn} className='img-fluid'></img>
                                           </div>
                                        </div>
                                        <div className="bite-wrapper">
                                            <h4>Pixel Persuit</h4>
                                            <div className="chanel-text d-flex gap-3 align-items-center">
                                                <img src={favouritesDark} className="bite-inner-img" alt="" />
                                                <div>
                                                    <h6 className='fw-600 mb-0'>The walking dead</h6>
                                                </div>
                                            </div>
                                            <p className='line-limit-para'>Lorem ipsum dolor sit amet consectetur. Lectus dolor maurisz....</p>
                                        </div>
                                    </div>

                                    <div className='dotdropdown'>
                                        <Dropdown>
                                            <Dropdown.Toggle id="custom-dropdown-toggle">
                                                <img src={blackdot} className='img-fluid'></img>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item >Edit</Dropdown.Item>
                                                <Dropdown.Item> Delete</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                                <div className="col-xl-4  col-lg-4 col-md-6  response-col position-relative" onClick={()=>navigate(ROUTES.CREATER_INTERACTIVE_DETAIL)} style={{cursor:"pointer"}}>
                                    <div className="channel-bites-content br-23 ">
                                        <div className='position-relative'>
                                           <img src={biteImg} className="inter-profile" alt="" />
                                           <div className='play-inter'>
                                               <img src={playLightBtn} className='img-fluid'></img>
                                           </div>
                                        </div>
                                        <div className="bite-wrapper">
                                            <h4>Pixel Persuit</h4>
                                            <div className="chanel-text d-flex gap-3 align-items-center">
                                                <img src={favouritesDark} className="bite-inner-img" alt="" />
                                                <div>
                                                    <h6 className='fw-600 mb-0'>The walking dead</h6>
                                                </div>
                                            </div>
                                            <p className='line-limit-para'>Lorem ipsum dolor sit amet consectetur. Lectus dolor maurisz....</p>
                                        </div>
                                    </div>

                                    <div className='dotdropdown'>
                                        <Dropdown>
                                            <Dropdown.Toggle id="custom-dropdown-toggle">
                                                <img src={blackdot} className='img-fluid'></img>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item >Edit</Dropdown.Item>
                                                <Dropdown.Item> Delete</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                              
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}

export default MyInteractives;