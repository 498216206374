import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { play, section2, section3 } from '../../../../Constants/images'
import './streamEntertainment.css'

const StreamEntertainment = () => {
    const OwlCarouselEnt = {
        autoplay: true,
        rewind: true /* use rewind if you don't want loop */,
        margin: 20,
  
        animateOut: "fadeOut",
        animateIn: "fadeIn",
  
        responsiveClass: true,
        autoHeight: true,
        autoplayTimeout: 7000,
        smartSpeed: 800,
        nav: false,
        responsive: {
          0: {
            items: 1,
          },
  
          600: {
            items: 1,
          },
  
          1024: {
            items: 1,
          },
  
          1366: {
            items: 1,
          },
        },
      }

  return (
    <>
          <section className="streaming-wrapper text-center">
        <div className="container wrapper-border pt-5">
          <h2>Unlimited Streaming at Your Fingertips</h2>
          <p className="py-4">
            With our app/website, you have the power to watch your favorite
            movies and episodes anytime, anywhere. Say goodbye to limitations
            and schedules – stream on your terms. Whether you're lounging at
            home, on the go, or enjoying a weekend getaway, your personal
            theater is just a tap or click away.
          </p>
          <img src={section2} alt="" />
        </div>
      </section>

      <section className="entertaiment-wrapper py-60">
        <div className="container">
          <div className="row align-items-center flex-wrap d-flex">
            <div className="col-lg-6">
              <div className="owl-slider">
                <OwlCarousel id="entertainment" className="owl-carousel" {...OwlCarouselEnt}>
                  <div className="item item-relative">
                    <img
                      src={section3}
                      className="img-fluid"
                      alt=""
                    />
                    <button className="img-absolute">
                      <img src={play}alt="" />
                    </button>
                  </div>
                  <div className="item item-relative">
                    <img
                      src={section3}
                      className="img-fluid"
                      alt=""
                    />
                    <button className="img-absolute">
                      <img src={play} alt="" />
                    </button>
                  </div>
                  <div className="item item-relative">
                    <img
                      src={section3}
                      className="img-fluid"
                      alt=""
                    />
                    <button className="img-absolute">
                      <img src={play} alt="" />
                    </button>
                  </div>
                </OwlCarousel>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="entertaiment-text">
                <h2>
                  <span className="f-70 pink">S</span>tart Your Entertainment
                  Journey Today
                </h2>
                <p className="py-3">
                  Join our community of movie and TV lovers and embark on a
                  limitless entertainment adventure. Download our app or visit
                  our website to unlock a world of captivating movies and
                  episodes at your fingertips. Get ready to lose yourself in
                  mesmerizing stories and unforgettable characters.
                </p>
                <div className="d-flex gap-3 mt-5 join-btn flex-wrap">
                  <a href="" className="common-btn">Join Play Human</a>
                  <a href="" className="common-btn btn-blue">Read More</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default StreamEntertainment