import React, {useState } from 'react';
import './allSeries.css';
import TrailorPage from './trailorPage';
import ClipPage from './clipPage';
import { useLocation } from 'react-router-dom';


const TrailorClip = () => {
    const [tabSwitch, setTabSwitch] = useState(0);
    const location = useLocation()
    const series_clips = location.state.series_clip
    const trailer = series_clips.filter(clip => clip.type === 1);
    const clip = series_clips.filter(clip => clip.type === 2)

    function modifyDuration(duration) {
        const durationParts = duration?.split(':');
        if (durationParts && durationParts[0] === '00') {
          return durationParts.slice(1)?.join(':');
        }
        return duration;
      }
      
    return (
        <div>
            <div className="main-inner-content">
                <div className='row align-items-center'>
                    <div className='col-sm-5'>
                        <div className="">
                            <h3 className="fw-600">Trailor & Clips</h3>
                        </div>
                    </div>
                </div>
                {tabSwitch == 0 ?
                    <>
                        <TrailorPage setTabSwitch={setTabSwitch} trailer={trailer} modifyDuration={modifyDuration}/>

                    </>
                    : <>
                        <ClipPage setTabSwitch={setTabSwitch} clip={clip}  modifyDuration={modifyDuration}/>
                    </>
                }
            </div>
        </div>
    )
}

export default TrailorClip;