import React from "react";
import "../../../src/CreaterDashboard/CreatorMembership/creatorMembership.css"
import { useNavigate } from "react-router-dom";
import ROUTES from "../../Helper/route-helper";
import { cancel, check } from "../../Constants/images";
const CreatorMembership = () => {
    const navigate = useNavigate()
    return (
        <div>
            <div className="main-inner-content">
                <section className="creator-dashboard">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="d-flex w-100 border-bottom">
                                    <div className="text-center  subscription-fees">
                                        <h3 className="fw-600">Subscription Fees</h3>
                                        <p>Choose the Plan That Fits You Best</p>
                                    </div>
                                    <div className="skip-subscrip">
                                        <button className="common-btn" onClick={() => navigate(ROUTES.CREATER_DASHBOARD)}>Skip</button>
                                    </div>
                                </div>
                                <div className="table-responsive mt-3 ">
                                    <table className="w-100 table " style={{ width: "100%", borderCollapse: "separate", borderSpacing: "20px" }} >
                                        <thead >
                                            <tr>
                                                <th className="border-bottom-0 px-3"><div className="theme-bg text-white"><p className="mb-0">FEATURE SET</p></div></th>
                                                <th className="border-bottom-0 px-3">
                                                    <p className="mb-1 fw-500 f18-size">CREATOR</p>
                                                    <p className="mb-0">
                                                        <span className="large-text pink">£19</span>
                                                        <span className="small-text pink">.95</span>
                                                        <span>/Month</span>
                                                    </p>
                                                    <button className=" buy-btn w-100">Buy Now</button>
                                                </th>
                                                <th className="border-bottom-0 px-3">
                                                    <p className="mb-1 fw-500 f18-size">LUMINARY</p>
                                                    <p className="mb-0">
                                                        <span className="large-text pink">£39</span>
                                                        <span className="small-text pink">.95</span>
                                                        <span>/Month</span>
                                                    </p>
                                                    <button className=" buy-btn w-100">Buy Now</button>
                                                </th>
                                                <th className="border-bottom-0 px-3">
                                                    <p className="mb-1 fw-500 f18-size">CELEBRITY</p>
                                                    <p className="mb-0">
                                                        <span className="large-text pink">£119</span>
                                                        <span className="small-text pink">.95</span>
                                                        <span>/Month</span>
                                                    </p>
                                                    <button className=" buy-btn w-100">Buy Now</button>
                                                </th>
                                                <th className="border-bottom-0 px-3">
                                                    <p className="mb-1 fw-500 f18-size">STAR</p>
                                                    <p className="mb-0">
                                                        <span className="large-text pink">£199</span>
                                                        <span className="small-text pink">.95</span>
                                                        <span>/Month</span>
                                                    </p>
                                                    <button className=" buy-btn w-100">Buy Now</button>
                                                </th>
                                                <th className="border-bottom-0 px-3">
                                                    <p className="mb-1 fw-500 f18-size">SUPERSTAR</p>
                                                    <p className="mb-0">
                                                        <span className="large-text pink">£1195</span>
                                                        {/* <span className="small-text pink">.95</span> */}
                                                        <span>/Month</span>
                                                    </p>
                                                    <button className=" buy-btn w-100">Buy Now</button>
                                                </th>
                                            </tr>
                                        </thead>
                                    </table>
                                </div>
                                {/* table new */}
                                {/* <div className="subscription">
                                    <div className="table-responsive">
                                        <table className="table subscription-table"
                                            style={{ width: "100%", borderCollapse: "separate", borderSpacing: "20px" }}>
                                            <tbody className="subscription subscription-table" style={{ width: "100%", borderCollapse: "separate", borderSpacing: "20px" }}>
                                                <tr className="">
                                                    <td className="border-0">
                                                        <p className="features text-start">Dashboard</p>
                                                    </td>
                                                    <td className="text-center"><img src={check} className="check-img" /></td>
                                                    <td className="text-center"><img src={check} className="check-img" /></td>
                                                    <td className="text-center"><img src={check} className="check-img" /></td>
                                                    <td className="text-center"><img src={check} className="check-img" /></td>
                                                    <td className="text-center"><img src={check} className="check-img" /></td>

                                                </tr>
                                                <tr className="">
                                                    <td className="text-center border-0">
                                                        <p className="text-muted text-start">Revenue Split</p>
                                                    </td>
                                                    <td className="text-center">
                                                        <p className="text-muted ">65/35</p>
                                                    </td>
                                                    <td className="text-center">
                                                        <p className="text-muted ">65/35</p>
                                                    </td>
                                                    <td className="text-center">
                                                        <p className="text-muted ">65/35</p>
                                                    </td>
                                                    <td className="text-center">
                                                        <p className="text-muted ">65/35</p>
                                                    </td>
                                                    <td className="text-center">
                                                        <p className="text-muted ">65/35</p>
                                                    </td>

                                                </tr>
                                                <tr className="">
                                                    <td className="border-0">
                                                        <p className="features text-start">Vertical Video</p>
                                                    </td>
                                                    <td className="text-center"><img src={check} className="check-img" /></td>
                                                    <td className="text-center"><img src={check} className="check-img" /></td>
                                                    <td className="text-center"><img src={check} className="check-img" /></td>
                                                    <td className="text-center"><img src={check} className="check-img" /></td>
                                                    <td className="text-center"><img src={check} className="check-img" /></td>

                                                </tr>

                                                <tr className="">
                                                    <td className="border-0">
                                                        <p className="features text-start">Wide Video</p>
                                                    </td>
                                                    <td className="text-center"><img src={check} className="check-img" /></td>
                                                    <td className="text-center"><img src={check} className="check-img" /></td>
                                                    <td className="text-center"><img src={check} className="check-img" /></td>
                                                    <td className="text-center"><img src={check} className="check-img" /></td>
                                                    <td className="text-center"><img src={check} className="check-img" /></td>

                                                </tr>
                                                <tr className="">
                                                    <td className="border-0">
                                                        <p className="features text-start">Subscription</p>
                                                    </td>
                                                    <td className="text-center"><img src={check} className="check-img" /></td>
                                                    <td className="text-center"><img src={check} className="check-img" /></td>
                                                    <td className="text-center"><img src={check} className="check-img" /></td>
                                                    <td className="text-center"><img src={check} className="check-img" /></td>
                                                    <td className="text-center"><img src={check} className="check-img" /></td>

                                                </tr>
                                                <tr className="">
                                                    <td className="border-0">
                                                        <p className="features text-start">Custom Subscription</p>
                                                    </td>
                                                    <td className="text-center"><img src={cancel} className="check-img" /></td>
                                                    <td className="text-center"><img src={check} className="check-img" /></td>
                                                    <td className="text-center"><img src={check} className="check-img" /></td>
                                                    <td className="text-center"><img src={check} className="check-img" /></td>
                                                    <td className="text-center"><img src={check} className="check-img" /></td>

                                                </tr>
                                                <tr className="">
                                                    <td className="border-0">
                                                        <p className="features text-start">Live Chat</p>
                                                    </td>
                                                    <td className="text-center"><img src={check} className="check-img" /></td>
                                                    <td className="text-center"><img src={check} className="check-img" /> </td>
                                                    <td className="text-center"><img src={check} className="check-img" /></td>
                                                    <td className="text-center"><img src={check} className="check-img" /></td>
                                                    <td className="text-center"><img src={check} className="check-img" /></td>

                                                </tr>
                                                <tr className="">
                                                    <td className="border-0">
                                                        <p className="features text-start">Gifting & Tips</p>
                                                    </td>
                                                    <td className="text-center"><img src={check} className="check-img" /></td>
                                                    <td className="text-center"><img src={check} className="check-img" /></td>
                                                    <td className="text-center"><img src={check} className="check-img" /></td>
                                                    <td className="text-center"><img src={check} className="check-img" /></td>
                                                    <td className="text-center"><img src={check} className="check-img" /></td>

                                                </tr>
                                                <tr className="">
                                                    <td className="border-0">
                                                        <p className="features text-start">Watermark</p>
                                                    </td>
                                                    <td className="text-center"><img src={cancel} className="check-img" /></td>
                                                    <td className="text-center"><img src={check} className="check-img" /></td>
                                                    <td className="text-center"><img src={check} className="check-img" /></td>
                                                    <td className="text-center"><img src={check} className="check-img" /></td>
                                                    <td className="text-center"><img src={check} className="check-img" /></td>

                                                </tr>
                                                <tr className="">
                                                    <td className="border-0">
                                                        <p className="features text-start">Shop</p>
                                                    </td>
                                                    <td className="text-center"><img src={cancel} className="check-img" /></td>
                                                    <td className="text-center"><img src={check} className="check-img" /></td>
                                                    <td className="text-center"><img src={check} className="check-img" /></td>
                                                    <td className="text-center"><img src={check} className="check-img" /></td>
                                                    <td className="text-center"><img src={check} className="check-img" /></td>

                                                </tr>
                                                <tr className="">
                                                    <td className="border-0">
                                                        <p className="features text-start">Asset Bank</p>
                                                    </td>
                                                    <td className="text-center"><img src={cancel} className="check-img" /></td>
                                                    <td className="text-center"><img src={cancel} className="check-img" /></td>
                                                    <td className="text-center"><img src={check} className="check-img" /></td>
                                                    <td className="text-center"><img src={check} className="check-img" /></td>
                                                    <td className="text-center"><img src={check} className="check-img" /></td>

                                                </tr>
                                                <tr className="">
                                                    <td className="border-0">
                                                        <p className="features text-start">Live Stream</p>
                                                    </td>
                                                    <td className="text-center"><img src={cancel} className="check-img" /></td>
                                                    <td className="text-center"><img src={cancel} className="check-img" /></td>
                                                    <td className="text-center"><img src={cancel} className="check-img" /></td>
                                                    <td className="text-center"><img src={check} className="check-img" /></td>
                                                    <td className="text-center"><img src={check} className="check-img" /></td>

                                                </tr>
                                                <tr className="">
                                                    <td className="border-0">
                                                        <p className="features text-start">Custom Interface Design</p>
                                                    </td>
                                                    <td className="text-center"><img src={cancel} className="check-img" /></td>
                                                    <td className="text-center"><img src={cancel} className="check-img" /></td>
                                                    <td className="text-center"><img src={cancel} className="check-img" /></td>
                                                    <td className="text-center"><img src={check} className="check-img" /></td>
                                                    <td className="text-center"><img src={check} className="check-img" /></td>

                                                </tr>
                                                <tr className="">
                                                    <td className="border-0">
                                                        <p className="features text-start">White Label Application</p>
                                                    </td>
                                                    <td className="text-center"><img src={cancel} className="check-img" /></td>
                                                    <td className="text-center"><img src={cancel} className="check-img" /></td>
                                                    <td className="text-center"><img src={cancel} className="check-img" /></td>
                                                    <td className="text-center"><img src={cancel} className="check-img" /></td>
                                                    <td className="text-center"><img src={check} className="check-img" /></td>

                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className="playhuman-btn  "
                                                style={{ borderCollapse: "collapse", borderSpacing: "0px" }}>
                                                <p className="text-start">Playhuman+ (Playbutton)</p>
                                            </div>
                                            <div className="table-responsive" >
                                                <table className="table subscription-table"
                                                    style={{ width: "100%", borderCollapse: "separate", borderSpacing: "20px" }}>
                                                    <tbody>
                                                        <tr className="">
                                                            <td className="border-0">
                                                                <p className="features text-start">Video Challenges </p>
                                                            </td>
                                                            <td className="text-center"><img src={check} className="check-img" /></td>
                                                            <td className="text-center"><img src={check} className="check-img" /></td>
                                                            <td className="text-center"><img src={check} className="check-img" /></td>
                                                            <td className="text-center"><img src={check} className="check-img" /></td>
                                                            <td className="text-center"><img src={check} className="check-img" /></td>

                                                        </tr>
                                                        <tr className="">
                                                            <td>
                                                                <p className="text-muted text-start">Live Connect</p>
                                                            </td>
                                                            <td className="text-center"><img src={cancel} className="check-img" /></td>
                                                            <td className="text-center"><img src={check} className="check-img" /></td>
                                                            <td className="text-center"><img src={check} className="check-img" /></td>
                                                            <td className="text-center"><img src={check} className="check-img" /></td>
                                                            <td className="text-center"><img src={check} className="check-img" /></td>

                                                        </tr>
                                                        <tr className="">
                                                            <td>
                                                                <p className="text-muted text-start">Storychain</p>
                                                            </td>
                                                            <td className="text-center"><img src={cancel} className="check-img" /></td>
                                                            <td className="text-center"><img src={check} className="check-img" /></td>
                                                            <td className="text-center"><img src={check} className="check-img" /></td>
                                                            <td className="text-center"><img src={check} className="check-img" /></td>
                                                            <td className="text-center"><img src={check} className="check-img" /></td>

                                                        </tr>
                                                        <tr className="">
                                                            <td className="border-0">
                                                                <p className="features text-start">Direct Response</p>
                                                            </td>
                                                            <td className="text-center"><img src={cancel} className="check-img" /></td>
                                                            <td className="text-center"><img src={cancel} className="check-img" /></td>
                                                            <td className="text-center"><img src={check} className="check-img" /></td>
                                                            <td className="text-center"><img src={check} className="check-img" /></td>
                                                            <td className="text-center"><img src={check} className="check-img" /></td>

                                                        </tr>

                                                        <tr className="">
                                                            <td className="border-0">
                                                                <p className="features text-start">Accordian</p>
                                                            </td>
                                                            <td className="text-center"><img src={cancel} className="check-img" /></td>
                                                            <td className="text-center"><img src={cancel} className="check-img" /></td>
                                                            <td className="text-center"><img src={check} className="check-img" /></td>
                                                            <td className="text-center"><img src={check} className="check-img" /></td>
                                                            <td className="text-center"><img src={check} className="check-img" /></td>

                                                        </tr>
                                                        <tr className="">
                                                            <td className="border-0">
                                                                <p className="features text-start">Metaverse</p>
                                                            </td>
                                                            <td className="text-center"><img src={cancel} className="check-img" /></td>
                                                            <td className="text-center"><img src={cancel} className="check-img" /></td>
                                                            <td className="text-center"><img src={cancel} className="check-img" /></td>
                                                            <td className="text-center"><img src={check} className="check-img" /></td>
                                                            <td className="text-center"><img src={check} className="check-img" /></td>

                                                        </tr>
                                                        <tr className="">
                                                            <td className="border-0">
                                                                <p className="features text-start">NFT Marketplace</p>
                                                            </td>
                                                            <td className="text-center"><img src={cancel} className="check-img" /></td>
                                                            <td className="text-center"><img src={cancel} className="check-img" /></td>
                                                            <td className="text-center"><img src={cancel} className="check-img" /></td>
                                                            <td className="text-center"><img src={check} className="check-img" /></td>
                                                            <td className="text-center"><img src={check} className="check-img" /></td>

                                                        </tr>
                                                        <tr className="">
                                                            <td className="border-0">
                                                                <p className="features text-start">Repurpose </p>
                                                            </td>
                                                            <td className="text-center"><img src={cancel} className="check-img" /></td>
                                                            <td className="text-center"><img src={cancel} className="check-img" /></td>
                                                            <td className="text-center"><img src={cancel} className="check-img" /></td>
                                                            <td className="text-center"><img src={check} className="check-img" /></td>
                                                            <td className="text-center"><img src={check} className="check-img" /></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                    </div>
                                </div> */}

<div className="subscription">
                                {/* <div className="table-responsive">
                                    <table className="w-100 table">
                                        <div className="subscription"> */}
                                            <div className="table-responsive">
                                                <table className="table subscription-table"
                                                    style={{ width: "100%", borderCollapse: "separate", borderSpacing: "20px" }}>
                                                    <tbody>
                                                        <tr className="">
                                                            <td className="border-0">
                                                                <p className="features text-start">Dashboard</p>
                                                            </td>
                                                            <td className="text-center"><img src={check} className="check-img" /></td>
                                                            <td className="text-center"><img src={check} className="check-img" /></td>
                                                            <td className="text-center"><img src={check} className="check-img" /></td>
                                                            <td className="text-center"><img src={check} className="check-img" /></td>
                                                            <td className="text-center"><img src={check} className="check-img" /></td>
                                                        </tr>
                                                        <tr className="">
                                                            <td className="text-center">
                                                                <p className="text-muted text-start">Revenue Split</p>
                                                            </td>
                                                            <td className="text-center">
                                                                <p className="text-muted ">65/35</p>
                                                            </td>
                                                            <td className="text-center">
                                                                <p className="text-muted ">65/35</p>
                                                            </td>
                                                            <td className="text-center">
                                                                <p className="text-muted ">65/35</p>
                                                            </td>
                                                            <td className="text-center">
                                                                <p className="text-muted ">65/35</p>
                                                            </td>
                                                            <td className="text-center">
                                                                <p className="text-muted ">65/35</p>
                                                            </td>
                                                        </tr>
                                                        <tr className="">
                                                            <td className="border-0">
                                                                <p className="features text-start">Vertical Video</p>
                                                            </td>
                                                            <td className="text-center"><img src={check} className="check-img" /></td>
                                                            <td className="text-center"><img src={check} className="check-img" /></td>
                                                            <td className="text-center"><img src={check} className="check-img" /></td>
                                                            <td className="text-center"><img src={check} className="check-img" /></td>
                                                            <td className="text-center"><img src={check} className="check-img" /></td>
                                                        </tr>
                                                        <tr className="">
                                                            <td className="border-0">
                                                                <p className="features text-start">Wide Video</p>
                                                            </td>
                                                            <td className="text-center"><img src={check} className="check-img" /></td>
                                                            <td className="text-center"><img src={check} className="check-img" /></td>
                                                            <td className="text-center"><img src={check} className="check-img" /></td>
                                                            <td className="text-center"><img src={check} className="check-img" /></td>
                                                            <td className="text-center"><img src={check} className="check-img" /></td>
                                                        </tr>
                                                        <tr className="">
                                                            <td className="border-0">
                                                                <p className="features text-start">Subscription</p>
                                                            </td>
                                                            <td className="text-center"><img src={check} className="check-img" /></td>
                                                            <td className="text-center"><img src={check} className="check-img" /></td>
                                                            <td className="text-center"><img src={check} className="check-img" /></td>
                                                            <td className="text-center"><img src={check} className="check-img" /></td>
                                                            <td className="text-center"><img src={check} className="check-img" /></td>
                                                        </tr>
                                                        <tr className="">
                                                            <td className="border-0">
                                                                <p className="features text-start">Custom Subscription</p>
                                                            </td>
                                                            <td className="text-center"><img src={cancel} className="check-img" /></td>
                                                            <td className="text-center"><img src={check} className="check-img" /></td>
                                                            <td className="text-center"><img src={check} className="check-img" /></td>
                                                            <td className="text-center"><img src={check} className="check-img" /></td>
                                                            <td className="text-center"><img src={check} className="check-img" /></td>
                                                        </tr>
                                                        <tr className="">
                                                            <td className="border-0">
                                                                <p className="features text-start">Live Chat</p>
                                                            </td>
                                                            <td className="text-center"><img src={check} className="check-img" /></td>
                                                            <td className="text-center"><img src={check} className="check-img" /> </td>
                                                            <td className="text-center"><img src={check} className="check-img" /></td>
                                                            <td className="text-center"><img src={check} className="check-img" /></td>
                                                            <td className="text-center"><img src={check} className="check-img" /></td>
                                                        </tr>
                                                        <tr className="">
                                                            <td className="border-0">
                                                                <p className="features text-start">Gifting & Tips</p>
                                                            </td>
                                                            <td className="text-center"><img src={check} className="check-img" /></td>
                                                            <td className="text-center"><img src={check} className="check-img" /></td>
                                                            <td className="text-center"><img src={check} className="check-img" /></td>
                                                            <td className="text-center"><img src={check} className="check-img" /></td>
                                                            <td className="text-center"><img src={check} className="check-img" /></td>
                                                        </tr>
                                                        <tr className="">
                                                            <td className="border-0">
                                                                <p className="features text-start">Watermark</p>
                                                            </td>
                                                            <td className="text-center"><img src={cancel} className="check-img" /></td>
                                                            <td className="text-center"><img src={check} className="check-img" /></td>
                                                            <td className="text-center"><img src={check} className="check-img" /></td>
                                                            <td className="text-center"><img src={check} className="check-img" /></td>
                                                            <td className="text-center"><img src={check} className="check-img" /></td>
                                                        </tr>
                                                        <tr className="">
                                                            <td className="border-0">
                                                                <p className="features text-start">Shop</p>
                                                            </td>
                                                            <td className="text-center"><img src={cancel} className="check-img" /></td>
                                                            <td className="text-center"><img src={check} className="check-img" /></td>
                                                            <td className="text-center"><img src={check} className="check-img" /></td>
                                                            <td className="text-center"><img src={check} className="check-img" /></td>
                                                            <td className="text-center"><img src={check} className="check-img" /></td>
                                                        </tr>
                                                        <tr className="">
                                                            <td className="border-0">
                                                                <p className="features text-start">Asset Bank</p>
                                                            </td>
                                                            <td className="text-center"><img src={cancel} className="check-img" /></td>
                                                            <td className="text-center"><img src={cancel} className="check-img" /></td>
                                                            <td className="text-center"><img src={check} className="check-img" /></td>
                                                            <td className="text-center"><img src={check} className="check-img" /></td>
                                                            <td className="text-center"><img src={check} className="check-img" /></td>
                                                        </tr>
                                                        <tr className="">
                                                            <td className="border-0">
                                                                <p className="features text-start">Live Stream</p>
                                                            </td>
                                                            <td className="text-center"><img src={cancel} className="check-img" /></td>
                                                            <td className="text-center"><img src={cancel} className="check-img" /></td>
                                                            <td className="text-center"><img src={cancel} className="check-img" /></td>
                                                            <td className="text-center"><img src={check} className="check-img" /></td>
                                                            <td className="text-center"><img src={check} className="check-img" /></td>
                                                        </tr>
                                                        <tr className="">
                                                            <td className="border-0">
                                                                <p className="features text-start">Custom Interface Design</p>
                                                            </td>
                                                            <td className="text-center"><img src={cancel} className="check-img" /></td>
                                                            <td className="text-center"><img src={cancel} className="check-img" /></td>
                                                            <td className="text-center"><img src={cancel} className="check-img" /></td>
                                                            <td className="text-center"><img src={check} className="check-img" /></td>
                                                            <td className="text-center"><img src={check} className="check-img" /></td>
                                                        </tr>
                                                        <tr className="">
                                                            <td className="border-0">
                                                                <p className="features text-start">White Label Application</p>
                                                            </td>
                                                            <td className="text-center"><img src={cancel} className="check-img" /></td>
                                                            <td className="text-center"><img src={cancel} className="check-img" /></td>
                                                            <td className="text-center"><img src={cancel} className="check-img" /></td>
                                                            <td className="text-center"><img src={cancel} className="check-img" /></td>
                                                            <td className="text-center"><img src={check} className="check-img" /></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        {/* </div>
                                    </table>
                                </div> */}
                                <div className="playhuman-btn  "
                                    style={{ borderCollapse: "collapse", borderSpacing: "0px" }}>
                                    <p className="text-start">Playhuman+ (Playbutton)</p>
                                </div>
                                <div className="table-responsive">
                                    <table className="table subscription-table"
                                        style={{ width: "100%", borderCollapse: "separate", borderSpacing: "20px" }}>
                                        <tbody>
                                            <tr className="">
                                                <td className="border-0">
                                                    <p className="features text-start">Video Challenges </p>
                                                </td>
                                                <td className="text-center"><img src={check} className="check-img" /></td>
                                                <td className="text-center"><img src={check} className="check-img" /></td>
                                                <td className="text-center"><img src={check} className="check-img" /></td>
                                                <td className="text-center"><img src={check} className="check-img" /></td>
                                                <td className="text-center"><img src={check} className="check-img" /></td>
                                            </tr>
                                            <tr className="">
                                                <td>
                                                    <p className="text-muted text-start">Live Connect</p>
                                                </td>
                                                <td className="text-center"><img src={cancel} className="check-img" /></td>
                                                <td className="text-center"><img src={check} className="check-img" /></td>
                                                <td className="text-center"><img src={check} className="check-img" /></td>
                                                <td className="text-center"><img src={check} className="check-img" /></td>
                                                <td className="text-center"><img src={check} className="check-img" /></td>
                                            </tr>
                                            <tr className="">
                                                <td>
                                                    <p className="text-muted text-start">Storychain</p>
                                                </td>
                                                <td className="text-center"><img src={cancel} className="check-img" /></td>
                                                <td className="text-center"><img src={check} className="check-img" /></td>
                                                <td className="text-center"><img src={check} className="check-img" /></td>
                                                <td className="text-center"><img src={check} className="check-img" /></td>
                                                <td className="text-center"><img src={check} className="check-img" /></td>
                                            </tr>
                                            <tr className="">
                                                <td className="border-0">
                                                    <p className="features text-start">Direct Response</p>
                                                </td>
                                                <td className="text-center"><img src={cancel} className="check-img" /></td>
                                                <td className="text-center"><img src={cancel} className="check-img" /></td>
                                                <td className="text-center"><img src={check} className="check-img" /></td>
                                                <td className="text-center"><img src={check} className="check-img" /></td>
                                                <td className="text-center"><img src={check} className="check-img" /></td>
                                            </tr>
                                            <tr className="">
                                                <td className="border-0">
                                                    <p className="features text-start">Accordian</p>
                                                </td>
                                                <td className="text-center"><img src={cancel} className="check-img" /></td>
                                                <td className="text-center"><img src={cancel} className="check-img" /></td>
                                                <td className="text-center"><img src={check} className="check-img" /></td>
                                                <td className="text-center"><img src={check} className="check-img" /></td>
                                                <td className="text-center"><img src={check} className="check-img" /></td>
                                            </tr>
                                            <tr className="">
                                                <td className="border-0">
                                                    <p className="features text-start">Metaverse</p>
                                                </td>
                                                <td className="text-center"><img src={cancel} className="check-img" /></td>
                                                <td className="text-center"><img src={cancel} className="check-img" /></td>
                                                <td className="text-center"><img src={cancel} className="check-img" /></td>
                                                <td className="text-center"><img src={check} className="check-img" /></td>
                                                <td className="text-center"><img src={check} className="check-img" /></td>
                                            </tr>
                                            <tr className="">
                                                <td className="border-0">
                                                    <p className="features text-start">NFT Marketplace</p>
                                                </td>
                                                <td className="text-center"><img src={cancel} className="check-img" /></td>
                                                <td className="text-center"><img src={cancel} className="check-img" /></td>
                                                <td className="text-center"><img src={cancel} className="check-img" /></td>
                                                <td className="text-center"><img src={check} className="check-img" /></td>
                                                <td className="text-center"><img src={check} className="check-img" /></td>
                                            </tr>
                                            <tr className="">
                                                <td className="border-0">
                                                    <p className="features text-start">Repurpose </p>
                                                </td>
                                                <td className="text-center"><img src={cancel} className="check-img" /></td>
                                                <td className="text-center"><img src={cancel} className="check-img" /></td>
                                                <td className="text-center"><img src={cancel} className="check-img" /></td>
                                                <td className="text-center"><img src={check} className="check-img" /></td>
                                                <td className="text-center"><img src={check} className="check-img" /></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}
export default CreatorMembership