import React, { useState } from 'react'
import { buy_btn, no_images, playBtn } from '../../Constants/images'
import ReactLoader from '../../ControlComponents/react-loader'
import PriceTag from '../../CreaterDashboard/component/price-tag'
import AddDetailModal from '../UserShop/detailImageModal'
import BuyModal from '../UserShop/buyModal'
function ImagesChannel({detailList, loading,setLoading,getListAPI }) {
    const [deltId, setDeltId] = useState(null)
    const [detailOpen, setDetailOpen] = useState(false)
    const [buyModal, setBuyModal] = useState(false)
    const [item, setItem] = useState({})

    const handleOpen = (item) => {
        setItem(item)
        setBuyModal(true)
    }
    const hanldeClick = (item) => {
        setDeltId(item.id)
        setDetailOpen(true)
    }

    return (
        <>

            <div className="container-fluid">
                <div className="row">
                  
                    <div className='row mt-4'>
                        {detailList?.length > 0 ? (
                            <>
                             {  (
                                    detailList.length > 0 && detailList.map(item => (
                                        <div className="col-12 col-sm-6 col-lg-4 col-xl-4 col-xxl-3 pb-4" key={item?.id}>
                                            <div className="latest-video mx-auto">
                                                <div className='position-relative'>
                                                    <div className="latest-video-content position-relative pt-2 pt-sm-3 px-2 px-sm-3" style={{ cursor: "pointer" }}>
                                                        <img src={item?.shop_media[0]?.media_url} className="img-fluid latest-vid-img new-latest-video" alt="thumbnail" />
                                                        {item.is_purchase == 0 && <PriceTag classes={"price_tag"} firstValue={`$${item?.price}`} secondValue={"00"} />}
                                                      
                                                    </div>
                                                </div>
                                                <div className="px-3 py-3 schedule-grid">
                                                    <div className=''>
                                                        <div className="tooltip-container">
                                                            <h4 className="schedule-title">{item.title?.length > 25 ? item.title.substring(0, 25) + "..." : item.title}</h4>
                                                            <span className="tooltip-text">{item.title}</span>
                                                        </div>
                                                        <h6 className="images-count">{item.shop_media.length} {item.shop_media.length === 1 ? "Image" : "Images"}</h6>
                                                        <p className="images-description">{item.description}</p>
                                                        {item.is_purchase == 0 ? (
                                                            <button style={{ cursor: 'pointer', backgroundColor:'transparent' }} className="common-btn watch-btn buy-btn-shop px-0"
                                                             onClick={() => handleOpen(item)}
                                                            >
                                                                <img src={buy_btn} alt='buy-btn' style={{ width: '50px', height: '50px' }} />                                                            </button>
                                                        ) : (
                                                            <span style={{ cursor: "pointer" }} 
                                                            onClick={() => hanldeClick(item)} 
                                                            className="text-end">
                                                                <img src={playBtn} alt='buy-btn' style={{ width: '50px', height: '50px' }} />                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                )}
                            </>
                        ) : (
                            <>
                                {loading == false && (
                                    <div style={{ flexDirection: 'column' }} className='no-search-results position-relative'>
                                        <img src={no_images} alt="no image" />
                                        <h6 className='position-absolute' style={{ bottom: 6 }}>No Images yet</h6>
                                    </div>
                                )}
                            </>
                        )}
                    </div>

                </div>

                {
                    detailOpen && <AddDetailModal setDetailModal={setDetailOpen} deltId={deltId} />
                }
                {
                    buyModal && <BuyModal item={item} setLoading={setLoading} setBuyModal={setBuyModal} check="images" getListAPI={getListAPI} />
                } 

            </div>
        </>
    )
}

export default ImagesChannel