import React, { useRef, useEffect, useState } from "react";
import dashjs from "dashjs";
import { collection, query, orderBy, onSnapshot } from "firebase/firestore";
import { db } from "../../firebase";
import DEVELOPMENT_CONFIG from "../../Helper/config";
import apiHelper from "../../Helper/api-helper";
import ReactLoader from "../../ControlComponents/react-loader";
import { profile_image_placeholder } from "../../Constants/images";
import { useLocation } from "react-router-dom";
import "./webView.css";
const WebView = () => {
  const [loading, setIsLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [chatId, setChatId] = useState("1");
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  const token = searchParams.get("token");
  const refreshToken = searchParams.get("refresh_token");
  if (refresh != true) localStorage.setItem("tokenApp", token);
  if (refresh != true) localStorage.setItem("refreshTokenApp", refreshToken);
  const [visibleChat, setVisibleChat] = useState([]);
  const [recordingUrl, setRecordingUrl] = useState(null);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const videoRef = useRef(null);
  const [videoTimestamp, setVideoTimestamp] = useState(0);
  const [messages, setMessages] = useState([]);

  async function getDetailAPI() {
    setIsLoading(true);
    let token = localStorage.getItem("tokenApp");
    let result = await apiHelper.getRequest1(
      "creator/schedule-details?id=" + id,
      token
    );
    if (result.code === DEVELOPMENT_CONFIG.statusCode) {
      setRecordingUrl(result.data?.recording?.recording_url);
      const trimmedName = result.data?.name?.replace(/\s/g, "");
      const id = result?.data?.id;
      const chatId = `${id}${trimmedName}`;
      setChatId(chatId);
      setTitle(result.data?.name);
      setIsLoading(false);
      setDescription(result.data?.description);
    } else if (result.code === 401) {
      let refreshToken = localStorage.getItem("refreshTokenApp");
      let data = {
        refresh_token: refreshToken,
        scope: "refresh_token",
      };
      let refreshTokenResult = await apiHelper.postRequest1(
        "auth/refresh-token",
        data,
        token
      );
      if (refreshTokenResult.code === DEVELOPMENT_CONFIG.createStatusCode) {
        const newToken = refreshTokenResult.data.token;
        const newRefreshToken = refreshTokenResult.data.refresh_token;
        localStorage.setItem("tokenApp", newToken);
        localStorage.setItem("refreshTokenApp", newRefreshToken);
        token = newToken;
        refreshToken = newRefreshToken;
        setRefresh(true);
        setIsLoading(false);

        const url = new URL(window.location.href);
        url.searchParams.set("token", newToken);
        url.searchParams.set("refresh_token", newRefreshToken);
        window.history.replaceState({}, "", url);
      }
    } else {
    }
  }

  useEffect(() => {
    getDetailAPI();
  }, [refresh]);

  useEffect(() => {
    const q = query(
      collection(db, "LiveStreamCollection", chatId, "Comments"),
      orderBy("createdAt", "asc")
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const fetchedMessages = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setMessages(fetchedMessages);
    });
    return () => {
      unsubscribe();
    };
  }, [chatId]);

  useEffect(() => {
    const player = dashjs.MediaPlayer().create();
    player.initialize(videoRef.current, recordingUrl, true);
    return () => {
      if (player) {
        player.reset();
      }
    };
  }, [recordingUrl]);

  const handleVideoProgress = () => {
    const currentTime = videoRef.current.currentTime;
    setVideoTimestamp(currentTime * 1000);
  };

  useEffect(() => {
    const filteredChat = messages?.filter(
      (chat) => chat.timeStamp <= videoTimestamp
    );
    setVisibleChat(filteredChat);
  }, [messages, videoTimestamp]);

  return (
    <>
      {loading && <ReactLoader />}

      <div className="webview-container">
        <div className="video-container" style={{marginBottom:'-6px'}}>
          <video
            ref={videoRef}
            controls
            autoPlay
            onTimeUpdate={handleVideoProgress}
            className="full-screen-video"
          />
        </div>
        <div className="video-text-container p-3">
            <div className="recorded-live-title mb-1 ">{title}</div>
            <div className="pb-3 mb-1" style={{borderBottom:'1px solid #F3F3F3', fontSize:'14px'}} >{description}</div>
            <div>
              <h5 className="m-0 recorded-live-title mb-1">Live Chats</h5>
            </div>
            <div className="webview-chat-scroll">
              {visibleChat.map((item, index) => {
                return (
                  <div key={index}>
                    <div className="chat-listing py-2 px-0 mb-2" style={{borderBottom:'1px solid #F3F3F3'}}>
                      <div className="profile-chat">
                        <img
                          src={
                            item.profile_pic
                              ? item.profile_pic
                              : profile_image_placeholder
                          }
                        />
                      </div>
                      <div className="d-flex align-items-center">
                        <p className="mb-0 lh-sm f16-size fw-500  text-capitalize side_text">
                          {item.name}:
                        </p>
                        <p className=" mb-0 channel-text f16-size fw-600 ">
                          &nbsp; {item.comment}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            {visibleChat.length > 0 &&  <div className="custom-chat-listing"></div>
            }
        </div>
      </div>
    </>
  );
};

export default WebView;
