import React, { useState } from 'react';
import { DEMO_IMAGE_EIGHT, DEMO_IMAGE_THREE, SCHEDULE_DOT, live_calender, live_clock, no_live } from '../../Constants/images';
import { Dropdown } from 'react-bootstrap';
import './allLives.css'
import ScheduledModal from './scheduledModal';
import DeleteLiveModal from './deleletLive';
import DEVELOPMENT_CONFIG from "../../../src/Helper/config"
import apiHelper from '../../Helper/api-helper';
import { NotificationManager } from 'react-notifications';
import RecordedVideoPlayer from '../component/recordedVideo';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../Helper/route-helper';
import PriceTag from '../component/price-tag';

function PastLives({ liveList, setIsLoading, setRefresh, getLivesAPI, setTabSwitch, isLoading }) {
  const [scheduledModal, setSheduledModal] = useState(false)
  const [item, setItem] = useState()
  const [videoUrl, setVideoUrl] = useState(null)
  const [show, setShow] = useState(false)
  const navigate = useNavigate()

  const handleOpen = (item) => {
    setItem(item)
    setSheduledModal(!scheduledModal)
  }
  const [deleteModal, setDeleteModal] = useState(false)
  const [id, setId] = useState(null)

  const handleDeleteOpen = (id) => {
    setId(id)
    setDeleteModal(!deleteModal)
  }

  const dateFunction = (date) => {
    const parsedDate = new Date(date);
    if (isNaN(parsedDate.getTime())) {
      return "";
    }
    const formattedDate = parsedDate.toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric'
    }).replace(/\//g, '-');
    return formattedDate;
  }

  function addTime(originalDateTimeString) {
    let originalDateTime = new Date(originalDateTimeString);
    originalDateTime.setHours(originalDateTime.getHours() + 5);
    originalDateTime.setMinutes(originalDateTime.getMinutes() + 30);

    let modifiedTime = originalDateTime.toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    });
    return modifiedTime;
  }

  async function getDeleteAPI() {
    setIsLoading(true)
    let result = await apiHelper.getRequest("creator/delete-schedules?id=" + id)
    if (result.code == DEVELOPMENT_CONFIG.statusCode) {
      setIsLoading(false)
      setDeleteModal(false)
      NotificationManager.success(result.message)
      getLivesAPI()
    }
    else if (result.code == 401) {
      let refreshToken = localStorage.getItem("refresh-token")
      let data = {
        "refresh_token": refreshToken,
        "scope": "refresh_token"
      }
      let refreshTokenResult = await apiHelper.postService("auth/refresh-token", data)
      if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
        localStorage.setItem("token", refreshTokenResult.data.token)
        localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
        setRefresh(true)
      }
    }
    else {
      NotificationManager.error(result.message)
    }
  }

  const hanldeClick = (item) => {
    localStorage.setItem("item", JSON.stringify(item))
    navigate(ROUTES.CREATER_RECORDED_VIDEO)
  }

  return (
    <div>
      <section className="schedule-wrapper">
        <div className="container-fluid">
          <div className="container-fluid">
            <div className="row">
              <div className="stream-btn pt-3">
                <button onClick={() => {
                  setTabSwitch(0)
                  getLivesAPI()
                }} className="common-btn btn-white">Upcoming Lives</button>
                <button onClick={() => {
                  setTabSwitch(1)
                  getLivesAPI()
                }} className="common-btn btn-blue">Recorded Lives</button>
              </div>
            </div>
          </div>
          <div className='row mt-4'>
            {liveList?.length > 0 ? (
              <>
                {liveList?.map((item) => (
                  <div className="col-12 col-sm-6 col-lg-4 col-xl-4 col-xxl-3 pb-4" key={item?.id}>
                    <div className="latest-video mx-auto" >
                      <div className='position-relative' onClick={() => hanldeClick(item)}>
                        <div
                          className="latest-video-content  pt-2 pt-sm-3 px-2 px-sm-3"
                          style={{ cursor: "pointer" }}>
                          <img
                            src={item?.thumbnail}
                            className="img-fluid latest-vid-img"
                            alt="thumbnail"
                          />
                            {
                            item.price != 0 &&
                            <PriceTag classes={"price_tag"} firstValue={`$${item.price}`} secondValue={"00"} />
                          }
                        </div>
                      </div>
                      <div className="latest-video-cus px-3 py-3 schedule-grid">
                        <div className=''>
                          <h4 className="schedule-title">{item?.name}</h4>
                          <div className="timing-flex">
                            <div className='mb-0 timing-inner-flex'>
                              <img src={live_calender} alt="calendar" />
                              <span className='ps-1'>{dateFunction(item.date_time)}</span>
                            </div>
                            <div className='mb-0 timing-inner-flex'>
                              <img src={live_clock} alt="clock" />
                              <span className='ps-1'>{addTime(item.date_time)}</span>
                            </div>
                          </div>
                        </div>
                        <div className='dotschedule bg-transparent'>
                          <div className='dropdownschedule'>
                            <Dropdown>
                              <Dropdown.Toggle id="custom-dropdown-toggle">
                                <img src={SCHEDULE_DOT} className='img-fluid' alt="dot" />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item onClick={() => handleOpen(item)}>Edit</Dropdown.Item>
                                <Dropdown.Item onClick={() => handleDeleteOpen(item.id)}>Delete</Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            ) : <>
              {
                isLoading == false &&
                (
                  <div style={{ flexDirection: 'column' }} className='no-search-results'>
                    <img src={DEMO_IMAGE_EIGHT} alt="No Challenges" />
                    <h6 className='position-absolute' style={{ top: 530 }}>No lives yet</h6>                  </div>
                )
              }
            </>
            }
          </div>
        </div>

        {scheduledModal && <ScheduledModal liveList={liveList} setSheduledModal={setSheduledModal} setIsLoading={setIsLoading} item={item} getLivesAPI={getLivesAPI} check="past" setTabSwitch={setTabSwitch}/>}
        {
          deleteModal && <DeleteLiveModal id={id} getDeleteAPI={getDeleteAPI} setDeleteModal={setDeleteModal} check="past" />
        }
      </section>
    </div>
  )
}

export default PastLives;