import React, { useEffect, useState } from 'react';
import apiHelper from '../../Helper/api-helper';
import DEVELOPMENT_CONFIG from '../../Helper/config';
import './allLives.css';
import ReactLoader from '../../ControlComponents/react-loader'
import MyScheduledLives from './myscheduledLives';
import ScheduledModal from './scheduledModal';
import ROUTES from '../../Helper/route-helper';
import { useNavigate } from 'react-router';
import LiveStartModal from './liveStartModal';
import PastLives from './pastLives';


const Lives = () => {
    const [tabSwitch, setTabSwitch] = useState(0);
    const navigate = useNavigate()
    const [liveList, setLiveList] = useState([])
    const [pastList, setPastList] = useState([])
    const [refresh, setRefresh] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [scheduledModal, setSheduledModal] = useState(false)
    let message = localStorage.getItem("message")


    useEffect(() => {
        if (message) {
            getLivesAPI()
            localStorage.removeItem("message")
        }
        return () => {
            message = null
        }
    }, [])

    const handleOpen = () => {
        setSheduledModal(!scheduledModal)
    }

    const [liveStartModal, setLiveStartModal] = useState(false)
    const handleLiveOpen = () => {
        setLiveStartModal(!liveStartModal)
    }

    async function getLivesAPI() {
        setIsLoading(true)
        let result = await apiHelper.getRequest("creator/get-schedules")
        if (result?.code == DEVELOPMENT_CONFIG?.statusCode) {
            setLiveList(result?.data?.upcoming)
            setPastList(result?.data?.history)
            setIsLoading(false)
        }
        else if (result?.code == 401) {
            let refreshToken = localStorage.getItem("refresh-token")
            let data = {
                "refresh_token": refreshToken,
                "scope": "refresh_token"
            }
            let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
            if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
                localStorage.setItem("token", refreshTokenResult.data.token)
                localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
                setRefresh(true)
            }
        }
    }

    useEffect(() => {
        getLivesAPI()
    }, [refresh])

    return (
        <div>
            {isLoading && <ReactLoader />}
            <div className="main-inner-content">
                <div className='row align-items-center'>
                    <div className='col-sm-5'>
                        <div className="px-2">
                            <h3 className="fw-600">Lives</h3>
                        </div>
                    </div>
                    <div className='col-sm-7 ms-auto text-end d-flex justify-content-end live-start-btn'>
                        <button className="common-btn" onClick={handleLiveOpen}
                        >Start Live</button>
                        <button className="common-btn btn-white ms-4" onClick={handleOpen} >Scheduled</button>
                    </div>
                </div>
                {tabSwitch == 0 ?
                    <MyScheduledLives liveList={liveList} setIsLoading={setIsLoading} setRefresh={setRefresh} getLivesAPI={getLivesAPI} isLoading={isLoading} setTabSwitch={setTabSwitch} /> : <PastLives liveList={pastList} setIsLoading={setIsLoading} isLoading={isLoading} setRefresh={setRefresh} getLivesAPI={getLivesAPI} setTabSwitch={setTabSwitch} />
                }
                {scheduledModal && <ScheduledModal getLivesAPI={getLivesAPI} setSheduledModal={setSheduledModal} setIsLoading={setIsLoading} setRefresh={setRefresh} setTabSwitch={setTabSwitch} />}
                {liveStartModal && <LiveStartModal setLiveStartModal={setLiveStartModal} setIsLoading={setIsLoading} />}
            </div>
        </div>
    )
}

export default Lives;