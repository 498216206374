import React, { useState } from 'react'
import { modalCross, pinkgallery } from '../../Constants/images'
import ERR_MESSAGE from '../../Helper/error-helper';


const CastModal = ({ setCastModal, handleChange, info, fileChangeCast, handleSaveCast, castUrl, castFileUrl, setInfo, setCastFileUrl, setErrors, errors }) => {

    const handleValidation = () => {
        let errors = {};
        let formIsValid = true;
        const { castName, castSeriesName } = info;
        if (!castFileUrl || castFileUrl.trim().length === 0) {
            formIsValid = false;
            errors["castFileUrl"] = ERR_MESSAGE.CAST_EMPTY
        }
        if (!castName || castName?.trim().length === 0) {
            formIsValid = false;
            errors["castName"] = ERR_MESSAGE.CASTNAME_EMPTY
        }
        if (!castSeriesName || castSeriesName.trim().length === 0) {
            formIsValid = false;
            errors["castSeriesName"] = ERR_MESSAGE.CASTINSERIES_EMPTY
        }
        setErrors(errors);
        return formIsValid;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (handleValidation()) {
            const episodeObject = {
                image: castUrl,
                name: info.castName,
                name_in_series: info.castSeriesName,
            };
            const episodeObject1 = {
                image: castFileUrl,
                name: info.castName,
                name_in_series: info.castSeriesName,
            };
            handleSaveCast(episodeObject, episodeObject1);
            setInfo({
                ...info,
                castName: "",
                castSeriesName: ""
            })

            setCastFileUrl("")
            setCastModal(false);
        }
    };

    return (
        <div className="modal fade show modal-cast" style={{ display: "block", opacity: "1", backdropFilter: "blur(5px)" }}>
            <div className='loader'>
            </div>
            <div className="modal-dialog modal-dialog-centered modal-schedule-width">
                <div className="modal-content modal-radius  border-0">
                    <div className="modal-header forgot-header align-items-center pt-4">
                        <h3 className="modal-title text-center mx-auto forgot-title" id="exampleModalLabel">Add Cast</h3>
                        <a className="m-0 px-0 btn-closed-main" data-bs-dismiss="modal" aria-label="Close"><img style={{ cursor: "pointer" }} onClick={() => {
                            setErrors("")
                            setCastModal(false)
                            setInfo({
                                ...info,
                                castName: "",
                                castSeriesName: ""
                            })
                
                            setCastFileUrl("")
                        }}
                            src={modalCross} alt="" className="img-fluid btn-cloesed" /></a>
                    </div>
                    <div className="modal-body  verify-modal-box px-2 px-sm-5">
                        <form>
                            <div className=''>
                                <div className="mb-3 schedule-thumb">
                                    <div className="cast-upload position-relative">
                                        <div className={!castFileUrl ? 'cast-after-pre' :""}><img src={castFileUrl} className='thumbnail-preview' /></div>
                                        {
                                            !castFileUrl && <div className='d-flex m-auto justify-content-center align-items-center h-100'>
                                                <div className='cast-before'>
                                                    <div className='text-center'>
                                                        <img src={pinkgallery}></img>
                                                    </div>
                                                    <div className="d-flex m-auto justify-content-center align-items-center h-100">
                                                        <span className="f16-size fw-500 lighttext pt-2">Add Thumbnail</span>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        <input type="file" accept=".jpg, .jpeg, .png"
                                            style={{ cursor: "pointer" }} className="video-file-schedule" onChange={fileChangeCast} />
                                    </div>
                                    {errors.castFileUrl && <span className="err_msg">{errors.castFileUrl}</span>}
                                </div>
                                <div className='mb-3'>
                                    <label className='fw-500 f18-size text-black pb-2'>Name</label>
                                    <input type='text' className='border-edit  w-100' placeholder='Enter Here' name="castName" value={info.castName} onChange={handleChange}></input>
                                    {errors.castName && <span className="err_msg">{errors.castName}</span>}
                                </div>
                                <div className='mb-3'>
                                    <label className='fw-500 f18-size text-black pb-2'>Name in Series</label>
                                    <input type='text' className='border-edit  w-100' placeholder='Enter Here' name="castSeriesName" value={info.castSeriesName} onChange={handleChange}></input>
                                    {errors.castSeriesName && <span className="err_msg">{errors.castSeriesName}</span>}
                                </div>
                            </div>
                            <div className=" my-4">
                                <input type='submit' value="Save" onClick={handleSubmit} className="common-btn w-100" />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CastModal
