import React from 'react'
import { Link } from 'react-router-dom'
import { aboutUsBanner, aboutVision, entertainmentSec, visionSec } from '../../../../Constants/images'
import './bannerEntertainment.css'

function BannerEntertainment() {
    const visionStyle = {
        backgroundImage:`url(${aboutVision})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
    }

  return (
    <div>
    <section className="about-banner py-100">
        <div className="container">
            <div className="row align-items-start">
                <div className="col-12 col-lg-6 pt-0 pt-lg-2 pt-xl-5 pb-4 pb-lg-0">
                    <h1 className="pb-0 pb-xl-3"><span className="pink-text font-120">A</span>bout PlayHuman </h1>
                    <p className="about-content pb-0 pb-xl-3">Welcome to PlayHuman , where we are passionate about bringing you the best movies and episodes from around the world. We believe in the power of storytelling and the magic of cinema, and our mission is to provide you with a seamless and immersive streaming experience that keeps you entertained and engaged.</p>
                    <Link to="" className="common-btn read-more-btn">Read More</Link>
                </div>
                <div className="col-12 col-lg-6">
                    <img src={aboutUsBanner} alt="" className="img-fluid"/>
                </div>
            </div>
        </div>
    </section>
    <section className="our-vision">
        <div className="container">
            <div className="row">
                <div className="col-12 text-center">
                    <div className="vision-head" >
                        <h2><span className="pink-text font-70">O</span>ur Vision</h2>
                        <p className="vision-content">At PlayHuman, our vision is to be the ultimate destination for movie and TV enthusiasts, catering to diverse tastes and preferences. We strive to create a platform that not only offers a vast library of content but also fosters a sense of community, where viewers can connect, share their love for entertainment, and discover new cinematic experiences together.</p>
                        <img src={visionSec} alt="" className="img-fluid py-0 py-lg-5 vision-img"/>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section className="entertainment-banner py-100">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-12 col-lg-6 order-2 order-lg-1">
                    <img src={entertainmentSec} alt="" className="img-fluid"/>
                </div>
                <div className="col-12 col-lg-6 order-1 order-lg-2 pb-5 pb-lg-0">
                    <div className="entertainment-content">
                    <h2 className="pb-0 pb-xl-3"><span className="pink-text font-70">U</span>nlimited Entertainment</h2>
                    <p className="pb-3">We are committed to offering you unlimited entertainment options. Our extensive library spans across various genres, eras, and cultures, ensuring that there is always something for everyone. From Hollywood blockbusters to independent films, popular TV series to hidden gems, we curate our collection to provide a diverse selection that satisfies both mainstream and niche interests.</p>
                    <div className='abouts-ltd-btn'>
                        <Link to="" className="common-btn btn-blue join-play-btn  ">Join Play Human</Link>
                        <Link to="" className="common-btn read-more-btn">Read More</Link>
                    </div>

                </div>
                </div>
            </div>
        </div>
    </section>
    </div>
  )
}

export default BannerEntertainment