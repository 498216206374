import React, { useEffect, useRef, useState } from "react";
import {
  favRed,
  favouritesDark,
  reply_icon,
  uparrow,
} from "../../Constants/images";
import { profile_image_placeholder, send } from '../../Constants/images';
import { collection, query, orderBy, onSnapshot, updateDoc, doc, setDoc, arrayRemove, arrayUnion, getDoc } from "firebase/firestore";
import { db } from '../../firebase';
import { v4 as uuidv4 } from 'uuid';

function SeriesChat(props) {
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const chatId = localStorage.getItem("chatId") ? localStorage.getItem("chatId") : "1"
  const creatorId = localStorage.getItem("creator_id")
  const [reply, setReply] = useState(0)
  const [replyItem, setReplyItem] = useState(null)
  const [openReplies, setOpenReplies] = useState([]);
  const [expandedComments, setExpandedComments] = useState([]);
  const newAddCommentRef = useRef(null);
  const sendInputRef = useRef(null);

  const sendMessage = async (e) => {
    e.preventDefault();
    if (reply == 0) {
      addComment()
    }
    else if (reply == 1) {
      addReply()
    }
  }

  const handleClick = (uuid) => {
    setOpenReplies(prevState => {
      if (prevState.includes(uuid)) {
        return prevState.filter(id => id !== uuid);
      } else {
        return [uuid];
      }
    });
  };

  const addComment = async () => {
    if (message !== "") {
      try {
        const commentUid = uuidv4();
        const commentsCollectionRef = collection(db, "SeriesCollection", chatId, 'Comments');
        const newCommentRef = doc(commentsCollectionRef, commentUid);
        await setDoc(newCommentRef, {
          name: props.creatorName,
          comment: message,
          createdAt: new Date().toISOString(),
          profile_pic: props.creatorImg,
          likes: [],
          reply: [],
          id: creatorId,
          uuid: commentUid,
        });
        if (newAddCommentRef.current) {
          newAddCommentRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
        setMessage("")
      } catch (e) {
      }
    }
  }

  const addReply = async () => {
    let copyComment = [...replyItem?.reply];
    let replyComment = {};
    const commentUid = uuidv4();

    if (message?.trim() != '') {
      replyComment = {
        id: creatorId,
        name: props.creatorName,
        comment: message,
        createdAt: new Date().toISOString(),
        likes: [],
        profile_pic: props.creatorImg,
        uuid: commentUid,
        commentUuid: replyItem?.uuid
      };
      copyComment.push(replyComment);
      
      await updateDoc(doc(db, "SeriesCollection", chatId, "Comments", replyItem.uuid), {
        reply: copyComment
      })
        .then(() => {
          if (newAddCommentRef.current) {
            newAddCommentRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }
          setReply(0);
          setMessage('');
          setOpenReplies(prevState => {
            if (prevState.includes(replyItem.uuid)) {
              return prevState.filter(id => id === replyItem.uuid);
            } else {
              return [...prevState, replyItem.uuid];
            }
          });
        });
    }
  };

  useEffect(() => {
    const q = query(
      collection(db, "SeriesCollection", chatId, "Comments"),
      orderBy("createdAt", "asc")
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const fetchedMessages = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data()
      }));
      setMessages(fetchedMessages);
    });

    return () => unsubscribe();
  }, [props.chatOpen]);


 const handleReply = (item, index) => {
    setReply(1);
    setReplyItem(item);
    if (index != 0) {
      sendInputRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  const handleLikeComment = async (commentUid) => {
    const likedCommentIndex = messages.findIndex(msg => msg.uuid === commentUid)
    if (likedCommentIndex !== -1) {
      const likedComment = messages[likedCommentIndex];
      const likedByCurrentUser = likedComment?.likes?.includes(creatorId);
      try {
        if (likedByCurrentUser) {
          await updateDoc(doc(db, "SeriesCollection", chatId, "Comments", commentUid), {
            likes: arrayRemove(creatorId),
          });
        } else {
          await updateDoc(doc(db, "SeriesCollection", chatId, "Comments", commentUid), {
            likes: arrayUnion(creatorId),
          });
        }
      } catch (error) {
      }
    }
  };

  const handleLikeReply = async (replyUuid, parentCommentUuid) => {
    try {
      const commentRef = doc(db, "SeriesCollection", chatId, "Comments", parentCommentUuid);
      const commentDoc = await getDoc(commentRef);

      if (commentDoc.exists()) {
        const commentData = commentDoc.data();
        const replyIndex = commentData.reply.findIndex(reply => reply.uuid === replyUuid);

        if (replyIndex !== -1) {
          const likedReply = commentData.reply[replyIndex];
          const likedByCurrentUser = likedReply.likes.includes(creatorId);

          if (likedByCurrentUser) {
            await updateDoc(commentRef, {
              reply: [
                ...commentData.reply.slice(0, replyIndex),
                {
                  ...likedReply,
                  likes: likedReply.likes.filter(id => id !== creatorId)
                },
                ...commentData.reply.slice(replyIndex + 1)
              ]
            });
          } else {
            await updateDoc(commentRef, {
              reply: [
                ...commentData.reply.slice(0, replyIndex),
                {
                  ...likedReply,
                  likes: [...likedReply.likes, creatorId]
                },
                ...commentData.reply.slice(replyIndex + 1)
              ]
            });
          }
        }
      }
    } catch (error) {
    }
  };


  function getTimeAgo(createdAt) {
    const currentDate = new Date();
    const createdAtDate = new Date(createdAt);
    const timeDifference = currentDate.getTime() - createdAtDate.getTime();
    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    if (days > 0) {
      return days + (days === 0 || days === 1 ? " day ago" : " days ago");
    } else if (hours > 0) {
      return hours + (hours === 0 || hours === 1 ? " hour ago" : " hours ago");
    } else if (minutes > 0) {
      return minutes + (minutes === 0 || minutes === 1 ? " min ago" : " mins ago");
    } else {
      return (seconds >= 0 ? seconds : 0) + " sec ago";
    }
  }

  const renderCommentText = (text, uuid) => {
    const isExpanded = expandedComments.includes(uuid);

    if (text.length > 600 && !isExpanded) {
      return (
        <>
          {`${text.substring(0, 600)}...`}
          <span className="read-more" style={{ cursor: "pointer" }} onClick={() => handleExpandComment(uuid)}> Read More</span>
        </>
      );
    } else if (isExpanded) {
      return (
        <>
          {text}
          <span className="read-more" style={{ cursor: "pointer" }} onClick={() => handleCollapseComment(uuid)}> ...Read Less</span>
        </>
      );
    } else {
      return text;
    }
  };

  const handleExpandComment = (uuid) => {
    setExpandedComments(prevState => [...prevState, uuid]);
  };

  const handleCollapseComment = (uuid) => {
    setExpandedComments(prevState => prevState.filter(item => item !== uuid));
  };

  // const scrollToBottom = () => {
  //   messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
  // };

  // useEffect(() => {
  //   if (messages?.length == 0 && props.chatOpen == true) {
  //     scrollToBottom();
  //   }
  // }, [messages]);

  return (
    <div className="row" id="input-message">
      <div className="col-md-12">
        <div className="challenge-new-chat  mt-5">
          <h3 className="fw-600 mb-2" >Comments ({messages.length})</h3>
          <form onSubmit={sendMessage}>
            <div className="user-comments" >
              <ul> 
                {
                  messages.map((msg, index) => (
                    <li className="comment-input" key={index} ref={index === messages.length - 1 && reply == 1 ? newAddCommentRef : null} >
                      <div className="d-flex gap-3 mt-4 mb-2"  >
                        <div className="comment-user-profile" ref={index === messages.length - 1 && reply == 0 ? newAddCommentRef : null}>
                          {
                            msg.profile_pic ? <img src={msg.profile_pic} className="comment-profile-pic"></img>
                              : <img src={profile_image_placeholder} className="comment-profile-pic"></img>
                          }
                        </div>
                        <div className="comment-user-name-head">
                          <h6 className="comment-user-name text-black mb-0 fw-600 f20-size">{msg.name}</h6>
                          <p className="comment-time f14-size fw-400 my-2" style={{ color: "#737373", lineHeight: "15px" }}>{getTimeAgo(msg.createdAt)}</p>
                          <p className="user-comments f16-size fw-500 mt-2" style={{ color: "#07143A", lineHeight: "15px" }}>
                            {renderCommentText(msg.comment, msg.uuid)}
                          </p>
                          <div className="d-flex gap-3 align-items-center mb-2">
                            <div className="d-flex align-items-center gap-1" onClick={() => handleLikeComment(msg.uuid)} style={{ cursor: "pointer" }}>
                              <img src={msg.likes.includes(creatorId) ? favRed : favouritesDark} alt="like-icon" />
                              <span>{msg.likes.length} {msg.likes.length == 1 ? "Like" : "Likes"}</span>
                            </div>
                            <div className="d-flex align-items-center gap-1" style={{ cursor: "pointer" }} onClick={() => handleReply(msg, index)}>
                              <img src={reply_icon} alt="reply-icon" style={{ cursor: "pointer" }} />
                              <span>Reply</span>
                            </div>
                          </div>
                          {/* multiple-reply-section */}

                          <div className="multiple-reply mt-3 mb-2">
                            {
                              msg?.reply?.length > 0 && <span className="multiple-reply-text" onClick={() => handleClick(msg.uuid)} > <img src={uparrow} className="me-1 mb-1" alt="up-arrow" style={{ width: "14px" }} />{msg?.reply?.length} Reply</span>
                            }

                            {/* multiple-reply-items */}

                            {
                              openReplies.includes(msg.uuid) && msg.reply.map((item, i) => (
                                <div className="d-flex gap-3 mt-4 mb-2">
                                  <div className="comment-user-profile">
                                    <img src={item.profile_pic ? item.profile_pic : profile_image_placeholder} className="comment-profile-pic"></img>
                                  </div>
                                  <div className="comment-user-name-head">
                                    <h6 className="comment-user-name text-black mb-0 fw-600 f20-size">{item.name}</h6>
                                    <p className="comment-time f14-size fw-400 my-2" style={{ color: "#737373", lineHeight: "15px" }}>{getTimeAgo(item.createdAt)}</p>
                                    <p className="user-comments f16-size fw-500 mt-2" style={{ color: "#07143A", lineHeight: "15px" }}>
                                      {renderCommentText(item.comment, item.uuid)}
                                    </p>
                                    <div className="d-flex gap-3 align-items-center mb-2">
                                      <div style={{ cursor: "pointer" }} className="d-flex align-items-center gap-1" onClick={() => handleLikeReply(item.uuid, msg.uuid)}>
                                        <img src={item.likes.includes(creatorId) ? favRed : favouritesDark} alt="like-icon" />
                                        <span>{item.likes.length} {item.likes.length == 1 ? "Like" : "Likes"}</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))
                            }
                          </div>

                        </div>
                      </div>
                    </li>
                  ))
                }
              </ul>
            </div>
            <div
              className="chat-bottom position-relative bottom-0 m-0 w-100"
              style={{ borderRadius: "15px" }}
            >
              <div className="profile-chatbottom">
                <img
                  src={props.creatorImg ? props.creatorImg : profile_image_placeholder}
                  className="profile-bottom"
                ></img>
              </div>
              <input
                className="input-message w-100 border-0 comment-input pb-1"
                type="text"
                ref={sendInputRef}
                placeholder={reply == 0 ? "Type a Comment" : "Type a Reply"}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
              <img src={send} onClick={sendMessage}></img>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default SeriesChat;
