import React, { useState } from 'react'
import { bites1, dashBackground1, playLight, playLightBtn, playLightbtn } from '../../Constants/images'
import ROUTES from '../../Helper/route-helper'
import { useLocation, useNavigate } from 'react-router-dom'
import SeriesVideoPlayer from '../../Components/VideoPlayer/seriesVideoPlayer'

const EpisodeList = () => {
    const navigate = useNavigate();
    const location = useLocation()
    const [video, setVideo] = useState('')
    const [show, setShow] = useState(false)

    const handleClick = (item) => {
        setVideo(item?.video)
        setShow(!show)
    }
    function secondsToHms(d) {
        d = Number(d);
        var h = Math.floor(d / 3600);
        var m = Math.floor(d % 3600 / 60);
        var s = Math.floor(d % 3600 % 60);

        var hDisplay = h > 0 ? h + ":" : "";
        var mDisplay = m > 0 ? m : "00";
        var sDisplay = s > 0 ? s : "00";


        return hDisplay + mDisplay + ":" + sDisplay;
    }

    return (
        <div className="main-inner-content">
            <section className="episode-list-main bite response-padding ">
                <div className="container">
                    <h2>{location.state?.episodes?.title}</h2>
                    <h3 className="fw-600 mt-4">All Episodes</h3>
                    <div className="chanel-wrapper mt-4">
                        <div className="row gy-4">
                            {
                                location.state?.episodes?.episodes?.map((item, index) => {
                                    return (
                                        <div key={item?.id} className="col-xl-4 col-xxl-4  col-lg-4  col-md-6 response-col">
                                            <div className="chanel-content bg-white episodelist-box">
                                                <div className='position-relative'>
                                                    <div style={{ cursor: "pointer" }} className="bite-relative ">
                                                        <img src={item?.thumbnail} className="bite-img" />
                                                    </div>
                                                    <div className='btn-play-icon'>
                                                        <img style={{ cursor: "pointer" }} src={playLightBtn} className="" />
                                                    </div>
                                                </div>
                                                <div className="bite-wrapper">
                                                    <h4>{item?.title}</h4>
                                                    <div className="chanel-text d-flex gap-3 align-items-center">
                                                        {/* <img src={location.state?.episodes?.channel?.logo_url} className="bite-inner-img" /> */}
                                                        <div>
                                                            <h5 className='fw-500 video-text '>{location.state?.episodes?.channel?.name}</h5>
                                                            {/* <p>{location.state?.episodes?.channel?.category?.name}</p> */}
                                                            <p className='fw-400 channel-text'>{secondsToHms(item?.duration)}</p>
                                                        </div>
                                                    </div>
                                                    <button
                                                        onClick={() => handleClick(item)} className="common-btn"><img src={playLight} />Play</button>
                                                </div>
                                            </div>
                                        </div>
                                    )

                                })
                            }

                        </div>
                    </div>
                </div>
            </section>

            {
                show && <SeriesVideoPlayer show={show} setShow={setShow} video_url={video} />
            }


        </div>

    )
}

export default EpisodeList