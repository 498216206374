import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { biteImg, bitesDark, challenge1, challengeBack, favRed, favouriteLight, favouritesDark, noFavourites, playLight, playLightBtn, videoPlayButton } from '../../Constants/images';
import ROUTES from '../../Helper/route-helper';
import './createrSubscriber.css'

function CreaterSubscribers() {
  const navigate = useNavigate()

  return (
    <div>
      <div className="main-inner-content">
        <section className="favourite-cus bite response-padding ">
          <div className="container">
            <div className="chanel-header d-flex align-items-center justify-content-between">
              <h3 className="fw-600 mb-0">Followers</h3>
              <div className="col-lg-4 ">
                <form>
                  <div className="form-group search-filter-category ms-0 ms-lg-0 position-relative">
                  </div>
                </form>
              </div>
            </div>
            <section className="py-60  favchannel-main-cus">
              <div className="container-fluid">
                <div className="row">

                  <div className="col-12 col-sm-6 col-lg-6 col-xl-3 mb-4 position-relative">
                    <div className="channels-box  mb-3 mb-lg-3 mb-xl-0" style={{ cursor: 'pointer' }}>
                      <img src={noFavourites} className="img-fluid  subscribe-img" />
                      <h4 className="channel-text fw-600 mt-3 mb-0">John Smith</h4>
                      <p className="channel-para mb-2"><i>Get Access with Combo</i></p>
                      <h5 className='price-subs mb-3'>$150.<span className='f17-size'>00</span></h5>
                      <span className='subs-date mb-0 f18-size fw-500 channel-text'>Expiry date :<span className='video-text'> 31-10-2023</span></span>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>
      </div>
    </div>
  )
}

export default CreaterSubscribers;