import React from 'react'

const VideoPlayModal = ({ setVideoPlay, url }) => {

    const handleClick = () => {
        setVideoPlay(false)
    }

    return (
        <div className=" modal-cus modalvideoplayer" id="videoplayer">
            <div className="container-fluid">
                <a onClick={() => handleClick()} style={{ cursor: "pointer" }} className="m-0 px-0 video-back-btn">
                    <i className=" fa fa-chevron-left video-back-control" />
                </a>
                <div className="custom-videoplayer position-relative p-0">
                    <video playsInline={true} autoPlay={true} src={url} controls></video>
                </div>
            </div>
        </div>
    )
}

export default VideoPlayModal