import React, { useState, useEffect } from 'react'

import ReactLoader from '../../ControlComponents/react-loader';
import { noBites, playLight } from '../../Constants/images';
import BiteModal from '../../Components/Bites';


function BitesChannel({ loading, detailList }) {
   const [bitesList, setBitesList] = useState([])
   const [currentBiteIndex, setCurrentBiteIndex] = useState(0);

   //    const [bitesList2, setBitesList2] = useState([])
   const [showBite, setShowBite] = useState(false);
   // const [isLoading, setIsLoading] = useState(false);
   //    const [refresh, setRefresh] = useState(false);
   const [index, setIndex] = useState(0)
   //    const [offset, setOffset] = useState(0);
   //    const [limit, setLimit] = useState(4);
   
   const handleBitePlay = (index) => {
      setShowBite(!showBite);
      setCurrentBiteIndex(index);
   }

   return (
      <>
         <div className="chanel-wrapper ">
            <div className="row gy-2" style={{ cursor: "pointer" }}>
               {detailList?.map((bite, index) =>
                  <div
                     onClick={() => handleBitePlay(index)} key={bite?.id}
                     className="col-xl-4 col-xxl-3 col-lg-4 col-md-6  response-col">
                     <div className="channel-bites-content">
                        <img src={bite.thumbnail} className="channelbite-img" alt="" />
                        <div className="bite-wrapper">
                           <h4>{bite?.title}</h4>
                           <div className="chanel-text d-flex gap-3 align-items-center">
                              <img src={bite?.biteChannel?.profile_pic} className="bite-inner-img" alt="" />
                              <div>
                                 <h6 className='fw-600 mb-0'>{bite?.biteChannel?.name}</h6>
                                 <p className='mt-1'>{bite.category?.name}</p>
                              </div>
                           </div>
                           <button
                              onClick={() => handleBitePlay(index)}
                              className="common-btn"><img src={playLight} alt="" />Play</button>
                        </div>
                     </div>
                  </div>
               )}
            </div>
         </div>
         {
            showBite &&
            <BiteModal currentBiteIndex={currentBiteIndex} setCurrentBiteIndex={setCurrentBiteIndex} index={index} bitesList={detailList} showBite={showBite} setShowBite={setShowBite} check="channel" />
         }


         {
            !loading &&
            <>
               {
                  detailList?.length == 0 &&
                  <div className='no-search-results'>
                     <img src={noBites} alt='No Bites Found' />
                  </div>
               }
            </>
         }
      </>

   )
}

export default BitesChannel;