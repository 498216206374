import React from 'react'
import { section4 } from '../../../../Constants/images'
import './Community.css'

const Community = () => {
  return (
    <>
      <section className="join-comunity">
        <div className="container">
          <div className="comunnity-wraper py-60">
            <div className="row d-flex flex-wrap align-items-center">
              <div className="col-lg-6 ps-5">
                <div className="join-text ps-3">
                  <h2>Welcome to our Movies and Episodes app/website</h2>
                  <p className="py-3">
                    Join our community of movie and TV lovers and embark on a
                    limitless entertainment adventure. Download our app or visit
                    our website to unlock a world of captivating movies and
                    episodes at your fingertips. Get ready to lose yourself in
                    mesmerizing stories and unforgettable characters.
                  </p>
                  <div className="d-flex gap-3 join-btn flex-wrap">
                    <a href="" className="common-btn">Join Play Human</a>
                    <a href="" className="common-btn btn-blue">Read More</a>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 px-5 py-2">
                <img src={section4} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Community