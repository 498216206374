import React, { useEffect, useState } from 'react';
import apiHelper from '../../Helper/api-helper';
import DEVELOPMENT_CONFIG from '../../Helper/config';
import './createrChallenges.css';
import ReactLoader from '../../ControlComponents/react-loader'
import MyChallenges from './myChallenges';
import { DEMO_IMAGE_TWO, filter_icon, noChallenges } from '../../Constants/images';
import ChallengeModal from './createChallengeModal';
import FilterModal from './filterModal';
import ProgressBarComponent from '../../ControlComponents/progressBar';
import { NotificationManager } from 'react-notifications';

const CreaterChallenges = () => {
    const [tabSwitch, setTabSwitch] = useState();
    const [catList, setCatList] = useState([])
    const [check, setCheck] = useState(false)
    const [filterList, setFilterList] = useState([])
    const [progressLoading, setProgressLoading] = useState(false)
    const [progress, setProgress] = useState(0);
    const [challenges, setChallenges] = useState([])
    const [refresh, setRefresh] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [challengeModal, setChallengeModal] = useState(false)
    const [filterModal, setFilterModal] = useState(false)
    const [tagsSelected, setTagsSelected] = useState([]);
    const handleOpen = () => {
        setChallengeModal(!challengeModal)
    }

    const handleOpenFilter = () => {
        setFilterModal(!filterModal)
        setTagsSelected([])
    }

    let message = localStorage.getItem("message")
    useEffect(() => {
        if (message) {
            NotificationManager.success(message);
            localStorage.removeItem("message")
        }
        return () => {
            message = null
        };
    }, []);


    async function getDetailsAPI() {
        setIsLoading(true)
        let result = await apiHelper.getRequest("creator/my-challenges")
        if (result.code == DEVELOPMENT_CONFIG.statusCode) {
            setChallenges(result?.data?.allchallenge);
            let response = await apiHelper.getRequest("creator/challenge-category")
            setCatList(response.data)
            setFilterList([])
            setCheck(false)
            setIsLoading(false)
            setTabSwitch()
        }
        else if (result.code == 401) {
            let refreshToken = localStorage.getItem("refresh-token")
            let data = {
                "refresh_token": refreshToken,
                "scope": "refresh_token"
            }
            let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
            if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
                localStorage.setItem("token", refreshTokenResult.data.token)
                localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
                setRefresh(true)
            }
        }
    }

    async function getFilterAPI() {
        setIsLoading(true)
        let result = await apiHelper.getRequest(`creator/my-challenges?sort_by=${tabSwitch == 0 ? 1 : 2}&category_id=${tagsSelected}`)
        if (result.code == DEVELOPMENT_CONFIG.statusCode) {
            setFilterList(result.data.allchallenge);
            setIsLoading(false)
            setFilterModal(false)
            setCheck(true)
        }
        else if (result.code == 401) {
            let refreshToken = localStorage.getItem("refresh-token")
            let data = {
                "refresh_token": refreshToken,
                "scope": "refresh_token"
            }
            let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
            if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
                localStorage.setItem("token", refreshTokenResult.data.token)
                localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
                setRefresh(true)
            }
        }
    }

    useEffect(() => {
        getDetailsAPI()
    }, [refresh])

    useEffect(() => {
        getDetailsAPI()
    }, [])

    return (
        <div>
            {isLoading && <ReactLoader />}
            {progressLoading && <>
                <ProgressBarComponent progress={progress} />
            </>}
            <div className="main-inner-content">
                <div className='row align-items-center'>
                    <div className='col-12 col-sm-5'>
                        <div className="">
                            <h3 className="fw-600">Challenges</h3>
                        </div>
                    </div>
                    <div className='col-12 col-sm-7 ms-auto challenge-create text-end d-flex justify-content-end live-start-btn'>
                        <button className="common-btn" style={{ cursor: "pointer" }} onClick={handleOpen} >Create Challenge</button>
                        {
                            check == true ? <button className="common-btn ms-4 btn-blue" style={{ cursor: "pointer" }} onClick={getDetailsAPI}>Reset filter</button> : <button className="common-btn ms-4 btn-blue" style={{ cursor: "pointer" }} onClick={handleOpenFilter}><img src={filter_icon} /> filter</button>
                        }
    
                    </div>
                   </div>
                <MyChallenges challenges={challenges} filterList={filterList} tabSwitch={tabSwitch} tagsSelected={tagsSelected} setProgressLoading={setProgressLoading} setIsLoading={setIsLoading} setRefresh={setRefresh} setProgress={setProgress} getDetailsAPI={getDetailsAPI} check={check} />

                {(filterList?.length === 0 && isLoading == false && tagsSelected?.length > 0 && check == true) && (
                    <>
                        <div className="no-search-results ">
                            <img src={DEMO_IMAGE_TWO} alt="No Challenges" />

                        </div>
                        <h6 className='text-center my-2' style={{ bottom: 0 }}>No challenges yet</h6>
                    </>
                )}

                {(challenges?.length === 0 && isLoading == false) && (
                    <>
                        <div className="no-search-results">
                            <img src={DEMO_IMAGE_TWO} alt="No Challenges" />
                        </div>
                        <h6 className='text-center' style={{ bottom: 0 }}>No challenges yet</h6>
                    </>

                )}

                {challengeModal && <ChallengeModal getListAPI={getDetailsAPI} setIsLoading={setIsLoading} setChallengeModal={setChallengeModal} setProgressLoading={setProgressLoading} setProgress={setProgress} />}

                {filterModal && <FilterModal tagsSelected={tagsSelected} setTagsSelected={setTagsSelected} catList={catList} getFilterAPI={getFilterAPI} setFilterModal={setFilterModal} setTabSwitch={setTabSwitch} tabSwitch={tabSwitch} />}

            </div>
        </div>
    )
}

export default CreaterChallenges;