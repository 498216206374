import React, { useState } from 'react'
import { gallery, modalCross, plusgray, videoPlayButton } from '../../Constants/images'


const AddTrailor = ({ setAddTrailorModal, selectedOption, handleOptionChange, fileChangeTrailer, thumbnail, handleEditAPI, fileChangeClip,errors,setErrors
}) => {


    return (
        <div className="modal fade show modal-react" style={{ display: "block", opacity: "1", backdropFilter: "blur(5px)" }}>
            <div className='loader'>
            </div>
            <div className="modal-dialog modal-dialog-centered modal-schedule-width">
                <div className="modal-content modal-radius  border-0">
                    <div className="modal-header forgot-header align-items-center pt-4">
                        <h3 className="modal-title text-center mx-auto forgot-title" id="exampleModalLabel">Add Trailor & Clip</h3>
                        <a onClick={() => {
                            setAddTrailorModal(false)
                            setErrors("")
                        }}
                            className="m-0 px-0 btn-closed-main" data-bs-dismiss="modal" aria-label="Close"><img style={{ cursor: "pointer" }} src={modalCross} alt="" className="img-fluid btn-cloesed" /></a>
                    </div>
                    <div className="modal-body  verify-modal-box px-2 px-sm-5">
                        <form >
                            <div className='creat-label pb-3'>
                                <label>
                                    <input
                                        type="radio"
                                        value="1"
                                        checked={selectedOption === 1}
                                        onChange={handleOptionChange}
                                        className='radio-login'
                                    />
                                    <span>Trailor</span>
                                </label>
                                <label className='ms-3'>
                                    <input
                                        type="radio"
                                        value="2"
                                        checked={selectedOption === 2}
                                        onChange={handleOptionChange}
                                        className='radio-login'
                                    />
                                    <span>Clip</span>
                                </label>
                            </div>
                            <div className='mt-3'>
                                <label className='fw-500 f18-size text-black pb-2'> Video</label>
                                <div className="mb-3 schedule-thumb">
                                    <div className="video-upload video-schedule position-relative">
                                        {
                                            thumbnail &&
                                            <div className={thumbnail ? " episode-preview" : ""}><img src={thumbnail ? thumbnail : ""}></img>
                                                <div class="play-bite"><img src={videoPlayButton} class="img-fluid img-bite" />
                                                </div>
                                            </div>

                                        }
                                        {
                                            thumbnail ? "" : <>
                                                <div className='d-flex m-auto justify-content-center align-items-center h-100 episode-preview-after'>

                                                    <div className=''>
                                                        <div className='text-center'>
                                                            <img src={gallery}></img>
                                                        </div>

                                                        <div className="d-flex m-auto justify-content-center align-items-center h-100">
                                                            <img src={plusgray} alt="" className="img-fluid me-1" />
                                                            <span className="Upload-text fw-500 create-text border-bottom ">Add Video</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                        <input type="file" className="video-file-schedule" onChange={selectedOption == 1 ? fileChangeTrailer : fileChangeClip} accept=".mp4, .mov, .avi" style={{ cursor: "pointer" }}
                                        />
                                        {errors.thumbnail && <span className="err_msg">{errors.thumbnail}</span>}
                                    </div>
                                </div>
                            </div>
                            <div className=" my-5"
                                onClick={handleEditAPI}
                            >
                                <input type='submit' value="Save" className="common-btn w-100" />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default AddTrailor
