import React, { useEffect, useState } from 'react'
import apiHelper from '../../../../Helper/api-helper';
import DEVELOPMENT_CONFIG from '../../../../Helper/config'
import ROUTES from '../../../../Helper/route-helper';
import { useNavigate } from 'react-router-dom';
import './category.css';


function Category() {

  const [catList, setCatList] = useState([])
  const navigate = useNavigate()
  async function getAPI() {
    let result = await apiHelper.getRequest("auth/category-list")
    if (result.code === DEVELOPMENT_CONFIG.statusCode) {
      return setCatList(result.data)
    }
  }

  useEffect(() => {
    getAPI()
  }, []);
  
  return (
    <div>
      <section className="category py-6 response-padding category-dis-cus">
        <div className="container">
          <div className="chanel-header">
            <h3 className="fw-600 mb-0">Category</h3>
          </div>
          <div className="row mt-4" style={{cursor:'pointer'}}>

            {catList.map((category) =>
              <div key={category?.id} className="category-box col-12 col-md-4 col-lg-3 mb-3  "
               onClick={() => navigate(ROUTES.CATEGORY, { state: { id: category?.id, catName: category?.name } })}
               >
                <img key={category.id} src={category.icon} alt="" />
                <h4 className="f24-size m-0">{category.name}</h4>
              </div>
            )}
          </div>
        </div>
      </section>
    </div>
  )
}

export default Category;