import React from 'react'
import { profile_image_placeholder } from '../../../../Constants/images';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../../../Helper/route-helper';

function Recomended(props) {
  const navigate = useNavigate()

  return (
    <div>
      <section className="recoomended-main-cus recomended response-padding mt-5">
        <div className="container">
          <div className="chanel-header">
            <h3 className="fw-600 mb-0">Recomended</h3>
          </div>
   
          <div className="chanel-wrapper mt-4" style={{ cursor: 'pointer' }}>
            <div className="row one-row-scroll">
              {
                props?.featuredList?.data?.map((featured) => {
                  return (
                    <div key={featured?.id} className=" col-12 col-md-6 col-xl-4  col-xxl-3 response-col ">
                      <div className="chanel-content dis-recommend-cus">
                        <div className="bite-relative">
                          <img  onClick={() => navigate(ROUTES.DISCOVER_USER_SERIES_DETAIL, { state: { id: featured?.id } })}
                            src={featured?.thumbnail}
                            className="bite-img"
                            alt=""
                          />
                        </div>
                        <div className="bite-wrapper"  onClick={() => navigate(ROUTES.DISCOVER_USER_SERIES_DETAIL, { state: { id: featured?.id } })}>
                          <h4 >{featured?.title}</h4>
                          <div className="chanel-text d-flex gap-3 align-items-center p-height">
                            {/* <img
                              src={featured?.channel?.logo_url}
                              className="bite-inner-img"
                              alt=""
                            /> */}
                            <div>
                              <h5>{featured?.channelDetail?.name}</h5>
                              <p>{featured?.category?.name}</p>
                              <p>
                                {featured?.episodes_count} {featured.episodes_count == 1 ? "Episode" : "Epsiodes"}</p>
                            </div>
                          </div>
                         
                        </div>
                        <div className='bites-recoomend-bottom'>
                          <img
                            src={featured?.channelDetail?.profile_pic ? featured?.channelDetail?.profile_pic : profile_image_placeholder}
                            className="bite-inner-img"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  )

                })
              }

            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Recomended;