import React from 'react'
import Header1 from './../../Components/Header1/Header1'
import PopularTestimonial from '../../Components/PopularTestimonial/PopularTestimonial'
import Footer from './../../Components/Footer/Footer'
import { aboutUsBackground } from '../../Constants/images'
import BannerEntertainment from './Components/BannerEntertainment/BannerEntertainment'
import './aboutUs.css'
const AboutUs = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div style={{backgroundImage:`url(${aboutUsBackground})`}} className='about-us-back'>
    <Header1/>
    <BannerEntertainment/>
    <PopularTestimonial/>
    <Footer/>
    </div>
  )
}

export default AboutUs