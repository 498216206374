import React, { useEffect, useRef, useState } from 'react'
import { graycalender, graytimer, profile_image_placeholder, send, videoPlayButton } from '../../../../Constants/images';
import { collection, addDoc, query, orderBy, onSnapshot } from "firebase/firestore";
import { db } from '../../../../firebase';
import ROUTES from '../../../../Helper/route-helper';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import MultiVideoPlayer from '../../../../CreaterDashboard/component/multiVideoPlayer';
import axios from 'axios';
import DEVELOPMENT_CONFIG from "../../../../Helper/config"
import ReactLoader from '../../../../ControlComponents/react-loader';
import ChallengePlayer from '../../../../CreaterDashboard/component/challengePlayer';
function VideoChallengeUser(props) {
    const [isLoading, setIsLoading] = useState(false)
    const [show, setShow] = useState(false)
    const [show1, setShow1] = useState(false)
    const [videoId, setVideoId] = useState()
    const [embededVedio, setEmbededVedio] = useState(false);
    const [expandedComments, setExpandedComments] = useState([]);
    const [currentVideoIndex, setCurrentVideoIndex] = useState(0);

    const handleClick = (item, index) => {
        setVideoId(item?.video_id)
        setShow(!show)
        if (item.video_type == 2) {
            setEmbededVedio(true);
        }
        setCurrentVideoIndex(index)
    }

    const handleClick1 = (item, index) => {
        setVideoId(item?.video_id)
        setShow1(!show1)
        if (item.video_type == 2) {
            setEmbededVedio(true);
        }
        setCurrentVideoIndex(index)
    }

    const [videoUrl, setVideoUrl] = useState('');
    const [message, setMessage] = useState("");
    const [messages, setMessages] = useState([]);
    const messageListRef = useRef();
    const navigate = useNavigate()
    const challenegeData = props.data
    const chatId = localStorage.getItem("chatId") ? localStorage.getItem("chatId") : "1"

    const sendMessage = async (e) => {
        e.preventDefault();
        const commentUid = uuidv4();
        if (message != "") {
            try {
                await addDoc(collection(db, "ChallengesCollection", chatId, 'Comments'), {
                    name: props.creatorName,
                    comment: message,
                    createdAt: new Date().toISOString(),
                    profile_pic: props.creatorImg,
                    uuid: commentUid,
                });
                setMessage("")
            } catch (e) {
                console.error("Error adding message: ", e);
            }
        }
    }

    useEffect(() => {
        const q = query(collection(db, "ChallengesCollection", chatId, "Comments"), orderBy("createdAt", "asc"));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const fetchedMessages = querySnapshot.docs.map((doc) => ({
                id: doc?.id,
                ...doc.data()
            }));
            setMessages(fetchedMessages);
        });
        return () => unsubscribe();
    }, [props.chatOpen])

    useEffect(() => {
        const fetchVideoUrl = async () => {
            if (props.seriesDetail && props.seriesDetail.creatorVideo && props.seriesDetail.creatorVideo.video_url && props.seriesDetail.creatorVideo.video_type == 1) {
                const url = props.seriesDetail.creatorVideo.video_url;
                setVideoUrl(url);
            }
            else if (props.seriesDetail && props.seriesDetail.creatorVideo && props.seriesDetail.creatorVideo.video_url && props.seriesDetail.creatorVideo.video_type == 2) {
                getYouTubeUrl(props.seriesDetail.creatorVideo.video_id)
            }
            else if (props.seriesDetail && props.seriesDetail.creatorVideo && props.seriesDetail.creatorVideo.video_url && props.seriesDetail.creatorVideo.video_type == 3) {
                const uploadUrl = props.seriesDetail.creatorVideo.video_url;
                setVideoUrl(uploadUrl);
            }
        };
        fetchVideoUrl();
    }, [props.seriesDetail])

    async function getYouTubeUrl(id) {
        let token = localStorage.getItem("token")
        setIsLoading(true)
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${DEVELOPMENT_CONFIG.base_url}auth/generate-url?url=${id}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };

        axios.request(config)
            .then((response) => {
                setIsLoading(false)
                setVideoUrl(response.data.generatedUrl)
            })
            .catch((error) => {
                console.log(error);
            });
    }
    useEffect(() => {
        scrollToBottom()
    }, [messages]);


    const scrollToBottom = () => {
        if (messageListRef.current) {
            const { scrollHeight, clientHeight } = messageListRef.current;
            messageListRef.current.scrollTop = scrollHeight - clientHeight
        }
    }

    const formatDate = (date) => {
        let createdAtDate = new Date(date);
        const formattedDate = `${createdAtDate.getDate().toString().padStart(2, '0')}-${(createdAtDate.getMonth() + 1).toString().padStart(2, '0')}-${createdAtDate.getFullYear()}`;
        return formattedDate;
    };

    const formatTime = (date) => {
        let createdAtDate = new Date(date);
        const formattedTime = createdAtDate.toLocaleTimeString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
        });
        return formattedTime
    };

    function modifyDuration(duration) {
        const durationParts = duration?.split(':');
        if (durationParts && durationParts[0] === '00') {
            return durationParts.slice(1)?.join(':');
        }
        return duration;
    }

    function getTimeAgo(createdAt) {
        const currentDate = new Date();
        const createdAtDate = new Date(createdAt);
        const timeDifference = currentDate.getTime() - createdAtDate.getTime();
        const seconds = Math.floor(timeDifference / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        if (days > 0) {
            return days + (days === 0 || days === 1 ? " day ago" : " days ago");
        } else if (hours > 0) {
            return hours + (hours === 0 || hours === 1 ? " hour ago" : " hours ago");
        } else if (minutes > 0) {
            return minutes + (minutes === 0 || minutes === 1 ? " min ago" : " mins ago");
        } else {
            return (seconds >= 0 ? seconds : 0) + " sec ago";
        }
    }

    const renderCommentText = (text, uuid) => {
        const isExpanded = expandedComments.includes(uuid);

        if (text.length > 200 && !isExpanded) {
            return (
                <>
                    {`${text.substring(0, 200)}...`}
                    <span className="read-more" style={{ cursor: "pointer" }} onClick={() => handleExpandComment(uuid)}> Read More</span>
                </>
            );
        } else if (isExpanded) {
            return (
                <>
                    {text}
                    <span className="read-more" style={{ cursor: "pointer" }} onClick={() => handleCollapseComment(uuid)}> ...Read Less</span>
                </>
            );
        } else {
            return text;
        }
    };

    const handleExpandComment = (uuid) => {
        setExpandedComments(prevState => [...prevState, uuid]);
    };

    const handleCollapseComment = (uuid) => {
        setExpandedComments(prevState => prevState.filter(item => item !== uuid));
    };

    return (
        <div>
            {
                isLoading && <ReactLoader />
            }
            <section className="frame-video-banner frame-video-main">
                <div className="container-fluid">
                    <section className="frame-video-banner frame-video-main">
                        <div className="container-fluid container-lg">
                            <div className='row'>
                                <div className='col-lg-8 order-one'>
                                    <ChallengePlayer videoUrl={videoUrl} />
                                    <div className="movie-wrapper">
                                        <div className='main' >
                                            <div className="row d-flex flex-wrap gap-3">
                                                <div className="col-lg-2 bgwhite">
                                                    <div className="d-flex align-items-center gap-4">
                                                        <div className="mvie-title">
                                                            <img src={props?.seriesDetail?.channelData?.profile_pic ? props?.seriesDetail?.channelData?.profile_pic : profile_image_placeholder} className="py-2 px-2 mv-title" alt="" />
                                                        </div>
                                                        <h4 className="m-0">{props?.seriesDetail?.channelData?.name}</h4>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2 bgwhite">
                                                    <div className="d-flex align-items-center gap-4">
                                                        <div className="mvie-title">
                                                            <img
                                                                src={props?.seriesDetail?.category?.icon}
                                                                className="py-2 px-2 mv-title"
                                                                alt=""
                                                            />
                                                        </div>
                                                        <h4 className="m-0">{props?.seriesDetail?.category?.name}</h4>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-4 mt-4 mt-lg-0 order-three'>
                                    <div className='challenge-chat cus-series-chat new-cus-series-chat'>
                                        <div className='chat-right cus-chat-right position-relative '>
                                            <div className='rounded-0 btn-blue px-4 '>
                                                <h4 className='mb-0 text-white fw-600'>Comments</h4>
                                            </div>
                                            <div className='chat-message new-chat-msg'  >
                                                <div ref={messageListRef} className='get-msg cus-get-msg' style={{ position: 'absolute', width: '90%' }}>
                                                    {
                                                        messages.map((msg, index) => (
                                                            <div className='chat-wrapper border-bottom ' key={index}>
                                                                <div className='chat-listing py-3'>
                                                                    <div className='profile-chat'>
                                                                        <img src={msg.profile_pic ? msg.profile_pic : profile_image_placeholder}></img>
                                                                    </div>
                                                                    <div className='d-flex align-items-start'>
                                                                        <p className='mb-0 lh-sm f16-size fw-500  text-capitalize side_text user-sidetext'>{msg.name}:</p>
                                                                        <p className=' mb-0 channel-text f16-size fw-600 user-side-comment'>
                                                                            {renderCommentText(msg.comment, msg.uuid)}                                                                            </p>
                                                                    </div>

                                                                    <p className='user-side-day'>{getTimeAgo(msg.createdAt)}</p>

                                                                    {/* <div className=''>
                                                                        <img src={dotshorizontal}></img>
                                                                    </div> */}
                                                                </div>
                                                                {/* <p className='mb-0 f14-size fw-400 lh-base para-chat'>message</p> */}
                                                            </div>
                                                        ))
                                                    }
                                                </div>

                                                <form onSubmit={sendMessage}>
                                                    <div className='chat-bottom drop-bottom cus-chat-bottom'>
                                                        <div className='profile-chatbottom'>
                                                            <img src={props.creatorImg ? props.creatorImg : profile_image_placeholder} className='profile-bottom'></img>
                                                        </div>
                                                        <input
                                                            id="input-message"
                                                            className="input-message w-100 border-0"
                                                            type="text"
                                                            placeholder="Type a Comment"
                                                            value={message}
                                                            onChange={(e) => setMessage(e.target.value)}
                                                        />
                                                        <img src={send}
                                                            onClick={sendMessage}
                                                        ></img>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-8 order-two">
                                    <section className="movie-detail py-60">
                                        <div className="container-fluid">
                                            <h2 className='fw-600'>{props.seriesDetail?.title}</h2>
                                        </div>
                                    </section>
                                    <section className="plot-summary">
                                        <div className="container-fluid">
                                            <h3 className="fw-600">Plot Summary</h3>
                                            <p className="mt-2">
                                                {props?.seriesDetail?.description}
                                            </p>
                                        </div>
                                    </section>
                                    <section className='bottom-button'>
                                        <div className='container-fluid'>
                                            <div className='bottom-button-wrapper d-flex gap-3'>
                                                {/* {
                                                    props?.responses?.length >= 1 ? */}
                                                     {/* <button disabled style={{ cursor: "not-allowed" }} className='common-btn-grey'
                                                    >Create</button>
                                                        : */}
                                                         <button className='common-btn custom-common-btn'
                                                            onClick={() => { navigate(ROUTES.USER_CHALLENGE_CREATE, { state: { challenegeData: props?.seriesDetail } }) }} >Create</button>
                                                {/* } */}
                                            </div>
                                        </div>
                                    </section>
                                    <section className="schedule-wrapper">
                                        {/* <div className="container-fluid"> */}
                                        {/* episode */}
                                        <div className='row mt-4'>
                                            <div class="col-12">
                                                {
                                                    props.episodes?.length > 0 && <h3 className='fw-600 mb-0'>Responses</h3>
                                                }
                                            </div>
                                        </div>

                                        <div className='row mt-4'>
                                            {
                                                props.episodes.map((item, i) => (
                                                    <div className="col-12 col-md-6 col-lg-12 col-xxl-6 pb-4" key={item.id} >
                                                        <div className="latest-video mx-auto challenges-detailcreate">
                                                            <div className='position-relative'>
                                                                <div onClick={() => handleClick1(item, i)}

                                                                    className="latest-video-content  pt-2 pt-sm-3 px-2 px-sm-3 position-relative"
                                                                    style={{ cursor: "pointer" }}>
                                                                    <img
                                                                        src={item.thumbnail}
                                                                        className="img-fluid latest-vid-img"
                                                                    />
                                                                    <div className="play-epi"
                                                                    >
                                                                        <img src={videoPlayButton} className="img-fluid" />
                                                                    </div>

                                                                    <div className=''> <span className="videoepisode">
                                                                        {item.video_type == 1 ? "URL Link" : item.video_type == 2 ? "Embeded" : "video"}
                                                                    </span></div>
                                                                </div>
                                                            </div>
                                                            <div className="latest-video-cus px-3 py-3 challenege-grid-create position-relative">
                                                                <div className='profile-challengecreate'>
                                                                    <img src={item.user.profile_pic ? item.user.profile_pic : profile_image_placeholder}></img>
                                                                </div>
                                                                <div className='text-start my-2 my-sm-0'>
                                                                    <h4 className="schedule-title mb-0 one-line">{item.user.name}</h4>
                                                                    <div className="timing-flex-create mt-2 d-flex gap-2 align-items-center">
                                                                        <div className='mb-0 timing-inner-flex d-flex '>
                                                                            <img src={graycalender} />
                                                                            <span className='ps-1 new-text fw-400 f14-size '>{formatDate(item.created_at)}</span>
                                                                        </div>
                                                                        <div className='mb-0 timing-inner-flex d-flex '>
                                                                            <img src={graytimer} />
                                                                            <span className='ps-1 new-text f11-size fw-500 '>{formatTime(item.created_at)}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='dotschedule'>
                                                                    <p className='mb-0 fw-500 f12-size'>{modifyDuration(item.duration)
                                                                    }</p>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                        {/* challenges response */}
                                        {
                                            props?.responses?.length > 0 && <div className='row'>
                                                <div className="col-12">
                                                    <h3 className='fw-600 mb-0'>My Response</h3>
                                                </div>
                                            </div>
                                        }

                                        <div className='row mt-4'>
                                            {
                                                props?.responses?.map((item, i) => (
                                                    <div className="col-12 col-md-6 col-lg-12 col-xxl-6 pb-4" key={item.id} >
                                                        <div className="latest-video mx-auto challenges-detailcreate">
                                                            <div className='position-relative'>
                                                                <div onClick={() => handleClick(item, i)}

                                                                    className="latest-video-content  pt-2 pt-sm-3 px-2 px-sm-3 position-relative"
                                                                    style={{ cursor: "pointer" }}>
                                                                    <img
                                                                        src={item.thumbnail}
                                                                        className="img-fluid latest-vid-img"
                                                                    />
                                                                    <div className="play-epi"

                                                                    >
                                                                        <img src={videoPlayButton} className="img-fluid" />
                                                                    </div>

                                                                    <div className=''> <span className="videoepisode">
                                                                        {item.video_type == 1 ? "URL Link" : item.video_type == 2 ? "Embeded" : "video"}
                                                                    </span></div>
                                                                </div>
                                                            </div>
                                                            <div className="latest-video-cus px-3 py-3 challenege-grid-create position-relative">
                                                                <div className='profile-challengecreate'>
                                                                    <img src={item.user.profile_pic ? item.user.profile_pic : profile_image_placeholder}></img>
                                                                </div>
                                                                <div className='text-start my-2 my-sm-0'>
                                                                    <h4 className="schedule-title mb-0 one-line">{item.user.name}</h4>
                                                                    <div className="timing-flex-create mt-2 d-flex gap-2 align-items-center">
                                                                        <div className='mb-0 timing-inner-flex d-flex '>
                                                                            <img src={graycalender} />
                                                                            <span className='ps-1 new-text fw-400 f14-size '>{formatDate(item.created_at)}</span>
                                                                        </div>
                                                                        <div className='mb-0 timing-inner-flex d-flex '>
                                                                            <img src={graytimer} />
                                                                            <span className='ps-1 new-text f11-size fw-500 '>{formatTime(item.created_at)}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='dotschedule'>
                                                                    <p className='mb-0 fw-500 f12-size'>{modifyDuration(item.duration)
                                                                    }</p>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                        {
                                            show == true && <MultiVideoPlayer show={show}
                                                setShow={setShow}
                                                video_url={props.responses[currentVideoIndex]?.video_url}
                                                embededVedio={embededVedio}
                                                setEmbededVedio={setEmbededVedio}
                                                currentVideoIndex={currentVideoIndex}
                                                setCurrentVideoIndex={setCurrentVideoIndex}
                                                videoList={props.responses.map(video => video.video_url)}
                                                videoId={videoId}

                                            />
                                        }
                                        {
                                            show1 == true && <MultiVideoPlayer show={show1}
                                                setShow={setShow1}
                                                video_url={props.episodes[currentVideoIndex]?.video_url}
                                                embededVedio={embededVedio}
                                                setEmbededVedio={setEmbededVedio}
                                                currentVideoIndex={currentVideoIndex}
                                                setCurrentVideoIndex={setCurrentVideoIndex}
                                                videoList={props.episodes.map(video => video.video_url)}
                                                videoId={videoId}

                                            />
                                        }
                                    </section>
                                </div>
                            </div>
                        </div>
                    </section>

                </div>
            </section >


        </div >
    )
}

export default VideoChallengeUser;
