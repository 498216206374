const ROUTES = {
    ABOUT_US: "/about-us",
    CONTACT_US: "/contact-us",
    SIGN_IN: "/sign-in",
    SIGN_UP: "/sign-up",
    HOME: "/home",
    LIVES: "/lives",
    RECORDING_LIVES:"/lives/recordings",
    RECORDED_VIDEO: "/recorded-video",
    SCHEDULE_CREATOR:"/lives/creator-schedules",
    LIVE_SECTION: "/lives/creator-live",
    CHANNELS: "/home/all-channels",
    DISCOVER: "/discover",
    USER_SERIES:"/series-list",
    USER_SERIES_DETAIL:"/series-list/series-detail/:seriesId",
    DISCOVER_USER_SERIES_DETAIL:"/discover/series-detail",
    BITES: "/bites",
    BITE_SHARE:"/bites/:id",
    MORE_EPISODE:"/user-shop/detail/series-detail/episodes",
    USER_SOCIAL_FEEDS:"/user-feeds",
    USER_SOCIAL_FEEDS_SHARE:"/user-feeds/:postId",
    USER_SOCIAL_FEEDS_DETAIL:"/user-feeds/detail",
    USER_SHOP:"/user-shop",
    USER_SHOP_DETAIL:"/user-shop/detail",
    USER_RECORDED_VIDEO_SHOP:"/user-shop/detail/recorded-video",
    SHOP_SERIES_DETAIL:"/user-shop/detail/series-detail",
    FAVOURITES: "/favourites",
    FAV_CHANNELS: "/favchannels",   
    CHALLENGES: "/challenges",
    USER_RECORDED_VIDEO: "/lives/recorded-video",
    NEW_TALENT: "/home/new-talent",
    CATEGORY: "/discover/category",
    INTERACTIVES: "/home/interactives",
    CHALLENGES_DETAILS: "/challenges/challenges-details",
    USER_CHALLENGE_CREATE:"/challenges/challenges-details/create-challenge",
    INTERACTIVE_PALY: "/home/interactivePlay",
    CHANNELS_DETAILS: "/home/channel-details",
    EPISODE_LIST: "/home/episode-list",
    EPISODE_DETAIL: "/episode-detail",
    SERIES_DETAIL: "/home/series-detail",
    VIDEO_PLAYER: "/video-player",
    GLOBAL_SEARCH: "/global-search",
    MY_CHALLENGES: "/home/my-challenges",
    MY_CHALLENGES_DETAIL: "/home/my-challenges-detail",
    HELP: "/help",
    DISCLAIMER: "/disclaimer",
    TERM: "/term",
    CREATER: "/creator",
    CREATER_DASHBOARD: "/creator/dashboard",
    CREATER_LIVES: "/creator/lives",
    CREATER_START_LIVE: "/creator/lives/start-live",
    CREATER_SERIES: "/creator/series",
    CREATER_CREATER_SERIES: "/creator/series/create-series",
    CREATER_SERIES_DETAIL: "/creator/series/series-detail",
    CREATER_EDIT_SERIES1: "/creator/series/edit-series",
    CREATER_EDIT_SERIES: "/creator/series/series-detail/edit-series",
    CREATER_CHALLENGES: "/creator/challenges",
    CREATER_CHALLENGES_DETAIL: "/creator/challenges/challenges-detail",
    CREATOR_ALL_RESPONSES:"/creator/challenges/challenges-detail/responses",
    CREATER_BITES: "/creator/bites",
    CREATER_INTERACTIVES: "/creator/interactives",
    CREATER_INTERACTIVE_DETAIL: "/creator/interactives/interactive-detail",
    CREATER_SUBSCRIBERS: "/creator/subscribers",
    CREATER_BUCKETS: "/creator/tiers",
    CREATER_CHOOSE_SERIES: "/creator/buckets/series",
    CREATER_BUCKET_ACCESS: "/creator/buckets/bucket-detail",
    CREATER_ANALYST: "/creator/analyst",
    CREATER_TEAM_MEMBER: "/creator/team-members",
    CREATER_MEMBERSHIP: "/creator/subscription",
    CREATER_TRAILOR_CLIP: "/creator/series/series-detail/trailors-clips",
    CREATER_RECORDED_VIDEO: "/creator/lives/recorded-video",
    CREATER_SHOP_RECORDED_VIDEO: "/shop/recorded-video",
    SOCIAL_FEEDS:"/feeds",
    SOCIAL_FEEDS_DETAIL:"/feeds/detail",
    SHOP:"/shop",
    CREATER_SHOP_SERIES_DETAIL:"/shop/series-detail",
    CHOOSE_SERIES_SHOP:"/shop/choose-series",
    DOWNLOAD:'/downloads',
    DOWNLOAD_DETAIL:'/downloads/detail',
    USER_RECORDED_VIDEO_DOWNLOAD:"/downloads/detail/recorded-video",
    DOWNLOAD_SERIES_DETAIL:"/downloads/detail/series-detail",
    CHOOSE_LIVES_SHOP:"/shop/choose-live",
    CHOOSE_UPCOMING_LIVES:"/shop/choose-upcoming-live",
}
export default ROUTES;