import React, { useState } from 'react'
import { modalCross, noFavourites } from '../../Constants/images'
import EditDetailModal from './editDetailModal'


const MemberModal = ({ setMemberModal }) => {

    const [editModal, setEditModal] = useState(false)
    const handleOpen = () => {
        setEditModal(!editModal)
    }

    return (
        <>
            <div className="modal fade show modal-react" style={{ display: "block", opacity: "1", backdropFilter: "blur(5px)" }}>
                <div className='loader'>
                </div>
                <div className="modal-dialog modal-dialog-centered modal-schedule-width">
                    <div className="modal-content modal-radius  border-0">
                        <div className="modal-header forgot-header align-items-center pt-4">
                            <h3 className="modal-title text-center mx-auto forgot-title" id="exampleModalLabel">Team Member</h3>
                            <a className="m-0 px-0 btn-closed-main" data-bs-dismiss="modal" aria-label="Close"><img onClick={() => setMemberModal(false)} style={{ cursor: "pointer" }} src={modalCross} alt="" className="img-fluid btn-cloesed" /></a>
                        </div>
                        <div className="modal-body  verify-modal-box px-2 px-sm-5">
                            <div className='text-center mx-auto'>
                                <img src={noFavourites} className="img-fluid channel-img" />
                                <h4 className="channel-text fw-600 mt-3 mb-1">John Smith</h4>
                                <p className="channel-para mb-0 team-mail">Smithjohn@gmail.com</p>
                                <a className='fw-500 f20-size text-decoration-underline	' style={{ color: '#CA3392', cursor: "pointer" }} onClick={handleOpen}>Edit Details</a>
                                {/* <h3 className="text-center mx-auto forgot-title mt-2">Give Access</h3> */}
                            </div>
                        </div>
                        <div className="user-access text-center mx-4 my-4">
                            <h2>Give Access</h2>
                            <div className="container my-4">
                                <div className="row">
                                    <div className="col-6 text-start my-2 ">
                                        <h5>All</h5>
                                    </div>
                                    <div className="col-6 text-end ">
                                        <label className="switch">
                                            <input type="checkbox" />
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-6 text-start my-2 ">
                                        <h5>Create Series</h5>
                                    </div>
                                    <div className="col-6 text-end ">
                                        <label className="switch">
                                            <input type="checkbox" />
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-6 text-start my-2">
                                        <h5>Shortlisted episodes</h5>
                                    </div>
                                    <div className="col-6 text-end ">
                                        <label className="switch">
                                            <input type="checkbox" />
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-6 text-start my-2">
                                        <h5>Managed Buckets</h5>
                                    </div>
                                    <div className="col-6 text-end ">
                                        <label className="switch">
                                            <input type="checkbox" />
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-6 text-start my-2">
                                        <h5>Send Gifts</h5>
                                    </div>
                                    <div className="col-6 text-end ">
                                        <label className="switch">
                                            <input type="checkbox" />
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                </div>

                                <div className="py-4">
                                    <button className="btn w-100 text-white p-3">Save</button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                editModal && <EditDetailModal setEditModal={setEditModal} />
            }
        </>
    )
}

export default MemberModal
