import React, { useState } from 'react'
import { buy_btn, no_audio, playBtn, show_audio } from '../../Constants/images'
import PriceTag from '../../CreaterDashboard/component/price-tag'
import AddDetailAudioModal from '../UserShop/detailAudioModal'
import BuyModal from '../UserShop/buyModal'


function AudioChannel({detailList, loading,setLoading,getListAPI}) {
    const [deltId, setDeltId] = useState(null)
    const [addDetailAudioModal, setAddDetailAudioModal] = useState(false)
    const [buyModal, setBuyModal] = useState(false)
    const [item, setItem] = useState({})

    const handleOpen = (item) => {
        setItem(item)
        setBuyModal(true)
    }

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                 
                    <div className='row mt-4'>
                        {detailList?.length > 0 ? (
                            <>
                                { (
                                    detailList.length > 0 && detailList.map(item => (
                                        <div className="col-12 col-sm-6 col-lg-4 col-xl-4 col-xxl-3 pb-4" key={item?.id}>
                                            <div className="latest-video mx-auto">
                                                <div className='position-relative'>
                                                    <div className="latest-video-content position-relative pt-2 pt-sm-3 px-2 px-sm-3" style={{ cursor: "pointer" }}>
                                                        <div className='show-audio'>
                                                            <img src={show_audio} alt="audio icon" />
                                                        </div>
                                                        {item.is_purchase == 0 && <PriceTag classes={"price_tag"} firstValue={`$${234}`} secondValue={"00"} />}
                                                      
                                                    </div>
                                                    <div className="px-3 py-3 schedule-grid">
                                                        <div className=''>
                                                            <div className="tooltip-container">
                                                                <h4 className="schedule-title">{item.title?.length > 25 ? item.title.substring(0, 25) + "..." : item.title}</h4>
                                                                <span className="tooltip-text">{item.title}</span>
                                                            </div>                                                            <h6 className="images-count">{item?.shop_media?.length} {item?.shop_media?.length === 1 ? "Audio" : "Audios"}</h6>
                                                            <p className="images-description">{item.description}</p>
                                                            {item.is_purchase == 0 ? (
                                                                <button style={{ cursor: 'pointer', backgroundColor:'transparent' }} className="common-btn watch-btn buy-btn-shop px-0"

                                                                onClick={() => handleOpen(item)}
                                                                >
                                                                    <img src={buy_btn} alt='buy-btn' style={{ width: '50px', height: '50px' }} />                                                                </button>
                                                            ) : (


                                                                <span style={{ cursor: "pointer" }} 
                                                                onClick={() => {
                                                                    setDeltId(item.id)
                                                                    setAddDetailAudioModal(true)
                                                                }} 
                                                                className="text-end">
                                                                    <img src={playBtn} alt='buy-btn' style={{ width: '50px', height: '50px' }} />                                                            </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                )}
                            </>
                        ) : (
                            <>
                                {loading == false && (
                                    <div style={{ flexDirection: 'column' }} className='no-search-results position-relative'>
                                        <img src={no_audio} alt="no audio" />
                                        <h6 className='position-absolute' style={{ bottom: 0 }}>No Audios yet</h6>
                                    </div>
                                )}
                            </>
                        )}
                    </div>

                </div>
                 {
                    addDetailAudioModal && <AddDetailAudioModal setAddDetailAudioModal={setAddDetailAudioModal} deltId={deltId} />
                }
                {
                    buyModal && <BuyModal item={item} setLoading={setLoading} setBuyModal={setBuyModal} check="audios" getListAPI={getListAPI} />
                } 

            </div>
        </>
    )
}

export default AudioChannel