import React, { useEffect, useState } from 'react'
import apiHelper from '../../../Helper/api-helper'
import DEVELOPMENT_CONFIG from '../../../Helper/config'
import ReactLoader from '../../../ControlComponents/react-loader'
import Recomended from './Recomended/Recomended'
import Featured from '../../../Components/Featured/Featured'
import SeriesList from './seriesList'
import { DEMO_IMAGE_FOUR } from '../../../Constants/images'
import FeatureSlider from './featureSlider'

function UserSeries() {
  const [featuredList, setFeaturedList] = useState([])
  const [seriesList, setSeriesList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [page, setPage] = useState(1);
  const [scroll, setScroll] = useState(false)
  const [hasMoreItems, setHasMoreItems] = useState(true);

  async function getAPI() {
    setIsLoading(true)
    let result = await apiHelper.getRequest(`series/series-list?offset=${page}`)
    if (result.code === DEVELOPMENT_CONFIG.statusCode) {
      const newSeries = result?.data;
      if (page === 1) {
        setSeriesList(result?.data);
      } else {
        setSeriesList((prevSeries) => [...prevSeries, ...newSeries]);
      }
      setHasMoreItems(newSeries.data.length === 12);
      setIsLoading(false)
    }
    else if (result.code == 401) {
      let refreshToken = localStorage.getItem("refresh-token")
      let data = {
        "refresh_token": refreshToken,
        "scope": "refresh_token"
      }
      let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
      if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
        localStorage.setItem("token", refreshTokenResult.data.token)
        localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
        setRefresh(true)
      }
    }
  }
  async function getFeatureAPI() {
    setIsLoading(true)
    let result = await apiHelper.getRequest("series/series-list?channel_id=0")
    if (result.code === DEVELOPMENT_CONFIG.statusCode) {
      setIsLoading(false)
      return setFeaturedList(result?.data)
    }
    else if (result.code == 401) {
      let refreshToken = localStorage.getItem("refresh-token")
      let data = {
        "refresh_token": refreshToken,
        "scope": "refresh_token"
      }
      let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
      if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
        localStorage.setItem("token", refreshTokenResult.data.token)
        localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
        setRefresh(true)
      }
    }
  }

  const handleLoadMore = () => {
    setScroll(true)
    setPage(prevPage => prevPage + 1);
  };

  useEffect(() => {
    getAPI()
    getFeatureAPI()
  }, [refresh, page])

  return (
    <div className="main-inner-content">
      {
        isLoading && <ReactLoader />
      }
      {
        featuredList?.data?.length > 0 ? <>
          {/* <Featured featuredList={featuredList} getAPI={getAPI} /> */}
          <FeatureSlider featuredList={featuredList} />
          <SeriesList featuredList={seriesList} getAPI={getAPI} scroll={scroll} handleLoadMore={handleLoadMore} hasMoreItems={hasMoreItems} setScroll={setScroll} />
        </>
          :
          <>
            {!isLoading && <><div className="chanel-header">
              <h3 className="fw-600 mb-0 mx-5 my-3">Series</h3>
            </div>
              <div style={{ flexDirection: 'column' }} className='no-search-results'>
                <img src={DEMO_IMAGE_FOUR} alt="No Challenges" />
                <h6 className='position-absolute' style={{ top: 440 }}>No series yet</h6>                        </div></>}
          </>

      }

    </div>
  )
}

export default UserSeries;