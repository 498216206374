import React from 'react';
import { pinkLeftIcons, pinkRightIcons } from '../../../../Constants/images';

const PrevArrow = (props) => {
    const { onClick } = props;
    return (
        <button className="custom-prev-arrow" onClick={onClick}>
            <img src={pinkLeftIcons} alt="Previous" />
        </button>
    );
};

const NextArrow = (props) => {
    const { onClick } = props;
    return (
        <button className="custom-next-arrow" onClick={onClick}>
            <img src={pinkRightIcons} alt="Next" />
        </button>
    );
};

export { PrevArrow, NextArrow };
