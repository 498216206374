import React, { useEffect, useState } from "react";
import { modalCross, dummy, crossthemebgpink, selectImage, profile_image_placeholder } from "../../Constants/images";
import uploadToS3 from "../component/fileUpload";
import ProgressBarComponent from "../../ControlComponents/progressBar";
import ERR_MESSAGE from "../../Helper/error-helper";
import apiHelper from "../../Helper/api-helper";
import DEVELOPMENT_CONFIG from "../../Helper/config"
import { NotificationManager } from "react-notifications";
const PostModal = ({ setPostModal, userDetail, setIsLoading, id, getPostAPI }) => {
    const [thumbnailFileUrl, setThumbnailFileUrl] = useState([]);
    const [description, setDescription] = useState("");
    const [progressLoading, setProgressLoading] = useState(false);
    const [progress, setProgress] = useState();
    const [errors, setErrors] = useState("");
    const [refresh, setRefresh] = useState(false)

    async function getDetailAPI() {
        setIsLoading(true)
        let result = await apiHelper.getRequest(`post/post-details?post_id=${id}`)
        if (result?.code == DEVELOPMENT_CONFIG?.statusCode) {
            setIsLoading(false)
            setDescription(result.data?.description)
            setThumbnailFileUrl(result?.data?.post_details)
        }
        else if (result.code == 401) {
            let refreshToken = localStorage.getItem("refresh-token")
            let data = {
                "refresh_token": refreshToken,
                "scope": "refresh_token"
            }
            let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
            if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
                localStorage.setItem("token", refreshTokenResult.data.token)
                localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
                setRefresh(true)
            }
        }
    }

    useEffect(() => {
        if (id != undefined) {
            getDetailAPI()
        }
    }, [refresh])

    const fileChange = (e) => {
        setErrors("");
        if (e.target.files.length === 0) {
            return;
        }
        const file = e.target.files[0];
        console.log(file, "file");
        if (file) {
            const fileType = file.type;
            if (fileType.startsWith('image/')) {
                if (fileType === 'image/jpeg' || fileType === 'image/png' || fileType === 'image/jpg') {
                    const formData = new FormData();
                    formData.append("image", file);
                    fileUpload(file, null, 1);
                }
            } else if (fileType.startsWith('video/')) {
                const fileType = file.type.split('/')[0];
                if (fileType === 'video') {
                    const files = e.target.files;
                    const videoElement = document.createElement('video');
                    videoElement.preload = 'metadata';
                    videoElement.addEventListener('loadedmetadata', async () => {
                        const durationInSeconds = videoElement.duration;
                        const { thumbnailURL, fileThumbnail } = await generateThumbnailFromVideo(videoElement, durationInSeconds / 2, files);
                        fileUpload(file, fileThumbnail, 2);
                    });
                    videoElement.src = URL.createObjectURL(file);
                }
            }
        }
    };

    const fileUpload = async (file, thumb, mediaType) => {
        const state = { videoLocation: null, thumbnailLocation: null };
        setProgressLoading(true);
        try {
            if (file) {
                await uploadToS3(thumb, file, state, setProgress);
                if (state.videoLocation || state.thumbnailLocation) {
                    setProgressLoading(false);
                    setThumbnailFileUrl(prevState => [
                        ...prevState,
                        {
                            media_url: state.videoLocation || URL.createObjectURL(file),
                            media_thumbnail: state.thumbnailLocation || thumb,
                            media_type: mediaType
                        }
                    ]);
                }
            }
        } catch (error) {
            console.error("File upload error:", error);
        }
    };

    const generateThumbnail = async (videoElement, files) => {
        const canvas = document.createElement('canvas');
        const videoWidth = videoElement.videoWidth;
        const videoHeight = videoElement.videoHeight;
        canvas.width = videoWidth;
        canvas.height = videoHeight;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(videoElement, 0, 0, videoWidth, videoHeight);
        const thumbnailURL = canvas.toDataURL('image/jpeg');
        const fileThumbnail = dataURLtoFile(thumbnailURL, files[0].name.split(".")[0]);
        return { thumbnailURL, fileThumbnail };
    };

    const generateThumbnailFromVideo = async (videoElement, timeInSeconds, files) => {
        return new Promise((resolve) => {
            const seekTime = Math.min(timeInSeconds, videoElement.duration - 0.1);
            videoElement.currentTime = seekTime;
            videoElement.addEventListener('seeked', async () => {
                const { thumbnailURL, fileThumbnail } = await generateThumbnail(videoElement, files);
                resolve({ thumbnailURL, fileThumbnail });
            });
        });
    };

    function dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    }

    const handleValidation = () => {
        let errors = {};
        let formIsValid = true;
        if (!description) {
            formIsValid = false;
            errors["description"] = ERR_MESSAGE.ADD_DESCRIPTION
        }
        if (thumbnailFileUrl.length == 0) {
            formIsValid = false;
            errors["thumbnailFileUrl"] = ERR_MESSAGE.SELECT_AUDIO_VIDEO
        }
        setErrors(errors);
        return formIsValid;
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (handleValidation()) {
            setIsLoading(true)
            let data = {
                "description": description,
                "post_media": thumbnailFileUrl
            }
            try {
                const result = await apiHelper.postRequest("post/create-post", data);
                if (result.code == DEVELOPMENT_CONFIG.statusCode) {
                    setIsLoading(false)
                    NotificationManager.success(result.message)
                    setPostModal(false);
                    getPostAPI()

                }
            } catch (error) {
                setIsLoading(false)
            }
        }
    }

    const handleEdit = async (e) => {
        e.preventDefault()
        if (handleValidation()) {
            setIsLoading(true)
            let data = {
                "post_id": id,
                "description": description,
                "post_media": thumbnailFileUrl
            }
            try {
                const result = await apiHelper.postRequest("post/edit-post", data);
                if (result.code == DEVELOPMENT_CONFIG.statusCode) {
                    setIsLoading(false)
                    NotificationManager.success(result.message)
                    setPostModal(false);
                    getPostAPI()

                }
            } catch (error) {
                setIsLoading(false)
            }
        }
    }
    return (

        <div
            className="modal fade show modal-cast"
            style={{ display: "block", opacity: "1", backdropFilter: "blur(5px)" }}
        >
            {progressLoading && <>
                <ProgressBarComponent progress={progress} />
            </>}
            <div className="loader"></div>
            <div className="modal-dialog modal-dialog-centered modal-schedule-width justify-content-center">
                <div className="modal-content modal-radius border-0">
                    <div className="modal-header forgot-header align-items-center pt-4">
                        <h3 className="modal-title text-center mx-auto forgot-title" id="exampleModalLabel">
                            {id == undefined ? "Create Post" : "Edit Post"}
                        </h3>
                        <a className="m-0 px-0 btn-closed-main" data-bs-dismiss="modal" aria-label="Close">
                            <img
                                style={{ cursor: "pointer" }}
                                onClick={() => setPostModal(false)}
                                src={modalCross}
                                alt=""
                                className="img-fluid btn-cloesed"
                            />
                        </a>
                    </div>
                    <div className="modal-body verify-modal-box px-2 px-sm-4">
                        <div className="feed-user px-0">
                            <a href="" className="text-decoration-none text-black">
                                <img src={userDetail.profile_pic ? userDetail.profile_pic: profile_image_placeholder} className="userimg" />
                            </a>
                            <div className="">
                                <a href="" className="text-decoration-none text-black">
                                    <h4 className="fw-600 mb-2 lh-1">{userDetail.name}</h4>
                                </a>
                            </div>
                        </div>
                        <form className="my-2">
                            <textarea
                                className="form-control create-post-textarea"
                                rows={7}
                                placeholder="What's on your mind?"
                                value={description}
                                onChange={(e) => {
                                    setErrors("")
                                    setDescription(e.target.value)
                                }}
                            ></textarea>
                            {errors.description && <span className="err_msg">{errors.description}</span>}
                            <div className="d-flex my-4">
                                <div className="mx-2 position-relative">
                                    <span className="py-5 px-4 create-post-select-span">
                                        <img src={selectImage} className="create-post-select-img" />
                                    </span>
                                    <input type="file" className="create-input" onChange={fileChange} style={{ cursor: "pointer" }} />
                                </div>
                                <div className="d-flex justify-content-between overflow-scroll">
                                    {thumbnailFileUrl.map((item, index) => (
                                        <div key={index} className="mx-2 position-relative">
                                            <img src={item.media_type == 2 ? item.media_thumbnail : item.media_url} className="create-post-img" />
                                            <img
                                                src={crossthemebgpink}
                                                className="create-post-cross-btn"
                                                onClick={() => setThumbnailFileUrl(prevState =>
                                                    prevState.filter((_, i) => i !== index)
                                                )}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                            {errors.thumbnailFileUrl && <span className="err_msg">{errors.thumbnailFileUrl}</span>}
                            {
                                id == undefined ?
                                    <div className="px-4 py-3">
                                        <button className="common-btn w-100" onClick={(e) => {
                                            handleSubmit(e)
                                        }}>
                                            Post
                                        </button>
                                    </div> :
                                    <div className="px-4 py-3">
                                        <button className="common-btn w-100" onClick={(e) => {
                                            handleEdit(e)
                                        }}>
                                            Save
                                        </button>
                                    </div>
                            }

                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PostModal;
