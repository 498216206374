import React, { useState } from 'react'
import { dummy, embed, line, linkVideo, plusgray, profile_image_placeholder, series, video, videoPlayButton } from './../../../../Constants/images'
import './challengesDetail.css'
import { useLocation, useNavigate } from 'react-router-dom';
import DEVELOPMENT_CONFIG from '../../../../Helper/config'
import ReactLoader from '../../../../ControlComponents/react-loader';
import apiHelper from '../../../../Helper/api-helper';
import { NotificationManager } from 'react-notifications';
import uploadToS3 from '../../../../CreaterDashboard/component/fileUpload';
import ERR_MESSAGE from "../../../../../src/Helper/error-helper"
import ProgressBarComponent from '../../../../ControlComponents/progressBar';
import ROUTES from '../../../../Helper/route-helper';
import ReactPlayer from 'react-player';

function ChallengeCreate() {
    const [embedDuration, setEmbedDuration] = useState(0)
    const [progressLoading, setProgressLoading] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const location = useLocation()
    const [challengeData, setChallengeData] = useState(location?.state?.challenegeData)
    const [link, setLink] = useState("")
    const navigate = useNavigate()
    const [embedCode, setEmbedCode] = useState("")
    const [errorMessage, setErrorMessage] = useState('');
    const [video1, setVideo1] = useState()
    const [video2, setVideo2] = useState()
    const [thumbnail1, setThumbnail1] = useState()
    const [thumbnail2, setThumbnail2] = useState(null)
    const [thumbnail, setThumbnail] = useState()
    const [duration, setDuration] = useState()
    const [progress, setProgress] = useState(0);
    const [orientation, setOrientation] = useState('')

    const handleEmbedCodeChange = (e) => {
        setErrorMessage("")
        setEmbedCode(e.target.value);
        if (e.target.value) {
            extractVideoAndThumbnailFromEmbedCode(e.target.value)
        }
        setOrientation("Portrait")
    };

    const extractVideoAndThumbnailFromEmbedCode = async (embedCode) => {
        try {
            const urlRegex = /https?:\/\/[^\s]+/i;
            const urlMatch = embedCode.match(urlRegex);
            if (urlMatch && urlMatch[0]) {
                let videoUrl = urlMatch[0];
                videoUrl = videoUrl.replace(/"/g, '');
                setVideo1(embedCode)
                let thumbnailUrl;
                if (videoUrl.includes('youtube.com')) {
                    const videoIdMatch = videoUrl.match(/(?:embed\/|v=)([^"&?\/\s]{11})/);
                    if (videoIdMatch && videoIdMatch[1]) {
                        const videoId = videoIdMatch[1];
                        thumbnailUrl = `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
                        setThumbnail1(thumbnailUrl)
                    } else {
                        return { videoUrl, thumbnailUrl: null };
                    }
                } else if (videoUrl.includes('vimeo.com')) {
                    let oEmbedUrl = 'https://vimeo.com/api/oembed.json?url=' + encodeURIComponent(videoUrl);
                    const response = await fetch(oEmbedUrl);
                    if (response.ok) {
                        const data = await response.json();
                        if (data.thumbnail_url) {
                            const thumbnailUrl = data.thumbnail_url;
                            setThumbnail1(thumbnailUrl)
                            setVideo1(embedCode)
                            return { videoUrl, thumbnailUrl };
                        } else {
                            return { videoUrl, thumbnailUrl: null };
                        }
                    } else {
                        return { videoUrl, thumbnailUrl: null };
                    }
                } else {
                    return { videoUrl, thumbnailUrl: null };
                }
            }
        } catch (error) {
        }
        return { videoUrl: null, thumbnailUrl: null };
    };
    const handleLinkChange = async (e) => {
        setErrorMessage("");
        const link = e.target.value;
        setLink(link);
        const videoExtensions = /\.(mp4|mov|avi|wmv|flv|mkv|webm)$/i;

        if (link && videoExtensions.test(link)) {
            try {
                setIsLoading(true);
                const response = await fetch(link);
                const blob = await response.blob();
                const fileName = link.substring(link.lastIndexOf('/') + 1);
                const file = new File([blob], fileName, { type: blob.type });
                const videoElement = document.createElement('video');
                videoElement.preload = 'metadata';
                videoElement.src = URL.createObjectURL(file);

                videoElement.onloadedmetadata = async () => {
                    const videoWidth = videoElement.videoWidth;
                    const videoHeight = videoElement.videoHeight;

                    const durationInSeconds = videoElement.duration;
                    const formattedDuration = formatDuration(durationInSeconds);

                    const { thumbnailURL, fileThumbnail } = await generateThumbnailFromVideo(videoElement, durationInSeconds / 2, file);
                    fileUploadLink(fileThumbnail, formattedDuration);

                    // Check the video orientation
                    const orientation = videoWidth > videoHeight ? 'Landscape' : 'Portrait';
                    setOrientation(orientation)
                    // You can set this orientation to the state if needed
                    // setVideoOrientation(orientation); // Make sure to define setVideoOrientation in your state

                    URL.revokeObjectURL(videoElement.src); // Free up memory
                };
            } catch (error) {
                setErrorMessage("Error processing the video");
            } finally {
                setIsLoading(false);
            }
        } else if (link && !videoExtensions.test(link)) {
            setErrorMessage("Invalid video link");
        }
    };



    const fileChange1 = async (e) => {
        setErrorMessage("");
        if (e.target.files.length === 0) {
          return;
        }
        const file = e.target.files[0];
        if (file) {
          const fileType = file.type.split('/')[0];
          if (fileType === 'video') {
            const videoElement = document.createElement('video');
            videoElement.preload = 'metadata';
    
            videoElement.addEventListener('loadedmetadata', async () => {
              const videoWidth = videoElement.videoWidth;
              const videoHeight = videoElement.videoHeight;
    
              const durationInSeconds = videoElement.duration;
              const formattedDuration = formatDuration(durationInSeconds);
    
              const { thumbnailURL, fileThumbnail } = await generateThumbnailFromVideo(videoElement, durationInSeconds / 2, file);
    
              const formData = new FormData();
              formData.append('mediaFile', file);
    
              const videoURL = URL.createObjectURL(file);
    
              // Check the video orientation
              const orientation = videoWidth > videoHeight ? 'Landscape' : 'Portrait';
              setOrientation(orientation)
              // You can set this orientation to the state if needed
              // setVideoOrientation(orientation); // Make sure to define setVideoOrientation in your state
    
              fileUpload1(fileThumbnail, file, formattedDuration);
    
              // Free up memory
              URL.revokeObjectURL(videoElement.src);
            });
    
            videoElement.src = URL.createObjectURL(file);
          }
        }
      };

    const formatDuration = (durationInSeconds) => {
        const hours = Math.floor(durationInSeconds / 3600);
        const minutes = Math.floor((durationInSeconds % 3600) / 60);
        const seconds = Math.floor(durationInSeconds % 60);
        const hoursStr = hours < 10 ? `0${hours}` : `${hours}`;
        const minutesStr = minutes < 10 ? `0${minutes}` : `${minutes}`;
        const secondsStr = seconds < 10 ? `0${seconds}` : `${seconds}`;
        return `${hoursStr}:${minutesStr}:${secondsStr}`;
    };


    const generateThumbnail = async (videoElement, files) => {
        const canvas = document.createElement('canvas');
        const videoWidth = videoElement.videoWidth;
        const videoHeight = videoElement.videoHeight;
        canvas.width = videoWidth;
        canvas.height = videoHeight;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(videoElement, 0, 0, videoWidth, videoHeight);
        const thumbnailURL = canvas.toDataURL('image/jpeg');
        const fileThumbnail = dataURLtoFile(thumbnailURL, files?.name?.split(".")[0]);
        return { thumbnailURL, fileThumbnail };
    };

    const generateThumbnailFromVideo = async (videoElement, timeInSeconds, files) => {
        return new Promise((resolve) => {
            const seekTime = Math.min(timeInSeconds, videoElement.duration - 0.1);
            videoElement.currentTime = seekTime;
            videoElement.addEventListener('seeked', async () => {
                const { thumbnailURL, fileThumbnail } = await generateThumbnail(videoElement, files);
                resolve({ thumbnailURL, fileThumbnail });
            });
        });
    };

    const fileUpload1 = async (thumb, file, formattedDuration) => {
        const state = { videoLocation: null, thumbnailLocation: null };
        setProgressLoading(true)
        try {
            if (file || thumb) {
                await uploadToS3(thumb, file, state,
                    setProgress
                );
                if (state.videoLocation || state.thumbnailLocation) {
                    setProgressLoading(false)
                    setThumbnail2(state.thumbnailLocation)
                    setVideo2(state.videoLocation)
                    setDuration(formattedDuration)
                }
            }
        }
        catch (error) {
        }
    }

    const fileUploadLink = async (thumb, formattedDuration, file) => {
        const state = { videoLocation: null, thumbnailLocation: null };
        try {
            if (thumb) {
                await uploadToS3(thumb, file, state,
                    setProgress
                );
                if (state.thumbnailLocation) {
                    setIsLoading(false)
                    setThumbnail(state.thumbnailLocation)
                    setDuration(formattedDuration)
                }
            }
        }
        catch (error) {
        }
    }

    function dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    }
    const formatDurationEmbed = (seconds) => {
        const h = Math.floor(seconds / 3600);
        const m = Math.floor((seconds % 3600) / 60);
        const s = Math.floor(seconds % 60);
        return [h, m, s]
            .map(v => (v < 10 ? `0${v}` : v))
            .join(':');
    };

    const handleValidation2 = () => {
        if (!(link?.length > 0 || embedCode?.length > 0 || thumbnail2?.length > 0)) {
            setErrorMessage(ERR_MESSAGE.ENTER_OPTION);
            return false;
        }
        return true;
    };
    const extractVideoIdEmbedCode = (embedCode) => {
        try {
            const urlRegex = /https?:\/\/[^\s]+/i;
            const urlMatch = embedCode.match(urlRegex);
            if (urlMatch && urlMatch[0]) {
                const videoUrl = urlMatch[0].replace(/"/g, '');
                const regex = /\/embed\/([^?]+)/;
                const match = videoUrl.match(regex);
                if (match && match[1]) {
                    return match[1];
                } else {
                    return null;
                }
            } else {
                return null;
            }
        } catch (error) {
            return null;
        }
    };

    async function handleSubmit(e) {
        e.preventDefault();
        if (handleValidation2()) {
            let videoType;
            let videoUrl;
            let thumbnailUrl;
            if (link) {
                videoType = 1;
                videoUrl = link
                thumbnailUrl = thumbnail
            } else if (embedCode) {
                videoType = 2;
                videoUrl = embedCode
                thumbnailUrl = thumbnail1
            } else if (video2) {
                videoType = 3;
                videoUrl = video2
                thumbnailUrl = thumbnail2
            }
            let data = {
                "video_type": videoType,
                "challenge_id": challengeData.id,
                "video_url": videoUrl,
                "thumbnail": thumbnailUrl,
                "duration": videoType == 2 ? embedDuration : duration,
                "video_id": extractVideoIdEmbedCode(embedCode),
                "orientation": orientation


            }
            setIsLoading(true);
            let result = await apiHelper.postService("challenges/upload-challenge", data);
            if (result.code === DEVELOPMENT_CONFIG.statusCode) {
                setIsLoading(false);
                localStorage.setItem("message", result.message)
                navigate(ROUTES.CHALLENGES_DETAILS)
                setLink("")
                setEmbedCode("")

            } else {
                setIsLoading(false);
                NotificationManager.error(result.message);
            }
        }
    }

    return (
        <div>
            <div className='loader'>
                {isLoading && <ReactLoader />}
            </div>
            {progressLoading && <>
                <ProgressBarComponent progress={progress} />
            </>}
            <div className="main-inner-content">
                <section className="challenge-upload-custom">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 pt-3  pb-0">
                                <h3 className="fw-600">Create Challenge Response</h3>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-12 text-center">
                                <div className="challenge-title">
                                    <img src={challengeData.channelData.profile_pic ? challengeData.channelData.profile_pic : profile_image_placeholder} alt="" className="img-fluid challenge-img" />
                                </div>
                            </div>
                        </div>
                        <div className='challenges row  justify-content-center'>
                            <div className="col-12  text-center py-5 px-2 mt-4">
                                <div className="challenge-head col-md-4 bg-white mx-auto py-4 border20">
                                    <h3 className="fw-600">{challengeData?.title}</h3>
                                    <div className=" challenge-preview mb-3"><img src={challengeData?.thumbnail}></img></div>
                                    <h3 className="fw-600">{challengeData.channelData.name}</h3>
                                    <p className="py-2 mb-0">challenges  you to create and record your fan art based<br />on their series ‘Creed’</p>
                                </div>
                            </div>
                            <div className="col-12 col-lg-4">
                                <form>
                                    <div className='row pt-3'>
                                        <div className='col-3 col-sm-2'>
                                            <div className='link-img'>
                                                <img src={linkVideo}></img>
                                            </div>
                                        </div>
                                        <div className='col-9 col-sm-10 ps-0 embed-main-input'>
                                            <div className='link-input'>
                                                <input type='text' value={link} onChange={handleLinkChange} name="link" className='border-edit input-bg  w-100 border-0' placeholder='Link paste here' disabled={embedCode || thumbnail2 !== null}
                                                ></input>
                                            </div>
                                            <p className="or-bg mt-3 mb-2 d-flex justify-content-center" style={{ backgroundImage: `url(${line})` }}><span>OR</span></p>
                                        </div>
                                    </div>
                                    <div className='row mt-3'>
                                        <div className='col-3 col-sm-2'>
                                            <div className='link-img'>
                                                <img src={embed}></img>
                                            </div>
                                        </div>
                                        <div className='col-9 col-sm-10 ps-0 embed-main-input'>
                                            <div className='link-input embed-input'>
                                                <textarea value={embedCode} onChange={handleEmbedCodeChange} name="embedCode" className='border-edit input-bg  w-100 border-0' placeholder='Embed code'
                                                    disabled={link || thumbnail2 !== null}
                                                ></textarea>
                                            </div>
                                            <p className="or-bg mt-3 mb-2 d-flex justify-content-center" style={{ backgroundImage: `url(${line})` }}><span>OR</span></p>

                                        </div>
                                    </div>
                                    <div className='mt-3'>
                                        <div className="mb-3 schedule-thumb">
                                            <div className="video-upload video-schedule position-relative">
                                                {
                                                    thumbnail2 && <div className={thumbnail2 ? " challenge-preview" : ""}><img src={thumbnail2 ? thumbnail2 : ""}></img>
                                                        <div class="play-bite"><img src={videoPlayButton} class="img-fluid img-bite" />
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    thumbnail2 ? "" : <div className='d-flex m-auto justify-content-center align-items-center h-100 challenge-preview-after'>
                                                        <div className=''>
                                                            <div className="d-flex m-auto justify-content-center align-items-center h-100">
                                                                <img src={plusgray} alt="" className="img-fluid me-1" />
                                                                <span className="Upload-text fw-500  create-text ">Upload Video</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                <input type="file" className="video-file-schedule" style={{ cursor: "pointer" }} onChange={fileChange1} accept=".mp4, .mov, .avi" disabled={link || embedCode}></input>
                                                {errorMessage && <span className="err_msg">{errorMessage}</span>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="pt-5 mb-5">
                                        <input type='button' value="Submit" onClick={handleSubmit} className="common-btn w-100" />
                                    </div>
                                    {
                                        embedCode && <ReactPlayer
                                            url={embedCode.match(/src="([^"]+)"/) ? embedCode.match(/src="([^"]+)"/)[1] : ""}
                                            controls={false}
                                            playing={false}
                                            onDuration={(duration) => {
                                                const formatDuration = formatDurationEmbed(duration)
                                                setEmbedDuration(formatDuration);

                                            }}
                                            width="0%"
                                            style={{ position: 'absolute', top: 0, left: 0, opacity: 0 }}
                                            height="0%"
                                        />
                                    }
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default ChallengeCreate;