import React, { useEffect, useState } from 'react';
import { mv, playLight, playLightBtn, topVideoThumbnail } from '../../Constants/images';
import './interactivePlay.css';
import { useLocation } from 'react-router-dom';
import apiHelper from '../../Helper/api-helper';
import DEVELOPMENT_CONFIG from '../../Helper/config';
import VideoPlayer from '../../Components/VideoPlayer';
import ReactLoader from '../../ControlComponents/react-loader';


function InteractivePlay() {
    const [interactiveDetail, setInteractiveDetail] = useState()
    const [refresh, setRefresh] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const [showVideoPlayer, setShowVideoPlayer] = useState(false)


    const location = useLocation()

    async function getAPI() {
        setIsLoading(true)
        let result = await apiHelper.getRequest("interactive/interactive-detail?id=" + location?.state?.id)
        if (result.code === DEVELOPMENT_CONFIG.statusCode) {
            setInteractiveDetail(result.data)
            setIsLoading(false)
        } else if (result.code == 401) {
            let refreshToken = localStorage.getItem("refresh-token")
            let data = {
                "refresh_token": refreshToken,
                "scope": "refresh_token"
            }
            let refreshTokenResult = await apiHelper.postRequest("auth/refresh-token", data)
            if (refreshTokenResult.code == DEVELOPMENT_CONFIG.statusCode) {
                localStorage.setItem("token", refreshTokenResult.data.token)
                localStorage.setItem("refresh-token", refreshTokenResult.data.refresh_token)
                setRefresh(true)
            }
        }
    }

    useEffect(() => {
        getAPI()
    }, [refresh]);
    return (
        <div className="main-inner-content">
            {isLoading && <ReactLoader />}
            <div>
                <section className="frame-video-banner">
                    <div className="container position-relative">


                        <div className="frame-video-img">
                            <div style={{ position: 'relative', borderWidth: 1, borderColor: 'red' }}>
                                <img src={interactiveDetail?.thumbnail} className="img-fluid thumb-interact-img" />
                              
                            </div>

                        </div>
                        <div className="movie-wrapper">
                        <div className="row d-flex flex-wrap justify-content-between">

                            <div className="row d-flex flex-wrap gap-3 col-lg-10 col-md-9">
                                <div className="col-lg-2 bgwhite">
                                    <div className="d-flex align-items-center gap-4">
                                        <div className="mvie-title">
                                            <img className='img-new' src={interactiveDetail?.channel?.logo_url} />
                                        </div>
                                        <h4 className="m-0">{interactiveDetail?.channel?.name}</h4>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-1 col-md-2  col-12'>
                            <button className='bg-pink-play w-100'>
                                    <img onClick={() => setShowVideoPlayer(!showVideoPlayer)} data-bs-toggle="modal" data-bs-target="#video-modal" style={{ width: 30,  objectFit: 'contain' }} src={playLightBtn} /> Play
                                </button>
</div>
                            </div>

                        </div>
                    </div>
                    <VideoPlayer setShowVideoPlayer={setShowVideoPlayer} showVideoPlayer={setShowVideoPlayer} url={interactiveDetail?.video} />
                </section>
                <section className="plot-summary py-60">
                    <div className="container">
                        <h2>{interactiveDetail?.title}</h2>
                        <p className="mt-2">{interactiveDetail?.description}
                        </p>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default InteractivePlay;